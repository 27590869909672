import {
    Box,
    Container,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    makeStyles,
  } from "@material-ui/core";
  import heroImage from "../../assets/images/blog/hero_search_background.jpg";
  import SearchInput from "../../components/shared/inputs/SearchInput";
  import React, { useState } from "react";
  import { ExportIcon, OutlinedEditIcon } from "../../components/icons";
  import {
    BlogTab,
    BlogTabs,
    ProfTab,
    ProfTabs,
  } from "../../components/profile/profile-details/TabsDetailsblog";
  import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
  import { Link } from "react-router-dom";
  import {
    BackBtn,
    BackBtn1,
  } from "../../components/shared/buttons/custom-buttons/CustomButtons";
  
  const useStyles = makeStyles({
    root: {
      minHeight: 'calc(180vh - 200px)',
      //minHeight: "calc(170vh - 380px)",
      marginBottom: 100,
    },
    heroSection: {
      backgroundImage: `url(${heroImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: 250,
    },
    overlay: {
      height: "100%",
      background:
        "linear-gradient(90deg,rgba(0,38,100,0.95) 0%,rgba(0,38,100,0.85) 50%,rgba(0,38,100,0.75) 100%)",
    },
    container: {
      paddingTop: 185,
      justifyContent: "center",
      //marginLeft:800,
    },
    title: {
      color: "#fff",
      marginBottom: -8,
      marginTop: -60,
      justifyContent: "center",
    },
    leftSide: {
      width: "50%",
      maxWidth: 460,
      justifyContent: "center",
      marginRight: 25,
    },
    rightSide: {
      width: "50%",
      maxWidth: 650,
      justifyContent: "center",
      marginLeft: 25,
    },
  
    subTitle: {
      fontSize: 20,
      lineHeight: "24px",
      color: "#fff",
      justifyContent: "center",
      padding: "0px 0",
      marginTop: -20,
      marginBottom: 10,
      marginLeft:13,
    },
    grid: {
      display: "flex",
      justifyContent: "center",
      marginLeft: 580,
      width: 700,
      position: "absolute",
      marginTop: -830,
      //marginBottom:-30,
    },
    grid1: {
      display: "flex",
      justifyContent: "center",
      marginLeft: -30,
      width: 700,
    },
    alert: {
      borderLeft: "6px solid #123b64",
      background: "#f4f4f7",
      padding: "24px 24px 24px 16px",
    },
    title2: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: 1.25,
    },
  });
  
  function BlogPage() {
    const classes = useStyles();
    const [searchValue, setSearchValue] = useState("");
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleChange = (e) => {
      setSearchValue(e.target.value);
    };
    const [value, setValue] = React.useState(0);
  
    const handleChange1 = (event, newValue) => {
      setValue(newValue);
    };
  
    const setAttributes = (index) => {
      return {
        id: `nav-tab-${index}`,
        "aria-controls": `nav-tabpanel-${index}`,
      };
    };
  
    return (
      <Box className={classes.root}>
        <Box className={classes.heroSection}>
          <Box className={classes.overlay}>
          <Container className={classes.container}>
             
             <Typography className={classes.subTitle}>
               An open portal to 15168 datasets throughout Datasouk
             </Typography>
             <div
               style={{
                 margin: "1px solid #1f20201a",
                 border: "1px solid #f79f19",
               }}
             ></div>
  
             <BlogTabs
               value={value}
               onChange={handleChange1}
               aria-label="ant example"
             >
                <BlogTab label="Data Policy" {...setAttributes(1)} />
                <BlogTab label="Sharing Data" {...setAttributes(2)} />
                <BlogTab label="Using Data" {...setAttributes(3)} />
                <BlogTab label="Our work" {...setAttributes(4)} />
                <BlogTab label="About" {...setAttributes(5)} />
                <BlogTab label="Contact" {...setAttributes(6)} />
             </BlogTabs>
          
          {/* <Breadcrumbs style={{marginLeft:-100,}}/> */}
  
          <Grid className={classes.grid1} item xs={12} sm={6}>
            <Box className={classes.signInWrapper}>
              <Typography
                variant="h1"
                color="primary"
                style={{ marginTop: 30, justifyContent: "center" }}
              >
                <Link
                  to="/blog"
                  style={{ textDecoration: "none", color: "#123b64" }}
                >
                  Data Policy{" "}
                </Link>
              </Typography>
              <div
                style={{ border: "1px solid #123b64", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
  
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Key Datasouk Data Legislation and Policies
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Datasouk Data Strategy
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{
                  marginTop: 15,
                  marginLeft: 20,
                  "&:hover": {
                    color: "#abd",
                    cursor: "pointer",
                  },
                }}
              >
                Datasouk Data Glossary
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Smart Places Data Protection Policy
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Information Management Framework
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Data Custodianship Policy
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Open Data Policy
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Data Governance Toolkit
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Infrastructure Data Management Framework
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
              >
                Data Quality Reporting Tool
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
              <Typography
                variant="h3"
                color="primary"
                style={{
                  marginTop: 15,
                  marginLeft: 20,
                  width: 500,
                  cursor: "pointer",
                }}
              >
                Datasouk Information Classification,
                <span>Labelling and Handing Guideliness</span>
              </Typography>
              <div
                style={{ border: "1px solid #adb5bd", marginTop: 20, width: 500 }}
              >
                {" "}
              </div>
            </Box>
          </Grid>
         
          <Grid className={classes.grid} item xs={12} sm={6}>
            <Box className={classes.signInWrapper}>
              <Typography
                variant="h1"
                color="primary"
                style={{ marginTop: -50, justifyContent: "center" }}
              >
                Smart Places Data Protection Policy
              </Typography>
              <Typography
                variant="h4"
                //color='primary'
              >
                The Datasouk Smart Places Data Protection Policy brings the key
                guidance on data protection in the smart places context together
                in one place. It aligns with the Datasouk Government Data Strategy
                which includes the central theme of strengthening transparency and
                trust in the way Datasouk government collects, manages, uses and
                shares data, ensuring this is in accordance with the highest
                privacy, security and ethical standards. The development of a
                Smart Places Data Protection Policy is identified as an action in
                the Datasouk Smart Places Strategy.
              </Typography>
              <BackBtn1
                color="primary"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                Download the Policy here
              </BackBtn1>
              {/* <Box className={classes.alert}>
                  <Typography className={classes.title2}>
                  Have your say
                  </Typography>
                  <Typography style={{marginTop: 12}}>
                  You can visit us at <Link to="https://datasouk.ai/" target='_blank'>https://datasouk.ai/</Link>
                  We welcome all feedback and discussions on these issues.
                   You can email us at
                    :info@datasouk.ai
                  </Typography>
  
              </Box> */}
              <Typography
                variant="h1"
                color="primary"
                style={{ marginTop: 10, justifyContent: "center" }}
              >
                Who is this policy for?
              </Typography>
              <Typography>
                The Policy has been designed to make it easier for public and
                private sector smart places developers, managers and place owners
                to follow lawful and best practice data protection across the
                lifecycle of smart places data. The Policy highlights the need to
                build data protection into smart places project design, and
                actively manage smart places data from collection to disposal.
                This Policy is also designed to support the Datasouk Smart Places
                Customer Charter and help members of the public understand what it
                means for Datasouk Government to commit to treating data safely
                and securely and protecting personal information.
              </Typography>
              <Typography
                variant="h2"
                color="primary"
                style={{ marginTop: 10, justifyContent: "center" }}
              >
                The purpose of the policy
              </Typography>
              <Typography>
                The purpose of the Smart Places Data Protection Policy is to
                support the Privacy and Personal Information Protection Act 1998
                (PPIP Act), to guide how data is collected, managed and stored as
                part of smart places implementation. The PPIP Act outlines how
                Datasouk public sector agencies manage personal information.
                Personal information is defined in section 4 of the PPIP Act. In
                practical terms, personal information is any information that
                identifies an individual; it could include records with a name and
                address, photographs, video or audio footage, or fingerprints,
                blood or DNA.
              </Typography>
  
              <Typography variant="h5" style={{ marginTop: 20 }} color="Primary">
                <b style={{ fontSize: 23, marginLeft: 20 }}>Last updated </b>
                25 Jul 2023{" "}
              </Typography>
            </Box>
          </Grid>
          {/* </Container>
          </Box> */}
        {/* </Box> */}
        <div style={{ border: "1px solid #adb5bd", marginTop: 50,width:10000,marginLeft:-6000 }}></div>
        <Box style={{ marginTop: 30 }}>
          {/* <div style={{ border: "1px solid #adb5bd", marginTop: 10 }}></div> */}
          <Box style={{ marginLeft: 50 }}>
            <Typography variant="h3" style={{ marginTop: 10 }}>
              Popular
            </Typography>
            <span>About Data.Datasouk</span>
            <Typography>Key Datasouk Data Legislation and Policies</Typography>
            <span>Data.Datasouk Policy</span>
            <Typography>Data Skills</Typography>
            <span>
              Twitter <ExportIcon />
            </span>
          </Box>
          <Box style={{ marginLeft: 500, marginTop: -160 }}>
            <Typography variant="h3" style={{ marginTop: 10 }}>
              Open Data Portals
            </Typography>
            <span>API.Datasouk</span>
            <Typography>Environmental Data Portal ,SEED</Typography>
            <span>Datasouk Department of Education</span>
            <Typography>Datasouk Health</Typography>
            <span>
              Transport for Datasouk <ExportIcon />
            </span>
            <Typography>
              Datasouk Buraeu Of Crime Statistics and Research
            </Typography>
            <span>Spatial Collaboration Portal</span>
            <Typography>Datasouk Flood Portal</Typography>
            <span>ePlanning</span>
            <Typography>State Insurance Regulatory Authority</Typography>
            <span>
              Transport for Datasouk <ExportIcon />
            </span>
          </Box>
          <Box style={{ marginLeft: 1000, marginTop: -320 }}>
            <Typography variant="h3" style={{ marginTop: 10 }}>
              About This Site
            </Typography>
            <span>Accessibility</span>
            <Typography>Copyright</Typography>
            <span>Disclaimer</span>
            <Typography>Get in Touch</Typography>
            <span>
              Privacy <ExportIcon />
            </span>
            <Typography>Site Search</Typography>
          </Box>
        </Box>
        </Container>
          </Box>
          </Box>
      </Box>
    );
  }
  
  export default BlogPage;
  