import api from "../core/api";

export const SearchService = {
  searchNodes: (data) => {
    return api.post(`/api/graph/graphNodeSearch`, data);
  },
  fetchGraphSeachNodes: (nodeName) => {
    return api.get(`/api/graph/graphSearchQuery?name=${nodeName}`);
  },
  setTailNodeHistory: (key, data) => {
    return api.post(`/api/graph/getGraphTailNodes?key=${key}`, data);
  },
  setViewHistory: (nodeId) => {
    return api.get(`/api/nodes/viewHistory`, {
      params: { id: nodeId },
    });
  },
  fetchUsageData: (key) => {
    return api.get(`/usage?key=${key}`);
  },
  fetchRecentTrending: () => {
    return api.get(`/api/graph/graphRecentTrend`);
  },

  fetchElements: (id) => {
    return api.get(`/dataelements`, {
      params: { id },
    });
  },
  fetchPrivacyAndRisk: (key, typeName) => {
    return api.get(`/privacyRisk?key=${key}&type=${typeName}`, {
      // params: { key, type },
    });
  },
  fetchViewCount: (key) => {
    return api.post(`viewCount?id=${key}`);
  },
  fetchExamplesData: (data) => {
    return api.post(`api/nodes/examples`, data);
  },
  // fetchVersionData: (name) => {
  //   return api.get(`/api/nodes/variants`, {
  //     params: { name: name },
  //   });
  // },
  fetchVersionData: (name, payload) => {
    return api.post(`/api/nodes/variants?name=${name}`, payload);
  },
  fetchContextData: (key) => {
    return api.get(`/getNodeContextCategories?key=Nodes/${key}`);
  },
  // fetchMoreFromUser: (name) => {
  //   return api.get(`/moreFromUser`, {
  //     params: { userName: name },
  //   });
  // },
  fetchMoreFromUser: (payload) => {
    return api.post(`/api/nodes/moreFromUser`, payload);
  },
  fetchMorefromSourceSystem: (paylaod) => {
    return api.post(`/api/nodes/moreFromSourceSystem`, paylaod);
  },
  fetchSimilarSearchNodes: (nodeName) => {
    return api.get(`/similarity`, {
      params: { Name: nodeName },
    });
  },
  fetchfacetsData: (name) => {
    return api.get(`/facets`, {
      params: { Name: name },
    });
  },
  // fetchPopularTags: (tag) => {
  //   return api.post(`/api/nodes/popularTags`, {
  //     params: { Tag: tag },
  //   });
  // },

  fetchPopularTags: (tag, payload) => {
    return api.post(`/api/nodes/popularTags`, {
      ...payload, // Include the payload in the request
    });
  },
  fetchRelatedTags: (tag) => {
    return api.get(`/myViewNodeTags`, {
      params: { Tag: tag },
    });
  },
  fetchRefreshFrequencyOptions: () => {
    return api.get(`/getDataRefreshFrequency`);
  },
  fetchDeliveryPlatformOptions: () => {
    return api.get(`/getDeliveryPlatform`);
  },
  fetchDeliveryPreferenceOptions: () => {
    return api.get(`/getDeliverypreferences`);
  },
  fetchPriorityOptions: () => {
    return api.get(`/getPriority`);
  },
  fetchRelatedSearches: (query) => {
    return api.get(`/api/graph/myGraphRelatedSearches`, {
      params: { name: query },
    });
  },
  fetchNodeTypeOptions: () => {
    return api.get(`/nodeTypesDropDown`);
  },
  fetchTeamOptions: () => {
    return api.get(`/Teams`);
  },
  fetchRegistredUsers: () => {
    return api.get(`/getAllregisterUserDetails`);
  },
  fetchRoleOptions: () => {
    return api.get(`/Roles`);
  },
  fetchMyRecentTags: () => {
    return api.get(`/myRecentTags`);
  },
  fetchRecentSearches: () => {
    return api.get(`/api/graph/myRecentGraphSearches`);
  },
  fetchPopularTagsCount: () => {
    return api.get(`/api/nodes/popularTagsCount`);
  },
  fetchOrdersDropdownOptions: () => {
    return api.get(`/ordersDropDownValues`);
  },
  // fetchLatestOrderedData: (option, id) => {
  //   return api.get(`/yourOrdersDropDown`, {
  //     params: { input: option, userId: id },
  //   });
  // },
  fetchLatestOrderedData: (option, id) => {
    return api.get(`/yourOrdersPagedDropDown`, {
      params: { input: option, userId: id },
    });
  },
  fetchExpiredOrdersDropDown: (option, id) => {
    return api.get(`/expiredOrdersDropDown`, {
      params: { input: option, userId: id },
    });
  },
  fetchPlatformDropDownOptions: () => {
    return api.get(`/platformAvailabilityDropDown`);
  },
  fetchNodesCreatedByMe: (user) => {
    return api.get(`/api/nodes/createdByMe?loginUser=${user}`, {
      //params: { loginUser: user },
    });
  },
  // fetchNodesSharedWithMe: (userId) => {
  //   return api.get(`/api/nodes/sharedWithMe?`, {
  //     params: { loginId: userId },
  //   });
  // },
  // fetchMySharedNodes: (userId) => {
  //   return api.get(`/api/nodes/sharedByMe?loginId=${userId}`, {
  //     //params: { loginId: userId },
  //   });
  // },
  fetchNodesSharedWithMe: (payload) => {
    return api.post(`/api/nodes/sharedWithMe`, payload);
  },
  fetchMySharedNodes: (payload) => {
    return api.post(`/api/nodes/sharedByMe`, payload);
  },
  fetchNodesFromCart: (userEmail) => {
    return api.get(`/getCartNodes`, {
      params: { cartHolder: userEmail },
    });
  },
  addNodeToCart: (email, nodeId) => {
    return api.post(
      `/addToCartForCheckouts?cartHolder=${email}&arangoNodeKey=${nodeId}`
    );
  },
  removeNodeFromCart: (email, deletedNodes) => {
    return api.delete(`/removeCart?userEmailId=${email}`, {
      data: deletedNodes,
    });
  },
  removeAllNodesFromCart: (email) => {
    return api.delete(`/clearCart`, {
      params: { cartHolder: email },
    });
  },
  // fetchFavoriteNodes: () => {
  //   return api.post(`api/nodes/getPagedFavoriteNodes`
  //     // params: { favHolder: userEmail },
  //   );
  // },

  fetchFavoriteNodes: (payload) => {
    return api.post(`api/nodes/getPagedFavoriteNodes`, payload);
  },

  // fetchFavoriteNodes: (data) => {
  //   return api.get(`api/nodes/getPagedFavoriteNodes`, data);
  //  },
  addNodeToFavCards: (email, userId, data) => {
    return api.post(
      `/addNodeKeyToFavoriteNodes?favHolder=${email}&favHolderId=${userId}`,
      data
    );
  },
  removeNodeFromFav: (email, userId, nodeId) => {
    return api.delete(
      `/removeArangoNodeKeyFromFavoriteNodes?favHolder=${email}&favHolderId=${userId}&arangoNodeKey=${nodeId}`
    );
  },
  // My saved filters
  SaveFilters: (email, userId, data) => {
    return api.post(
      `/savePreferences?savePreferencesHolder=${email}&savePreferencesHolderId=${userId}`,
      data
    );
  },
  fetchSavedFilters: (email, userId) => {
    return api.get(
      `/getPreferences?savePreferencesHolder=${email}&savePreferencesHolderId=${userId}`
    );
  },
  deleteSavedFilters: (filterId) => {
    return api.delete(`/deleteSavedPreferences?savedFilterId=${filterId}`);
  },
  updateFilterName: (filterId, updatedName, email) => {
    return api.post(
      `/updateNameInSavePreferences?savedFilterId=${filterId}&savedFilterReName=${updatedName}&savePreferencesHolderId=${email}`
    );
  },
  deleteFilterOption: (filterId, data) => {
    return api.delete(`/deleteFilterValues?savedFilterId=${filterId}`, {
      data,
    });
  },
  fetchTags: () => {
    return api.get(`/tagsDropDown`);
  },

  fetchControls: (id) => {
    return api.get(`/getControls?nodes=${id}`);
  },
  fetchNodeList: (data) => {
    return api.post(`/api/pin/getNodeDetails`, data);
  },
  uploadPinImage: (data) => {
    return api.post(`/uploadPinImage`, data);
  },
  uploadProfileImage: (userId, data) => {
    return api.post(`/uploadProfileImage?userId=${userId}`, data);
  },
  fetchGraphData: (id, type, depth = 1) => {
    return api.get(`/graph`, {
      params: { nodeId: id, nodeType: type, depth: depth },
    });
  },
  fetchNodeType: (type) => {
    return api.get(`/getNodeType`, {
      params: { type },
    });
  },
  updateUserProfile: (email, formData) => {
    return api.post(`/generalInformation?userName=${email}`, formData);
  },
  fetchProfileData: (email) => {
    return api.get(`/getGeneralInformation`, {
      params: { userName: email },
    });
  },
  addCategories: (data) => {
    return api.post(`/addProfileCategories`, data);
  },
  yourPreferences: (email, data) => {
    return api.post(`/yourPreferences?userName=${email}`, data);
  },
  yourCategories: (data) => {
    return api.post(`/yourPreferencesProfileCategories`, data);
  },
  fetchYourPreferences: (email) => {
    return api.get(`/getyourPreferences`, {
      params: { userName: email },
    });
  },
  removeProfileContext: (userId, contextName) => {
    return api.post(
      `/removeProfileContext?profileId=${userId}&context=${contextName}`
    );
  },
  // fetchNodeTags: (id) => {
  //   return api.get(`/getTags`, {
  //     params: { id },
  //   });
  // },

  fetchNodeTags: (key) => {
    return api.get(`/getTags?id=${key}`);
  },
  fetchFilterOptions: (data) => {
    return api.post(`/api/graph/graphnodesearch-filters`, data);
  },
  addTags: (id, data) => {
    return api.post(`/tags?id=${id}`, data);
  },
  deleteTag: (id, tagName) => {
    return api.delete(`/deleteTags?id=${id}&tagName=${tagName}`);
  },
  addRating: (key, email, userId, rating) => {
    return api.post(
      `/ratings?key=${key}&userName=${email}&userId=${userId}&ratings=${rating}`
    );
  },
  fetchRatingData: (key, userId) => {
    return api.get(`/ratingsInfo`, {
      params: { key, userId },
    });
  },
  sendMessage: (data) => {
    return api.post(`/mySharedNodesCommunity`, data);
  },
  fetchOrganisationFunData: () => {
    return api.get(`/organizationFunctions`);
  },
  addPolicyDetail: (data) => {
    return api.post(`/api/policy/policy-details`, data);
  },
  saveForLater: (data, email) => {
    return api.patch(`/saveForLater?cartHolder=${email}`, data);
  },
  moveSaveForLaternodeAToCart: (email, data) => {
    return api.patch(`/saveForLaterToMovetoCart?cartHolder=${email}`, data);
  },
  fetchSaveForlaterList: (email) => {
    return api.get(`/saveForLaterList?cartHolder=${email}`);
  },
  removeNodesFromSavedForLater: (email, data) => {
    return api.delete(`/removeCartsFromSaveForLater?cartHolder=${email}`, {
      data,
    });
  },
  fetchNodeGraphData: (nodeId, depth) => {
    return api.get(`/graph`, {
      params: { nodeId, depth },
    });
  },
  // fetchProfileCategories: (userId) => {
  //   return api.get(`/getNodeCategories?key=registerUsers/${userId}`);
  // },
  fetchProfileCategories: (userId) => {
    return api.get(`getYourPreferencesProfileCategories?userId=${userId}`);
  },
  // fetchNotificatioon: () => {
  //   return api.post(`/notification`);
  // },
  fetchNotificatioon: (payload) => {
    return api.post(`/notificationPaged`, payload);
  },
  // fetchStaging: () => {
  //   return api.post(
  //     `http://162.241.127.85:9096/mlsouk/v1/UpdateTransitionStage`
  //   );
  // },
};
