import { Button, Box, makeStyles, withStyles } from "@material-ui/core";

export const YellowButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 12,
    color: '#fff!important',
    padding: '12.5px 22.5px',
    lineHeight: 1.5,
    backgroundColor: '#F79F19',
    '&:hover': {
      backgroundColor: '#C6811A',
      boxShadow: 'none',
    },
  },
  disabled: {
    background: '#C6811A'
  }
})(Button);

export const OutlinedButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 12,
    border: '1px solid #123b64',
    color: '#123b64',
    padding: '11px 22.5px',
    lineHeight: '150%',
    "&:hover": {
      background: '#E0E7EC'
    }
  },
  disabled: {
    color: "#E0E7EC",
    border: '1px solid #E0E7EC'
  }
})(Button);

export const BackBtn = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    width: "182px",
    height: "48px",
    borderRadius: 8,
    border: '1px solid #123b64',
    color: '#123b64',
    lineHeight: '150%',
    "&:hover": {
      background: '#123B64',
      color: '#fff'
    }
  },
  disabled: {
    color: "#E0E7EC",
    border: '1px solid #E0E7EC'
  }
})(Button);
export const BackBtn1 = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    width: "220px",
    height: "48px",
    borderRadius: 8,
    border: '1px solid #123b64',
    color: '#ffff',
    lineHeight: '150%',
    background:'#123b64',
    fontSize:16,
    "&:hover": {
      background: '#123B64',
      color: '#fff'
    }
  },
  disabled: {
    color: "#123B64",
    border: '1px solid #E0E7EC'
  }
})(Button);

export const TextButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    color: '#123b64',
    padding: 0,
    lineHeight: 1.5,
    borderColor: '#123B64',
  },
})(Button);

export const PrimaryButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    background: '#123b64',
    fontWeight: 700,
    color: '#fff',
    padding: '12px 22.5px',
    fontSize: 16,
    lineHeight: 1.5,
    borderColor: '#123B64',
    borderRadius: 12,
    '&:hover': {
      backgroundColor: '#092B4E',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#092B4E',
    },
  },
  disabled: {
    background: '#E0E7EC',
    color: "#123b64!important"
  }
})(Button);

export const CircleButton = withStyles({
  root: {
    minWidth: 48,
    height: 48,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: '50%',
    color: '#123b64',
    padding: 10,
    backgroundColor: '#DEE6EB',
    '&:hover': {
      backgroundColor: '#123b64',
      '& path': {
        stroke: '#fff',
        fill: '#fff'
      }
    },
  },
})(Button);

export const OverlayButton = withStyles({
  root: {
    minWidth: 40,
    height: 40,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 4,
    color: '#123b64',
    padding: 10,
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      boxShadow: 'none',
    },
  },
})(Button);

const AddtoCartBtn = withStyles({
  root: {
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 8,
    padding: '4.5px 5px 4.5px 10px',
    lineHeight: '140%',
    '& .MuiButton-endIcon': {
      marginRight: 0
    },
  },
})(Button);

const useStyles = makeStyles({
  addToCart: {
    background: (props) => props.name === 'Add to cart' ? '#f79f19' : "#fff",
    color: (props) => props.name === 'Add to cart' ? '#fff' : '#123b64',
    border: (props) => props.name === 'Add to cart' ? 'unset' : '1px solid #123b64',
    "& .icon-wrapper": {
      marginRight: 0,
      marginLeft: 8,
      '& path': {
        fill: (props) => props.name === 'Add to cart' ? '#fff' : '#123b64'
      }
    },
    '&:hover': {
      background: (props) => props.name === 'Add to cart' ? '#C6811A' : "#fff",
    }
  }
})

export const NodeIconButton = (props) => {
  const classes = useStyles(props)
  return (
    <AddtoCartBtn className={classes.addToCart} disableRipple
      endIcon={<Box className="icon-wrapper">{props.icon}</Box>} >
      {props.name}
    </AddtoCartBtn>
  )
}

export const AddToCartButton = withStyles({
  root: {
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 8,
    minWidth: 125,
    color: '#fff',
    padding: '4.5px 10px 5px 10px',
    backgroundColor: '#f79f19',
    '& .MuiButton-endIcon': {
      marginRight: 0,
      marginLeft: 6,
      '& path': {
        fill: '#fff'
      }
    },
    '&:hover': {
      backgroundColor: '#f79f19',
      boxShadow: 'none',
    },
  },
})(Button);

export const AddedToCartButton = withStyles(theme => ({
  root: {
    fontSize: 12,
    border: '1px solid #123b64',
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 8,
    color: '#123b64',
    padding: '4.5px 4px 4.5px 6px',
    backgroundColor: '#fff',
    minWidth: 125,
    '& .MuiButton-endIcon': {
      marginRight: 0,
      marginLeft: 11,
    },
    '&:hover': {
      backgroundColor: '#fff',
      boxShadow: 'none',
    },
  },
}))(Button);

export const SuccessButton = withStyles({
  root: {
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 12,
    color: '#fff',
    padding: '4px 8px',
    backgroundColor: '#13A35C',
    '&:hover': {
      backgroundColor: '#3bce86',
      boxShadow: 'none',
    },
  },
})(Button);

export const SecondaryButton = withStyles({
  root: {
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'none',
    borderRadius: 12,
    color: '#fff',
    padding: '4px 8px',
    backgroundColor: '#808C9A',
    '&:hover': {
      backgroundColor: '#9fabba',
      boxShadow: 'none',
    },
  },
})(Button);
export const SuccessButton2 = withStyles({
  root: {
    fontSize: 14,
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 12,
    color: "#fff",
    padding: "4px 8px",
    // backgroundColor: '#808C9A',
    backgroundColor: "#F79F19",
    "&:hover": {
      backgroundColor: "#F79F19",
      //backgroundColor: '#9fabba',
      boxShadow: "none",
    },
  },
})(Button);
export const SuccessButton3 = withStyles({
  root: {
    fontSize: 14,
    boxShadow: "none",
    textTransform: "none",
    borderRadius: 12,
    color: "#ffff",
    backgroundColor: "#1EB5A7",
    "&:hover": {
      backgroundColor: "#1EB5A7",
      boxShadow: "none",
    },
  },
})(Button);


