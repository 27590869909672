

export const HeadCells = [
    { id: 'job-id', label: 'ID' },
    { id: 'job-name', label: 'JOB NAME' },
    { id: 'group', label: 'GROUP' },
    { id: 'type', label: 'TYPE' },
    { id: 'estimated-time', label: 'ESTIMATED TIME' },
    { id: 'status', label: 'STATUS' },
    { id: 'trigger-name', label: 'TRIGGER NAME' },
    { id: 'springjobname', label: 'SPRING JOB NAME' },
    { id: 'job-description', label: 'JOB DESCRIPTION' },
    { id: 'params', label: 'PARAMS' },
    { id: 'callbackurl', label: 'CALL BACK URL' },
    { id: 'updated-at', label: 'UPDATED AT' },
    { id: 'created-at', label: 'CREATED AT' },    
];
    
