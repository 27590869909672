// import { useRef, useEffect, useState } from "react";
// import { ForceGraph2D } from "react-force-graph"
// import Table from './../../../../assets/images/card-icons/table.png'
// import Column from './../../../../assets/images/card-icons/column.png'
// import BusinessProcess from './../../../../assets/images/card-icons/BusinessProcess.png'
// import DataDomain from './../../../../assets/images/card-icons/data-domain.png'
// import DataSet from './../../../../assets/images/card-icons/data-set.png'
// import File from './../../../../assets/images/card-icons/file.png'
// import Failed from './../../../../assets/images/card-icons/failed.png'
// import Term from './../../../../assets/images/card-icons/term.png'
// import Server from '../.././../../assets/images/card-icons/server.png'
// import DataBase from './../../../../assets/images/card-icons/data-base.png'
// import Schema from './../../../../assets/images/card-icons/schema.png'
// import Policy from './../../../../assets/images/card-icons/policy.png'
// import Report from './../../../../assets/images/card-icons/report.png'
// import Application from './../../../../assets/images/card-icons/application.png'
// import Node from './../../../../assets/images/card-icons/node.png'
// import Collection from './../../../../assets/images/card-icons/collection.png'
// import Directory from './../../../../assets/images/card-icons/directory.png'
// import Standart from './../../../../assets/images/card-icons/standart.png'
// import Geography from './../../../../assets/images/card-icons/geography.png'
// import Api from './../../../../assets/images/card-icons/Api.png'

// const demoData = {
//   nodes: [
//     {
//       path: "Customers Rented a movie DVD more than once",
//       module: "Customers Rented a movie DVD more than once",
//       type: "Data Set"
//     },
//     {
//       path: "inventory",
//       module: "inventory",
//       type: "Table"
//     },
//     {
//       path: "Assets",
//       module: "Assets",
//       type: "Table"
//     },
//     {
//       path: "rental",
//       module: "rental",
//       type: "Table"
//     },
//     {
//       path: 'Customers',
//       module: 'Customers',
//       type: 'Column'
//     },
//     {
//       path: 'Customer data',
//       module: 'Customer data',
//       type: 'Column'
//     },
//     {
//       path: 'Schema',
//       module: 'Schema',
//       type: 'Column'
//     },
//     {
//       path: 'Employee keys',
//       module: 'Employee keys',
//       type: 'Column'
//     },
//     {
//       path: 'Data set',
//       module: 'Data set',
//       type: 'Column'
//     }
//   ],
//   links: [
//     {
//       source: "Customers Rented a movie DVD more than once",
//       target: "inventory"
//     },
//     {
//       source: "Customers Rented a movie DVD more than once",
//       target: "rental"
//     },
//     {
//       source: "Customers Rented a movie DVD more than once",
//       target: "Assets"
//     },
//     {
//       source: "inventory",
//       target: "Customers"
//     },
//     {
//       source: "inventory",
//       target: "Customer data"
//     },
//     {
//       source: "rental",
//       target: "Schema"
//     },
//     {
//       source: "rental",
//       target: "Employee keys"
//     },
//     {
//       source: "inventory",
//       target: "Data set"
//     },
//     {
//       source: "rental",
//       target: "Data set"
//     },
//     {
//       source: "Assets",
//       target: "Schema"
//     },
//     {
//       source: "Assets",
//       target: "Customers"
//     },
//   ]
// }

// const NodeGraph = ({ graphData, width, height, backgroundColor, linkColor, labelColor }) => {
//   const fgRef = useRef();
//   const [collapsedClusters, setCollapsedClusters] = useState([]);
//   const [linkedTables, setLinkedTables] = useState([])
//   const [graph, setGraph] = useState(demoData)
//   const [externalLinks, setExternalLinks] = useState([])
//   const nodeWidth = 4
//   const nodeHeight = 4

//   const setNodeImage = (Type) => {
//     let iconName;
//     switch (Type) {
//       case 'Column':
//         iconName = Column;
//         break;
//       case 'Business Process':
//         iconName = BusinessProcess;
//         break;
//       case 'Data Set':
//         iconName = DataSet;
//         break;
//       case 'Data domain':
//         iconName = DataDomain;
//         break;
//       case 'Application':
//         iconName = Application;
//         break;
//       case 'Database':
//         iconName = DataBase;
//         break;
//       case 'Failed':
//         iconName = Failed;
//         break;
//       case 'File':
//         iconName = File;
//         break;
//       case 'Policy':
//         iconName = Policy;
//         break;
//       case 'Business Term':
//         iconName = Term;
//         break;
//       case 'Server':
//         iconName = Server;
//         break;
//       case 'Schema':
//         iconName = Schema;
//         break;
//       case 'Report':
//         iconName = Report;
//         break;
//       case 'Collection':
//         iconName = Collection;
//         break;
//       case 'Data Source':
//         iconName = Node;
//         break;
//       case 'Directory':
//         iconName = Directory;
//         break;
//       case 'Standart':
//         iconName = Standart;
//         break;
//       case 'Geography':
//         iconName = Geography;
//         break;
//       case "API":
//         iconName = Api
//         break;
//       case "API Endpoint":
//         iconName = Api
//         break;
//       default:
//         iconName = Table
//         break;
//     }
//     return iconName
//   }
//   console.log("collapsedClusters: ", collapsedClusters);

//   const handleNodeClick = (node) => {
//     if (node.type === "Table") {
//       if (collapsedClusters.includes(node.path)) {
//         const filterdlink = graph.links.filter(link => link.source.path === node.path || link.target.path === node.path)
//           .filter(item => (item.source.type === 'Table' && item.target.type === 'Table'))

//         const filteredGraphLinks = graph.links.filter(link => {
//           return !filterdlink.find(item => item.source.path === link.source.path && item.target.path === link.target.path)
//         })

//         setGraph({ nodes: graph.nodes, links: filteredGraphLinks })
//         setCollapsedClusters(collapsedClusters.filter((id) => id !== node.path));
//       } else {
//         console.log('else case...');
//         const link = externalLinks.filter(link => link.source === node.path || link.target === node.path)
//         console.log("link5: ", link);
//         setGraph(prev => {
//           return { ...prev, links: [...prev.links, ...link] }
//         })
//         setCollapsedClusters([...collapsedClusters, node.path]);
//       }
//     }
//   }

//   console.log('real links: ', graph.links);

//   console.log("externalLinks: ", externalLinks);

//   const setLinkNodes = (a, b) => {
//     const isAvailable = graph.links.some(link => (link.source === a && link.target === b) || (link.source === b && link.target === a))
//     if (!isAvailable) {
//       demoData.links.push({ source: a, target: b })
//       setExternalLinks(prev => ([...prev, { source: a, target: b }]))
//     }
//   }

//   // console.log('externalLinks: ', externalLinks);
//   // console.log("links: ", graph.links);

//   useEffect(() => {
//     fgRef.current.d3Force('charge').strength(-120)
//   }, []);

//   useEffect(() => {
//     const clusters = graph.nodes.filter(node => node.type === "Table")
//     const clusterIds = clusters.map(cluster => cluster.path)
//     const linkedTables = []

//     for (let i = 0; i < demoData.links.length; i++) {
//       for (let j = 0; j < demoData.links.length; j++) {
//         if (i !== j && demoData.links[i].target === demoData.links[j].target)
//           setLinkNodes(demoData.links[i].source, demoData.links[j].source)
//       }
//     }

//     // console.log("linkedTables: ", linkedTables);

//     demoData.nodes.forEach((node) => {
//       if (node.type !== 'Column') {
//         node.clusterId = null
//       } else {
//         const link = graph.links.find(link => link.target === node.path)
//         node.clusterId = link && link.source
//       }
//     })

//     setGraph(demoData)
//     setCollapsedClusters(clusterIds)
//   }, [])

//   return (
//     <ForceGraph2D
//       ref={fgRef}
//       graphData={graph}
//       dagMode="lr"
//       dagLevelDistance={40}
//       backgroundColor={backgroundColor}
//       linkColor={() => linkColor}
//       nodeRelSize={4}
//       onNodeClick={handleNodeClick}
//       nodeVal={node => 100 / (node.level + 1)}
//       nodeLabel="path"
//       nodeId="path"
//       nodeColor={() => '#90D1CC'}
//       width={width && width}
//       height={height && height}
//       nodeCanvasObjectMode={() => 'after'}
//       nodeCanvasObject={(node, ctx, globalScale) => {
//         const label = node.path.toLowerCase()
//         const fontSize = 14 / (globalScale * 1.1)
//         const newImage = new Image();
//         newImage.src = setNodeImage(node.type)
//         ctx.drawImage(newImage, node.x - nodeWidth / 2, node.y - nodeHeight / 2, nodeWidth, nodeHeight)
//         ctx.font = `${fontSize}px Roboto sans-serif`
//         ctx.fillStyle = labelColor
//         ctx.fillText(label, node.x + nodeWidth, node.y)
//       }}
//       nodeVisibility={(node) => {
//         if (collapsedClusters.includes(node.clusterId)) {
//           return false;
//         } else {
//           return true
//         }
//       }}
//       linkVisibility={(link) => {
//         if (collapsedClusters.includes(link.target.clusterId)) {
//           return false;
//         } else return true;
//       }}
//     />
//   )
// };
// export default NodeGraph
import { useRef, useEffect, useState } from "react";
import { ForceGraph2D } from "react-force-graph";
import Table from "./../../../../assets/images/card-icons/table.png";
import Column from "./../../../../assets/images/card-icons/column.png";
import BusinessProcess from "./../../../../assets/images/card-icons/BusinessProcess.png";
import DataDomain from "./../../../../assets/images/card-icons/data-domain.png";
import DataSet from "./../../../../assets/images/card-icons/data-set.png";
import File from "./../../../../assets/images/card-icons/file.png";
import Failed from "./../../../../assets/images/card-icons/failed.png";
import Term from "./../../../../assets/images/card-icons/term.png";
import Server from "./../../../../assets/images/card-icons/server.png";
import Schema from "./../../../../assets/images/card-icons/schema.png";
import Policy from "./../../../../assets/images/card-icons/policy.png";
import Report from "./../../../../assets/images/card-icons/report.png";
import Application from "./../../../../assets/images/card-icons/application.png";
import Node from "./../../../../assets/images/card-icons/node.png";
import Collection from "./../../../../assets/images/card-icons/collection.png";
import Directory from "./../../../../assets/images/card-icons/directory.png";
import Standart from "./../../../../assets/images/card-icons/standart.png";
import Geography from "./../../../../assets/images/card-icons/geography.png";
import Api from "./../../../../assets/images/card-icons/Api.png";
import Field from "./../../../../assets/images/card-icons/field.png";
import Bucket from "./../../../../assets/images/card-icons/Bucket.png";
import Route from "./../../../../assets/images/card-icons/Route.png";
import LineofBusiness from "./../../../../assets/images/card-icons/Line of Business.png";
import DataQualityMetric from "./../../../../assets/images/card-icons/Data quality metric.png";
import DataQualityRule from "./../../../../assets/images/card-icons/Data quality rule.png";
import Foreignkey from "./../../../../assets/images/card-icons/Foreign key.png";
import APIEndpoint from "./../../../../assets/images/card-icons/API Endpoint.png";
import APIParameter from "./../../../../assets/images/card-icons/API Parameter.png";
import SensitiveTypegroup from "./../../../../assets/images/card-icons/Sensitive Typegroup.png";
import DataProduct from "./../../../../assets/images/card-icons/Data Product.png";
import DataBase from "./../../../../assets/images/card-icons/Database.png";
import DataSharingAgreement from "./../../../../assets/images/card-icons/Data sharing.png";
import Featureset from "./../../../../assets/images/card-icons/Feature set.png";
import Datause from "./../../../../assets/images/card-icons/Datause.png";
import SensitiveType from "./../../../../assets/images/card-icons/Sensitive type.png";
import Container from "./../../../../assets/images/card-icons/Container.png";
import Product from "./../../../../assets/images/card-icons/Product.png";
import Feature from "./../../../../assets/images/card-icons/Feature.png";
import Mlmodel from "./../../../../assets/images/card-icons/ml model (2).png";



const NodeGraph = ({
  graphData,
  width,
  height,
  backgroundColor,
  linkColor,
  labelColor,
}) => {
  const fgRef = useRef();
  const [collapsedClusters, setCollapsedClusters] = useState([]);
  const [externalLinks, setExternalLinks] = useState([]);
  const nodeWidth = 4;
  const nodeHeight = 4;

  const setNodeImage = (Type) => {
    let iconName;
    switch (Type) {
      case "Column":
        iconName = Column;
        break;
      case "column":
        iconName = Column;
        break;
      case "Business Process":
        iconName = BusinessProcess;
        break;
      case "Data Set":
        iconName = DataSet;
        break;
      case "Data domain":
        iconName = DataDomain;
        break;
      case "Application":
        iconName = Application;
        break;
  
      case "Database":
        iconName = DataBase;
        break;
      case "Failed":
        iconName = Failed;
        break;
      case "File":
        iconName = File;
        break;
      case "Policy":
        iconName = Policy;
        break;
      case "Business Term":
        iconName = Term;
        break;
      case "Server":
        iconName = Server;
        break;
      case "Schema":
        iconName = Schema;
        break;
      case "schema":
        iconName = Schema;
        break;
      case "Collection":
        iconName = Collection;
        break;
      case "Data Source":
        iconName = Node;
        break;
      case "Directory":
        iconName = Directory;
        break;
      case "Standart":
        iconName = Standart;
        break;
      case "Geography":
        iconName = Geography;
        break;
      case "API":
        iconName = Api;
        break;
      case "API Endpoint":
        iconName = Api;
        break;
      case "Data Product":
        iconName = DataProduct;
        break;
      case "Table":
        iconName = Table;
        break;
      case "Data Sharing Agreement":
        iconName = DataSharingAgreement;
        break;
      case "Reports":
        iconName = Report;
        break;
      case "Feature Set":
        iconName = Featureset;
        break;
      case "Data Use":
        iconName = Datause;
        break;
      case "SensitiveType":
        iconName = SensitiveType;
        break;
      case "container":
        iconName = Container;
        break;
      case "Product":
        iconName = Product;
        break;
      case "Feature":
        iconName = Feature;
        break;
      case "ML Model":
        iconName = Mlmodel;
        break;
      case "SensitiveTypeGroup":
        iconName = SensitiveTypegroup;
        break;
      case "API Parameter":
        iconName = APIParameter;
        break;
      case "API Endpoint":
        iconName = APIEndpoint;
        break;
      case "Foreign key":
        iconName = Foreignkey;
        break;
      case "Data Quality Rule":
        iconName = DataQualityRule;
        break;
      case "Data Quality Metric":
        iconName = DataQualityMetric;
        break;
      case "Line of Business":
        iconName = LineofBusiness;
        break;
      case "Route":
        iconName = Route;
        break;
      case "Bucket":
        iconName = Bucket;
        break;
      case "Field":
        iconName = Field;
        break;
      default:
        iconName = Node;
        break;
    }
    return iconName;
  };

  const handleNodeClick = (node) => {
    if (node.type === "Table") {
      if (collapsedClusters.includes(node.path)) {
        const filterdlink = graphData.links
          .filter(
            (link) =>
              link.source.path === node.path || link.target.path === node.path
          )
          .filter(
            (item) =>
              item.source.type === "Table" && item.target.type === "Table"
          );

        const filteredGraphLinks = graphData.links.filter((link) => {
          return !filterdlink.find(
            (item) =>
              item.source.path === link.source.path &&
              item.target.path === link.target.path
          );
        });

        graphData.links = filteredGraphLinks;
        setCollapsedClusters(
          collapsedClusters.filter((id) => id !== node.path)
        );
      } else {
        console.log("else case...");
        const link = externalLinks.filter(
          (link) => link.source === node.path || link.target === node.path
        );
        console.log("link5: ", link);
        graphData.links = [...graphData.links, ...link];
        setCollapsedClusters([...collapsedClusters, node.path]);
      }
    }
  };

  const setLinkNodes = (a, b) => {
    const isAvailable = graphData.links.some(
      (link) =>
        (link.source === a && link.target === b) ||
        (link.source === b && link.target === a)
    );
    if (!isAvailable) {
      graphData.links.push({ source: a, target: b });
      setExternalLinks([...externalLinks, { source: a, target: b }]);
    }
  };

  useEffect(() => {
    fgRef.current.d3Force("charge").strength(-120);
  }, []);

  useEffect(() => {
    const clusters = graphData.nodes.filter((node) => node.type === "Table");
    const clusterIds = clusters.map((cluster) => cluster.path);

    for (let i = 0; i < graphData.links.length; i++) {
      for (let j = 0; j < graphData.links.length; j++) {
        if (i !== j && graphData.links[i].target === graphData.links[j].target)
          setLinkNodes(graphData.links[i].source, graphData.links[j].source);
      }
    }

    graphData.nodes.forEach((node) => {
      if (node.type !== "Column") {
        node.clusterId = null;
      } else {
        const link = graphData.links.find((link) => link.target === node.path);
        node.clusterId = link && link.source;
      }
    });
    setCollapsedClusters(clusterIds);
  }, [graphData]);

  return (
    <ForceGraph2D
      ref={fgRef}
      graphData={graphData}
      dagMode="lr"
      dagLevelDistance={40}
      backgroundColor={backgroundColor}
      linkColor={() => linkColor}
      nodeRelSize={4}
      onNodeClick={handleNodeClick}
      nodeVal={(node) => 100 / (node.level + 1)}
      nodeLabel="path"
      nodeId="path"
      nodeColor={() => "#90D1CC"}
      width={width && width}
      height={height && height}
      nodeCanvasObjectMode={() => "after"}
      nodeCanvasObject={(node, ctx, globalScale) => {
        const label = node.path.toLowerCase();
        const fontSize = 14 / (globalScale * 1.1);
        const newImage = new Image();
        newImage.src = setNodeImage(node.type);
        ctx.drawImage(
          newImage,
          node.x - nodeWidth / 2,
          node.y - nodeHeight / 2,
          nodeWidth,
          nodeHeight
        );
        ctx.font = `${fontSize}px Roboto sans-serif`;
        ctx.fillStyle = labelColor;
        ctx.fillText(label, node.x + nodeWidth, node.y);
      }}
      nodeVisibility={(node) => {
        if (collapsedClusters.includes(node.clusterId)) {
          return false;
        } else {
          return true;
        }
      }}
      linkVisibility={(link) => {
        if (collapsedClusters.includes(link.target.clusterId)) {
          return false;
        } else return true;
      }}
    />
  );
};

export default NodeGraph;
