import axios from "axios";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

instance.interceptors.request.use((x) => {
    x.meta = x.meta || {};
    x.meta.requstStartedAt = new Date().getTime();
    return x;
});

instance.interceptors.response.use((x) => {
    x.responseTime = (new Date().getTime() - x.config.meta.requstStartedAt) / 1000;
    return x;
});

const api = (axios) => {
    return {
        get: (url, config = {}) => {
            return axios.get(url, config);
        },
        delete: (url, config = {}) => {
            return axios.delete(url, config);
        },
        put: (url, body, config = {}) => {
            return axios.put(url, body, config);
        },
        post: (url, body, config = {}) => {
            return axios.post(url, body, config);
        },
        patch: (url, body, config = {}) => {
            return axios.patch(url, body, config);
        }
    }
}

export default api(instance);