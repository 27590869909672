import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';

// Customized Tooltip component from Material-UI
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        minWidth: 200,
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 2px 20px rgba(23, 87, 123, 0.2)',
        padding: 10,
        borderRadius: 10,
        margin: 0
    },
}))(Tooltip);

// Custom CSS styles using Material-UI's makeStyles hook
const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: '0!important',
        marginBottom: 8
    },
    listItemAvatar: {
        minWidth: 42,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32
        }
    },
    listItemText: {
        margin: 0,
        color: '#123b64',
        '& span, p': {
            fontSize: 14
        },
        '& p': {
            color: '#123b64c2'
        }
    }
}));

// Functional component for CollapseTooltip
const CollapseTooltip = ({ children, data, type }) => {
    const classes = useStyles();
    const [roles, setRoles] = useState();
    
    useEffect(() => {
        // Logic to transform data based on 'type' prop
        if (type === "matrix") {
            let employees = [];
            Object.keys(data[0]).forEach(role => {
                let users = data[0][role].map(user => {
                    return {
                        ...user,
                        role
                    };
                });
                employees.push(...users);
            });
            setRoles(employees);
        } else {
            setRoles(data);
        }
    }, [data, type]);

    // Function to render content for Tooltip
    const setContent = () => {
        return (
            <List style={{ padding: 0 }}>
                {
                    roles && roles.map((item, index) => (
                        <ListItem key={index} className={classes.listItem}>
                            {
                                type === "simple" ?
                                    <ListItemText
                                        className={classes.listItemText}
                                        primary={item.name}
                                    /> :
                                    <>
                                        <ListItemAvatar className={classes.listItemAvatar}>
                                            <Avatar src={!type && item.url} className={classes.avatar} alt={item.name} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            className={classes.listItemText}
                                            primary={item.name}
                                            secondary={type && item.role}
                                        />
                                    </>
                            }
                        </ListItem>
                    ))
                }
            </List>
        );
    };

    // Return the Tooltip component with provided content
    return (
        <LightTooltip placement='bottom-start' title={setContent()} aria-label="add">
            <div>
                {children}
            </div>
        </LightTooltip>
    );
};

export default CollapseTooltip;
