import { Box } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import HomeSlider from "../../components/home/home-slider/HomeSlider";
import RecommendedCollections from "../../components/home/recommended-collections/RecommendedCollections";
import RecomendedNodes from "../../components/home/recommended-nodes/RecommendedNodes";
import MyWorkTabs from "../../components/my-work/MyWorkTabs";
import ErorrPage from "../../components/shared/error/Erorr";
import { SETTINGS } from "../../constants/home";
import {
  createSearchLoadingPayload,
  SEARCH_NODE_PAYLOAD,
} from "../../constants/searchNodes";
import { TAB_TYPES } from "../../constants/tab";
import DataContext from "../../contexts/Context";
import TabContext from "../../contexts/TabContext";
import { CollectionService } from "../../services/CollectionService";
import { SearchService } from "../../services/SearchService";
import Loader from "./../../components/shared/loader/Loader";
import { filterHelper } from "../../constants/filter-helper";
import "../../components/home/recommended-nodes/home.scss";

// Define tab options for the home component
const tabOptions = [
  {
    label: "Nodes",
    value: "nodes",
  },
  {
    label: "Collections",
    value: "collections",
  },
];

// Define the Home component
const Home = () => {
   // Define state variables using useState hook
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCollection, setIsLoadingCollection] = useState(true);
  const [isError, setIsError] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [recentCollections, setRecentCollections] = useState([]);
  const { updatePayload, setSearchTerm, setPhraseFilterType, setActiveTab } =
    useContext(TabContext);
  const { recentSearches, isRecentSearchLoading, getRealtedSearches } =
    useOutletContext(updatePayload,"updatePayload");
   
  const navigate = useNavigate();
  const { setrecent, recent } = useContext(DataContext);
  const { addSearchTabHandler, nodeType } = useContext(TabContext);


   // Hook to handle window resize
  useEffect(() => {
  
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
    return () =>
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
  }, []);

// Hook to fetch recent trending data and recent collections on component mount
useEffect(() => {
  // Fetch recent trending data
    SearchService.fetchRecentTrending()
      .then((res) => {
        const recentData = { recent: [], trending: [] };
        if (res.data && res.data.trending) {
          recentData.trending = res.data.trending;
        }

        if (res.data && res.data.recent) {
          recentData.recent = res.data.recent;
        }
        setrecent(recentData);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
      // Fetch recent collections
    CollectionService.fetchRecentCollections()
      .then((res) => {
        setRecentCollections(res.data);
        setIsLoadingCollection(false);
      })
      .catch(() => {
        setIsLoadingCollection(false);
        setIsError(true);
      });
  }, []);

  const getSearchNodes = (payload, resentSearch) => {
    const loadingPaload = createSearchLoadingPayload();
    addSearchTabHandler(loadingPaload);
    updatePayload(payload);
    setSearchTerm(resentSearch);
    setPhraseFilterType("");
  };

  // Function to perform search based on related text
  const searchByRelatedText = (resentSearch) => {
    getRealtedSearches(resentSearch);
    setActiveTab(TAB_TYPES.FILTER);
    navigate({
      pathname: `/tabs`,
      search: `?nodeName=${resentSearch}`,
    });
    const searchPayload = { ...SEARCH_NODE_PAYLOAD };
    searchPayload.filters = searchPayload.filters.map((filter) => {
      if (
        filter.fieldName !== "doc.nodesClassification" &&
        filter.fieldName !== "doc.typeName"
      ) {
        return { ...filter, fieldValue: resentSearch };
      } else {
        return filter;
      }
    });

    if (nodeType.length) {
      const selectedNodeTypes = nodeType.map((type) => {
        return { label: "nodeType", value: type };
      });
      const selectedFilters = filterHelper(selectedNodeTypes, "nodeType");
      //searchPayload.filters.push(...selectedFilters);
    }

    getSearchNodes(searchPayload, resentSearch);
  };

  if (isLoading || isLoadingCollection) {
    return <Loader />;
  }
  if (isError) {
    return <ErorrPage />;
  }

  return (
    <Box mb={15} sx={{ minHeight: "calc(100vh - 150px)" }}>
      <div className="recent-search-container">
        {/* <HomeSlider
       recentSearch={recentSearches}
          isLoading={isRecentSearchLoading}
          settings={SETTINGS}
          searchByRelatedText={searchByRelatedText}
        /> */}

{recentSearches && recentSearches.length > 0 ? (
  <HomeSlider
    recentSearch={recentSearches}
    isLoading={isRecentSearchLoading}
    settings={SETTINGS}
    searchByRelatedText={searchByRelatedText}
  />
) : null}

      </div>
      <MyWorkTabs
        screenWidth={screenWidth}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        isDuality={true}
        tabLabel={tabOptions}
        tabPanelContents={{
          contentOne: <RecomendedNodes recent={recent} />,
          contentTwo: (
            <RecommendedCollections collections={recentCollections} />
          ),
        }}
      />
    </Box>
  );
};

export default Home;
