import { Typography } from '@material-ui/core';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import NodeTooltip from './NodeTooltip';

function OverflowTooltip({ maxWidth, title, variant, currentNodes }) {
    const [isOverflowed, setIsOverflowed] = useState(false)
    const textElmRef = useRef()

    useEffect(() => {
        setIsOverflowed(textElmRef.current.scrollWidth > textElmRef.current.clientWidth);
        return () => {
            setIsOverflowed(false)
        }
    }, [currentNodes])

    return (
        <NodeTooltip name="card" disableHoverListener={!isOverflowed} placement='bottom-start' title={title}>
            <Typography
                ref={textElmRef}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: maxWidth,
                    width: '100%'
                }}
                color='primary'
                variant={variant}
            >
                {title}
            </Typography>
        </NodeTooltip>
    );
}

export default OverflowTooltip;