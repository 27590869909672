import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { InfoIcon } from "../icons";
import CustomModal from "../shared/modal/CustomModal";
import { CustomTooltip } from "../shared/tooltip/NodeTooltip";
import Warning from "../shared/warning/Warning";
import AddedToCart from "./details/AddedToCart";
import ModalContent from "./details/ModalContent";

const useStyles = makeStyles(theme => ({
    root: {
        background: '#E0E7EC',
        height: '100%',
        width: "100%",
        [theme.breakpoints.up('768')]: {
            width: 720
        }
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        padding: '14px 50px'
    },
    h6: {
        textDecoration: 'underline',
        margin: '0 6.5px 0 12px',
        cursor: 'pointer'
    },
}))


function CheckoutSidebar({ setState, displayName }) {
    const classes = useStyles()
    const [ open, setOpen ] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.box}>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.60156 0.951172V2.95117H13.6016V0.951172H15.6016V2.95117H19.6016C19.8668 2.95117 20.1211 3.05653 20.3087 3.24407C20.4962 3.4316 20.6016 3.68596 20.6016 3.95117V19.9512C20.6016 20.2164 20.4962 20.4707 20.3087 20.6583C20.1211 20.8458 19.8668 20.9512 19.6016 20.9512H1.60156C1.33635 20.9512 1.08199 20.8458 0.894456 20.6583C0.706919 20.4707 0.601563 20.2164 0.601562 19.9512V3.95117C0.601562 3.68596 0.706919 3.4316 0.894456 3.24407C1.08199 3.05653 1.33635 2.95117 1.60156 2.95117H5.60156V0.951172H7.60156ZM18.6016 9.95117H2.60156V18.9512H18.6016V9.95117ZM13.6376 11.0872L15.0516 12.5012L10.1016 17.4512L6.56556 13.9152L7.98156 12.5012L10.1026 14.6232L13.6386 11.0872H13.6376ZM5.60156 4.95117H2.60156V7.95117H18.6016V4.95117H15.6016V5.95117H13.6016V4.95117H7.60156V5.95117H5.60156V4.95117Z" fill="#123B64" />
                </svg>
                <Typography onClick={() => setOpen(true)} variant='h6' color="primary" className={classes.h6}>
                        Check the platform availability
                    </Typography>
                <CustomModal open={open} setOpen={setOpen}>
                    <ModalContent setOpen={setOpen} />
                </CustomModal>
                <CustomTooltip
                    bottomStart="bottom-start"
                    title={<Warning />}
                >
                    <InfoIcon />
                </CustomTooltip>
            </div>
      <AddedToCart setState={setState} />
    </div>
  );
}

export default CheckoutSidebar;
