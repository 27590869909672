import React from 'react';
import { InputBase, makeStyles, withStyles, InputAdornment } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';

const Input = withStyles((theme) => ({
    root: {
        width: 101,
        backgroundColor: '#fff',
        borderRadius: 8,
        border: "1px solid #808C9A",
        height: 32,
        '& ::placeholder': {
            color: '#123b64!important',
            opacity: 1
        },
        "&:focus-within": {
            background: '#F2F3F4'
        },
    },
    input: {
        height: 20,
        color: '#123b64',
        position: 'relative',
        fontSize: 14,
        paddingLeft: 5,
        caretColor: '#123b64'
    },
}))(InputBase);

const useStyles = makeStyles({
    clearIcon: {
        marginRight: 6,
        width: 14,
        background: 'transparent',
        cursor: 'pointer',
        '& path': {
            color: '#123b64'
        }
    },
    searchIconWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 28,
        background: '#F79F19',
        borderRadius: 6,
        marginLeft: 6,
        marginRight: 2,
        cursor: 'pointer'
    },
})


const NewTableSearchBar = (props) => {

    const classes = useStyles();
    const {
        searchText,
        setSearchText,
        setSearchByColumn,
        column
    } = props

    const clearInputText = () => {
        setSearchText('')
    }
    return (
        <Input
            key={column.id}
            type="text"
            autoComplete='off'
            value={searchText}
            onBlur={() => {
                setSearchText('')
            }}
            onChange={(e) => {
                setSearchText(e.target.value.toLowerCase())
                setSearchByColumn(column.id)
            }}
            endAdornment={
                <InputAdornment position="end">
                    {
                        searchText && <ClearIcon onClick={clearInputText} className={classes.clearIcon} />
                    }
                </InputAdornment>
            }
            startAdornment={
                <InputAdornment position="end">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 13L10.1 10.1M6.33333 11.6667C9.27885 11.6667 11.6667 9.27885 11.6667 6.33333C11.6667 3.38781 9.27885 1 6.33333 1C3.38781 1 1 3.38781 1 6.33333C1 9.27885 3.38781 11.6667 6.33333 11.6667Z" stroke="#123B64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </InputAdornment>
            }
        />
    );
}

export default NewTableSearchBar;