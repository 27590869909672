import Alation from "./../../../assets/images/datasetsource/Alation.png"
import Collibra from "./../../../assets/images/datasetsource/Collibra.png"
import GCP from "./../../../assets/images/datasetsource/GCP.png"
import  PKWARE from "./../../../assets/images/datasetsource/PKWARE.png"
import PostgreSQL from "./../../../assets/images/datasetsource/PostgreSQL.png"
import EMUDSON_MYSQL from "./../../../assets/images/datasetsource/EMUDSON_MYSQL.png"
import Hive from "./../../../assets/images/datasetsource/Hive.png"
import Database from "./../../../assets/images/datasetsource/Database.png"



export function findNodeIcon(Type) {
  let iconName;
  switch (Type) {
    case "Alation":
      iconName = Alation;
      break;
    case "Collibra":
      iconName = Collibra;
      break;
    case "GCP":
      iconName = GCP;
      break;
    case "PKWARE":
      iconName = PKWARE;
      break;
    case "PostgreSQL":
      iconName = PostgreSQL;
      break;
    case "EMUDSON_MYSQL":
      iconName = EMUDSON_MYSQL;
      break;
    case "Hive":
      iconName = Hive;
      break;
      default:
        iconName =Database;
        break;
    }
  
    return iconName;
  }