import React from 'react';
import { useEffect, useState } from 'react';

const NodeLock = ({ SecurityClassification, PersonallyIdentifiableInformation, currentNodes }) => {
    const [lockColor, setLockColor] = useState()

    useEffect(() => {
        if (SecurityClassification && PersonallyIdentifiableInformation === 'true') {
            setLockColor("#FE413B")
        } else if (SecurityClassification == undefined && PersonallyIdentifiableInformation == undefined) {
            setLockColor("#fff") 
        }
        else if (!SecurityClassification && PersonallyIdentifiableInformation === 'false') {
            setLockColor("#14A35E") 
        } else if ((SecurityClassification && PersonallyIdentifiableInformation==="false") || (!SecurityClassification && PersonallyIdentifiableInformation === 'true')) {
            setLockColor('#F79F19')
        }
    }, [currentNodes])

    return (
        <svg style={lockColor === "#fff" ? {display: 'none'} : {display: 'block'}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd" d="M12 22C12 22 20 18 20 12V5L12 2L4 5V12C4 18 12 22 12 22Z" fill={lockColor}
            />
            <path d="M9.75 9.125V7.18056C9.75 5.8382 10.7574 4.75 12 4.75C13.2426 4.75 14.25 5.8382 14.25 7.18056V9.125M12 12.6338V12.625M9 16.125H15C15.8284 16.125 16.5 15.3415 16.5 14.375V10.875C16.5 9.9085 15.8284 9.125 15 9.125H9C8.17157 9.125 7.5 9.9085 7.5 10.875V14.375C7.5 15.3415 8.17157 16.125 9 16.125Z"
                stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default NodeLock;