import { Box } from "@material-ui/core";
import axios from "axios";
import { useState } from "react";

import { BackBtn } from "../../shared/buttons/custom-buttons/CustomButtons";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

const submitForm = (e) => {
  var formdata = new FormData();
  e.preventDefault();
  var name = document.getElementById("testName").value;
  formdata.append("name", name);
  var file = document.getElementById("testFile");
  formdata.append("file", file.files[0]);
  for (var key of formdata.entries()) {
    console.log(key[0] + ", " + key[1]);
  }
};

function CurateResponsibilitesone() {
  const [file, setFile] = useState();

  const downloadFile = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = window.document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToJson = (data) => {
    downloadFile({
      data: JSON.stringify(data),
      fileName: "users.json",
      fileType: "text/json",
    });
  };

  const downloadFileExcel = ({ data, fileName, fileType }) => {
    // Create a blob with the data we want to download as a file
    const blob = new Blob([data], { type: fileType });
    // Create an anchor element and dispatch a click event on it
    // to trigger a download
    const a = window.document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const exportToExcel = (data) => {
    downloadFileExcel({
      data: data,
      fileName: "users.xlsx",
      fileType: "text/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // fileType: "application/csv",
    });
  };

  const onConvert = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await axios.post(
        "http://163.172.157.141:8081/api/import/excel",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      exportToJson(result.data);
      // exportToExcel()
      console.log("result", result);
    } catch (error) {
      console.log("error ---  ", error);
    }
  };
  const onConvertexcel = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await axios.post(
        "http://163.172.157.141:8081/api/export/excel",
        formData,

        {
          headers: {
            Accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          },
          responseType: "blob",
        }
      );
      //exportToJson(result.data);
      exportToExcel(result.data);
      console.log("result", result);
    } catch (error) {
      console.log("error ---  ", error);
    }
  };

  const handleFileChange = (e) => {
    const values = e.target.files;
    setFile(values[0]);
  };

  return (
    <div>
      <div
        style={{
          minHeight: 400,
          justifyContent: "center",
        }}
      >
        <form
          // onSubmit={submitForm}
          id="testForm"
          style={{ marginLeft: 500, marginTop: 300 }}
        >
          {/* <input type="text" id="testName" /> */}
          <input
            type="file"
            name="test"
            onChange={handleFileChange}
            id="testFile"
          />
          {/* <button type="submit">Submit</button> */}
          <Box style={{ marginTop: "-47px", marginLeft: "800px" }}>
            <BackBtn
              onClick={() =>
                file && file.name.includes(".xlsx")
                  ? onConvert()
                  : onConvertexcel()
              }
            >
              {
                !file
                  ? "Download"
                  : file && file.name.includes(".xlsx")
                    ? "Download JSON"
                    : "Download EXCEL"
              }
            </BackBtn>
          </Box>
        </form>
      </div>
    </div>
  );
}

export default CurateResponsibilitesone;
