import { Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

// Defining custom styles using makeStyles hook provided by Material-UI
const useStyles = makeStyles(theme => ({
    container: {
        // Setting minimum height of container to fill the viewport minus 81px
        minHeight: "calc(100vh - 81px)",
        // Centering content horizontally and vertically within the container
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
}))

// Functional component for displaying a "Page Not Found" message
function PageNotFound() {
    // Applying custom styles defined above
    const classes = useStyles()
    return (
        // Rendering a Material-UI Container component with custom styles
        <Container className={classes.container}>
            {/* Rendering a Material-UI Typography component with variant 'h1' and primary color */}
            <Typography variant='h1' color='primary'>Page Not Found</Typography>
        </Container>
    );
}

export default PageNotFound;