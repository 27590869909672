import React, { useContext, useEffect, useState } from "react";
import { makeStyles, Box, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useQuery } from "react-query";
import { SearchService } from "../../../../services/SearchService";
import { SubdirectoryArrowRightTwoTone } from "@material-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { TAB_CLEAR } from "../../../../state/constants/tab";
import TabContext from "../../../../contexts/TabContext";
import { nodeDetailTab } from "../../../../constants/tab";
import ShortNodeCardLogo from "../../short-node-card/short-node-card-logo/ShortNodeCardLogo";
import FullNodeCardLogo from "../full-node-card-logo/FullNodeCardLogo";
import "./model.scss";
import Tooltip from "../../tooltip/Tooltip";
import { CustomTooltip } from "../../tooltip/NodeTooltip";
const useStyles = makeStyles((theme) => ({
  root: {
    width: 568,
    background: "#fff",
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 4,
    [theme.breakpoints.down("768")]: {
      width: 328,
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: "20px 18px 20px 30px",
    borderBottom: "1px solid #ffff",
    [theme.breakpoints.down("768")]: {
      padding: "18px 16px",
    },
  },
  mainContent: {
    padding: 30,
    boxShadow: "0px 2px 12px rgba(23, 87, 123, 0.2)",
    background: "#ffffff",
    // height:100,
    // width:100,
    // [theme.breakpoints.down("768")]: {
    //   padding: "20px 16px",
    // },
  },
  microsoftWrapper: {
    width: "100%",
    background: "#fffff",
    height: 150,
    boxShadow: "0px 2px 12px rgba(23, 87, 123, 0.2)",
    borderRadius: 15,
    marginBottom: 20,
    padding: "20px 24px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("768")]: {
      padding: "14px 24px",
      marginBottom: 28,
    },
  },


  microsoftWrapper1: {
    width: "90%",
    background: "#fffff",
    height: 500,
    boxShadow: "0px 2px 12px rgba(23, 87, 123, 0.2)",
    borderRadius: 8,
    marginBottom: 37,
    padding: "20px 24px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("768")]: {
      padding: "14px 24px",
      marginBottom: 28,
    },
  },
  alertLabel: {
    [theme.breakpoints.down("768")]: {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  selectBox: {
    marginBottom: 20,
    [theme.breakpoints.down("768")]: {
      marginBottom: 12,
    },
  },
  addtooltip: {
    backgroundColor: "#fff", 
    color: "#123b64", 
    fontSize: "15px",
    boxShadow: '0px 4px 20px rgba(23, 87, 123, 0.2)', 
    padding: 10,
    borderRadius: 12
   
  },
  successIcon: {
    width: 32,
    height: 32,
    //background: "#ffff",
    display: "flex",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginRight: 10,
  },
  noteWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: 7,
    [theme.breakpoints.down("768")]: {
      marginTop: 4,
    },
  },
  note: {
    color: "#123b64b3",
    marginRight: 10,
    fontWeight: 400,
  },
  typography: {
    fontWeight: 400,
  },
}));

function ModalContent({ setOpen, data }) {
  // const ModalContent = ({ data, setOpen }) => {
  //   console.log("mnbv", data);
  //   const {
  //     securityClassification,
  //     personallyIdentifiableInformation,
  //     type,
  //     qualityScore,
  //   } = data;
  console.log("jh", data);
  const classes = useStyles();
  const [platform, setPlatform] = useState("Microsoft");
  const [options, setOptions] = useState([]);
  const [inNodetype, setInNodetype] = useState([]);
  const { pathname: location } = useLocation();
  const navigate = useNavigate();
  const { addTabHandler, dispatch } = useContext(TabContext);
  const extractContent = (s) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  const clickHandler = async (i) => {
    // i.stopPropagation();
    //const dataSearch = await SearchService.setTailNodeHistory(data.id, null);
    //  console.log("gfdfkjh", dataSearch.data);
    // console.log("kytyd", dataSearch.data.data);

    if (location !== "/tabs") {
      dispatch({ type: TAB_CLEAR });
    }
    const tabDetail = nodeDetailTab(i.id, extractContent(i.displayName));
    addTabHandler(tabDetail);
    navigate({
      pathname: "/tabs",
      search: `?key=${i.id}`,
    });
    //window.open(clickHandler(), "_blank");
  };

  const handleChange = (e) => {
    setPlatform(e.target.value);
  };
  const apiHandler = (url, event) => {
    event.stopPropagation();
    window.open(url, "_blank");
  };  
  //   const nodeType = async (event) => {
  //     //event.stopPropagation();
  //     const dataSearch = await SearchService.setTailNodeHistory(id, null);
  //     setInNodetype(dataSearch.data.data);
  //     console.log("gfdfkjh", dataSearch.data);
  //   };
  //   useEffect(() => {
  //     nodeType();
  //   }, [id]);
  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <Typography variant="h4" color="primary">
          Node Types
        </Typography>
        <IconButton
          onClick={() => setOpen(false)}
          style={{ padding: "0", marginLeft: "auto" }}
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.mainContent}>
        <Typography
          className={classes.alertLabel}
          // variant="subtitle1"
          color="primary"
        >
          {(data || []).map((i) => (
            <Box
              className={classes.microsoftWrapper}
              onClick={() => clickHandler(i)}
            >
              {" "}
              <div style={{ marginRight: 16 }}>
                <div style={{ marginTop: 10 }}>
                  <ShortNodeCardLogo Type={data.typeName} />
                </div>
                {/* <div>
                 {i.typeName} 
                </div> */}
                <div className="database-btn-wrapper w-100 d-none d-lg-flex justify-content-center">
                  <button
                    style={{
                      border: "none",
                      color: "#123B64",
                      borderRadius: 4,
                      fontSize: 10,
                      marginTop: -10,
                      width: 50,
                      height: 20,
                      padding: "2 8",
                      
                    }}
                  >
                    {i.typeName}
                  </button>
                </div>
              </div>
              <div style={{ marginLeft: -20, marginTop: 30, marginBottom: 5 }}>
              <CustomTooltip
    bottomStart="bottom-start"
    title="Click here"
    classes={{ tooltip: classes.addtooltip }}
  >
              <Typography
                style={{ marginLeft: 30,
                  fontSize: 20,
                  marginTop: -60,
                  cursor: "pointer",
                  whiteSpace: 'nowrap'
                  
                 }}
              >
                {i.displayName}
              </Typography>
              </CustomTooltip>
              </div>
              <div style={{ marginLeft: 20, marginTop: -60, marginBottom: 5 }}>
                <Box
                  disabled={!data.url}
                  style={{
                    borderRadius: 10,
                    fontSize: 16,
                    //fontFamily:'roboto',
                    color: "#123b64",
                    backgroundColor: "#dee6eb",
                    width: 100,
                    textAlign: "center",
                    border: "none",
                    cursor: "pointer",
                  }}
                  variant="h1"
                  onClick={(e) => apiHandler(i.url, e)}
                >
                  {i.sourceSystem}
                </Box>
              </div>
 

            
<div style={{ marginLeft:-200,marginTop:50 }}>
  <Tooltip
    variant="subtitle2"
    maxHeight={84}
    maxWidth={310}
    fontSize={14}
    title={i.description}
   
  />
</div>

              {/* <Box className={classes.microsoftWrapper1}>
              {i.description}
              </Box> */}
            </Box>
          ))}
        </Typography>
      </Box>
    </div>
  );
}
export default ModalContent;
