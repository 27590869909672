import React from 'react';
import { makeStyles, ButtonGroup, Button } from '@material-ui/core';

const useStyles = makeStyles({
    buttonGroup: {
        boxShadow: '0px 4px 4px rgba(18, 59, 100, 0.1)',
        borderRadius: 8
    },
    buttonOne: {
        borderRadius: "8px 1px 1px 8px",
        background: props => props.showSaved ? '#123b64' : '#fff',
        '& svg': {
            '& path': {
                stroke: props => props.showSaved ? '#fff' : '#123b64'
            }
        },
        '&:hover': {
            background: props => props.showSaved ? '#123b64' : '#fff',
            boxShadow: 'none'
        }
    },
    buttonTwo: {
        borderRadius: "1px 8px 8px 1px",
        background: props => props.showSaved ? '#fff' : '#123b64',
        '& svg': {
            '& path': {
                stroke: props => props.showSaved ? '#123b64' : '#fff'
            }
        },
        '&:hover': {
            background: props => props.showSaved ? '#fff' : '#123b64',
            boxShadow: 'none'
        }
    }
})
const ListViewButtonTwo = ({ setShowSaved, ...props }) => {
    const classes = useStyles(props)
    return (
        <ButtonGroup className={classes.buttonGroup} disableRipple variant="contained">
            <Button onClick={() => setShowSaved(true)} className={classes.buttonOne}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.75 5H8.58333M2.75 8.33333H8.58333M2.75 11.6667H8.58333M2.75 15H8.58333M11.9167 5H17.75M11.9167 8.33333H17.75M11.9167 11.6667H17.75M11.9167 15H17.75" stroke="#123B64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Button>
            <Button onClick={() => setShowSaved(false)} className={classes.buttonTwo}>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.25 2.5H9.08333V8.33333H3.25V2.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.4167 2.5H18.25V8.33333H12.4167V2.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12.4167 11.6667H18.25V17.5H12.4167V11.6667Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M3.25 11.6667H9.08333V17.5H3.25V11.6667Z" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </Button>
        </ButtonGroup>
    );
}

export default ListViewButtonTwo;