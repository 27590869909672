import React, { useEffect, useState } from "react";
import { Container, Box, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import { Link, useParams, useNavigate } from "react-router-dom";
import SectionAddRoles from "../../../add-section/section-roles/SectionAddRoles";
import { TeamService } from "../../../../services/TeamService";
import { FilterIcon } from "../../../icons";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import Sharenode from "../../../share/Shareregistration";
import SharePopover from "../../../../components/shared/popover/SharePopover";
import CustomDrawer from "../../../shared/drawer/CustomDrawer";
import CollectionSidebar from "../../../../pages/digital-publication/CollectionSidebar";

const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 150px)",
    paddingTop: 32,
    paddingBottom: 100,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapper: {
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 4,
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E0E7EC",
    padding: "20px 40px",
  },
  save: {
    marginRight: 15,
  },
});

function CurateResponsibility(data) {
  const classes = useStyles();
  const [teamOptions, setTeamOptions] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [openShareContent, setOpenShareContent] = useState(null);
  const [editContextData, setEditContextData] = useState({});
  const [contextName, setContextName] = useState("");
  const [team, setTeam] = useState([]);
  const [teamRoles, setTeamRoles] = useState(null);
  const [isTeamAvailable, setIsTeamAvailable] = useState(true);
  const [newTeam, setNewTeam] = useState("");
  const [teamName, setTeamName] = useState("");
  const [isTeamLoading, setIsTeamLoading] = useState(false);
  const navigate = useNavigate();
  const { key, nodeName } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);

  const deleteTeam = () => {
    TeamService.delete(teamId).then(() => {
      setTeamName("");
      if (teamType === "matrix") {
        setTeam([]);
      }
    });
  };

  const handleEditTeam = () => {
    TeamService.edit(teamId).then((res) => {
      if (res.data && res.data.length) {
        setTeamRoles(res.data);
      }
    });
  };

  const getUpdatedSimpleData = (data) => {
    let simpleTeam = { teamId: teamId, roles: {} };
    data.roles.forEach((item) => {
      simpleTeam.roles[item.role] = item.users;
    });

    setTeam(simpleTeam);
    if ((!teamName || teamName !== newTeam) && newTeam) {
      setTeamName(newTeam);
    }
  };

  const deleteContext = (context) => {
    TeamService.deleteContext(context, teamId).then(() => {
      let filteredTeam = team.filter((item) => item.context !== context);
      if (team.length === 1) {
        setNewTeam("");
        setTeamName("");
      }
      setTeam(filteredTeam);
    });
  };

  const getNewContext = (data) => {
    let max = 0;
    let roles = {};
    let matrixTeam = Array.isArray(team) ? team : [];

    if (team.length) {
      let contexts = matrixTeam.map((item) => item.context);
      contexts.forEach((item) => {
        let num = +item[item.length - 1];
        max = Math.max(max, num);
      });
      data.context = `Context${max + 1}`;
    } else {
      data.context = "Context1";
    }

    data.roles.forEach((item) => {
      roles[item.role] = item.users;
    });

    matrixTeam.push({ ...data, products: data.product, roles: [roles] });

    setTeam(matrixTeam);

    if ((!teamName || teamName !== newTeam) && newTeam) {
      setTeamName(newTeam);
    }
  };

  const handleEditContext = (context) => {
    TeamService.editContext(context, teamId).then((res) => {
      const { roles } = res.data;

      setContextName(res.data.context);
      const formattedRoles = roles.map((item) => {
        return {
          role: Object.keys(item)[0],
          users: Object.values(item)[0],
          errors: {
            role: null,
            users: null,
          },
        };
      });

      const contextData = {
        dataDomain: res.data["Data Domain"],
        business: res.data.Business,
        geography: res.data.Geography,
        product: res.data.Products,
        errors: {
          business: null,
          dataDomain: null,
          geography: null,
          product: null,
        },
        roles: formattedRoles,
      };
      setEditContextData(contextData);
    });
  };

  const getTeamOptions = () => {
    TeamService.fetchTeamOptions().then((res) => {
      let formatedData = res.data.map((item) => {
        return {
          value: item._key,
          label: item.displayName,
          type: item.teamStructure,
        };
      });
      setTeamOptions(formatedData);
    });
  };

  const updateContext = (updatedData) => {
    const { context } = updatedData;
    const products = context[0].product;
    delete context[0].product;
    let newRoles = {};

    context[0].roles.forEach((item) => (newRoles[item.role] = item.users));

    const updatedContext = {
      ...context[0],
      teamId,
      context: context[0].contextName,
      products,
      roles: [newRoles],
    };

    const matrixTeam = team.map((item) =>
      item.context === updatedContext.context ? updatedContext : item
    );

    setTeam(matrixTeam);
  };

  const handleNext = () => {
    const paylaod = [{ name: teamName, roleName: "team", teamId }];
    const keys = Object.keys(team);
    if (keys.length) {
      NodeCreationService.addTeamToNode(key, paylaod).then(() => {
        setIsTeamAvailable(true);
        navigate(`/servicedataproduct/${nodeName}/${key}`);
      });
    } else {
      setIsTeamAvailable(false);
    }
  };

  useEffect(() => {
    if (teamOptions.length) {
      NodeCreationService.getDataProductTeamDetails(key).then((res) => {
        if (res.data.length) {
          const teamID = res.data[0].teamId.slice(6);
          let selectedTeam = teamOptions.find(
            (option) => option.value === teamID
          );
          setTeamName(selectedTeam.label);
        }
      });
    }
  }, [key, teamOptions]);

  useEffect(() => {
    if (teamName) {
      setIsTeamLoading(true);
      let selectedTeam = teamOptions.find(
        (option) => option.label === teamName
      );
      const { type: teamType, value: teamID } = selectedTeam;
      setTeamType(teamType);
      setTeamId(teamID);

      if (teamType === "matrix") {
        TeamService.fetchMatrixTeam(teamID)
          .then((res) => {
            setTeam(res.data);
            setTeamRoles([]);
            setIsTeamLoading(false);
          })
          .catch(() => setIsTeamLoading(false));
      }
      if (teamType === "simple") {
        TeamService.fetchSimpleTeam(teamID)
          .then((res) => {
            setTeam(res.data);
            setTeamRoles([]);
            setIsTeamLoading(false);
          })
          .catch(() => setIsTeamLoading(false));
      }
    } else {
      getTeamOptions();
    }
  }, [teamName]);

  return (
    <Container className={classes.container}>
      <Typography
        style={{ marginBottom: 24 }}
        align="center"
        variant="h1"
        color="primary"
      >
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
      </Typography>
      <Box className={classes.wrapper} mb={5}>
        <Box className={classes.titleWrapper}>
          <Typography variant="h5" color="primary">
            2.3 Curate / Responsibilities
          </Typography>
          <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer>
          <IconButton onClick={() => setOpenSidebar(true)} style={{ padding: 0 }}>
            <FilterIcon />
          </IconButton>
        </Box>
        <Box p={5}>
          <Box sx={{ maxWidth: 716 }}>
            <SectionAddRoles
              setTeamName={setTeamName}
              isTeamAvailable={isTeamAvailable}
              teamName={teamName}
              teamOptions={teamOptions}
              teamType={teamType}
              teamId={teamId}
              getUpdatedSimpleData={getUpdatedSimpleData}
              deleteTeam={deleteTeam}
              deleteContext={deleteContext}
              handleEditTeam={handleEditTeam}
              team={team}
              getTeamOptions={getTeamOptions}
              getNewContext={getNewContext}
              handleEditContext={handleEditContext}
              editContextData={editContextData}
              contextName={contextName}
              updateContext={updateContext}
              teamRoles={teamRoles}
              setNewTeam={setNewTeam}
              isTeamLoading={isTeamLoading}
              setIsTeamLoading={setIsTeamLoading}
            />
          </Box>
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none" }}
          to={`/curatesecurity/${nodeName}/${key}`}
        >
          <BackBtn>Back</BackBtn>
        </Link>
        <Box>
          <SharePopover
            anchorEl={openShareContent}
            setAnchorEl={setOpenShareContent}
            popoverButton={
              <div style={{ paddingLeft: 500 }}>
                <BackBtn type="submit">Assign</BackBtn>
              </div>
            }
          >
            <Sharenode setOpenShare={setOpenShareContent} data={data} />
          </SharePopover>
          <BackBtn
            onClick={handleNext}
            style={{ marginLeft: 800, marginTop: -80 }}
          >
            Next
          </BackBtn>
        </Box>
      </Box>
    </Container>
  );
}
export default CurateResponsibility;
