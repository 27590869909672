import React from 'react';
import { Box, InputBase, makeStyles, withStyles, InputAdornment } from '@material-ui/core'

const Input = withStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#F2F3F4',
        paddingRight: 2,
        borderRadius: 8,
        'label + &': {
            marginTop: theme.spacing(2.5),
        },
        '& ::placeholder': {
            color: '#123b64',
            opacity: 0.5
        }
    },
    input: {
        height: 20,
        color: '#123b64',
        border: 'none',
        position: 'relative',
        fontSize: 14,
        padding: '6px 10px!important',
        borderRadius: '8px 0 0 8px',
       
    },
}))(InputBase);

const useStyles = makeStyles({
    collectionInput: {
        display: 'flex',
        width: '100%',
    },
    searchIconWrapper: {
        width: 32,
        height: 28,
        background: '#F79F19',
        borderRadius: 6,
        marginLeft: 10,
        padding: '4px 6px'
    },
})




function CollectionInput({handleChange, text, placeholder="Search collection"}) {

    const classes = useStyles();
    return (
        <Box className={classes.collectionInput}>
            <Input
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
                value={text}
                id="bootstrap-input"
                endAdornment={
                    <InputAdornment position="end">
                        <Box className={classes.searchIconWrapper}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.5 16.5L12.875 12.875M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </Box>
                    </InputAdornment>
                }
            />
        </Box>
    );
}

export default CollectionInput;