import { makeStyles } from "@material-ui/core";

export const NEW_NODE = {
  api: {
    title: "API",
    description: `An application programming interface (API) is a connection between computers or between computer programs.
       It is a type of software interface, offering a service to other pieces of software. A document or
       standard that describes how to build or use such a connection or interface is called an API specification.
       A computer system that meets this standard is said to implement or expose an API.
      The term API may refer either to the specification or to the implementation.`,
    // `A Model represents a versioned artifact that captures a trained machine learning model
    //  and its associated metadata, allowing you to track, manage, and deploy
    // models throughout the machine learning lifecycle.`,
  },
  dataSet: {
    title: "Data Set",
    description: `A Data set  is a collection of data. In the case of tabular data,
         a data set corresponds to one or more database tables, where every column of a 
         table represents a particular variable, and each row corresponds to a given 
         record of the dataset  in question. The data set lists values for each of the variables, 
         such as for example height and weight of an object, for each member of the data set. 
         Data sets can also consist of a collection of documents or files.`,
  },
  dataProduct: {
    title: "Data Product",
    description: `Data product holds the context-oriented data and the code that performs 
        the necessary data transformations and policies governing and securely sharing 
        the data while encapsulating all the necessary components needed to implement 
        a discoverable, addressable (Unique graphID), Understandable , 
        trustful, accessible, Interoperable data product.`,
  },
};

export const useStyles = makeStyles({
  addNewRoles: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 146,
    height: 18,
    left: 470,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1070,
    color: "#123B64",
  },
  addNewRole: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 350,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1100,
    color: "#123B64",
  },
  Refine: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 355,
    left: 900,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1310,
    color: "#123B64",
  },
  Refinedata: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 800,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1340,
    color: "#123B64",
  },
  Explore: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 355,
    left: 465,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1585,
    color: "#123B64",
  },
  Exploredata: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 360,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1610,
    color: "#123B64",
  },
  Traverse: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 355,
    left: 900,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1755,
    color: "#123B64",
  },
  Traversedata: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 800,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1780,
    color: "#123B64",
  },
  stepsWrapper: {
    maxWidth: 236,
    width: "100%",
    padding: "40px 34px",
    boxShadow: "0px 4px 20px rgba(23, 87, 123, 0.2)",
    borderRadius: 16,
  },
  nodeTypeWrapper: {
    width: "100%",
    padding: 30,
    boxShadow: "0px 4px 20px rgba(23, 87, 123, 0.2)",
    borderRadius: 16,
    marginLeft: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 500,
    width: 30,
    height: 30,
    marginRight: 9,
    borderRadius: "50%",
    background: "#123B64",
    color: "white",
    "&:hover": {
      background: "#f79f19",
    },
  },
  verticalLine: {
    position: "absolute",
    left: 13.5,
    height: 64,
    width: 3,
    marginTop: 2.5,
    background: "#123b64",
  },
  description: {
    fontWeight: 400,
    marginTop: 10,
    minHeight: 80,
  },
  nodeTypeButton: {
    width: 290,
    height: 60,
    border: "1px solid #808C9A",
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderRadius: 16,
    cursor: "pointer",
  },
  activeNodeTypeButton: {
    width: 290,
    height: 60,
    border: "1px solid #808C9A",
    display: "flex",
    alignItems: "center",
    padding: 10,
    borderRadius: 16,
    background: "#123b64",
    color: "#fff",
    cursor: "pointer",
  },
  iconWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
    marginRight: 15,
    borderRadius: "50%",
    background: "linear-gradient(155.29deg, #90D1CC 3.46%, #418E84 110.38%)",
  },
  yellowButton: {
    width: 182,
    "& svg": {
      width: 15,
      height: 16,
    },
  },
  title: {
    lineHeight: 1.2,
    marginBottom: 40,
  },
  nodeHierarchyWrpper: {
    display: "flex",
    justifyContent: "center",
  },
});
