import React from 'react';
import { GraphIcon, OutlinedPinIcon, ShareIcon } from '../../../icons';
import './card-icon-btn.scss'

const CardIconBtn = (props) => {
    const {
        toggleName,
        data,
        space,
        positionRelative,
        positionAbsolute
    } = props

    return (
        <div className={`add-to-card ${positionRelative ? positionRelative : ''} ${data === 'add' ? 'basket' : ''} ${space ? space : ''} d-flex align-items-center`} >
            <p className={`text-secondary-100 ${data === 'add' ? 'd-block' : 'none'}`}>{toggleName}</p>
            <div className={`icon-wrapper d-flex align-items-center ${positionAbsolute ? positionAbsolute : ''}`}>
                {
                    data === 'saved' ? <OutlinedPinIcon />

                        : data === 'shared' ? <ShareIcon />
                            :
                            data === 'graph' ? <GraphIcon /> : null
                }
            </div>
        </div>

    );
};

export default CardIconBtn;
