import {
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { CustomCheckbox } from "../../../../shared/inputs/CheckBox";
import NodeTooltip from "../../../../shared/tooltip/NodeTooltip";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { useContext } from "react";
import DataContext from "../../../../../contexts/Context";

const useEnhancedTable = makeStyles({
  tableCell: {
    padding: 11,
    color: "#123b64",
    borderBottom: "1px solid  #1f20201a",
  },
  checkboxLabel: {
    marginLeft: 0,
    "& span": {
      fontSize: 14,
      fontWeight: 700,
    },
  },
  box: {
    width: 85,
  },
});

const TableHeaderJob = (props) => {
  const classes = useEnhancedTable();
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    expiredOrders,
    headCells,
    allNodes,
    removeAllNodesFromOrder,
  } = props;
  const { addAllNodestoCart } = useContext(DataContext);
 
  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.tableCell}>
          {/* <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <CustomCheckbox
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
              />
            }
            label="ALL"
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            style={
              headCell.id === "description-node"
                ? { width: 300 }
                : { minWidth: 110, }
            }
            className={classes.tableCell}
            key={headCell.id}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell>
          <NodeTooltip
            placement="bottom-end"
            title={expiredOrders ? " " : "Cancel all"}
            name="icon-button"
          >
            {expiredOrders ? (
              <IconButton onClick={() => addAllNodestoCart(allNodes)}>
                {/* <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.919922 1.07617C0.919922 0.523887 1.36764 0.0761719 1.91992 0.0761719H5.91992C6.39655 0.0761719 6.80693 0.412557 6.90047 0.879915L7.74035 5.07617H23.9199C24.2179 5.07617 24.5004 5.2091 24.6904 5.43873C24.8804 5.66835 24.958 5.97075 24.9022 6.2635L23.3008 14.661C23.0184 16.0829 21.7598 17.0989 20.3113 17.0762H10.6086C9.16036 17.0989 7.902 16.0833 7.61925 14.6618C7.61919 14.6616 7.61931 14.6621 7.61925 14.6618L5.94871 6.31537C5.94194 6.28777 5.9363 6.25971 5.93187 6.23125L5.10024 2.07617H1.91992C1.36764 2.07617 0.919922 1.62846 0.919922 1.07617ZM8.14065 7.07617L9.58076 14.2714C9.67513 14.7465 10.0965 15.0856 10.5808 15.0764L10.5999 15.0762H20.3199L20.3391 15.0764C20.8223 15.0856 21.2428 14.748 21.3384 14.2746L22.7112 7.07617H8.14065ZM7.91992 21.0762C7.91992 19.9716 8.81535 19.0762 9.91992 19.0762C11.0245 19.0762 11.9199 19.9716 11.9199 21.0762C11.9199 22.1807 11.0245 23.0762 9.91992 23.0762C8.81535 23.0762 7.91992 22.1807 7.91992 21.0762ZM18.9199 21.0762C18.9199 19.9716 19.8154 19.0762 20.9199 19.0762C22.0245 19.0762 22.9199 19.9716 22.9199 21.0762C22.9199 22.1807 22.0245 23.0762 20.9199 23.0762C19.8154 23.0762 18.9199 22.1807 18.9199 21.0762Z"
                    fill="#123B64"
                  />
                </svg> */}
              </IconButton>
            ) : (
              <IconButton onClick={() => removeAllNodesFromOrder()}>
                {/* <CancelOutlinedIcon /> */}
              </IconButton>
            )}
          </NodeTooltip>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
export default TableHeaderJob;
