import { createContext, useReducer, useState } from "react";
import { DEFAULT_SORT } from "../constants/searchResults";
import { TAB_TYPES } from "../constants/tab";
import { SearchService } from "../services/SearchService";
import { TAB_ADD, TAB_CLEAR, TAB_REMOVE, TAB_UPDATE } from "../state/constants/tab";
import useLocalStorage from './../hooks/useLocalStorage';

const TabContext = createContext()

export const TabProvider = ({ children }) => {
    const params = new URLSearchParams(window.location.search);
    const nodeId = params.get("key");
    const nodeName = params.get("nodeName");
    const [activeTab, setActiveTab] = useState(nodeId ? nodeId : nodeName ? TAB_TYPES.FILTER : 0)
    const [getLocalStorage, setLocalStorage] = useLocalStorage('tabStore')
    const [searchTerm, setSearchTerm] = useState()
    const [selectedFilterTypes, setSelectedFilterTypes] = useState([])
    const [phraseFilterType, setPhraseFilterType] = useState('')
    const [isListView, setIsListView] = useState(false)
    const [sort, setSort] = useState(DEFAULT_SORT);
    const [isNewSearch, setIsNewSearch] = useState(false)
    const [nodeType, setNodeType] = useState([])
    const [payload, setPayload] = useState({})
    const [searchPaylaod, setSearchPayload] = useState({})
    const [isNodeTypeFilter, setIsNodeTypeFilter] = useState(false)
    const [searchNodeData,setSearchNodeData]= useState([])

    const tabReducer = (prevState, action) => {
        let tabsStore = []
        switch (action.type) {
            case TAB_ADD:
                //always add search results at the beginning
                if (action.payload.type === TAB_TYPES.FILTER) {
                    const nonFilterTabs = prevState.filter(tab => tab.type !== TAB_TYPES.FILTER)
                    tabsStore = [action.payload, ...nonFilterTabs]
                } else {
                    tabsStore = [...prevState, action.payload]
                }
                setLocalStorage(tabsStore)
                return tabsStore;
            case TAB_UPDATE:
                tabsStore = [...prevState]
                if (action.payload.type === TAB_TYPES.FILTER) {
                    let newNodeTitle = ''

                    if (!(selectedFilterTypes && selectedFilterTypes.length)) {
                        newNodeTitle = action.payload.nodeTitle
                    }
                    tabsStore[0] = {
                        ...tabsStore[0],
                        ...action.payload,
                        nodeTitle: newNodeTitle
                    }
                } else {
                    const tabIndex = tabsStore.findIndex(tab => tab.id === action.payload.id);
                    tabsStore[tabIndex] = {
                        ...tabsStore[tabIndex],
                        loading: action.payload.loading,
                        error: action.payload.error,
                        data: action.payload.data
                    }
                }

                if (!action.payload.error) {
                    setLocalStorage(tabsStore)
                }
                return tabsStore;
            case TAB_CLEAR:
                setLocalStorage([])
                return []
            case TAB_REMOVE:
                tabsStore = [...prevState]
                const index = tabsStore.indexOf(action.payload);
                tabsStore = tabsStore.filter(tab => tab.id !== action.payload.id)

                if (action.payload.id === activeTab && tabsStore.length) {
                    if (tabsStore.length > index) {
                        setActiveTab(tabsStore[index].id)
                    } else {
                        setActiveTab(tabsStore[tabsStore.length - 1].id)
                    }
                }
                setLocalStorage(tabsStore)
                return tabsStore;
            default:
                return prevState
        }
    }

    const initialState = () => {
        return getLocalStorage() ? getLocalStorage() : []
    }

    const [tabs, dispatch] = useReducer(tabReducer, initialState())

    const addSearchTabHandler = (payload) => {
        const existingTab = tabs.find(tab => tab.type === TAB_TYPES.FILTER)
        if (existingTab) {
            dispatch({ type: TAB_UPDATE, payload: payload })
        } else {
            dispatch({ type: TAB_ADD, payload: payload })
        }
    }

    const addTabHandler = (payload) => {
        const existingTab = tabs.find(tab => tab.id === payload.id)
        setActiveTab(payload.id)
        if (!existingTab) {
            dispatch({ type: TAB_ADD, payload: payload })
            fetchNodeViewerData(payload.id)
        }
    }

    const fetchNodeViewerData = (id) => {
        SearchService.setViewHistory(id)
            .then(res => {
                return { loading: false, id: id, data: res.data, error: false }
            })
            .catch(() => {
                return { id: id, loading: false, error: true, data: null }
            }).then(payload => {
                dispatch({ type: TAB_UPDATE, payload: payload })
            })
    }

    const updatePayload = (payload) => {
        setPayload(payload)
    }

    const upadateSearchPayload = (payload) => {
        setSearchPayload(payload)
    }

    return (
        <TabContext.Provider value={{
            tabs, dispatch,
            addTabHandler, addSearchTabHandler,
            activeTab, setActiveTab,
            payload, updatePayload,
            phraseFilterType, setPhraseFilterType,
            searchTerm, setSearchTerm,
            isListView, setIsListView,
            selectedFilterTypes, setSelectedFilterTypes,
            sort, setSort,
            isNewSearch, setIsNewSearch,
            nodeType, setNodeType,
            upadateSearchPayload, searchPaylaod,
            isNodeTypeFilter, setIsNodeTypeFilter,
            searchNodeData,setSearchNodeData,
        }}>
            {children}
        </TabContext.Provider>
    )
}

export default TabContext