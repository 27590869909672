import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles({
  box: {
    width: 300,
    borderRadius: 8,
    background: "#E6ECF0",
    padding: 16,
    position: "relative",
  },
  arrow: {
    width: 20,
    height: 20,
    background: "#E6ECF0",
    position: "absolute",
    top: (props) => (props.pathname === "/home" ? "unset" : -10),
    bottom: (props) => (props.pathname === "/home" ? -10 : "unset"),
    right: 25,
    transform: "rotate(45deg)",
  },
  link: {
    fontWeight: 700,
    color: "#123b64",
    "&:hover": {
      color: "#3e76ad",
    },
  },
});

function TooltipContent() {
  const { pathname } = useLocation();
  const classes = useStyles({ pathname });
  return <></>;
}

export default TooltipContent;
