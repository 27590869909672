import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { NodeCreationService } from "../../../services/NodeCreationService";
import { NodeList } from "../../filter-region/node-list/NodeList";
import { FilterIcon, TrashBinIcon } from "../../icons";
import {
  CircleButton,
  OutlinedButton,
} from "../buttons/custom-buttons/CustomButtons";
import ListViewTypeButton from "../buttons/list-view-button/ListViewTypeButton";
import ErorrPage from "../error/Erorr";
import SearchInput from "../inputs/SearchInput";
import Loader from "../loader/Loader";
import SliderPlaceholder from "../placeholder/SliderPlaceholder";
import { CustomTooltip } from "../tooltip/NodeTooltip";


const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 150px)",
    paddingBottom: 100,
    paddingTop: 40,
  },
  header: {
    marginBottom: 30,
  },
  validation: {
    marginBottom: 60,
  },
  rightSideContent: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    fontSize: 12,
    minWidth: 108,
    textAlign: "center",
    color: "#123b64",
    padding: "7px 5px",
    borderRadius: 6,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  button: {
    width: 219,
    "& span": {
      fontWeight: 700,
    },
  },
}));

const SamplecardDataset = () => {
  const apiHandler = () => {
    window.open("https://pkware.alationcatalog.com/sources/", "_blank");
  };

  const classes = useStyles();
  const [searchNodeName, setSearchNodeName] = useState("");
  const [isListView, setIsListView] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [databases, setDatabases] = useState([]);
  const [sources, setSources] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedNodes, setSearchedNodes] = useState([]);
  const scrollRef = useRef(null);
  const { key, nodeName } = useParams();
  const params = useParams();
  const location = useLocation()
  const Index = location.search.replace("?source=","")
  // console.log(Index,"paramsss")

  const handleChange = (e) => {
    setSearchNodeName(e.target.value);
    const filteredNodes = nodes.filter((node) => {
      return node.displayName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchedNodes(filteredNodes);
  };

  // useEffect(() => {
  //   NodeCreationService.getDatabasesFromSource()
  //     .then((res) => {
  //       setNodes(res.data);
  //       setIsLoading(false);
  //     })
  //     .catch(() => {
  //       setIsError(true);
  //       setIsLoading(false);
  //     });
  // }, []);
 
  //
  const dataSources = () => {
   return NodeCreationService.getDatasetSourcesystems()
      .then((response) => {
        // console.log("iuyutreew",response)
        if (response && response.data && response.data.length > 0) {
           setSources(response.data);
           //setIsLoading(false);
          // setSources([])
          //navigate(`/data_set_samplecard/${nodeName}/${key}`)
        }
        else{
          console.log("iuyutreew")
        }
      })
      .catch((error) => {
        console.error("Error fetching more from user:", error);
      });
  };
  // console.log("';lkjhv",sources)
  useEffect(() => {
    dataSources()
   
    
  }, []);
  const sourceSelection = () => {
    const payloadData = {
      key: Index,
      pagination: {
        pageNumber: 1,
        pageSize: 9,
      },
    };
    // console.log(";/nbm,mnnb",payloadData )
    return NodeCreationService.getDatabasesFromSource(payloadData)
      .then((response) => {
        console.log("iuyutreew",response)
        if (response.data) {
           setNodes(response.data.data);
           setIsLoading(false);
          //navigate(`/data_set_samplecard/${nodeName}/${key}`)
        }
      })
      .catch((error) => {
        console.error("Error fetching more from user:", error);
      });
  };
 
 
 
  useEffect(() => {
    setSearchedNodes(nodes);
   
    // sourceSelection()
  }, [nodes]);
  // console.log(nodes,"nodesssss")
  useEffect(() => {
    sourceSelection()
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErorrPage />;
  }

  return (
    <Container ref={scrollRef} className={classes.root}>
      <Box>
        <Typography
          className={classes.header}
          variant="h1"
          color="primary"
          align="center"
        >
          {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
        </Typography>
        <Typography
          className={classes.validation}
          variant="h3"
          color="primary"
          align="center"
        >
          Please select any of the Database,you may find hierarchy of
          table,schemas and columns to create a relation!!
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 45,
          }}
        >
          <Box sx={{ maxWidth: 415, width: "100%" }}>
            <SearchInput
              value={searchNodeName}
              setValue={setSearchNodeName}
              handleChange={handleChange}
            />
          </Box>
          <Box className={classes.rightSideContent}>
            <CustomTooltip
              arrow
              title={
                <Typography className={classes.tooltip}>
                  Create New Dataset
                </Typography>
              }
            >
              <CircleButton
                onClick={apiHandler}
                style={{ margin: "0 8px" }}
                disableRipple
              >
                <AddIcon />
              </CircleButton>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={
                <Typography className={classes.tooltip}>Open filter</Typography>
              }
            >
              <CircleButton style={{ margin: "0 8px" }} disableRipple>
                <FilterIcon />
              </CircleButton>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={
                <Typography className={classes.tooltip}>Delete</Typography>
              }
            >
              <CircleButton style={{ margin: "0 8px" }} disableRipple>
                <TrashBinIcon />
              </CircleButton>
            </CustomTooltip>
            <Box ml={4}>
              <ListViewTypeButton
                handleListViewChange={setIsListView}
                isListView={isListView}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {nodes.length > 0 ? (
        <NodeList
          nodes={nodes}
          showSaved={isListView}
          nodesPerPage={6}
          ref={scrollRef}
          isClickable={true}

        />
      ) : (
      
       <SliderPlaceholder error={true} text="No data found" />
      )}
      <Box className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          
          to={`/data_set_source/${nodeName}/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
      </Box>
    </Container>
  );
};

export default SamplecardDataset;
