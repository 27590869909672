import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    footer: {
        background: theme.palette.primary.secondary,
        padding: '30px 40px',
        marginTop: 'auto'
    },
    link: {
        textDecoration: 'none',
        color: '#CCD1D7',
        fontSize: 14,
        marginRight: 24,
        marginTop: -1.5,
        "&:hover": {
            color: '#fff'
        }
    },
    typography: {
        fontSize: 14,
        color: '#CCD1D7',

    },
    copyRightWrapper: {
        [theme.breakpoints.between('xs', "sm")]: {
            order: 2
        }
    },
    copyRight: {
        fontSize: 14,
        textAlign: 'center',
        color: "#CCD1D7"
    },
    box: {
        display: 'flex',
        justifyContent: 'end',
        [theme.breakpoints.between('xs', 'sm')]: {
            justifyContent: 'center!important',
            marginBottom: 20,
            flexDirection: 'row-reverse',
            '& p': {
                marginRight: 32
            },
            '& a': {
                marginRight: 0
            }
        }
    }
}))

const Footer = () => {

    const classes = useStyles()
    return (
        <Box>
            <footer className={classes.footer}>
                <Grid container>
                    <Grid item md={4} />
                    <Grid className={classes.copyRightWrapper} item xs={12} md={4}>
                        <Typography className={classes.copyRight}>Copyright © 2024 Inc. All rights reserved.</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box className={classes.box}>
                            <Link className={classes.link} to="/privacy-policy">
                                Privacy Policy
                            </Link>
                            <Typography className={classes.typography}>Terms of Service</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </footer>
        </Box>

    );
};


export default Footer;