import React from 'react';
import errorImg from './../../../assets/images/node-viewer/errorImg.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    error: {
        minHeight: 'calc(100vh - 150px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    img: {
        marginBottom: '43px',
    },
    ops: {
        marginBottom: '20px',
        fontSize: '48px',
        color: '#123b64',
        fontWeight: 'bold'
    },
    pa: {
        fontSize: '28px',
        fontWeight: '500',
        color: '#123b64',
    }
})

const ErorrPage = () => {
    const classes = useStyles()
    return (
        <div className={classes.error}>
            <img src={errorImg} alt="error-page" className={classes.img} />
            <center className={classes.ops}>Oops!</center>
            <p className={classes.p}>We can’t seem to find the page you’re looking for.</p>
        </div>
    );
};

export default ErorrPage;

