import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CustomDrawer from '../../../../shared/drawer/CustomDrawer';
import SimpleTeamBuilder from '../../../../shared/team-builder/simple-team-builder/SimpleTeamBuilder';
import { TextButton } from '../../../../shared/buttons/custom-buttons/CustomButtons';
import AddIcon from '@material-ui/icons/Add';
import MatrixTeamWrapper from '../../../../shared/team-builder/matrix-team-builder/MatrixTeamWrapper';
// Custom styles using Material-UI's makeStyles hook
const useStyles = makeStyles({
    root: {
        background: '#F9F9F9',
        width: "100%",
        padding: 24,
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center'
    },
    textButton: {
        fontWeight: 700,
        '& .MuiButton-startIcon': {
            marginRight: 3,
        }
    },
})

function TeamPlaceholder(props) {
    const { teamId, teamType, getUpdatedSimpleData, getNewContext, setIsTeamLoading } = props
    const classes = useStyles()
    const [state, setState] = useState(false)
    return (
        <>
            <Box className={classes.root}>
                <Typography variant='body2' color="secondary">There is not data</Typography>
                <TextButton
                    onClick={() => setState(true)}
                    disableRipple
                    className={classes.textButton}
                    startIcon={<AddIcon />}
                >
                    {teamType === 'simple' ? 'Add role' : 'Add Context'}
                </TextButton>
            </Box>
            <CustomDrawer setState={setState} state={state} anchor='right'>
                {
                    teamType === "simple" ?
                    <SimpleTeamBuilder 
                        isEmptyTeam={true}
                        getUpdatedSimpleData={getUpdatedSimpleData} 
                        teamId={teamId} 
                        setState={setState} 
                    /> :
                    <MatrixTeamWrapper 
                        getNewContext={getNewContext} 
                        setState={setState} 
                        teamId={teamId}
                        setIsTeamLoading={setIsTeamLoading}
                        requestType="add-context"
                    />
                }
            </CustomDrawer>
        </>
    );
}

export default TeamPlaceholder;