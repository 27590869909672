import { Box, Container, Typography, makeStyles } from "@material-ui/core";
import { FaRegClock } from "react-icons/fa";
import Slider from "react-slick";
import RecentCollection from "./RecentCollection";
import { useQuery } from "react-query";

const useStyles = makeStyles({
  label: {
    display: "flex!important",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#63ada6",
    whiteSpace: "nowrap",
    height: (props) => (props.isRecentCollection ? 40 : 28),
  },
});

function HomeSlider({
  settings,
  isLoading,
  recentSearch,
  searchByRelatedText,
  isRecentCollection,
}) {
  const classes = useStyles({ isRecentCollection });

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  return (
    <Container>
      {recentSearch.length > 0 ? (
        <Slider {...settings}>
          <Box className={classes.label}>
            <FaRegClock />
            <Typography variant="body2" className="ms-2 me-3">
              {isRecentCollection
                ? "My recent collections"
                : "My recent searches"}
            </Typography>
          </Box>
          {recentSearch.map((search, index) => {
            // console.log(recentSearch,"recentSearch")
            if (isRecentCollection) {
              return (
                <RecentCollection
                  key={index}
                  searchByRelatedText={searchByRelatedText}
                  recentCollection={search}
                />
              );
            } else {
              return (
                <p
                  key={index}
                  onClick={() => searchByRelatedText(search)}
                  className="pb-0 slider-paragraph mb-0 mx-2 border-0"
                >
                  {search}
                </p>
              );
            }
          })}
        </Slider>
      ) : null}
    </Container>
  );
}

export default HomeSlider;
