import { useState } from "react";
import NewTableSearchBar from "../table-search-bar/NewTableSearchBar";
import './table-head-title.scss'
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        top: -4,
        right: 0,
        zIndex: 1,
    },
}));

const TableHeadTitle = (props) => {
    const classes = useStyles()

    const { clickHandler, setSearchText, searchText, setSearchByColumn, column } = props
    const ASC = "asc"
    const DESC = "desc"
    const [open, setOpen] = useState(false);

    const createSortHandler = (property, sortName) => (event) => {
        clickHandler(event, property, sortName);
    };

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <div className="table-heead-title d-flex align-items-center position-relative">
            <span className="me-2 text-secondary-100 fw-bold" style={{ lineHeight: '19.6px' }}>{column.name}</span>
            <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classes.root}>
                    <div
                        className="me-2 z-index"
                        onClick={handleClick}
                    >
                        <svg
                            width="12"
                            height="12"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M13 13L10.1 10.1M6.33333 11.6667C9.27885 11.6667 11.6667 9.27885 11.6667 6.33333C11.6667 3.38781 9.27885 1 6.33333 1C3.38781 1 1 3.38781 1 6.33333C1 9.27885 3.38781 11.6667 6.33333 11.6667Z" stroke="#123B64" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </div>
                    {open ? (
                        <div className={classes.dropdown}>
                             <NewTableSearchBar 
                                column={column} 
                                setSearchByColumn={setSearchByColumn} 
                                setSearchText={setSearchText} 
                                searchText={searchText} 
                            />
                        </div>
                    ) : null}
                </div>
            </ClickAwayListener>
            <div className="d-flex align-items-center flex-column">
                <button onClick={createSortHandler(column.id, ASC)} style={{ padding: 0, height: 4, marginBottom: 3 }} className="btn btn-light filter-arrow-btn">
                    <svg className="d-block" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 4L4 3.49691e-07L0 4" fill="#123B64" />
                    </svg>
                </button>
                <button onClick={createSortHandler(column.id, DESC)} style={{ padding: 0, height: 4 }} className="btn btn-light filter-arrow-btn">
                    <svg className="d-block" width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0L4 4L8 -6.99382e-07" fill="#123B64" />
                    </svg>
                </button>
            </div>
        </div>
    );
};

export default TableHeadTitle;