import React, { useState } from 'react';
import { makeStyles, Box, Typography, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CustomizedSelect from '../../shared/inputs/CustomizedSelect';
import { YellowButton } from './../../shared/buttons/custom-buttons/CustomButtons'
import { SearchService } from '../../../services/SearchService';
import { useQuery } from 'react-query';

const useStyles = makeStyles(theme => ({
    root: {
        width: 568,
        background: '#fff',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        [theme.breakpoints.down('768')]: {
            width: 328,
        }
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 18px 20px 30px',
        borderBottom: '1px solid #E0E7EC',
        [theme.breakpoints.down('768')]: {
            padding: '18px 16px'
        }
    },
    mainContent: {
        padding: 30,
        [theme.breakpoints.down("768")]: {
            padding: "20px 16px"
        }
    },
    microsoftWrapper: {
        width: '100%',
        background: '#DEE6EB',
        borderRadius: 8,
        marginBottom: 37,
        padding: '20px 24px',
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down("768")]: {
            padding: "14px 24px",
            marginBottom: 28
        }
    },
    alertLabel: {
        [theme.breakpoints.down('768')]: {
            fontSize: 16,
            fontWeight: 700
        }
    },
    selectBox: {
        marginBottom: 20,
        [theme.breakpoints.down("768")]: {
            marginBottom: 12
        }
    },
    successIcon: {
        width: 32,
        height: 32,
        background: '#13AF3F',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        marginRight: 10
    },
    noteWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 7,
        [theme.breakpoints.down("768")]: {
            marginTop: 4
        }
    },
    note: {
        color: '#123b64b3',
        marginRight: 10,
        fontWeight: 400
    },
    typography: {
        fontWeight: 400
    }
}))

const getPlatformDropDownOptions = () => {
    return SearchService.fetchPlatformDropDownOptions()
}

function ModalContent({ setOpen }) {
    const classes = useStyles()
    const [platform, setPlatform] = useState("Microsoft")
    const [options, setOptions] = useState([])

    const handleChange = (e) => {
        setPlatform(e.target.value)
    }

    useQuery(["platform-opotions"], getPlatformDropDownOptions, {
        onSuccess: data => {
            let newArr = data.data && data.data[0].map(item => {
                return {
                    value: item,
                    label: item
                }
            })
            setOptions(newArr)
        }
    })

    return (
        <div className={classes.root}>
            <Box className={classes.header}>
                <svg style={{ marginRight: 12 }} width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.60156 0.951172V2.95117H13.6016V0.951172H15.6016V2.95117H19.6016C19.8668 2.95117 20.1211 3.05653 20.3087 3.24407C20.4962 3.4316 20.6016 3.68596 20.6016 3.95117V19.9512C20.6016 20.2164 20.4962 20.4707 20.3087 20.6583C20.1211 20.8458 19.8668 20.9512 19.6016 20.9512H1.60156C1.33635 20.9512 1.08199 20.8458 0.894456 20.6583C0.706919 20.4707 0.601563 20.2164 0.601562 19.9512V3.95117C0.601562 3.68596 0.706919 3.4316 0.894456 3.24407C1.08199 3.05653 1.33635 2.95117 1.60156 2.95117H5.60156V0.951172H7.60156ZM18.6016 9.95117H2.60156V18.9512H18.6016V9.95117ZM13.6376 11.0872L15.0516 12.5012L10.1016 17.4512L6.56556 13.9152L7.98156 12.5012L10.1026 14.6232L13.6386 11.0872H13.6376ZM5.60156 4.95117H2.60156V7.95117H18.6016V4.95117H15.6016V5.95117H13.6016V4.95117H7.60156V5.95117H5.60156V4.95117Z" fill="#123B64" />
                </svg>
                <Typography variant='h6' color="primary">Check the platform availability</Typography>
                <IconButton onClick={() => setOpen(false)} style={{ padding: '0', marginLeft: 'auto' }} disableRipple>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box className={classes.mainContent}>
                <Box className={classes.microsoftWrapper}>
                    <Box className={classes.successIcon}>
                        <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.6016 1.95117L5.42693 8.71411C5.14002 9.03019 4.73046 9.03019 4.44356 8.71411L1.60156 5.60174" stroke="#F7F5F6" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    </Box>
                    <Typography className={classes.alertLabel} variant='subtitle1' color='primary'>Is available on Microsoft</Typography>
                </Box>
                <Box className={classes.selectBox}>
                    <CustomizedSelect
                        value={platform}
                        name="platform"
                        id="platform"
                        options={options}
                        label="You can choose a platform"
                        handleChange={handleChange}
                    />
                    <Box className={classes.noteWrapper}>
                        <Typography className={classes.note} variant='body2'>Note:</Typography>
                        <Typography className={classes.typography} variant='body2' color="primary">You can only select one platform</Typography>
                    </Box>
                </Box>
                <YellowButton
                    style={{ fontWeight: 700 }}
                    disableRipple
                    fullWidth={true}
                >
                    Сheck platform availability
                </YellowButton>
            </Box>
        </div>
    );
}
export default ModalContent;