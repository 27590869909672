import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { useState } from 'react';
import CreatedByYou from '../../my-work/my-collections/CreatedByYou';
import ListViewButtonTwo from '../../shared/buttons/ListViewButtonTwo';

const useStyles = makeStyles({
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 24
    }
})

function RecommendedCollections({ collections }) {
    const classes = useStyles()
    const [isListView, setIsListView] = useState(false);

    return (
        <Container>
            <Box className={classes.titleWrapper}>
                <Typography variant='h1' color='primary'>
                    Recommended for you
                </Typography>
                <ListViewButtonTwo
                    setShowSaved={setIsListView}
                    showSaved={isListView}
                />
            </Box>
            <CreatedByYou collections={collections} showShortCollectionCard={isListView} />
        </Container>
    );
}

export default RecommendedCollections;