import { Box, Typography } from '@material-ui/core';
import { PrimaryButton } from '../../buttons/custom-buttons/CustomButtons';
import FullNodeCardLogo from '../full-node-card-logo/FullNodeCardLogo';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { useStyles, users } from './ProgressCardDetails'
import DonutChart from '../../d3-graph/donut-cart/DonutChart';

function ProgressCard({node}) {
    const classes = useStyles()
    console.log("node: ", node);
    return (
        <Box className={classes.card}>
            <Box className={classes.leftSide}>
                <FullNodeCardLogo Type="Data Set" />
            </Box>
            <Box className={classes.rigthSide}>
                <Box>
                    <Typography variant='h3' color='primary'>{node.displayName}</Typography>
                    <Typography className={classes.typography}>Progress in creating a node</Typography>
                </Box>
                <Box className={classes.middleSide}>
                    <Box className={classes.chartBox}>
                        <DonutChart />
                    </Box>
                    <Box>
                        <Box mb={1} className={classes.dataAndMetadata}>
                            <Box className={clsx(classes.circle, classes.primaryCircle)} />
                            <Typography variant='h6' color='primary'>1.Data and Metadata 20%</Typography>
                        </Box>
                        <Box mb={1} className={classes.dataAndMetadata}>
                            <Box className={clsx(classes.circle, classes.successCircle)} />
                            <Typography variant='h6' color='primary'>2.Code 0%</Typography>
                        </Box>
                        <Box className={classes.dataAndMetadata}>
                            <Box className={clsx(classes.circle, classes.secondaryCircle)} />
                            <Typography variant='h6' color='primary'>3.Infrastructure 0%</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.bottomSide}>
                    <Box className={classes.avatarGroupWrapper}>
                        <Typography>Curated</Typography>
                        <AvatarGroup className={users.length > 6 ? classes.avatarGroup : classes.defaultGroup} max={6}>
                            {
                                users.map((user, index) => (
                                    <Avatar 
                                        className={classes.avatar} 
                                        alt={user.name} 
                                        src={user.imgUrl} 
                                        key={index}
                                    />
                                ))
                            }
                        </AvatarGroup>
                    </Box>
                    <PrimaryButton
                        endIcon={
                            <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.5 11L7.5 6L1.5 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        }
                        className={classes.primaryButton}
                    >
                        Continue
                    </PrimaryButton>
                </Box>
            </Box>
        </Box>
    );
}

export default ProgressCard;