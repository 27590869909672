import { useNavigate } from 'react-router-dom';

function Protected({children}) {
    const navigate = useNavigate()
    const  signInuser = localStorage.getItem('logged-in-user')
    const user = JSON.parse(signInuser)

    if(!user) {
        navigate('/home')
    }
    return children
}
export default Protected;