import Pagination from "./../pagination/Pagination";
import { useState, forwardRef } from "react";
import { NodeCardWrapper } from "./NodeCardWrapper";
import FullNodeCardSlider from "./../../home/full-card-slider/FullNodeCardSlider";
import { useEffect } from "react";

export const NodeList = forwardRef((props, ref) => {
  const {
    setSelectedNodes,
    selectedNodes,
    nodesPerPage,
    showSaved,
    isClickable,
    nodes,
    page,
  } = props;
  const [pageNumberLimit] = useState(7);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentNodes, setCurrentNodes] = useState([]);
  const numberOfPages = [];
  const numberOfNodes = nodes?.length;

  for (let i = 1; i <= Math.ceil(numberOfNodes / nodesPerPage); i++) {
    numberOfPages.push(i);
  }
  const paginate = (pageNum) => {
    setCurrentPage(pageNum);
    ref && ref.current.scrollIntoView({ behaivor: "smooth" });
  };

  const nextPage = () => {
    if (numberOfPages.length === currentPage) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage + 1);
    }
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
    ref && ref.current.scrollIntoView({ behaivor: "smooth" });
  };

  const prevPage = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else {
      setCurrentPage(currentPage - 1);
    }
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
    ref && ref.current.scrollIntoView({ behaivor: "smooth" });
  };

  useEffect(() => {
    const indexOfLastNode = currentPage * nodesPerPage;
    const indexOfFirstNode = indexOfLastNode - nodesPerPage;
    const slicedNodes = nodes.slice(indexOfFirstNode, indexOfLastNode);
    setCurrentNodes(slicedNodes);
  }, [currentPage, showSaved, numberOfNodes]);

  return (
    <>
      {page === "my-nodes" ? (
        <FullNodeCardSlider data={currentNodes} />
      ) : (
        <NodeCardWrapper
          showSaved={showSaved}
          nodes={currentNodes}
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          currentPage={currentPage}
          isClickable={isClickable}
        />
      )}{" "}
      {nodes.length > nodesPerPage && (
        <Pagination
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          paginate={paginate}
          nextPage={nextPage}
          prevPage={prevPage}
          maxPageNumberLimit={maxPageNumberLimit}
          minPageNumberLimit={minPageNumberLimit}
        />
      )}
    </>
  );
});
