import { useState } from 'react';
import ObjectiveInput from './details/ObjectiveInput';
import ObjectiveContent from './details/ObjectiveContent';
import CustomPoper from '../../tooltip/CustomPoper';
import AttributeContent from './details/AttributeContent';

function SelectAttributes(props) {
    const [popperAnc, setPopperAnc] = useState(null);
    const {
        setObjectiveOptions,
        setCheckedObjectives,
        checkedObjectives,
        objectiveOptions,
        setAppledObjectives
    } = props

        return(
            <CustomPoper
                popperAnc={popperAnc}
                setPopperAnc={setPopperAnc}
                button={<ObjectiveInput
                    checkedObjectives={checkedObjectives}
                    setCheckedObjectives={setCheckedObjectives} />
                }
            >
                <AttributeContent
                    setPopperAnc={setPopperAnc}
                    setAppledObjectives={setAppledObjectives}
                    objectiveOptions={objectiveOptions}
                    setObjectiveOptions={setObjectiveOptions}
                    checkedObjectives={checkedObjectives}
                    setCheckedObjectives={setCheckedObjectives}
                />
            </CustomPoper>
        );
}

export default SelectAttributes;