import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Footer from "./components/footer/Footer";
import CurateContext from "./components/navbar/Dataproductpages/curate-context/CurateContext";
import CuratePrivacy from "./components/navbar/Dataproductpages/curate-privacy/CuratePrivacy";
import CurateResponsibilitesone from "./components/navbar/Dataproductpages/CurateResponsibilitesone";
import CurateSecurity from "./components/navbar/Dataproductpages/curate-security/CurateSecurity";
import Classification from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/Classification";
import ClassificationDataset from "./pages/classification-dataset/ClassificationDataset";
import Democratize from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/Democratize";
import Discover from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/Discover";
import ResponsibilityDataSet from "./pages/dataset-pages/Responsibility";
import CurateResponsibility from "./components/navbar/Dataproductpages/curate-responsibility/CurateResponsibility";
import Semantics3 from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/Semantics3";
import CurationStatus from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/CurationStatus";
import SemanticsDataset from "./pages/semants-dataset/SemanticsDataset";
import ServiceLevelObjetives from "./components/navbar/Dataproductpages/service-level-objectives/ServiceLevelObjetives";
import Socialise from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/Socialise";
import { config } from "./components/okta-login/config";
import Login from "./components/okta-login/Login";
import Protected from "./components/protected/Protected";
import ResetPassword from "./components/reset-password/ResetPassword";
import Samplecard from "./components/shared/full-node-card/samplecard";
import DataProductBuilder from "./components/shared/full-node-card/DataProductBuilder";
import SamplecardDataset from "./components/shared/full-node-card/samplecardDataset";
import Loader from "./components/shared/loader/Loader";
import SuccessfullLogin from "./components/successful-login/SuccessfullLogin";
import { AuthWrapper } from "./pages/auth-wrapper/AuthWrapper";
import Controls from "./pages/controls/Controls";
import Elements from "./pages/elements/Elements";
import HomeMemo from "./pages/home/HomePage";
import NodeCreationSteps from "./pages/node-creating/NodeCreationSteps";
import NodeGraphPage from "./pages/node-graph/NodeGraphPage";
import DataEntryForm from "./pages/data-entry/Dataentryform";
import DatasetRegistration from "./pages/dataset-registration/DatasetRegistration";
import DataProductRegistration from "./pages/dataproduct-registration/DataProductRegistration";
import PageNotFound from "./pages/page-not-found/PageNotFound";
import SecurityPolicy from "./pages/privacy-policy/SecurityPolicy";
import { SignIn } from "./pages/sign-in/SignIn";
import { SignUp } from "./pages/sign-up/SignUp";
import AppLayout from "./components/app-layout/AppLayout";
import ErrorBoundary from "./components/error-boundry/ErrorBoundary";
import CurateContextdataset from "./components/navbar/Dataproductpages/curate-context/CurateContextdataset";
import CuratePrivacydataset from "./components/navbar/Dataproductpages/curate-privacy/CuratePrivacydataset";
import CurateSecuritydataset from "./components/navbar/Dataproductpages/curate-security/CurateSecuritydataset";
import Description from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/Description";
import MyJobs from "./pages/my -jobs/MyJobs";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blog-detail/BlogDetails";
import Teams from "./pages/my-teams/Teams";
import SidebarFlow from "./pages/sidebarflow/Filters";
import ModelRegistration from "./components/navbar/Dataproductpages/node-registration/ModelRegistration";
import Samplecardmodel from "./components/shared/full-node-card/samplecardmodel";
import ModelProductRegistration from "./pages/dataproduct-registration/ModelProductRegistration";
import CuratePrivacymodel from "./components/navbar/Dataproductpages/curate-privacy/CuratePrivacymodel";
import Modelform from "./components/navbar/Dataproductpages/curate-security/modelform";
import Model from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/API";
import ResponsibilityModel from "./pages/dataset-pages/Responsibilitymodel";
import ServiceLevelObjectivesModel from "./components/navbar/Dataproductpages/service-level-objectives/ServiceLevelObjetivesmodel";
import BlogPage from "./pages/blog/Blogpage";
import DescriptionDataset from "./components/navbar/nav-dropdown-input/logopagedesign/logopages/DescriptionDataset";
import SourceDataset from "./components/shared/full-node-card/sourceDataset";
import SourceDataproduct from "./components/shared/full-node-card/sourceDataproduct";

const oktaAuth = new OktaAuth(config.oidc);
const MyNodes = lazy(() => import("./pages/my-nodes/MyNodes"));
const MyCollections = lazy(() =>
  import("./pages/my-collections/MyCollections")
);
const Cart = lazy(() => import("./pages/check-out-cart/Cart"));
const CheckoutDetails = lazy(() =>
  import("./pages/checkout-details/CheckoutDetails")
);
const YourOrder = lazy(() => import("./pages/your-order/YourOrder"));
const MyWork = lazy(() => import("./pages/my-work/MyWork"));
const CollectionDetails = lazy(() =>
  import("./pages/digital-publication/CollectionDetails")
);
const Information = lazy(() => import("./pages/information/Information"));
const MyFavourites = lazy(() => import("./pages/my-favourites/MyFavourites"));
const PrivacyPolicy = lazy(() =>
  import("./pages/privacy-policy/PrivacyPolicy")
);
const AddSection = lazy(() => import("./pages/add-section/AddSection"));
const Profile = lazy(() => import("./pages/profile/Profile"));
const CreateNewCollection = lazy(() =>
  import("./pages/create-new-collection/CreateNewCollection")
);
const EditSection = lazy(() => import("./pages/edit-section/EditSection"));
const TabsContainer = lazy(() =>
  import("./pages/tabs-container/TabsContainer")
);
const SharedNodeViewer = lazy(() =>
  import("./pages/shared-node-viewer/SharedNodeViewer")
);

function onAuthRequired({ history }) {
  history.push("/login");
}
function AppRoutes() {
  const navigate = useNavigate();

  const triggerLogin = () => {
    // Navigate to the /login page that has a CustomLoginComponent
    navigate("/login");
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/home", window.location.origin));
  };

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    } else {
      // Ask the user to trigger the login process during token autoRenew process
      // setAuthRequiredModalOpen(true);
    }
  };

  const onAuthResume = async () => {
    navigate("/login");
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Suspense fallback={<Loader />}>
        <AuthWrapper>
          <Routes>
            <Route
              path="/login/callback"
              exact={true}
              render={(props) => (
                <LoginCallback {...props} onAuthResume={onAuthResume} />
              )}
            />
            <Route path="/login" render={() => <Login />} />
            <Route element={<AppLayout />}>
              <Route
                exact
                path="/"
                element={<Navigate to="/home" />}
                errorElement={<ErrorBoundary />}
              />
              <Route
                path="/home"
                element={<HomeMemo />}
                errorElement={<ErrorBoundary />}
              />
              <Route exact path="/my-collections" element={<MyCollections />} />
              <Route

                path="/curateprivacy/:nodeName/:key"
                element={<CuratePrivacy />}
              />
              <Route
                path="/curatesecurity/:nodeName/:key"
                element={<CurateSecurity />}
              />
              <Route
                path="/curatecontext/:nodeName/:key"
                element={<CurateContext />}
              />
              <Route
                path="/curateprivacymodel/:nodeName/:key"
                element={<CuratePrivacymodel />}
              />
              <Route path="/modelform/:nodeName/:key" element={<Modelform />} />

              <Route
                path="/curateprivacydataset/:selectedNodeName/:nodeName/:key"
                element={<CuratePrivacydataset />}
              />
              <Route
                path="/curatesecuritydataset/:selectedNodeName/:nodeName/:key"
                element={<CurateSecuritydataset />}
              />
              <Route
                path="/curatecontextdataset/:selectedNodeName/:nodeName/:key"
                element={<CurateContextdataset />}
              />
              <Route
                path="/uploadfile"
                element={<CurateResponsibilitesone />}
              />
              <Route
                path="/responsibility/:nodeName/:key"
                element={<CurateResponsibility />}
              />
              <Route
                path="/classificationdataset/:selectedNodeName/:nodeName/:key"
                element={<ClassificationDataset />}
              />
              <Route
                exact
                path="/semanticsdataset/:selectedNodeName/:nodeName/:key"
                element={<SemanticsDataset />}
              />
              <Route
                path="/responsibilitymodel/:nodeName/:key"
                element={<ResponsibilityModel />}
              />
              <Route
                path="/responsibilitydataset/:selectedNodeName/:nodeName/:key"
                element={<ResponsibilityDataSet />}
              />
              <Route path="/my-nodes" element={<MyNodes />} />
              <Route path="/blogpage" element={<BlogPage />} />
              <Route
                exact
                path="/my-collections/create-collection"
                element={<CreateNewCollection />}
              />
              <Route
                exact
                path="/my-collections/edit-section/:key"
                element={<EditSection />}
              />
              <Route
                exact
                path="/my-collections/:title/add-section/:key"
                element={<AddSection />}
              />
              <Route
                exact
                path="/my-collections/:title/semantics/:key"
                element={<Semantics3 />}
              />
              <Route
                exact
                path="/my-collections/:title/description/:key"
                element={<Description />}
              />
              <Route
                exact
                path="/descriptiondataset/:selectedNodeName/:nodeName/:key"
                element={<DescriptionDataset />}
              />
              <Route
                exact
                path="/my-collections/:title/Curationstatus/:key" 
                element={<CurationStatus />}
              />
              <Route
                exact
                path="/my-collections/:title/classification/:key"
                element={<Classification />}
              />
              <Route
                exact
                path="/my-collections/:title/:key"
                element={<CollectionDetails />}
              />
              <Route exact path="/cart" element={<Cart />} />
              <Route
                exact
                path="/servicedataproduct/:nodeName/:key"
                element={<ServiceLevelObjetives />}
              />
              <Route
                exact
                path="/servicemodel/:nodeName/:key"
                element={<ServiceLevelObjectivesModel />}
              />

              <Route
                exact
                path="/node-registration/:key/1"
                element={<DataProductRegistration />}
              />
              <Route
                exact
                path="/model-registration/:key"
                element={<ModelProductRegistration />}
              />
              <Route
                exact
                path="/dataentryform/:nodeName/:key"
                element={<DataEntryForm />}
              />
              <Route
                exact
                path="/dataset-registration/:key"
                element={<DatasetRegistration />}
              />
              <Route
                exact
                path="/model-registration/:key"
                element={<ModelRegistration />}
              />
              <Route
                exact
                path="/cart/checkout-details"
                element={<CheckoutDetails />}
              />
              <Route
                path="/data_product_samplecard/:nodeName/:key/2"
                element={<Samplecard />}
              />
              
              <Route
                path="/data_product_source/:nodeName/:key/2"
                element={<SourceDataproduct />}
              />
              <Route
                path="/model_samplecard/:nodeName/:key"
                element={<Samplecardmodel />}
              />
              <Route
                path="/samplecard1/:nodeName/:key/:id"
                element={<DataProductBuilder />}
              />
              <Route
                path="/data_set_samplecard/:nodeName/:key"
                element={<SamplecardDataset />}
              />
              <Route
                path="/data_set_source/:nodeName/:key"
                element={<SourceDataset />}
              />
              <Route path="/your-orders" element={<YourOrder />} />
              <Route path="/my-jobs" element={<MyJobs />} />
              <Route path="/my-teams" element={<Teams />} />
              <Route path="/user" element={<AuthWrapper />} />
              <Route path="/tabs" element={<TabsContainer />} />
              <Route path="/my-work" element={<MyWork />} />
              <Route path="/Information" element={<Information />} />
              <Route path="/security-policy" element={<SecurityPolicy />} />
              <Route path="/my-favourites" element={<MyFavourites />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/profile"
                element={
                  <Protected>
                    <Profile />
                  </Protected>
                }
              />
              <Route path="/discover" element={<Discover />} />
              <Route path="/socialise" element={<Socialise />} />
              <Route path="/model" element={<Model />} />
              <Route path="/dataproduct" element={<NodeCreationSteps />} />
              <Route path="/democratize" element={<Democratize />} />
              <Route path="/elements" element={<Elements />} />
              <Route path="/tabs?key:id" element={<SharedNodeViewer />} />
              <Route path="/controls/:id" element={<Controls />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:name" element={<BlogDetails />} />
            </Route>
            <Route path="/directed-graph/:id" element={<NodeGraphPage />} />
            <Route exact path="/reset" element={<ResetPassword />} />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route exact path="/sidebarflow" element={<SidebarFlow />} />
            <Route path="/successfull-login" element={<SuccessfullLogin />} />
            <Route
              element={
                <Security
                  issuer="https://dev-49032323.okta.com/oauth2/default"
                  client_id="0oa434v431tfXNY1o5d7"
                  redirect_uri="http://www.datasouktest.com/home"
                  onAuthRequired={onAuthRequired}
                >
                  <div className="container">
                    <Route
                      path="/login"
                      render={() => (
                        <Login baseUrl="https:/dev-49032323.okta.com" />
                      )}
                    />
                  </div>
                </Security>
              }
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
          <Footer />
        </AuthWrapper>
      </Suspense>
    </Security>
  );
}

export default AppRoutes;
