// import { useState, useEffect } from 'react';
// import NodeGraph from './NodeGraph';
// import { SearchService } from '../../../../services/SearchService';
// import { Link } from 'react-router-dom';
// import { Box, IconButton, makeStyles, Typography } from '@material-ui/core';
// import { BsSquare } from 'react-icons/bs'
// import CloseIcon from '@material-ui/icons/Close';
// const useStyles = makeStyles({
//     graphWrapper: {
//         position: 'relative',
//         width: 700,
//         height: 500
//     },
//     buttonsWrapper: {
//         position: "absolute",
//         top: 0,
//         right: 0,
//         display: 'flex',
//         "& svg": {
//             zIndex: 9,
//             fill: "#123b64"
//         }
//     },
//     maximizeButton: {
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         zIndex: 9,
//         '& svg': {
//             width: 18,
//             height: 18
//         }
//     },
//     noDataWrapper: {
//         width: 700,
//         height: 500,
//         background: '#101020',
//         padding: 40,
//         boxShadow: ' 0px 4px 20px rgba(1, 25, 54, 0.1)',
//         borderRadius: 4
//     }
// })

// function NodeGraphWrapper({ setOpen, node }) {
//     const classes = useStyles()
//     const [graphData, setGraphData] = useState({})
//     const [loading, setLoading] = useState(true)

//     useEffect(() => {
//         SearchService.fetchNodeGraphData(node.id, 1)
//             .then(res => {
//                 const nodes = res.data.nodes.map(node => {
//                     return {
//                         ...node,
//                         module: node.path
//                     }
//                 })
//                 const graphdata = {
//                     nodes,
//                     links: res.data.links
//                 }
//                 setGraphData(graphdata)
//                 setLoading(false)
//             })
//             .catch(() => {
//                 setLoading(false)
//             })
//     }, [])

//     return (
//         <Box sx={{ minHeight: "calc(100vh - 150px)", display: "flex", justifyContent: "center", alignItems: "center" }}>
//             {
//                 loading ? <h2>Loading...</h2> :
//                     // Object.keys(graphData).length && graphData.nodes.length ?
//                     true ?
//                         <Box className={classes.graphWrapper}>
//                             <Box className={classes.buttonsWrapper}>
//                                 <Link className={classes.maximizeButton} to={`/directed-graph/${node.id}`} target="_blank">
//                                     <BsSquare />
//                                 </Link>
//                                 <IconButton onClick={() => setOpen(false)} className={classes.cancelButton}>
//                                     <CloseIcon />
//                                 </IconButton>
//                             </Box>
//                             <NodeGraph
//                                 linkColor="#123b64"
//                                 backgroundColor="#fff"
//                                 labelColor="#123b64"
//                                 graphData={graphData}
//                                 width={700}
//                                 height={500}
//                             />
//                         </Box>
//                         :
//                         <Box className={classes.noDataWrapper}>
//                             <Typography variant='h2' color='secondary' align='center'>
//                                 {`No graph data found for this '${node.displayName}' node`}
//                             </Typography>
//                         </Box>
//             }
//         </Box>
//     );
// }

// export default NodeGraphWrapper;
import { useState, useEffect } from "react";
import NodeGraph from "./NodeGraph";
import { SearchService } from "../../../../services/SearchService";
import { Link } from "react-router-dom";
import { Box, IconButton, makeStyles, Typography } from "@material-ui/core";
import { BsSquare } from "react-icons/bs";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles({
  graphWrapper: {
    position: "relative",
    width: 700,
    height: 500,
  },
  buttonsWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    "& svg": {
      zIndex: 9,
      fill: "#123b64",
    },
  },
  maximizeButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9,
    "& svg": {
      width: 18,
      height: 18,
    },
  },
  noDataWrapper: {
    width: 700,
    height: 500,
    // background: "#101020",
    background:'white',
    padding: 40,
    boxShadow: " 0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 4,
  },
});

function NodeGraphWrapper({ setOpen, node }) {
  const classes = useStyles();
  const [graphData, setGraphData] = useState({});
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //     SearchService.fetchNodeGraphData(node.id, 1)
  //         .then(res => {
  //             console.log(res,"response")
  //             const nodes = res.data.nodes.map(node => {
  //                 console.log(node,"node")
  //                 return {
  //                     ...node,
  //                     module: node.path
  //                 }
  //             })
  //             const graphdata = {
  //                 nodes,
  //                 links: res.data.links
  //             }
  //             setGraphData(graphdata)
  //             console.log(graphdata)
  //             setLoading(false)
  //         })
  //         .catch(() => {
  //             setLoading(false)
  //         })
  // }, [])

  useEffect(() => {
    SearchService.fetchNodeGraphData(node.id, 2)
      .then((res) => {
        const nodes = res.data.nodes.map((node) => {
          return {
            ...node,
            module: node.path,
          };
        });

        const links = res.data.links;

        // Function to check for cycles in the links
        const hasCycles = (nodeId, visited, stack) => {
          console.log("Checking node:", nodeId);
          if (stack.has(nodeId)) return true; // Cycle detected

          if (!visited.has(nodeId)) {
            visited.add(nodeId);
            stack.add(nodeId);

            // Recursively check neighboring nodes
            for (const link of links.filter((link) => link.source === nodeId)) {
              if (link.target === nodeId) {
                // Handle self-referencing nodes here
                // You can skip or handle them as needed
                continue;
              }
              if (hasCycles(link.target, visited, stack)) {
                return true;
              }
            }
          }

          stack.delete(nodeId);
          return false;
        };

        // Check for cycles in the links
        const cyclic = nodes.some((node) =>
          hasCycles(node.id, new Set(), new Set())
        );

        if (cyclic) {
          // Handle cyclic data here, e.g., display an error message
          console.error("Cyclic data detected. Handle accordingly.");
          setLoading(false); // Set loading to false to prevent rendering the graph
        } else {
          const graphdata = {
            nodes,
            links,
          };
          setGraphData(graphdata);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [node.id]);



  return (
    <Box
      sx={{
        minHeight: "calc(100vh - 150px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {loading ? (
        <h2>Loading...</h2>
      ) : graphData.nodes && graphData.nodes.length > 0 ? (
        <Box className={classes.graphWrapper}>
          <Box className={classes.buttonsWrapper}>
            <Link
              className={classes.maximizeButton}
              to={`/directed-graph/${node.id}`}
              target="_blank"
            >
              <BsSquare />
            </Link>
            <IconButton
              onClick={() => setOpen(false)}
              className={classes.cancelButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <NodeGraph
            linkColor="#123b64"
            backgroundColor="#fff"
            labelColor="#123b64"
            graphData={graphData}
            width={700}
            height={500}
          />
        </Box>
      ) : (
        <Box className={classes.noDataWrapper}>
            <IconButton
              onClick={() => setOpen(false)}
              style={{float:'right'}}
              className={classes.cancelButtons}
            >
              <CloseIcon />
            </IconButton>
          <Typography variant="h2" align="center" sx={{color:'#123b64'}}>
            {`No graph data found for this '${node.displayName}' node`}
          </Typography>
        </Box>
      )}
    </Box>
  );

  // return (
  //   <Box
  //     sx={{
  //       minHeight: "calc(100vh - 150px)",
  //       display: "flex",
  //       justifyContent: "center",
  //       alignItems: "center",
  //     }}
  //   >
  //     {loading ? (
  //       <h2>Loading...</h2>
  //     ) : // Object.keys(graphData).length && graphData.nodes.length ?
  //     true ? (
  //       <Box className={classes.graphWrapper}>
  //         <Box className={classes.buttonsWrapper}>
  //           <Link
  //             className={classes.maximizeButton}
  //             to={`/directed-graph/${node.id}`}
  //             target="_blank"
  //           >
  //             <BsSquare />
  //           </Link>
  //           <IconButton
  //             onClick={() => setOpen(false)}
  //             className={classes.cancelButton}
  //           >
  //             <CloseIcon />
  //           </IconButton>
  //         </Box>
  //         <NodeGraph
  //           linkColor="#123b64"
  //           backgroundColor="#fff"
  //           labelColor="#123b64"
  //           graphData={graphData}
  //           width={700}
  //           height={500}
  //         />
  //       </Box>
  //     ) : (
  //       <Box className={classes.noDataWrapper}>
  //         <Typography variant="h2" color="secondary" align="center">
  //           {`No graph data found for this '${node.displayName}' node`}
  //         </Typography>
  //       </Box>
  //     )}
  //   </Box>
  // );
}

export default NodeGraphWrapper;
