import axios from "axios";
import { useMutation } from "react-query";

const apiUrl = process.env.REACT_APP_API_URL;

// add new user (sign up)
let emptyObj = {};
const addUser = (user) => {
  let newUser = { ...emptyObj, ...user };
  delete newUser.confirmPassword;
  return axios.post(`${apiUrl}/userRegistration`, newUser);
};

export const useAddUserData = (onSuccess, onError) => {
  return useMutation(addUser, {
    onSuccess,
    onError,
  });
};
// login
const checkLogin = (user) => {
  return axios.post(`${apiUrl}/loginAccess`, user);
};

export const useLogin = (onSuccess) => {
  return useMutation(checkLogin, {
    onSuccess,
  });
};

// Add card for buying
const orderCarts = (carts) => {
  return axios.post(`${apiUrl}/checkoutdetails`, carts);
};

export const useOrderCarts = (onSuccess) => {
  return useMutation(orderCarts, {
    onSuccess,
  });
};

// create new simple team
const createNewTeam = (data) => {
  return axios.post(`${apiUrl}/createNewTeamForSimple`, data);
};

export const useCreateSimpleTeam = (onError, onSuccess) => {
  return useMutation(createNewTeam, {
    onError,
    onSuccess,
  });
};

// create matrix team
const createMatrixTeam = (data) => {
  return axios.post(`${apiUrl}/createNewMatrixTeam`, data);
};

export const useMatrixTeam = (onSuccess) => {
  return useMutation(createMatrixTeam, {
    onSuccess,
  });
};

// semantis apis
export const semanticsApis = {
  addNewTerm: (colName, recName, status) => {
    return axios.post(
      `${apiUrl}/addNewTermManualInSemantics?columnName=${colName}&recommededName=${recName}&status=${status}`
    );
  },
  fetchListOfTerm: (text) => {
    return axios.get(`${apiUrl}/termsList`, {
      params: {
        name: text,
      },
    });
  },
  fetchSemanticsData: (key) => {
    return axios.get(`${apiUrl}/semantics`, {
      params: {
        pinId: key,
      },
    });
  },

  semanticStatusUpdate: (colName, recName, status, key) => {
    return axios.post(
      `${apiUrl}/semanticStatusUpdate?key=${key}&columnName=${colName}&recommededName=${recName}&status=${status}`
    );
  },
};

// Classication apis

export const classificationService = {
  fetchClassificationData: (key) => {
    return axios.get(`${apiUrl}/classification`, {
      params: {
        pinId: key,
      },
    });
  },
  fetchClassificationReview: (key) => {
    return axios.get(`${apiUrl}/classificationReview`, {
      params: {
        pinId: key,
      },
    });
  },
  fetchListOfTerm: (name) => {
    return axios.get(`${apiUrl}/termsList`, {
      params: {
        name: name,
      },
    });
  },
  classificationStatusUpdate: (colName, recName, status, key) => {
    return axios.post(
      `${apiUrl}/classificationStatusUpdate?key=${key}&columnName=${colName}&recommededName=${recName}&status=${status}`
    );
  },
  addNewTerm: (colName, recName, status) => {
    return axios.post(
      `${apiUrl}/addNewTermManualInClasssification?columnName=${colName}&recommededName=${recName}&status=${status}`
    );
  },
};
export const descriptionService = {
  fetchDescriptionData: (key) => {
    return axios.get(`${apiUrl}/description`, {
      params: {
        pinId: key,
      },
    });
  },
  descriptionStatusUpdate: (colName, recName, status, key) => {
    return axios.post(
      `${apiUrl}/descriptionStatusUpdate?key=${key}&termName=${colName}&recommededName=${recName}Descriptionfor this node&status=${status}`
    );
  },
};
