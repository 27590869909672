import { Container, makeStyles, Typography } from "@material-ui/core";
import ShortNodeCardSlider from "../short-card-slider/ShortNodeCardSlider";
import SliderPlaceholder from "../../shared/placeholder/SliderPlaceholder";

const useStyles = makeStyles({
  title: {
    margin: "130px 0 40px",
  },
});

const ShortNodeCardWrapper = ({ cardItems }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      {cardItems.trending.length ? (
        <ShortNodeCardSlider data={cardItems.trending} />
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}
      <Typography variant="h1" className={classes.title} color="primary">
        Recently viewed
      </Typography>
      {cardItems.recent.length ? (
        <ShortNodeCardSlider data={cardItems.recent} />
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}
      <Typography variant="h1" className={classes.title} color="primary">
        Trending searches
      </Typography>
      {cardItems.trending.length ? (
        <ShortNodeCardSlider data={cardItems.trending} />
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}
    </Container>
  );
};

export default ShortNodeCardWrapper;
