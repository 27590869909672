import { NextArrow, PrevArrow } from "../components/shared/buttons/slider-buttons/SliderButtons";

export const SORT_FIELDS = {
    field: "doc.name",
    sort: "asc"
}

export const NODE_TYPES = {
    status: 'status',
    nodeType: 'nodeType'
}

export const SORT_TYPES = {
    name: 'doc.displayName',
    avgRating: 'doc.avgRating',
    createdon: 'doc.createdOn',
    lastmodified: 'doc. updatedOn',
    // lastmodified: 'doc.attributes[*].LastModifiedOn'
}

export const NODE_TITLE_TYPE = {
    fieldName: 'doc.name',
    fieldValue: '',
    searchType: '',
    op: ''
}

export const DEFAULT_SORT = {
    type: '',
    order: 'asc'
}

export const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    vertical: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    variableWidth: true,
};

export const mobileSettings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    infinite: false,
    dots: false,
    variableWidth: true,
};
