import { Box, Container, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { classificationService } from "../../../../../services/custom-hooks/CustomHooks";
import Breadcrumbs from "../../../../shared/breadcrumb/Breadcrumbs";
import { OutlinedButton } from "../../../../shared/buttons/custom-buttons/CustomButtons";
import CustomModal from "../../../../shared/modal/CustomModal";
import SliderPlaceholder from "../../../../shared/placeholder/SliderPlaceholder";
import NewTermModal from "./popupformsoflogo/NewTermModal";
import SemanticsTable3 from "./SemanticsTable3";
import { FilterIcon } from "../../../../icons";
import Loader from "../../../../shared/loader/Loader";
import SemanticsTableClassification from "./SematicsTableClassification";
import CustomDrawer from "../../../../shared/drawer/CustomDrawer";
import CollectionSidebar from "../../../../../pages/digital-publication/CollectionSidebar";

const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 150px)",
    paddingTop: 32,
    paddingBottom: 100,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: 219,
    "& span": {
      fontWeight: 700,
    },
  },
  wrapper: {
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 4,
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 40px",
    borderBottom: "1px solid #E0E7EC",
  },
  tableWrapper: {
    padding: "40px 40px 20px",
  },
});

const columns = [
  { id: "columnName", name: "Column Name" },
  { id: "Term", name: "Term Name" },
  //{ id: "Description", name: "Description" },
  { id: "recommendedName", name: "Sensitive Datatype" },
  { id: "confidence_score", name: "Confidence Score" },
  { id: "Status", name: "Status" },
];

function Classification() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [termStatus, setTermStatus] = useState("");
  const [columnName, setColumnName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [semanticData, setSemanticData] = useState([]);
  const { key, title } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);

  const getClassificationData = () => {
    return classificationService
      .fetchClassificationData(key)

      .then((res) => {
        //console.log(res, "responsess");
        setSemanticData(res.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getClassificationData();
  }, []);

  const addNewTerm = (recommendedName) => {
    return classificationService
      .addNewTerm(columnName, recommendedName, termStatus)
      .then((res) => {
        if (res.status === 200) {
          let newArr = semanticData.map((obj) => {
            if (obj.columnName === columnName) {
              return { ...obj, recommendedName: recommendedName };
            }
            return obj;
          });
          setSemanticData(newArr);
          setOpenModal(false);
        }
      });
  };

  const getTermDetails = (colName, status) => {
    setColumnName(colName);
    setTermStatus(status);
    setOpenModal(true);
  };

  const updateStatus = (
    colName,
    recName,
    status,
    index,
    confidenceScore,
    suggestedNameIndex,
    collapse,
    keyProp
  ) => {
    return classificationService
      .classificationStatusUpdate(colName, recName, status, keyProp)
      .then((res) => {
        let copySemanticsData = [...semanticData];
        let suggestedNames;
        if (collapse && res.status === 200) {
          copySemanticsData[`${index}`].Status = status;
          copySemanticsData[`${index}`].recommendedName = recName;
          copySemanticsData[`${index}`].confidence_score = confidenceScore;
          suggestedNames = copySemanticsData[`${index}`].suggestedNames.map(
            (item, i) => {
              if (i === suggestedNameIndex) {
                if (status === "Accepted") {
                  return { ...item, Status: "Accepted" };
                } else {
                  return { ...item, Status: "Rejected" };
                }
              } else {
                return { ...item, Status: "Rejected" };
              }
            }
          );
          copySemanticsData[`${index}`].suggestedNames = suggestedNames;
        } else if (res.status === 200) {
          copySemanticsData[`${index}`].Status = status;
        }
        setSemanticData(copySemanticsData);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className={classes.container}>
      <CustomModal open={openModal} setOpen={setOpenModal}>
        <NewTermModal
          page="classification"
          addNewTerm={addNewTerm}
          setOpenModal={setOpenModal}
        />
      </CustomModal>
      <Box mb={2.5}>
        <Breadcrumbs pathId={true} />
      </Box>
      <Typography
        style={{ marginBottom: 24 }}
        align="center"
        variant="h1"
        color="primary"
      >
        Classification
      </Typography>
      <Box className={classes.wrapper} mb={5}>
        <Box className={classes.box}>
          <Typography variant="h5" color="primary">
            2.3 Curate / Classification
          </Typography>
          <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer>
          <IconButton onClick={() => setOpenSidebar(true)} style={{ padding: 0 }}>
            <FilterIcon />
          </IconButton>
        </Box>
        <Box className={classes.tableWrapper}>
          {semanticData.length > 0 ? (
            <SemanticsTable3
              semanticData={semanticData}
              getTermDetails={getTermDetails}
              updateStatus={updateStatus}
              columns={columns}
              getClassificationData={getClassificationData}
              page="classification"
            />
          ) : (
            <SliderPlaceholder error={true} text="No data found" />
          )}
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/my-collections/${title}/description/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
        <Box>
          <Link
            style={{ textDecoration: "none", color: "#123B64" }}
            to={`/my-collections/${title}/${key}`}
          >
            <OutlinedButton
              style={{ marginRight: 15 }}
              className={classes.button}
              disableRipple
            >
              Save
            </OutlinedButton>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default Classification;
