import React, { forwardRef, useState } from 'react';
import Pagination from '../../../filter-region/pagination/Pagination';
import CollectionCardWrapper from './card-wrapper/CollectionCardWrapper';

const CollectionList = forwardRef((props, ref) => {
    const {
        nodesPerPage,
        collectionNodes,
        paginationSpace,
        showShortCollectionCard
    } = props

    const [pageNumberLimit] = useState(7)
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);

    const numberOfPages = []
    const totalPage = collectionNodes && collectionNodes.length

    for (let i = 1; i <= Math.ceil(totalPage / nodesPerPage); i++) {
        numberOfPages.push(i)
    }

    const paginate = (pageNum) => {
        setCurrentPage(pageNum)
        ref.current.scrollIntoView({ behaivor: "smooth" })
    }

    const nextPage = () => {
        if (numberOfPages.length === currentPage) {
            setCurrentPage(currentPage)
        } else {
            setCurrentPage(currentPage + 1)
        }
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
        ref.current.scrollIntoView({ behaivor: "smooth" })
    }

    const prevPage = () => {
        if (currentPage === 1) {
            setCurrentPage(currentPage)
        } else {
            setCurrentPage(currentPage - 1)
        }
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
        ref.current.scrollIntoView({ behaivor: "smooth" })
    }

    const indexOfLastNode = currentPage * nodesPerPage;
    const indexOfFirstNode = indexOfLastNode - nodesPerPage;
    const currentNodes = collectionNodes?.slice(indexOfFirstNode, indexOfLastNode)

    return (
        <div>
            <CollectionCardWrapper
                currentPage={currentPage}
                collectionNodes={currentNodes}
                showShortCollectionCard={showShortCollectionCard}
            />
            {
                collectionNodes.length > nodesPerPage ?
                    <Pagination
                        numberOfPages={numberOfPages}
                        currentPage={currentPage}
                        paginate={paginate}
                        nextPage={nextPage}
                        prevPage={prevPage}
                        maxPageNumberLimit={maxPageNumberLimit}
                        minPageNumberLimit={minPageNumberLimit}
                        paginationSpace={paginationSpace}
                    /> : null
            }
        </div>
    );
}
)
export default CollectionList;