import { useState } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CollapseTooltip from "../../../add-section/section-roles/collapse/collapse-content/CollapseTooltip";
import MuiAvatarGroup from "../../../add-section/section-roles/collapse/collapse-content/MuiAvatarGroup";
import * as moment from "moment";
import { Box, Typography } from "@material-ui/core";
import { TableCell, TableRow } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import MatrixTable from "../../../add-section/section-roles/collapse/collapse-content/MatrixTable";
import SimpleTable from "../../../add-section/section-roles/collapse/collapse-content/SimpleTable";
import { TeamService } from "../../../../services/TeamService";

function CollapsibleRow({ row }) {
  const [open, setOpen] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(false);
  const [isSimpleLoading, setIsSimpleLoading] = useState([]);
  const [isMatrixLoading, setIsMatrixLoading] = useState([]);
  const [matrixdata, setMatrixData] = useState([]);
  const [simpledata, setSimpleData] = useState([]);

  const handleClick = (key, type) => {
    setOpen(!open);
    if (type === "simple" && !open) {
      TeamService.fetchSimpleTeamTable(key).then((res) => { 
        setSimpleData(res.data.roles);
        setIsSimpleLoading(false);
      });
    } else if (type === "matrix" && !open) {
      TeamService.fetchMatrixTeamTable(key).then((res) => {
        setMatrixData(res.data);
        setIsMatrixLoading(false);
      });
    }
  };

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleClick(row.key, row.type)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.teamName}
        </TableCell>
        <TableCell>
          {
            <CollapseTooltip data={row.participants}>
              <MuiAvatarGroup table="simple" avatars={row.participants} />
            </CollapseTooltip>
          }
        </TableCell>
        <TableCell>
          {moment(new Date(row.createdOn)).format("D MMM YY")}
        </TableCell>
        <TableCell>
          {moment(new Date(row.lastModifiedOn)).format("D MMM YY")}
        </TableCell>
        <TableCell scope="row">{row.type}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {
                row.type === 'simple' ?
                  <>
                    {
                      isSimpleLoading ?
                        <Typography>Loading...</Typography> :
                        <SimpleTable isReadOnly={true} teamGroup={simpledata} />
                    }
                  </> :
                  <>
                    {
                      isMatrixLoading ?
                        <Typography>Loading...</Typography> :
                        <MatrixTable
                          openCollapse={openCollapse}
                          setOpenCollapse={setOpenCollapse}
                          isReadOnly={true}
                          data={matrixdata}
                        />
                    }
                  </>
              }
            </Box>
          </Collapse>
        </TableCell>
      </TableRow >
    </>
  );
}

export default CollapsibleRow;
