import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import CustomizedInput from '../../CustomizedInput';
import { useStyles, initialValues, validationSchema } from './details';
import CustomizedSelect from '../../CustomizedSelect';
import { YellowButton } from '../../../buttons/custom-buttons/CustomButtons';
import { NodeCreationService } from '../../../../../services/NodeCreationService';

const options = [
    { label: "Time", value: "time" },
    { label: "Percentage", value: "percentage" },
    { label: "Number", value: 'numbeer' }
]

function AddNewObjective({ setOpen, setObjectiveOptions, objectiveOptions }) {
    const classes = useStyles()

    const onSubmit = (values, { resetForm }) => {
        NodeCreationService.addObjective([values])
            .then(() => {
                setObjectiveOptions([...objectiveOptions, values])
                resetForm({ values: '' })
                setOpen(false)
            })
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    const { values, handleSubmit, handleChange } = formik

    return (
        <Box className={classes.root}>
            <Box className={classes.titleWrapper}>
                <Typography className={classes.title}>Create new team</Typography>
                <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }} disableRipple>
                    <CloseIcon />
                </IconButton>
            </Box>
            <form onSubmit={handleSubmit} className={classes.form}>
                <Box>
                    <Box mb={2.5}>
                        <CustomizedInput
                            id="objective-name"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            formik={formik}
                            label="Write name objective"
                            placeholderText="Enter name"
                        />
                    </Box>
                    <Box>
                        <CustomizedSelect
                            id="scaleName"
                            name="valueType"
                            value={values.valueType}
                            handleChange={handleChange}
                            formik={formik}
                            options={options}
                            label="Choose value of the scale"
                            defaultValue="Select scale"

                        />
                    </Box>
                </Box>
                <YellowButton className={classes.yellowButton} type='submit'>Save</YellowButton>
            </form>
        </Box>
    );
}

export default AddNewObjective;
