import { makeStyles } from "@material-ui/styles";
import { Avatar, Box, Typography } from "@material-ui/core";
import { CustomTooltip } from "../../shared/tooltip/NodeTooltip";
import ShortCollectionCard from "../../shared/cards/ShortCollectionCard";

const useStyles = makeStyles({
    Box: {
        display: 'flex!important',
        alignItems: 'center',
        cursor: 'pointer'
    },
    typography: {
        marginLeft: 4
    },
})

function RecentCollection({ recentCollection, searchByRelatedText }) {
    const classes = useStyles({ recentCollection })
    const apiUrl = process.env.REACT_APP_API_URL;

    return (
        <CustomTooltip title={<ShortCollectionCard data={recentCollection} />}>
            <Box className={classes.Box} onClick={() => searchByRelatedText(recentCollection)} mx={1}>
                <Avatar alt={recentCollection.displayName} src={`${apiUrl}/getPinImage?file=${recentCollection.cover}`} />
                <Typography variant='h6' color='primary' className={classes.typography}>
                    {recentCollection.displayName}
                </Typography>
            </Box>
        </CustomTooltip>

    );
}

export default RecentCollection;