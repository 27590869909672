// import { Box, makeStyles } from "@material-ui/core";
// import React, { useContext, useState } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import logo01 from "../../assets/images/card-icons/brand-logo.svg";
// import cardIcon from "../../assets/images/navbarIcon/card.png";
// import whiteGraph from "../../assets/images/card-icons/icon1.png";
// import favourite from "../../assets/images/navbarIcon/favourite.png";
// import searchIcon from "../../assets/images/navbarIcon/search.png";
// import DataContext from "../../contexts/Context";
// import { CloseIcon, MenuIcon, GraphIcon } from "../icons";
// import { CustomBadge } from "../shared/custom-badge/CustomBadge";
// import "./navbar.scss";
// import bell from "../../assets/icons/bell.png";
// import UserImageFrame from "./UserImageFrame";
// import ShareTooltip from "../shared/popover/SharePopover";
// import Notifications from "../shared/notifications/Notifications";

// const useStyles = makeStyles(() => ({
//   nav: {
//     background: "#011936",
//   },
//   signIn: {
//     textDecoration: "none",
//     color: "#fff",
//     fontWeight: 500,
//     "&:hover": {
//       color: "#e1d1d1",
//     },
//   },
// }));

// const Navbar = (props) => {
//   const {
//     searchText,
//     isGraphOpen,
//     isFocused,
//     children,
//     setSearchText, // Add setSearchText function
//     handleSubmit,  // Add handleSubmit function
//     // clearSearchText,
//     openSidebar,
//   } = props;

//   const { cart = [], favCards = [], loggedInUser } = useContext(DataContext);
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [prevSearchText, setPrevSearchText] = useState('');
//   const [openSaveCollection, setOpenSaveCollection] = useState(null);
//   const navigate = useNavigate();

//   const user = JSON.parse(localStorage.getItem('logged-in-user'));
//   const roleName = user ? user.roleName : null;

//   const openPage = (link) => {
//     setOpen(false);
//     navigate(link);
//   };
//   const handleSearchButtonClick = () => {
//     if (isGraphOpen) {
//       handleSubmit(); // Perform the search when the graph icon is clicked
//     } else if (!searchText) {
//       // If the search text is empty, don't modify it
//       return;
//     } else if (searchText !== prevSearchText) {
//       // Set the search text to itself only if it's different from the previous search
//       setSearchText(searchText);
//     }
//     setPrevSearchText(searchText); // Remember the current search text for the next comparison
//   };
  
//   // const handleClearSearch = () => {
//   //   clearSearchText(); // Call the function to clear search text
//   //   // Additional logic to close the search or reset filters, if needed
//   // };

//   return (
//     <Box>
//       <nav
//         className={`${classes.nav} position-relative navbar navbar-expand-lg`}
//       >
//         <div className="container-fluid">
//           <div className="d-flex">
//             <button
//               onClick={() => setOpen(true)}
//               className="d-lg-none border-0 bg-transparent me-2"
//             >
//               <MenuIcon />
//             </button>
//             <Link to="/blog" className="nav-logo d-block">
//               <img src={logo01} alt="logo" />
//             </Link>
//           </div>
//           <div className="d-flex justify-content-between flex-lg-grow-1">
//             <ul className="d-none navbar-nav mb-2 mb-lg-0 d-lg-flex">
//               <li className="navbar-item">
//                 <Link
//                   to="/home"
//                   className="nav-link home text-white"
//                   aria-current="page"
//                 >
//                   Home
//                 </Link>
//               </li>
//               <li className="navbar-item">
//                 <div className="btn-group">
//                   <NavLink
//                     to="/my-work"
//                     className="nav-link my-work text-white"
//                     aria-current="page"
//                   >
//                     My Work
//                   </NavLink>
//                   <span
//                     className="nav-link dropdown-toggle text-white"
//                     data-bs-toggle="dropdown"
//                     aria-expanded="false"
//                   />
//                   <ul className="dropdown-menu dropdown-menu-end my-work-list">
//                     <Link
//                       to="/dataproduct"
//                       className="dropdown-item text-secondary-100"
//                       type="button"
//                     >
//                       My Work
//                     </Link>
//                     <Link
//                       to="/my-nodes"
//                       className="dropdown-item text-secondary-100"
//                       type="button"
//                     >
//                       My Nodes
//                     </Link>
//                     <Link
//                       to="/my-collections"
//                       className="dropdown-item text-secondary-100"
//                       type="button"
//                     >
//                       My Collections
//                     </Link>
//                     <Link
//                       to="/your-orders"
//                       className="dropdown-item text-secondary-100"
//                       type="button"
//                     >
//                       My Orders
//                     </Link>
//                     {roleName === "consumer" && (
//             <>
//                     <Link
//                       to="/my-jobs"
//                       className="dropdown-item text-secondary-100"
//                       type="button"
//                     >

//                       My Jobs
//                     </Link>
//                     <Link
//                       to="/my-teams"
//                       className="dropdown-item text-secondary-100"
//                       type="button"
//                     >
//                       My Teams
//                     </Link>
//             </> 
// )}

//                   </ul>
//                 </div>
//               </li>
//             </ul>
//             <div className="flex-grow-1 nav-search-wrapper">
//               <div className="d-flex flex-grow-1">{children}</div>
//               {/* <button
//                 form="navbar-form"
//                 type="submit"
//                 disabled={!searchText}
//                 className="btn btn-primary"
//               >
//                 {isGraphOpen ?
//                   // <img src={whiteGraph} alt="white-graph" />
//                   <img src={searchIcon} alt="Search-icon" />
//                   :
//                   // <img src={searchIcon} alt="Search-icon" />
//                   <img src={whiteGraph} alt="white-graph" />
//                 }
//               </button> */}
//               <button
//                 form="navbar-form"
//                 type="submit"
//                 disabled={!searchText}
//                 className="btn btn-primary"
//                 onClick={handleSearchButtonClick}
                
//               >
//                 {isGraphOpen ? (
//                   <img src={whiteGraph} alt="white-graph" />
//                 ) : (
//                   // <img src={GraphIcon} alt="Search-icon" />
//                   //  <GraphIcon />
//                   <img src={whiteGraph} alt="white-graph" />
//                 )}
//               </button>
//             </div>
//             <div className="nav-icon-wrapper d-flex justify-content-end align-items-center">
//               <Box mr={3.75}>
//                 <Link to="/cart">
//                   <CustomBadge
//                     overlap="rectangular"
//                     badgeContent={cart.length}
//                     color="error"
//                   >
//                     <img className="nav-icon" src={cardIcon} alt="card-icon" />
//                   </CustomBadge>
//                 </Link>
//               </Box>
//               <Box mr={3.75}>
//                 <ShareTooltip
//                   anchorEl={openSaveCollection}
//                   setAnchorEl={setOpenSaveCollection}
//                   popoverButton={
//                     <img className="nav-icon" src={bell} alt="card-icon" />
//                   }
//                 >
//                   <Notifications />
//                 </ShareTooltip>
//               </Box>
//               <Box mr={3.75}>
//                 <Link to="/my-favourites">
//                   <CustomBadge
//                     overlap="rectangular"
//                     badgeContent={favCards.favCount}
//                     color="secondary"
//                   >
//                     <img className="nav-icon" src={favourite} alt="fav-icon" />
//                   </CustomBadge>
//                 </Link>
//               </Box>
//               <Box>
//                 {loggedInUser ? (
//                   <UserImageFrame />
//                 ) : (
//                   <Link className={classes.signIn} to="/sign-in">
//                     Sign in
//                   </Link>
//                 )}
//               </Box>
//             </div>
//           </div>
//         </div>

//         {/* Mobile input  */}
//         <div className="mt-2 container-fluid d-lg-none">
//           <div className="d-flex d-lg-none flex-grow-1 nav-search-wrapper">
//             <div className={`d-flex flex-grow-1 ${isFocused ? "focused" : ""}`}>
//               {/* <NavbarSelect nodeTypes={nodeTypes} mobile="mobile" />
//               <NavbarDropdownInput
//                 popularTags={popularTags}
//                 recentSearches={recentSearches}
//                 handleRecentClick={onRecentClick}
//                 handleRelatedTag={handleRelatedTag}
//                 handlePopularTag={handlePopularTag}
//                 tags={tags}
//                 setIsFocused={setIsFocused}
//                 responseData={responseData}
//                 onHandleChange={handleChangeText}
//                 path={pathname}
//                 handleSubmit={handleSubmit}
//                 searchText={searchText}
//                 setSearchText={setSearchText}
//                 mobile="mobile"
//               /> */}
//             </div>
//             <button
//               form="navbar-form"
//               type="submit"
//               disabled={!searchText}
//               className="btn btn-primary"
//               // onClick={handleClearSearch}
//             >
//               {/* <img src={searchIcon} alt="Search-icon" /> */}
//               <img src={whiteGraph} alt="white-graph" />
//             </button>
//           </div>
//         </div>

//         {/* Mobile Menu */}
//         <div
//           className={`mobile-menu position-absolute top-0 start-0 end-0 ${
//             open ? "d-block" : "d-none"
//           }`}
//         >
//           <div className="d-flex justify-content-between">
//             <div
//               onClick={() => setOpen(false)}
//               className="close-btn d-flex align-items-center"
//             >
//               <div className="me-3">
//                 <CloseIcon />
//               </div>
//               <p className="mb-0 fs-5 text-white text-bold">Close</p>
//             </div>
//             <Link
//               to="/home"
//               className="navbar-brand bg-secondary rounded-circle d-flex align-items-center"
//             >
//               <img className="rounded-circle" src={logo01} alt="logo" />
//             </Link>
//           </div>
//           <div className="menu-list d-flex flex-column align-items-center">
//             <span
//               onClick={() => openPage("/home")}
//               className="text-white text-decoration-none"
//             >
//               Home
//             </span>
//             <span
//               onClick={() => openPage("/my-work")}
//               className="text-white text-decoration-none"
//             >
//               My work
//             </span>
//             <span
//               onClick={() => openPage("/my-nodes")}
//               className="text-white text-decoration-none"
//             >
//               My Nodes
//             </span>
//             <span
//               onClick={() => openPage("/my-collections")}
//               className="text-white text-decoration-none"
//             >
//               My Collections
//             </span>
//           </div>
//         </div>
//       </nav>
//     </Box>
//   );
// };

// export default Navbar;
import { Box, makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import logo01 from "../../assets/images/card-icons/brand-logo.svg";
import cardIcon from "../../assets/images/navbarIcon/card.png";
import whiteGraph from "../../assets/images/card-icons/icon1.png";
import favourite from "../../assets/images/navbarIcon/favourite.png";
import searchIcon from "../../assets/images/navbarIcon/search.png";
import DataContext from "../../contexts/Context";
import { CloseIcon, MenuIcon, GraphIcon } from "../icons";
import { CustomBadge } from "../shared/custom-badge/CustomBadge";
import "./navbar.scss";
import bell from "../../assets/icons/bell.png";
import UserImageFrame from "./UserImageFrame";
import ShareTooltip from "../shared/popover/SharePopover";
import Notifications from "../shared/notifications/Notifications";

const useStyles = makeStyles(() => ({
  nav: {
    background: "#011936",
  },
  signIn: {
    textDecoration: "none",
    color: "#fff",
    fontWeight: 500,
    "&:hover": {
      color: "#e1d1d1",
    },
  },
}));

const Navbar = (props) => {
  const {
    searchText,
    isGraphOpen,
    isFocused,
    children,
    setSearchText, // Add setSearchText function
    handleSubmit,  // Add handleSubmit function
    // clearSearchText,
    openSidebar,
  } = props;

  const { cart = [], favCards = [], loggedInUser } = useContext(DataContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [prevSearchText, setPrevSearchText] = useState('');
  const [openSaveCollection, setOpenSaveCollection] = useState(null);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('logged-in-user'));
  const roleName = user ? user.roleName : null;
  console.log(roleName,"roleName")

  const openPage = (link) => {
    setOpen(false);
    navigate(link);
  };
  const handleSearchButtonClick = () => {
    if (isGraphOpen) {
      handleSubmit(); // Perform the search when the graph icon is clicked
    } else if (!searchText) {
      // If the search text is empty, don't modify it
      return;
    } else if (searchText !== prevSearchText) {
      // Set the search text to itself only if it's different from the previous search
      setSearchText(searchText);
    }
    setPrevSearchText(searchText); // Remember the current search text for the next comparison
  };
  
  // const handleClearSearch = () => {
  //   clearSearchText(); // Call the function to clear search text
  //   // Additional logic to close the search or reset filters, if needed
  // };

  return (
    <Box>
      <nav
        className={`${classes.nav} position-relative navbar navbar-expand-lg`}
      >
        <div className="container-fluid">
          <div className="d-flex">
            <button
              onClick={() => setOpen(true)}
              className="d-lg-none border-0 bg-transparent me-2"
            >
              <MenuIcon />
            </button>
            <Link to="/blog" className="nav-logo d-block">
              <img src={logo01} alt="logo" />
            </Link>
          </div>
          <div className="d-flex justify-content-between flex-lg-grow-1">
            {/* <ul className="d-none navbar-nav mb-2 mb-lg-0 d-lg-flex">
              <li className="navbar-item">
                <Link
                  to="/home"
                  className="nav-link home text-white"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="navbar-item">
                <div className="btn-group">
                  <NavLink
                    to="/my-work"
                    className="nav-link my-work text-white"
                    aria-current="page"
                  >
                    My Work
                  </NavLink>
                  <span
                    className="nav-link dropdown-toggle text-white"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul className="dropdown-menu dropdown-menu-end my-work-list">
                    <Link
                      to="/dataproduct"
                      className="dropdown-item text-secondary-100"
                      type="button"
                    >
                      My Work
                    </Link>
                    <Link
                      to="/my-nodes"
                      className="dropdown-item text-secondary-100"
                      type="button"
                    >
                      My Nodes
                    </Link>
                    <Link
                      to="/my-collections"
                      className="dropdown-item text-secondary-100"
                      type="button"
                    >
                      My Collections
                    </Link>
                    <Link
                      to="/your-orders"
                      className="dropdown-item text-secondary-100"
                      type="button"
                    >
                      My Orders
                    </Link>
           
                    <Link
                      to="/my-jobs"
                      className="dropdown-item text-secondary-100"
                      type="button"
                    >

                      My Jobs
                    </Link>
                    <Link
                      to="/my-teams"
                      className="dropdown-item text-secondary-100"
                      type="button"
                    >
                      My Teams
                    </Link>

                    
  

                  </ul>
                </div>
              </li>
            </ul> */}
              <ul className="d-none navbar-nav mb-2 mb-lg-0 d-lg-flex">
              <li className="navbar-item">
                <Link
                  to="/home"
                  className="nav-link home text-white"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li className="navbar-item">
                {roleName === "admin" ? (
                  <div className="btn-group">
                    <NavLink
                      to="/my-work"
                      className="nav-link my-work text-white"
                      aria-current="page"
                    >
                      My Work
                    </NavLink>
                    <span
                      className="nav-link dropdown-toggle text-white"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <ul className="dropdown-menu dropdown-menu-end my-work-list">
                      <Link
                        to="/dataproduct"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Work
                      </Link>
                      <Link
                        to="/my-nodes"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Nodes
                      </Link>
                      <Link
                        to="/my-collections"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Collections
                      </Link>
                      <Link
                        to="/your-orders"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Orders
                      </Link>
                     
                          <Link
                            to="/my-jobs"
                            className="dropdown-item text-secondary-100"
                            type="button"
                          >
                            My Jobs
                          </Link>
                          <Link
                            to="/my-teams"
                            className="dropdown-item text-secondary-100"
                            type="button"
                          >
                            My Teams
                          </Link>
                      
                    </ul>
                  </div>
                ) : roleName === "producer" ? (

                  <>
                    <div className="btn-group">
                    <NavLink
                      // to="/my-work"
                      className="nav-link my-work text-white"
                      aria-current="page"
                    >
                      My Work
                    </NavLink>
                    <span
                      className="nav-link dropdown-toggle text-white"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <ul className="dropdown-menu dropdown-menu-end my-work-list">
                    <Link
                        to="/dataproduct"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Work
                      </Link>
                    
                      <Link
                        to="/my-nodes"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Nodes
                      </Link>
                     
                         
                        
                      
                    </ul>
                  </div>
                  </>
               ):roleName === "consumer" && (
                <>
                                  <div className="btn-group">
                    <NavLink

                      className="nav-link my-work text-white"
                      aria-current="page"
                    >
                      My Work
                    </NavLink>
                    <span
                      className="nav-link dropdown-toggle text-white"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    />
                    <ul className="dropdown-menu dropdown-menu-end my-work-list">
                     
                      <Link
                        to="/your-orders"
                        className="dropdown-item text-secondary-100"
                        type="button"
                      >
                        My Orders
                      </Link>
                     

                      
                    </ul>
                  </div>

                </>
               )
              }
              </li>
            </ul>
            <div className="flex-grow-1 nav-search-wrapper">
              <div className="d-flex flex-grow-1">{children}</div>
              {/* <button
                form="navbar-form"
                type="submit"
                disabled={!searchText}
                className="btn btn-primary"
              >
                {isGraphOpen ?
                  // <img src={whiteGraph} alt="white-graph" />
                  <img src={searchIcon} alt="Search-icon" />
                  :
                  // <img src={searchIcon} alt="Search-icon" />
                  <img src={whiteGraph} alt="white-graph" />
                }
              </button> */}
              <button
                form="navbar-form"
                type="submit"
                disabled={!searchText}
                className="btn btn-primary"
                onClick={handleSearchButtonClick}
                
              >
                {isGraphOpen ? (
                  <img src={whiteGraph} alt="white-graph" />
                ) : (
                  // <img src={GraphIcon} alt="Search-icon" />
                  //  <GraphIcon />
                  <img src={whiteGraph} alt="white-graph" />
                )}
              </button>
            </div>
            {/* <div className="nav-icon-wrapper d-flex justify-content-end align-items-center">
              <Box mr={3.75}>
                <Link to="/cart">
                  <CustomBadge
                    overlap="rectangular"
                    badgeContent={cart.length}
                    color="error"
                  >
                    <img className="nav-icon" src={cardIcon} alt="card-icon" />
                  </CustomBadge>
                </Link>
              </Box>
              <Box mr={3.75}>
                <ShareTooltip
                  anchorEl={openSaveCollection}
                  setAnchorEl={setOpenSaveCollection}
                  popoverButton={
                    <img className="nav-icon" src={bell} alt="card-icon" />
                  }
                >
                  <Notifications />
                </ShareTooltip>
              </Box>
              <Box mr={3.75}>
                <Link to="/my-favourites">
                  <CustomBadge
                    overlap="rectangular"
                    badgeContent={favCards.favCount}
                    color="secondary"
                  >
                    <img className="nav-icon" src={favourite} alt="fav-icon" />
                  </CustomBadge>
                </Link>
              </Box>
              <Box>
                {loggedInUser ? (
                  <UserImageFrame />
                ) : (
                  <Link className={classes.signIn} to="/sign-in">
                    Sign in
                  </Link>
                )}
              </Box>
            </div> */}

<div className="nav-icon-wrapper d-flex justify-content-end align-items-center">
  {roleName === "consumer" || roleName === "admin" ? (
    <>
      <Box mr={3.75}>
        <Link to="/cart">
          <CustomBadge
            overlap="rectangular"
            badgeContent={cart.length}
            color="error"
          >
            <img className="nav-icon" src={cardIcon} alt="card-icon" />
          </CustomBadge>
        </Link>
      </Box>
      </>

      ) : null}
      <Box mr={3.75}>
        <ShareTooltip
          anchorEl={openSaveCollection}
          setAnchorEl={setOpenSaveCollection}
          popoverButton={
            <img className="nav-icon" src={bell} alt="card-icon" />
          }
        >
          <Notifications />
        </ShareTooltip>
      </Box>
      <Box mr={3.75}>
        <Link to="/my-favourites">
          <CustomBadge
            overlap="rectangular"
            badgeContent={favCards.favCount}
            color="secondary"
          >
            <img className="nav-icon" src={favourite} alt="fav-icon" />
          </CustomBadge>
        </Link>
      </Box>
      <Box>
        {loggedInUser ? (
          <UserImageFrame />
        ) : (
          <Link className={classes.signIn} to="/sign-in">
            Sign in
          </Link>
        )}
      </Box>
    

</div>

          </div>
        </div>

        {/* {/ Mobile input  /} */}
        <div className="mt-2 container-fluid d-lg-none">
          <div className="d-flex d-lg-none flex-grow-1 nav-search-wrapper">
            <div className={`d-flex flex-grow-1 ${isFocused ? "focused" : ""}`}>
              {/* <NavbarSelect nodeTypes={nodeTypes} mobile="mobile" />
              <NavbarDropdownInput
                popularTags={popularTags}
                recentSearches={recentSearches}
                handleRecentClick={onRecentClick}
                handleRelatedTag={handleRelatedTag}
                handlePopularTag={handlePopularTag}
                tags={tags}
                setIsFocused={setIsFocused}
                responseData={responseData}
                onHandleChange={handleChangeText}
                path={pathname}
                handleSubmit={handleSubmit}
                searchText={searchText}
                setSearchText={setSearchText}
                mobile="mobile"
              /> */}
            </div>
            <button
              form="navbar-form"
              type="submit"
              disabled={!searchText}
              className="btn btn-primary"
              // onClick={handleClearSearch}
            >
              {/* {/ <img src={searchIcon} alt="Search-icon" /> /} */}
              <img src={whiteGraph} alt="white-graph" />
            </button>
          </div>
        </div>

        {/* {/ Mobile Menu /} */}
        <div
          className={`mobile-menu position-absolute top-0 start-0 end-0 ${
            open ? "d-block" : "d-none"
          }`}
        >
          <div className="d-flex justify-content-between">
            <div
              onClick={() => setOpen(false)}
              className="close-btn d-flex align-items-center"
            >
              <div className="me-3">
                <CloseIcon />
              </div>
              <p className="mb-0 fs-5 text-white text-bold">Close</p>
            </div>
            <Link
              to="/home"
              className="navbar-brand bg-secondary rounded-circle d-flex align-items-center"
            >
              <img className="rounded-circle" src={logo01} alt="logo" />
            </Link>
          </div>
          <div className="menu-list d-flex flex-column align-items-center">
            <span
              onClick={() => openPage("/home")}
              className="text-white text-decoration-none"
            >
              Home
            </span>
            <span
              onClick={() => openPage("/my-work")}
              className="text-white text-decoration-none"
            >
              My work
            </span>
            <span
              onClick={() => openPage("/my-nodes")}
              className="text-white text-decoration-none"
            >
              My Nodes
            </span>
            <span
              onClick={() => openPage("/my-collections")}
              className="text-white text-decoration-none"
            >
              My Collections
            </span>
          </div>
        </div>
      </nav>
    </Box>
  );
};

export default Navbar;
