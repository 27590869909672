import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NodeCreationService } from "../../../services/NodeCreationService";
import { NodeList } from "../../filter-region/node-list/NodeList";
import { FilterIcon, TrashBinIcon } from "../../icons";
import { CircleButton, OutlinedButton } from "../buttons/custom-buttons/CustomButtons";
import ListViewTypeButton from "../buttons/list-view-button/ListViewTypeButton";
import ErorrPage from "../error/Erorr";
import SearchInput from "../inputs/SearchInput";
import Loader from "../loader/Loader";
import SliderPlaceholder from "../placeholder/SliderPlaceholder";
import { CustomTooltip } from "../tooltip/NodeTooltip";
import CollectionSidebar from "../../../pages/digital-publication/CollectionSidebar";
import CustomDrawer from "../drawer/CustomDrawer";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 150px)',
    paddingBottom: 100,
    paddingTop: 40
  },
  header: {
    marginBottom: 40
  },
  rightSideContent: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltip: {
    fontSize: 12,
    minWidth: 108,
    textAlign: 'center',
    color: '#123b64',
    padding: '7px 5px',
    borderRadius: 6
  },
  button: {
    width: 219,
    marginTop: 60,
    "& span": {
      fontWeight: 700,
    },
  },
  button1: {
    width: 219,
    marginTop: -70,
    marginLeft:1000,
    "& span": {
      fontWeight: 700,
    },
  },
}));

const Samplecardmodel = () => {

  const apiHandler = () => {
    window.open("https://pkware.alationcatalog.com/sources/", "_blank");
  };

  const classes = useStyles();
  const [searchNodeName, setSearchNodeName] = useState('')
  const [isListView, setIsListView] = useState(false)
  const [isError, setIsError] = useState(false)
  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedNodes, setSearchedNodes] = useState([])
  const navigate = useNavigate()
  const scrollRef = useRef(null)
  const { key, nodeName } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);

  const handleChange = (e) => {
    setSearchNodeName(e.target.value)
    const filteredNodes = nodes.filter(node => {
      return node.displayName.toLowerCase().includes(e.target.value.toLowerCase())
    })
    setSearchedNodes(filteredNodes)
  }

  useEffect(() => {
    NodeCreationService.fetchDataSources()
      .then((res) => {
        setNodes(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true)
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setSearchedNodes(nodes)
  }, [nodes])

  const goBack = () => {
    navigate(`/model-registration/${key}`)
  }  
  const Back = () => {
    navigate(`/curateprivacymodel/${nodeName}/${key}`)
  }  

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErorrPage />
  }

  return (
    <Container ref={scrollRef} className={classes.root}>
      <Box>
        <Typography className={classes.header} variant="h1" color="primary" align="center">
          {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 45 }}>
          <Box sx={{ maxWidth: 415, width: '100%' }}>
            <SearchInput value={searchNodeName} setValue={setSearchNodeName} handleChange={handleChange} />
          </Box>
          <Box className={classes.rightSideContent}>
            <CustomTooltip arrow title={<Typography className={classes.tooltip}>Create New Dataset</Typography>}>
              <CircleButton onClick={apiHandler} style={{ margin: '0 8px' }} disableRipple>
                <AddIcon />
              </CircleButton>
            </CustomTooltip>
            <CustomTooltip arrow title={<Typography className={classes.tooltip}>Open filter</Typography>}>
            <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer>
                <CircleButton onClick={() => setOpenSidebar(true)} style={{ margin: "0 8px" }} disableRipple>
                  <FilterIcon />
                </CircleButton>
            </CustomTooltip>
            <CustomTooltip arrow title={<Typography className={classes.tooltip}>Delete</Typography>}>
              <CircleButton style={{ margin: '0 8px' }} disableRipple>
                <TrashBinIcon />
              </CircleButton>
            </CustomTooltip>
            <Box ml={4}>
              <ListViewTypeButton handleListViewChange={setIsListView} isListView={isListView} />
            </Box>
          </Box>
        </Box>
      </Box>
      {nodes.length ? 
        <NodeList
          nodes={searchedNodes}
          showSaved={isListView}
          nodesPerPage={6}
          ref={scrollRef}
          isClickable={true}
        /> :
        <SliderPlaceholder error={true} text="No data found" />
      }
      {/* <Box className={classes.buttonWrapper}> */}
          <OutlinedButton onClick={goBack} className={classes.button} disableRipple>
            Back
          </OutlinedButton>
          <OutlinedButton onClick={Back} className={classes.button1} disableRipple>
            Next
          </OutlinedButton>
      {/* </Box> */}
    </Container>
  );
};

export default Samplecardmodel;
