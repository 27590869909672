import { makeStyles } from "@material-ui/core"
import face from '../../../../assets/images/faces/face16.jpg'
import faceEight from '../../../../assets/images/faces/face17.jpg'
import faceTwo from '../../../../assets/images/faces/face18.jpg'
import faceThree from '../../../../assets/images/faces/face19.jpg'
import faceFour from '../../../../assets/images/faces/face20.jpg'
import faceFive from '../../../../assets/images/faces/face21.jpg'
import faceSix from '../../../../assets/images/faces/face22.jpg'
import faceSeven from '../../../../assets/images/faces/face23.jpg'

export const useStyles = makeStyles({
    card: {
        padding: 20,
        boxShadow: "0px 2px 20px rgba(23, 87, 123, 0.2)",
        borderRadius: 16,
        display: 'flex',
        maxWidth: 616,
        width: '100%',
        minHeight: 280
    },
    leftSide: {
        marginRight: 20
    },
    rigthSide: {
        width: '100%'
    },
    typography: {
        color: '#123b64',
        fontSize: 14,
        fontWeight: 700,
        marginTop: 5
    },
    middleSide: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 15,
        marginBottom: 19
    },
    chartBox: {
        width: 100,
        height: 100,
        marginRight: 30
    },
    dataAndMetadata: {
        display: 'flex',
        alignItems: 'center'
    },
    circle: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: 8
    },
    primaryCircle: {
        background: '#123b64'
    },
    successCircle: {
        background: '#62ADA6',
    },
    secondaryCircle: {
        background: '#F79F19',
    },
    bottomSide: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    avatarGroupWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            marginRight: 19
        }
    },
    avatarGroup: {
        '& .MuiAvatar-root': {
            width: '24px!important',
            height: '24px!important',
            border: 'none'
        },
        '& .MuiAvatar-root:last-child': {
            marginLeft: 8,
            background: 'transparent',
            color: '#123b64c2',
            fontSize: 14
        }
    },
    defaultGroup: {
        '& .MuiAvatar-root': {
            width: '24px!important',
            height: '24px!important',
            border: 'none'
        },
        '& .MuiAvatar-root:last-child': {
            marginLeft: -8,
            background:  '#bdbdbd',
            color: "#fafafa",
            fontSize: 20
        }
    },
    avatar: {
        width: 24,
        height: 24
    },
    primaryButton: {
        width: 156
    }
})

export const users = [
    { name: 'Ali', imgUrl: face },
    { name: 'Vali', imgUrl: faceTwo },
    { name: 'Ikrom', imgUrl: faceThree },
    { name: 'Ahror', imgUrl: faceFour },
    { name: 'Bilol', imgUrl: faceFive },
    { name: 'Sunnat', imgUrl: faceSeven },
    { name: 'Hikmat', imgUrl: faceSix },
    { name: 'Jalol', imgUrl: faceEight },
]
