import React from 'react';
import './FacetsSubContainer.scss';
import { MdClear } from 'react-icons/md';
import { BiSearch } from 'react-icons/bi';
import { makeStyles } from '@material-ui/styles';
import Tree from '../../shared/tree/Tree';
import { Chip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { FACETS_DATA } from '../../../assets/mock-data/filter-options';

const regions = [
    "Lorraine",
    "Midi-Pyrénées",
    "Aquitaine",
    "Haut",
    "Champagne-Ardenne",
    "Ile-de-France",
    "Pays de la Loire",
    "French Guiana",
    "Aquitaine",
]

const useStyles = makeStyles({
    treeWrapper: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#F79F19',
            borderRadius: 2,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#808c9a4d',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-button:start:increment': {
            height: '45px',
            display: 'block'
        },
        '&::-webkit-scrollbar-button:end:increment': {
            height: '23px',
            display: 'block'
        }
    },
    clearBtn: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    chip: {
        borderRadius: 12,
        color: '#123b64',
        margin: '0 8px 8px 0',
        '& .MuiChip-deleteIcon': {
            width: 14,
            height: 14,
            color: '#123b64',
            marginRight: 8
        }
    }
})

const FacetsSubContainer = ({ showInfo, setShowInfo, continents }) => {
    const classes = useStyles()

    const handleDelete = () => {
        console.log('delete region')
    }

    return (
        <div className='facets-sub position-fixed'>
            <div className="facets-sub-container">
                <button className="claer-btn position-absolute" onClick={() => setShowInfo(!showInfo)}>
                    <MdClear />
                </button>
                <div className="btns-wrapper">
                    {
                        regions.map((region, index) => {
                            return <Chip
                                key={index}
                                className={classes.chip}
                                label={region}
                                deleteIcon={<ClearIcon />}
                                onDelete={handleDelete}
                            />
                        })
                    }
                </div>
                <div className="region-search input-group">
                    <span className="btn-search input-group-text" id="basic-addon1"><BiSearch /></span>
                    <input
                        type="text"
                        className="form-control h-100"
                        placeholder="Search for a region"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                    />
                </div>
                <div className='clear-wrapper'>
                    <div className={classes.clearBtn}>clear all</div>
                    <div className={classes.treeWrapper}>
                        <div style={{ width: 320 }}>
                            <Tree data={FACETS_DATA} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacetsSubContainer
