import React, { useEffect, useState } from "react";
import { Box, Chip } from "@material-ui/core";
import { useStyles } from "./elementsDetails";
import ClearIcon from "@material-ui/icons/Clear";

// Component to display selected chips based on selectedRows
function SelectedChips({ rowsMap, selectedRows, setSelectedRows }) {
  const classes = useStyles();
  const [chips, setChips] = useState([]);

  // Remove chip from selectedRows when deleted
  const handleDeleteChip = (nodeId) => {
    const newSelectedRows = new Set(selectedRows);
    newSelectedRows.delete(nodeId);
    setSelectedRows(newSelectedRows);
  };

  // Update chips when selectedRows change
  useEffect(() => {
    setChips(Array.from(selectedRows));
  }, [selectedRows]);

  console.log(selectedRows, "chips");

  return (
    <Box className={classes.chipsWrapper}>
      {/* Map through selectedRows to display chips */}
      {chips.map((chip) => {
        const row = rowsMap.get(chip);
        const name = row ? row.name : "Name not found"; 

        return (
          <Chip
            key={chip}
            className={classes.chip}
            label={name !== "Name not found" ? name : `${chip}`}
            deleteIcon={<ClearIcon />}
            onDelete={() => handleDeleteChip(chip)}
          />
        );
      })}
    </Box>
  );
}

export default SelectedChips;
