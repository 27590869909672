import { makeStyles, Typography } from "@material-ui/core";
import FullNodeCardSlider from "../full-card-slider/FullNodeCardSlider";
import SliderPlaceholder from "../../shared/placeholder/SliderPlaceholder";

const useStyles = makeStyles({
  title: {
    fontSize: 40,
    fontWeight: 900,
    lineHeight: "48px",
    color: "#123b64",
    margin: "130px 0 40px",
    paddingLeft: 16,
    paddingRight: 16,
  },
});

const FullNodeCardSliderWrapper = ({ cardItems, setCardData, setState }) => {
  const classes = useStyles();
  const trendingSearchs = cardItems?.trending;
  const recentSearchs = cardItems?.recent;

  return (
    <div style={{ padding: "0 28px" }}>
      {trendingSearchs.length ? (
        <FullNodeCardSlider
          setState={setState}
          setCardData={setCardData}
          data={trendingSearchs}
        />
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}

      <Typography component="h1" className={classes.title}>
        Recently viewed
      </Typography>
      {recentSearchs.length ? (
        <FullNodeCardSlider
          setState={setState}
          setCardData={setCardData}
          data={recentSearchs}
        />
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}
      <Typography component="h1" className={classes.title}>
        Trending searches
      </Typography>
      {trendingSearchs.length ? (
        <FullNodeCardSlider
          setState={setState}
          setCardData={setCardData}
          data={trendingSearchs}
        />
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}
    </div>
  );
};

export default FullNodeCardSliderWrapper;
