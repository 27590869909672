import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/breadcrumb/Breadcrumbs";
import CustomizedRadio from "../../../shared/inputs/CustomizedRadio";
import CustomizedSelect from "../../../shared/inputs/CustomizedSelect";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import Loader from "../../../shared/loader/Loader";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { 
  useStyles, 
  validationSchema, 
  initialValues, 
  SECURTY_CLASSIFACTION, 
  RETENTION_PERIOD, 
  TOXIC_COMBINATION, 
  PII } from "./Curate-privacy";

function CuratePrivacymodel() {
  const classes = useStyles();
  const { key, nodeName, selectedNodeName } = useParams();
  const [refreshFreqOptions, setRefreshFreqOptions] = useState([]);
  const [isLoadingRefFreq, setIsLoadingRefFreq] = useState(true);
  const [formValues, setFormValues] = useState(initialValues)
  const navigate = useNavigate()


  useEffect(() => {
    // we need retention period options api instead of refresh frequency api
    NodeCreationService.fetchSelectOptions('RefreshFrequency')
      .then(res => {
        const transformedData = res.data[0].map(item => {
          return { label: item, value: item }
        })
        setRefreshFreqOptions(transformedData)
        setIsLoadingRefFreq(false)
      })
      .catch(() => {
        setIsLoadingRefFreq(false)
      })

      NodeCreationService.getCuratePrivacyValues(key)
      .then(res => {
        const hasData = Object.keys(res.data).length
        if (hasData) {
          const formValues = {
            [SECURTY_CLASSIFACTION]: res.data.securityClassification,
            [RETENTION_PERIOD]: res.data.privacyRetentionPeriod,
            [TOXIC_COMBINATION]: res.data.inforedToxicCombination,
            [PII]: res.data.pII
          }
          setFormValues(formValues)
        }
      })
  }, [key]);
  

  const onSubmit = (values) => {
    const payload = []
    Object.keys(values).forEach(item => {
      let emptyObj = {}
      emptyObj.attributeName = item
      emptyObj.attributeValue = values[item]
      payload.push(emptyObj)
    })
    
    NodeCreationService.createAttributes(payload, key)
      .then(() => {
        navigate(`/modelform/${nodeName}/${key}`)
      })
      .catch(() => {
        console.log('error is occured in api')
      })
  }


  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  });

  const { values, handleChange, handleSubmit } = formik

  if (isLoadingRefFreq) {
    return <Loader />
  }

  return (
    <div>
      <Container className={classes.root}>
        <Box mb={2.5}>
          <Breadcrumbs />
        </Box>
        <Box>
          <Typography variant="h1" color="primary" className={classes.header} align="center">
          {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
          </Typography>
          <Box className={classes.paper}>
            <Box className={classes.paperTitle} px={5} py={2.5}>
              <Typography variant="h5" color="primary">2.1 Curate/ Privacy</Typography>
            </Box>
            <Box className={classes.paperBody} p={5}>
              <form id="curate-privacy-form" style={{ maxWidth: 508 }} onSubmit={handleSubmit}>
                <Typography variant="h6" color="primary" className={classes.text}>
                  Security Classification
                </Typography>
                <Box pb={1.75}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioWrapper}
                      value={values[SECURTY_CLASSIFACTION]}
                      name={SECURTY_CLASSIFACTION}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="public"
                        control={<CustomizedRadio />}
                        label="Public"
                      />
                      <FormControlLabel
                        value="private"
                        control={<CustomizedRadio />}
                        label="Private"
                      />
                      <FormControlLabel
                        value="confidential"
                        control={<CustomizedRadio />}
                        label="Confidential"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Box mb={2.5}>
                  <CustomizedSelect
                    id="RetentionPeriod"
                    label={RETENTION_PERIOD}
                    options={refreshFreqOptions}
                    name={RETENTION_PERIOD}
                    value={values[RETENTION_PERIOD]}
                    handleChange={handleChange}
                    defaultValue="Select retention period"
                    formik={formik}
                  />
                </Box>
                <Typography variant="h6" color="primary" className={classes.text}>
                  Infored toxic Combination:
                </Typography>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioWrapper}
                      value={values[TOXIC_COMBINATION]}
                      name={TOXIC_COMBINATION}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value='true'
                        control={<CustomizedRadio />}
                        label="True"
                      />
                      <FormControlLabel
                        value='false'
                        control={<CustomizedRadio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Typography variant="h6" color="primary" className={classes.text}>
                  PII
                </Typography>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioWrapper}
                      value={values[PII]}
                      name={PII}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value='true'
                        control={<CustomizedRadio />}
                        label="True"
                      />
                      <FormControlLabel
                        value='false'
                        control={<CustomizedRadio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Link to={`/model_samplecard/${selectedNodeName}/${nodeName}/${key}`} style={{ textDecoration: "none" }}>
            <BackBtn>Back</BackBtn>
          </Link>
          <BackBtn form="curate-privacy-form" type="submit">Next</BackBtn>
        </Box>
      </Container>
    </div>
  );
}

export default CuratePrivacymodel;
