
import { Button, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
    button:{
        textTransform: 'none',
        padding: '13px 15px',
        marginRight: 14,
        borderRadius: 8,
        color: '#123b64',
        margin: '7.5px 0',
        '&:hover': {
            backgroundColor: '#123b64',
            color: '#fff',
            boxShadow: 'none',
            '& div': {
                display: 'block',
                fontSize: 10
            }
          },
          '&:active': {
            backgroundColor: '#123b64',
            boxShadow: 'none'
          },
    },
    typeNumbers: {
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: 14,
        height: 14,
        background: '#fff',
        fontSize: '10px!important',
        color: '#011936',
        borderRadius: 2,
        width: 'max-content'
    }
})

const CategoryBtn = ({name, number}) => {
    const classes = useStyles()
    return (
        <Button 
            className={classes.button}
            disableRipple
            variant="outlined" 
            endIcon={number && <div className={classes.typeNumbers}>{number}</div>}
        >
            {name}
        </Button>
    )
}
export default CategoryBtn