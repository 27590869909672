import React, { useState } from 'react';
import Select from '@material-ui/core/Select';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box, MenuItem, ListItemText, Chip, Typography } from '@material-ui/core'
import { CustomCheckbox } from './CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import TagsTooltip from '../tooltip/TagsTooltip';
import ChoiceUserForRole from '../team-builder/choice-user-for-role/ChoiceUserForRole';
import { useEffect } from 'react';
import { TrashBinIcon } from '../../icons';

const useStyles = makeStyles({
    box: {
        width: '100%',
        '& .MuiSelect-icon': {
            right: 11,
            width: 20
        },
        '& .MuiInputBase-root': {
            width: '100%'
        }
    },
    userRoles: {
        minHeight: 48,
        border: '1px solid #808C9A',
        padding: '6px 12px',
        width: '100%',
        borderRadius: 8,
        marginTop: 10,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    chip: {
        fontSize: 14,
        color: '#123b64',
        background: '#E0E7EC',
        margin: 4,
        borderRadius: 12,
        height: 28
    },
    clearIcon: {
        color: '#123b64',
        width: 14,
        height: 14,
        marginRight: 10
    },
    label: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.1,
        marginBottom: 8
    },
    select: {
        boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.1)"
    }
})

const SelectInput = withStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: 8,
        fontSize: 14,
        padding: '15px 16px',
        lineHeight: 1.4,
        color: '#123b64',
        border: '1px solid #ABADB3',
        '&:focus': {
            borderRadius: 8,
            background: '#fff'
        },
        '& .MuiSelect-icon': {
            right: 9
        }
    },
}))(Select)

const SelectRole = (props) => {
    const {
        label,
        roleOptions,
        users,
        field,
        setFormFields,
        formFields,
        index,
        numOfEditData,
        type,
        requestType,
        deleteRole
    } = props
    const classes = useStyles()
    const [showOptions, setShowOptions] = useState(false)
    const [open, setOpen] = useState(false);
    const [checkedUsers, setCheckedUsers] = useState({})

    useEffect(() => {
        if (numOfEditData > 0 || requestType === "update") {
            let users = field.users
            let copyCheckedUsers = checkedUsers
            users.forEach(user => {
                copyCheckedUsers[user.id] = true
            })
            setCheckedUsers(copyCheckedUsers)
        }
    }, [])

    const handleDelete = (userId) => {
        let data = type === "matrix-team" ? [...formFields.roles] : [...formFields]
        let copyCheckedUsers = checkedUsers
        delete copyCheckedUsers[userId]
        data[index].users = data[index].users.filter(user => user.id !== userId)
        const isEmpty = data[index].users.length === 0

        if (isEmpty) {
            data.forEach(item => {
                if (data[index].users.length === 0) {
                    data[index].errors.users = "At least one user must be selected"
                }
            })
        }
        type === "matrix-team" ?
            setFormFields(prev => {
                return {
                    ...prev,
                    roles: [
                        ...data,
                    ]
                }
            }) : setFormFields([...data])
        setCheckedUsers(copyCheckedUsers)
    }

    const handleChange = (event) => {
        if (type === "matrix-team") {
            let copyRoles = [...formFields.roles]
            let updatedRoles = copyRoles.map((item, i) => {
                if (i !== index) {
                    return item
                }
                return {
                    ...item,
                    [event.target.name]: event.target.value,
                    errors: {
                        ...item.errors,
                        [event.target.name]: event.target.value.length > 0 ?
                            null : "Role is required"
                    }
                }
            })
            setFormFields(prev => {
                return {
                    ...prev,
                    roles: [
                        ...updatedRoles
                    ]
                }
            })
        } else {
            setFormFields(prev => {
                return prev.map((item, i) => {
                    if (i !== index) {
                        return item
                    }
                    return {
                        ...item,
                        [event.target.name]: event.target.value,
                        errors: {
                            ...item.errors,
                            [event.target.name]: event.target.value.length > 0 ?
                                null : "Role is required"
                        }
                    }
                })
            })
        }

    };

    return (
        <Box className={classes.box}>
            <Box mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='body2' color='primary'>{label}</Typography>
                <Box mt={-0.5} onClick={() => deleteRole(index)}>
                    <TrashBinIcon width={15} />
                </Box>
            </Box>
            <SelectInput
                name="role"
                id="add-role"
                disableUnderline
                value={field.role}
                onClick={() => setShowOptions(!showOptions)}
                onBlur={() => setShowOptions(false)}
                onChange={handleChange}
                displayEmpty
                IconComponent={showOptions ? ExpandLessIcon : ExpandMoreIcon}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <Typography variant='body2' color="primary">Select role</Typography>
                    } else {
                        return selected
                    }
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    classes: { paper: classes.select }
                }}
            >
                <MenuItem disabled value="">
                    <ListItemText primary="Select role" />
                </MenuItem>
                {roleOptions.map((roleOptions) => (
                    <MenuItem key={roleOptions.value} value={roleOptions.value}>
                        <CustomCheckbox role="role" checked={roleOptions.value === field.role} />
                        <ListItemText primary={roleOptions.label} />
                    </MenuItem>
                ))}
            </SelectInput>
            <TagsTooltip
                teamBuilder={true}
                setOpen={setOpen}
                open={open}
                input={
                    <Box className={classes.userRoles}>
                        {
                            field.users.length > 0 ?
                                field.users.map(user => (
                                    <Chip
                                        key={user.id}
                                        className={classes.chip}
                                        label={user.name}
                                        deleteIcon={<ClearIcon className={classes.clearIcon} />}
                                        onDelete={() => handleDelete(user.id)}
                                    />
                                )) : <Typography variant="body2" color="primary">Select users for Role</Typography>
                        }
                    </Box>
                }>
                <ChoiceUserForRole
                    users={users}
                    setOpen={setOpen}
                    checkedUsers={checkedUsers}
                    setCheckedUsers={setCheckedUsers}
                    setFormFields={setFormFields}
                    formFields={formFields}
                    index={index}
                    field={field}
                    type={type}
                />
            </TagsTooltip>
        </Box>

    );
}

export default SelectRole;