import { Container, makeStyles, Typography, Box } from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../components/shared/loader/Loader";
import { SearchService } from "../../services/SearchService";
import { NodeList } from "../../components/filter-region/node-list/NodeList";
import { Link, useParams } from "react-router-dom";
import SliderPlaceholder from "../../components/shared/placeholder/SliderPlaceholder";
import { OutlinedButton } from "../../components/shared/buttons/custom-buttons/CustomButtons";

const useStyles = makeStyles({
  container: {
    paddingTop: 40,
    paddingBottom: 100,
    minHeight: "calc(100vh - 150px)",
  },
  errContainer: {
    minHeight: "calc(100vh - 150px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

function Controls() {
  const classes = useStyles();
  const [nodes, setNodes] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const { id } = useParams();

  const getNodes = () => {
    return SearchService.fetchControls(id)
      .then((res) => {
        setNodes(res.data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
      });
  };

  useEffect(() => {
    getNodes();
  }, []);

  if (isloading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <Link
        style={{ textDecoration: "none", color: "#123B64" }}
        to="/cart/checkout-details"
      >
        <OutlinedButton
          style={{
            width: 219,
            "& span": {
              fontWeight: 700,
            },
            marginLeft: 1560,
            marginTop: -50,
            marginBottom: -250,
          }}
          disableRipple
        >
          Back
        </OutlinedButton>{" "}
      </Link>
      <Typography variant="h1" color="primary" align="center">
        Controls
      </Typography>
      {nodes.length == 0 ? (
        <Box className={classes.errContainer}>
          <SliderPlaceholder error={true} text="No controls available" />
        </Box>
      ) : null}

      <Box>
        <NodeList nodes={nodes} showSaved={true} nodesPerPage={12} />
      </Box>
    </Container>
  );
}

export default Controls;
