import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    root: {
        width: 588,
        padding: '45px 40px 50px 40px',
        color: '#123b64'
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 29
    },
    title: {
        fontSize: 22,
        fontWeight: 700
    },
    inputsWrapper: {
        marginBottom: 40
    },
    addNewRoles: {
        marginBottom: 40
    },
    hr: {
        margin: "0 0 40px",
        background: '#E0E7EC'
    },
    yellowButton: {
        width: 182,
        height: 48
    },
    loading: {
        width: 588,
        padding: 40,
    },
    delete1:
    {
        marginLeft: 480,
        marginTop: -40,
        marginBottom: 30,
    },
    delete:
    {
        marginLeft: 480,
        marginTop: -10,
        marginBottom: -20,
    },
})