import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useEffect, useState } from "react";

export default function IdlePromptDialog(props) {
  const { onClose, open, onLogout, timeOut } = props;
  const [idleStatTime, setIdleStartTime] = useState('')

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setIdleStartTime(timeOut / 60000)
  }, [timeOut])


  return (
    <Dialog onClose={onClose} open={open}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid lightgrey', px: 2, alignItems: 'center' }}>
        <Typography variant="h3">Idle Prompt </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Box sx={{ px: 2, py: 3 }}>
          <p>
            You have beem idle for
            <strong> {idleStatTime} minutes </strong>. Would you like to proceed?
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogout}>No</Button>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
}
