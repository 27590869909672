import { Box } from '@material-ui/core';
import TeamTable from '../../shared/table/team-Table/TeamTable';
import SliderPlaceholder from '../../shared/placeholder/SliderPlaceholder';
import Loader from '../../shared/loader/Loader';

function MyTeams({myTeams, isLoading}) {
    
    if(isLoading) {
        return <Loader />
    }
    return (
        <Box mt={5}>
            {
                myTeams.length ? 
                <TeamTable rows={myTeams} /> :
                <SliderPlaceholder error={true} text="No data found"/>
            }
        </Box>
    );
}

export default MyTeams;