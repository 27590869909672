import React, { useContext } from "react";
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  Divider,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../shared/buttons/custom-buttons/CustomButtons";
import DataContext from "../../../contexts/Context";
import Node from "../../cart/description-node/Node";
import { Link, useNavigate } from "react-router-dom";


const useStyles = makeStyles(theme => ({
  addedToCart: {
    background: "#fff",
    padding: "50px 54px 50px 50px",
    [theme.breakpoints.down('768')]: {
      padding: '20px 16px'
    }
  },
  box: {
    display: "flex",
    alignItems: "center",
    padding: "14px 50px",
  },
  h6: {
    textDecoration: "underline",
    margin: "0 6.5px 0 12px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 30,
    [theme.breakpoints.down('768')]: {
      marginBottom: 20
    }
  },
  success: {
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#13AF3F",
    borderRadius: "50%",
    marginRight: 30,
  },
  divider: {
    background: "#E0E7EC",
    marginBottom: 20,
  },
  ButtonBox: {
    display: 'flex',
    [theme.breakpoints.down("578")]: {
      flexDirection: "column-reverse"
    }
  },
  primaryButton: {
    width: 340,
    [theme.breakpoints.down('578')]: {
      width: '100%',
      marginBottom: 15
    }
  },
  OutlinedButton: {
    "& span": {
      fontWeight: 700,
    },
    marginRight: 16,
    width: 160,
    [theme.breakpoints.down('578')]: {
      width: '100%'
    }
  },
  link: {
    textDecoration: "none",
  },
}));

function AddedToCart({ setState }) {
  const classes = useStyles();
  const { cart, setChecked, setSelectedNodes } = useContext(DataContext);
  const navigate = useNavigate()

  const clickHandler = () => {
    let nodesId = cart.map(node => node.id)
    let obj = nodesId.reduce((prev, id) => {
      return Object.assign(prev, { [id]: true })
    }, {})
    setChecked(obj)
    setSelectedNodes(cart)
    navigate("/cart/checkout-details")
  }

  return (
    <div className={classes.addedToCart}>
      <Box className={classes.header}>
        <Box className={classes.success}>
          <svg
            width="21"
            height="15"
            viewBox="0 0 21 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.3511 1.45117L8.54546 13.5279C8.04338 14.0923 7.32665 14.0923 6.82457 13.5279L1.85107 7.97004"
              stroke="#F7F5F6"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </Box>
        <Typography variant="h2" color="primary">
          Added to Cart
        </Typography>
        <IconButton
          style={{ padding: "0", marginLeft: "auto" }}
          onClick={() => setState(false)}
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider className={classes.divider} />
      <Box mb={5}>
        <Typography
          style={{ marginBottom: 10 }}
          variant="subtitle1"
          color="primary"
        >
          Cart subtotal ( {cart.length} items):
        </Typography>
        {cart.map((node) => (
          <Box key={node.id} mb={2.5}>
            <Node lockIcon="lock-icon" node={node} />
          </Box>)
        )}
      </Box>
      <Box className={classes.ButtonBox} display="flex">
        <Link className={classes.link} to="/cart">
          <OutlinedButton className={classes.OutlinedButton} disableRipple>
            Go to Cart
          </OutlinedButton>
        </Link>
        <PrimaryButton className={classes.primaryButton} onClick={clickHandler} disableRipple>
          Proceed to checkout ({cart.length} item)
        </PrimaryButton>
      </Box>
    </div>
  );
}

export default AddedToCart;
