import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import checkIcon from '../../../assets/images/check-icon.png'
import React from "react";

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    width: 24,
    height: 24,
    borderRadius: 8,
    border: '2px solid #808C9A',
    backgroundColor: props => props.filterCheckbox ? "transparent" : 'transparent',
  },
  checkedIcon: {
    backgroundColor: props => props.filterCheckbox ? "#F79F19" : '#F79F19',
    border: '2px solid #F79F19',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: `url(${checkIcon})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      content: '""',

    },
  },
});

export const CustomCheckbox = React.forwardRef(({ filterCheckbox, ...props }, ref) => {
  const classes = useStyles({ filterCheckbox });
  return (
    <Checkbox
      ref={ref}
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
})

export const FavouriteCheckbox = ({ page, ...props }) => {
  const giveStyle = (selected) => {
    if (page === "node-viewer" && selected !== "checked") {
      return { width: 27, height: 27, marginTop: -5 }
    } else if (page === "node-viewer" && selected === "checked") {
      return {width: 27, height: 27, marginTop: -5, fill: "#123b64"}
    } else if (page !== "node-viewer" && selected === "checked") {
      return { width: 24, fill: '#123b64' }
    } else {
      return { width: 24 }
    }
  }
  return (
    <Checkbox
      disableRipple
      icon={<FavoriteBorder style={giveStyle("unChecked")} />}
      checkedIcon={<Favorite style={giveStyle("checked")} />}
      {...props}
    />
  )
}