import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Box, Fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    inputBox: {
        width: props => props.teamBuilder ? '100%' : 'max-content'
    },
    dropdown: {
        position: 'absolute',
        right: props => props.position ? props.position.right : "unset",
        left: props => props.position ? props.position.left : 'unset',
        bottom: props => props.position ? props.position.bottom : 'unset',
        top: props => props.position ? props.position.top : 'unset',
        zIndex: 99
    }
}));

export default function TagsTooltip({ children, input, open, setOpen, ...props }) {
    const classes = useStyles(props);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = (e) => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <Box className={classes.inputBox} onClick={handleClick}>
                    {input}
                </Box>
                {open ? (
                    <Box className={classes.dropdown}>
                        {children}
                    </Box>
                ) : null}
            </div>
        </ClickAwayListener>
    );
}
