import { makeStyles, Box, Typography } from '@material-ui/core';
import { normalLabelComponent } from '../../../../shared/inputs/slider/Sliderdetails';
import CustomizedSlider from '../../../../shared/inputs/slider/Slider';

const useStyles = makeStyles({
    secondaryLabel: {
        color: '#808C9A',
        fontSize: 14
    },
    label: {
        lineHeight: '11px',
        marginBottom: 44
    },
})

function ObjectiveSlider(props) {
    const classes = useStyles()
    const {id, name, value=0, color, label, setFieldValue, valueType} = props

    return (
        <>
            <Typography variant="body2" color="primary" className={classes.label}>
                {label}
            </Typography>
            <CustomizedSlider
                id={id}
                name={name}
                value={value}
                setFieldValue={setFieldValue}
                valueType={valueType}
                valueLabelComponent={normalLabelComponent}
                color={color}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography className={classes.secondaryLabel}>
                    0%
                </Typography>
                <Typography className={classes.secondaryLabel}>
                    100%
                </Typography>
            </Box>
        </>
    );
}

export default ObjectiveSlider;