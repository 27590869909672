// import React from "react";
// import { Box, Typography, makeStyles ,Checkbox} from '@material-ui/core';
// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { CustomCheckbox } from "../../components/shared/inputs/CheckBox";
// import { CollectionService } from '../../services/CollectionService';
// const useStyles = makeStyles({
//   box: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     borderBottom: '1px solid #172f4b',
//     padding: '19px 32px',
//   },
//   label: {
//     color: '#fff',
//     width:'100%',

//   },
//   plusIcon: {
//     color: '#808C9A',
//     width: 20,
//     height: 20,
//   },
//   minusIcon:{
//     color:'#808C9A',
//     cursor: 'pointer',
//     marginLeft: '8px',
//     margintop:'150px', // Adjust the margin as needed  

//   },
//   root: {
//     color: "#fff",
//     width: 380,
//     background:
//       "linear-gradient(259.26deg, rgb(1, 25, 54) -14.93%, rgb(1, 25, 54) 61.45%)",
//   },
//   checkboxContainer: {
//     display: 'flex',
//     alignItems: 'center',
//     marginTop: '8px',
//   },
//   choicedomain:{
//     display:'flex',
//     justifyContent:'space-between',
//     width:'100%',

//   }  ,
//   Check : {
//     borderRadius:' 1px solid #123b64',

//   }
// })




// export default function App() {
//   const [selectedObj,setSelectedObject] = useState(null)
//   const[data,setData]=useState([])
  
//   const classes = useStyles();
//   const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
//   const fetchFilters = async () => {
//     try {
//       const response = await CollectionService.fetchCollectionFilters();
//       setData(response.data[0]);
//       console.log('response', response);
      
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };
  
  
//   useEffect(() => {
//     fetchFilters();
//   }, []);

 
 
//  let choiceDomainNames = Object.keys(data);
 
//  console.log(choiceDomainNames);
//  function getDomainValues(domainName) {
//   let domains = data[domainName] || [];

//   if (domains.length > 0 && domains[0].hasOwnProperty('name')) {
//     // If 'name' property is present, return the array as is
//     return domains;
//   } else {
//     const newArray = domains.map(item => {
//       return Object.entries(item).flatMap(([key, value]) => {
//         if (typeof value === 'object') {
//           return Object.entries(value).map(([innerKey, innerValue]) => {
//             return { name: innerKey, value: innerValue };
//           });
//         } else {
//           return { name: key, value: value };
//         }
//       });
//     }).flat();

//     return newArray;
//   }
// }
//  const onDomainSelect = (domainName,reset) => {
//    if(reset){
//      setSelectedObject({selectedDomain:domainName,valu:[]})
//    }else {
//     const valu =  getDomainValues(domainName);
//     setSelectedObject({selectedDomain:domainName,valu})
//     console.log(valu)
//    }
 
//  }
//    return (
//      <Box  className={classes.root}>
//      {choiceDomainNames.map(domainName => {
//       return <Box className={classes.box} key={domainName}>
//         <Typography style={{width:'100%'}}>
//         <h5 className={classes.choicedomain}>
//           {domainName}
//           {
//             selectedObj?.selectedDomain === domainName  && selectedObj?.valu?.length? 
           
//             <RemoveIcon onClick={() => {onDomainSelect(domainName,true)}} className={classes.plusIcon} />
//                : 
//                 <AddIcon onClick={() => {onDomainSelect(domainName,false)}}  className={classes.minusIconIcon} />
//           }
//           </h5>
//         {
//          selectedObj?.selectedDomain === domainName && selectedObj.valu.map(sel => {
//             return <div style={{display:'flex',justifyContent:'space-between'}}>
//               <p>
//               <CustomCheckbox  className={classes.Check}/> {sel?.name}</p>

//               <p> {sel?.value} {sel?.choiceCount}</p>
//             </div>
//           })
//         }
//         </Typography>
//         </Box>
//      })}
//      </Box>
//      )
//  }       


import React, { useState, useEffect } from "react";
import { Box, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import axios from 'axios';
import { CustomCheckbox } from "../../components/shared/inputs/CheckBox";
import { CollectionService } from '../../services/CollectionService';

const useStyles = makeStyles({
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #172f4b',
    padding: '19px 32px',
  },
  label: {
    color: '#fff',
    width:'100%',
  },
  plusIcon: {
    color: '#808C9A',
    width: 20,
    height: 20,
  },
  minusIcon:{
    color:'#808C9A',
    cursor: 'pointer',
    marginLeft: '8px',
    margintop:'150px', // Adjust the margin as needed  
  },
  root: {
    color: "#fff",
    width: 380,
    background:
      "linear-gradient(259.26deg, rgb(1, 25, 54) -14.93%, rgb(1, 25, 54) 61.45%)",
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  choicedomain:{
    display:'flex',
    justifyContent:'space-between',
    width:'100%',
  }  ,
  Check : {
    borderRadius:' 1px solid #123b64',
  }
})

export default function App() {
  const [selectedObj, setSelectedObject] = useState(null);
  const [data, setData] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const classes = useStyles();

  const fetchFilters = async () => {
    try {
      const response = await CollectionService.fetchCollectionFilters();
      setData(response.data[0]);
      console.log('response', response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  // const handleCheckboxSelect = async (domainName, selected) => {
  //   try {
  //     const response = await CollectionService.pinFiltersSearch(domainName, selected);
  //     console.log('POST request response:', response.data);
  //     // Update the state with filtered collections
  //     setFilteredCollections(response.data);
  //   } catch (error) {
  //     console.error('Error making POST request:', error);
  //   }
  // };


  const handleCheckboxSelect = async (domainName, selected) => {
    try {
      // Prepare the payload
      const payload = {
          "filters": [
              {
                  "fieldName": "collection.classification",
                  "fieldValue": "public,governed",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              },
              {
                  "fieldName": "teamData.teamStructure",
                  "fieldValue": "matrix",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              },
          {
                  "fieldName": "TagFilter",
                  "fieldValue": "TagsCollection/PII,TagsCollection/API",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              },
          {
                  "fieldName": "Product",
                  "fieldValue": "4905749",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              },
          {
                  "fieldName": "Line of Business",
                  "fieldValue": "4905763",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              },
              {
                  "fieldName": "Region",
                  "fieldValue": "24581507",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              },
          {
                  "fieldName": "Data Domain",
                  "fieldValue": "4905763",
                  "searchType": "FILTER",
                  "op": "in",
                  "condition": "and"
              }
          ],
          "pagination": {
              "pageNumber": 1,
              "pageSize": 9
          }
      
      };
  
      // Make POST request with the payload
      const response = await CollectionService.pinFiltersSearch(domainName, payload);
      console.log('POST request response:', response.data);
      // Update the state with filtered collections
      setFilteredCollections(response.data);
    } catch (error) {
      console.error('Error making POST request:', error);
    }
  };
  

  const onDomainSelect = (domainName, reset) => {
    if (reset) {
      setSelectedObject({ selectedDomain: domainName, valu: [] });
    } else {
      const valu = getDomainValues(domainName);
      setSelectedObject({ selectedDomain: domainName, valu });
      console.log(valu);
    }
  };

  const choiceDomainNames = Object.keys(data);

  console.log(choiceDomainNames);

  function getDomainValues(domainName) {
    let domains = data[domainName] || [];

    if (domains.length > 0 && domains[0].hasOwnProperty('name')) {
      return domains;
    } else {
      const newArray = domains.map(item => {
        return Object.entries(item).flatMap(([key, value]) => {
          if (typeof value === 'object') {
            return Object.entries(value).map(([innerKey, innerValue]) => {
              return { name: innerKey, value: innerValue };
            });
          } else {
            return { name: key, value: value };
          }
        });
      }).flat();

      return newArray;
    }
  }

  return (
    <Box className={classes.root}>
      {choiceDomainNames.map(domainName => (
        <Box className={classes.box} key={domainName}>
          <Typography style={{ width: '100%' }}>
            <h5 className={classes.choicedomain}>
              {domainName}
              {selectedObj?.selectedDomain === domainName && selectedObj?.valu?.length ?
                <RemoveIcon onClick={() => { onDomainSelect(domainName, true) }} className={classes.plusIcon} />
                :
                <AddIcon onClick={() => { onDomainSelect(domainName, false) }} className={classes.minusIconIcon} />
              }
            </h5>
            {selectedObj?.selectedDomain === domainName && selectedObj.valu.map(sel => (
              <div key={sel.name} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p>
                  <CustomCheckbox
                    className={classes.Check}
                    onClick={() => handleCheckboxSelect(domainName, sel.name)}
                  /> {sel?.name}
                </p>
                <p>{sel?.value} {sel?.choiceCount}</p>
              </div>
            ))}
          </Typography>
        </Box>
      ))}
      {/* Display the filtered collections */}
      {filteredCollections && filteredCollections.map(collection => (
        <div key={collection.id}>{collection.name}</div>
      ))}
    </Box>
  );
}
