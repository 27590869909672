import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Box } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    save: {
        color: '#fff',
        background: '#123b64',
        width: 77,
        marginRight: 10,
        padding: '7px 10px',
        borderRadius: 8,
        fontWeight: 500,
        lineHeight: '150%',
        textTransform: 'capitalize',
    }
}));

function CustomModal(props) {

    const { modalButton, open, setOpen, children } = props
    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Box onClick={handleOpen}>
                {modalButton}
            </Box>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
            >
                <Fade in={open}>
                    {
                        children
                    }
                </Fade>
            </Modal>
        </div>
    );
}

export default CustomModal;