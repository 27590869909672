import { Tabs, Tab } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";

export const StyledTabs = withStyles({
    indicator: {
        display: "none",
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


export const StyledTab = withStyles(() => ({
    root: {
        flexGrow: 1,
        textTransform: "none",
        color: "#123b64",
        fontWeight: 500,
        borderRadius: 10,
        fontSize: 22,
        opacity: 1
    },
}))((props) => <Tab disableRipple {...props} />);

export const tabPropsGenerator = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export const ORDERTYPES = {
    asc: 'ASC',
    desc: 'DESC'
}

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    AppBarWrapper: {
        display: "flex",
        justifyContent: (props) =>
            props.myCollections ? "space-between" : "center",
        marginBottom: 6,
    },
    Appbar: {
        width: (props) =>
            props.isDuality ? 500 : 992,
        background: "#fff",
        borderRadius: 10,
        boxShadow: "0px 2px 20px rgba(23, 87, 123, 0.2);",
    },
    tabs: {
        width: "100%",
        '& .MuiTab-root': {
            minWidth: props => props.isDuality ? '50%' : 'unset'
        }
    },
    active: {
        background: "#E6ECF0",
        color: "#123b64",
    },
    buttonWapper: {
        [theme.breakpoints.down("768")]: {
            padding: (props) => (props.isDuality ? "0 16px" : 0),
        },
    },
    sortBtnwrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    sortBtn: {
        padding: 0,
        width: 20,
        height: 20,
        '&:disabled': {
            '& svg': {
                fill: '#98a9b5'
            }
        }
    }
}));