import { useFormik } from "formik";
import {
  Button,
  Input,
  InputLabel,
  InputAdornment,
  Typography,
  Box,
} from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useState, useContext } from "react";
import PersonIcon from "../../assets/images/person-icon.png";
import MessageIcon from "../../assets/images/message-icon.png";
import LockIcon from "../../assets/images/lock-icon.png";
import "./SignUpForm.scss";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import KeyIcon from "../../assets/images/key-icon.png";
import { Clear } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useAddUserData } from "../../services/custom-hooks/CustomHooks";
import { useNavigate, Link } from "react-router-dom";
import DataContext from "../../contexts/Context";

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const useStyles = makeStyles({
  input: {
    border: "1px solid #808C9A",
    boxSizing: "borderBox",
    borderRadius: 8,
    padding: "14px 20px",
    fontSize: 14,
    lineHeight: "140%",
  },

  header: {
    fontSize: 40,
    fontWeight: 900,
    marginBottom: 30,
  },
});

export const SignUpForm = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();
  const { getloginInUser } = useContext(DataContext);

  const onSuccess = (data) => {
    const response = data.data.response;
    if (response === "login successful") {
      getloginInUser(data.data);
      navigate("/successfull-login");
    }
  };

  const onError = (err) => {
    setStatus(err.request.status);
  };
  const loginWithRedirect = () => {
    oktaAuth.signInWithRedirect({ originalUri: "/home" });
  };

  const { mutate: addUser } = useAddUserData(onSuccess, onError);

  const onSubmit = (values) => {
    addUser(values);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const resetField = (field) => {
    formik.setFieldValue(field, "");
  };

  return (
    <div>
      <Typography
        variant="h2"
        className={`${classes.header} text-secondary-100`}
      >
        Sign up
      </Typography>
      <Typography
        variant="subtitle1"
        className="have-account-text text-secondary-100 fw-bold"
      >
        Already have an account?
        <Link
          to="/sign-in"
          className="ms-2 sign-in-link text-primary text-decoration-none"
        >
          Sign in
        </Link>
      </Typography>
      <Link to="/login" className="m3">
        <Button
          onClick={loginWithRedirect}
          fullWidth={true}
          disableElevation
          disableFocusRipple
          disableTouchRipple
          variant="outlined"
          className="btn-signup-sso text-secondary-100 fw-bold"
        >
          <img src={KeyIcon} alt="key-icon" />
          Sign up with SSO
        </Button>
      </Link>

      <hr className="line" />
      <form
        autoComplete="off"
        className="sign-up-form"
        onSubmit={formik.handleSubmit}
      >
        <div className="form-group">
          <InputLabel htmlFor="firstName">First Name</InputLabel>
          <Input
            id="firstName"
            name="firstName"
            fullWidth={true}
            className={classes.input}
            {...formik.getFieldProps("firstName")}
            startAdornment={
              <InputAdornment position="start">
                <img src={PersonIcon} alt="" />
              </InputAdornment>
            }
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <p className="m-0 text-primary">{formik.errors.firstName}</p>
          ) : null}
        </div>
        <div className="form-group">
          <InputLabel htmlFor="lastName">Last Name</InputLabel>
          <Input
            id="lastName"
            name="lastName"
            fullWidth={true}
            {...formik.getFieldProps("lastName")}
            className={classes.input}
            startAdornment={
              <InputAdornment position="start">
                <img src={PersonIcon} alt="" />
              </InputAdornment>
            }
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <p className="m-0 text-primary">{formik.errors.lastName}</p>
          ) : null}
        </div>
        <div className="form-group">
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            name="email"
            type="email"
            fullWidth={true}
            className={classes.input}
            {...formik.getFieldProps("email")}
            startAdornment={
              <InputAdornment position="start">
                <img src={MessageIcon} alt="" />
              </InputAdornment>
            }
          />
          {formik.touched.email && formik.errors.email ? (
            <p className="m-0 text-primary">{formik.errors.email}</p>
          ) : null}
        </div>
        <div className="form-group">
          <InputLabel htmlFor="password" className="text-secondary-100">
            Password
          </InputLabel>
          <Input
            type={showPassword ? "text" : "password"}
            id="password"
            name="password"
            fullWidth={true}
            className={classes.input}
            sx={{
              border: "1px solid lightgrey",
            }}
            {...formik.getFieldProps("password")}
            startAdornment={
              <InputAdornment position="start">
                <img src={LockIcon} alt="" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <InputAdornment position="end">
                  {formik.touched.password && formik.values.password ? (
                    <IconButton onClick={() => resetField("password")}>
                      <Clear />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </InputAdornment>
                <InputAdornment
                  position="end"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              </InputAdornment>
            }
          />
          {formik.touched.password && formik.errors.password ? (
            <p className="m-0 text-primary">{formik.errors.password}</p>
          ) : null}
        </div>
        <div className="form-group">
          <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
          <Input
            type={showConfPassword ? "text" : "password"}
            id="confirmPassword"
            name="confirmPassword"
            fullWidth={true}
            className={classes.input}
            {...formik.getFieldProps("confirmPassword")}
            startAdornment={
              <InputAdornment position="start">
                <img src={LockIcon} alt="" />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="start">
                <InputAdornment position="start">
                  {formik.touched.confirmPassword &&
                  formik.values.confirmPassword ? (
                    <IconButton onClick={() => resetField("confirmPassword")}>
                      <Clear />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </InputAdornment>
                <InputAdornment
                  position="end"
                  onClick={() => setShowConfPassword(!showConfPassword)}
                >
                  {showConfPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              </InputAdornment>
            }
          />
          {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
            <p className="m-0 text-primary">{formik.errors.confirmPassword}</p>
          ) : null}
        </div>
        <Box>{status === 403 && <>The email is already exist</>}</Box>
        <Button
          fullWidth={true}
          type="submit"
          className="btn-sign-up fw-bold bg-primary text-white"
        >
          Sign up
        </Button>
      </form>
    </div>
  );
};
