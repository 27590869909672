import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'

export const ACCESS_RULES = 'Access rules' 
export const RETENTION_PERIOD = 'Retention period'
export const ENCYRPTION = 'Encryption'
export const BACKUP_FREQUENCY = 'Data backup frequency'
export const ACCESS_RESTRICTIONS = 'Data access restrictions'

export const initialValues = {
    [ACCESS_RULES]: '',
    [RETENTION_PERIOD]: '',
    [ENCYRPTION]: 'false',
    [BACKUP_FREQUENCY]: '',
    [ACCESS_RESTRICTIONS]: ''
}

export const validationSchema = yup.object({
    [ACCESS_RULES]: yup
        .string().required(`${ACCESS_RULES} is required`),
    [RETENTION_PERIOD]: yup
        .string().required(`${RETENTION_PERIOD} is required`),
    [BACKUP_FREQUENCY]: yup    
        .string().required(`${BACKUP_FREQUENCY} is required`),
    [ACCESS_RESTRICTIONS]: yup
        .string().required(`${ACCESS_RESTRICTIONS} is required`)    
});

export const getTransformedData = (data) => {
    return data.map(item => {
        return { label: item, value: item }
    })
}

export const useStyles = makeStyles(() => ({
    root: {
      minHeight: 'calc(100vh - 150px)',
      paddingTop: 30,
      paddingBottom: 100
    },
    header: {
      margin: '40px 0'
    },
    paper: {
      boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
      borderRadius: 4,
      marginBottom: 40
    },
    paperTitle: {
      borderBottom: '1px solid #E0E7EC'
    },
    form: {
      maxWidth: 508
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    radioWrapper: {
      display: "flex",
      flexDirection: "row",
      paddingBottom: 20,
      "& .MuiFormControlLabel-root": {
        marginRight: 50,
      },
    },
    text: {
      paddingBottom: 10,
      lineHeight: 1
    }
  }
  ));