import { Typography, makeStyles, Box } from "@material-ui/core";
import { useEffect } from 'react';
import { useState } from "react";
import Notification from "./Notification";
import { BiBellOff } from "react-icons/bi";
import { SearchService } from "../../../services/SearchService";

const useStyles = makeStyles(theme => ({
    root: {
        width: 328,
        boxShadow: '0px 2px 20px rgba(23, 87, 123, 0.2)',
        borderRadius: 16
    },
    wrapper: {
        padding: '15px 20px',
        borderBottom: '2px solid #E0E7EC'
    },
    notificationWrapper: {
        maxHeight: 400,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: 4,
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#F79F19",
            borderRadius: 2,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#808c9a4d",
            borderRadius: 2,
        },
        "&::-webkit-scrollbar-button:end:increment": {
            height: 20,
            display: "block",
        },
    }
}))

const Notifications = () => {
    const classes = useStyles()
    const [notifications, setNotifications] = useState([])
    const [isLoading, setIsLoading] = useState(true)
  const [userData,setUserData] =useState([])


    // useEffect(() => {
    //     SearchService.fetchNotificatioon()
    //         .then(res => {
    //             setNotifications(res.data)
    //             setIsLoading(false)
    //         }).catch(() => setIsLoading(false))
    // }, [])


//     useEffect(() => {
//     const payloadData = {
//           // key: loggedInUser.FirstName,
//           pagination: {
//             pageNumber: 1,
//             pageSize: 9,
//           },
//         };
//     SearchService.fetchNotificatioon(payloadData)
    
//         .then(res => {
//           console.log(res,"response")
//             setNotifications(res.data);
//         })
//         .catch(error => {
//             console.error("Error fetching notifications:", error);
//         })
//         .finally(() => {
//             setIsLoading(false);
//         });
// }, []);

const getNotification = () => {
    const payloadData = {
        pageNumber: 1,
        pageSize: 15
    };
    
    return SearchService.fetchNotificatioon(payloadData)
      .then((response) => {
        if (response.data) {
          setNotifications(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching notification:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  
  useEffect(() => {
    getNotification();
  }, []);
  
      

    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>
                <Typography variant="h5" color="primary" >
                    Your Notifications
                </Typography>
            </Box>
            <Box pr={1} py={2.5} pl={2.5}>
                <Box className={classes.notificationWrapper}>
                    {/* {
                        isLoading ? <Typography variant="h4" color="primary">Loading...</Typography>
                            : notifications.length ?
                                notifications.map((notification, index) => {
                                    return (
                                        <Box mb={2.5}>
                                            <Notification key={index} notification={notification} />
                                        </Box>
                                    )
                                }) :
                                <>
                                    <Typography align="center" color="primary">You dont have any Notifications recently</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <BiBellOff size={40} />
                                    </Box>
                                </>

                    } */}

{
    isLoading ? (
        <Typography variant="h4" color="primary">Loading...</Typography>
    ) : (
        notifications.length > 0 ? (
            notifications.map((notification, index) => (
                <Box mb={2.5} key={index}>
                    <Notification notification={notification} />
                </Box>
            ))
        ) : (
            <>
                <Typography align="center" color="primary">You don't have any Notifications recently</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <BiBellOff size={40} />
                </Box>
            </>
        )
    )
}
     

                </Box>
            </Box>
        </Box>
    );
}


export default Notifications; 
