import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import SelectRole from '../../inputs/SelectRole';
import { TextButton, YellowButton } from '../../buttons/custom-buttons/CustomButtons';
import { useState, useEffect } from 'react';
import { useStyles } from './SimpleTeamStyles'; 
import { TeamService } from '../../../../services/TeamService';
import { TrashBinIcon } from '../../../icons';

const SimpleTeamBuilder = (props) => {
    const {
        setState,
        page,
        teamRoles,
        teamId,
        getUpdatedSimpleData,
        setOpen
    } = props
    const classes = useStyles()

    const initialFormValue = {
        role: '',
        users: [],
        errors: { role: '', users: '' }
    }
    const [roleOptions, setRoleOptions] = useState([])
    const [users, setUsers] = useState([])
    const [loadingRole, setLoadingRole] = useState(true)
    const [loadingUsers, setLoadingUsers] = useState(true)
    const [formFields, setFormFields] = useState([initialFormValue])
    const [isUpdated, setIsUpdated] = useState('')
    const [isTeamCreated, setIsTeamCreated] = useState('')
    const id = teamRoles?.length > 0 ? teamRoles[0].teamId : teamId
    const numOfEditData = teamRoles ? teamRoles.length : []

    const createSimpleTeam = (data) => {
        TeamService.createSimpleTeam(data)
            .then(res => {
                setIsTeamCreated(res.status)
                getUpdatedSimpleData(data)
            })
    }

    const updateRoles = (data) => {
        TeamService.updateSimpleTeam(data)
            .then(res => {
                setIsUpdated(res.status)
                getUpdatedSimpleData(data)
            })
    }

    const getRoles = () => {
        TeamService.fetchRoles()
            .then(res => {
                const roles = res.data.map(role => {
                    return {
                        value: role,
                        label: role
                    }
                })
                setRoleOptions(roles)
                setLoadingRole(false)
            })
            .catch(err => {
                setLoadingRole(false)
            })
    }

    const getUsers = () => {
        return TeamService.fetchUsers()
            .then(res => {
                let copyRegistredUsers = [...res.data]
                copyRegistredUsers = copyRegistredUsers.length > 0 && copyRegistredUsers.map(user => {
                    return {
                        id: user.id,
                        name: user.FirstName
                    }
                })
                setUsers(copyRegistredUsers)
                setLoadingUsers(false)
            })
            .catch(err => {
                setLoadingUsers(false)
            })
    }

    useEffect(() => {
        if (teamRoles && teamRoles?.length > 0) {
            let team = teamRoles.map(item => {
                return {
                    role: item.role,
                    users: item.users,
                    errors: { role: null, users: null }
                }
            })
            setFormFields(team)
        }
            getRoles()
            getUsers()
            if (isTeamCreated === 200 || isUpdated === 200) {
                page === "simple-team" && setOpen(false)
                setState(false)
            }
            return () => {
                setFormFields([])
            }
        }, [isTeamCreated, isUpdated, teamRoles])

    const onSubmit = () => {
        let copySelectedUsers = [...formFields]
        let isInvalidRole = false

        for (let i = 0; i < copySelectedUsers.length; i++) {
            if (copySelectedUsers[i].errors.role !== null || copySelectedUsers[i].errors.users !== null) {
                isInvalidRole = true
                break
            }
        }

        if (!isInvalidRole) {
            copySelectedUsers = copySelectedUsers.map(item => {
                return {
                    role: item.role,
                    users: item.users
                }
            })
            const payload = {
                teamId: id,
                roles: copySelectedUsers,
            }
            teamRoles && numOfEditData > 0 ? updateRoles(payload) :
                createSimpleTeam(payload)
        }
    }

    const prevIsValid = () => {
        const someEmpty = formFields.some(item => item.role === "" || item.users.length === 0)

        if (someEmpty) {
            const allPrev = [...formFields]
            formFields.forEach((item, index) => {

                if (item.role === "") {
                    allPrev[index].errors.role = "Role is required"
                }

                if (item.users.length === 0) {
                    allPrev[index].errors.users = "At least one user must be selected"
                }
                setFormFields(allPrev)
            })
        }
        return !someEmpty
    }

    const addRoles = () => {
        let object = {
            role: '',
            users: [],
            errors: {
                role: '',
                users: ''
            }
        }
        if (prevIsValid()) {
            setFormFields([...formFields, object])
        }
    }

    const deleteAllRoles = () => {
        setFormFields([])
    }

    const deleteRole = (index) => {
        let copyFormFields = [...formFields]
        copyFormFields = copyFormFields.filter((item, itemIndex) => itemIndex !== index)
        setFormFields(copyFormFields)
    }

    if (loadingRole || loadingUsers || !id) {
        return (
            <Typography className={classes.loading} variant='h5' color="secondary">Loadiong...</Typography>
        )
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.titleWrapper}>
                <Typography className={classes.title}>
                    {
                        page === "edit-collection" ? "Edit collection" : "Simple Team builder"
                    }
                </Typography>
                <IconButton style={{ padding: 0 }} onClick={() => setState(false)} disableRipple>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box mb={2.5} sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Typography variant='body2' color='primary' >
                    Pick multiple roles
                </Typography>
                <div onClick={deleteAllRoles}>
                    <TrashBinIcon width={15}/>
                </div>
            </Box>

            <form className={classes.inputsWrapper}>
                {
                    formFields.map((field, index) => {
                        return (
                            <Box key={index} style={{ marginBottom: 20 }}>
                                <SelectRole
                                    deleteRole={deleteRole}
                                    users={users}
                                    roleOptions={roleOptions}
                                    label="Role"
                                    field={field}
                                    setFormFields={setFormFields}
                                    formFields={formFields}
                                    index={index}
                                    numOfEditData={numOfEditData}
                                />
                                {
                                    (formFields[index].errors.role && formFields[index].errors.users) ?
                                        <Typography color='secondary'>Role and users are required!</Typography> :
                                        formFields[index].errors.role ?
                                            <Typography color='secondary'>{formFields[index].errors.role}</Typography> :
                                            formFields[index].errors.users ?
                                                <Typography color='secondary'>{formFields[index].errors.users}</Typography> : null
                                }

                            </Box>
                        )
                    })
                }
                <TextButton
                    onClick={addRoles}
                    disableRipple
                    className={classes.addNewRoles}
                    startIcon={<AddIcon />}
                >
                    Add new roles
                </TextButton>
                <hr className={classes.hr} />
                <YellowButton
                    onClick={onSubmit}
                    className={classes.yellowButton}
                    startIcon={
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.619741 6.49748C0.184741 6.35248 0.180575 6.11831 0.628075 5.96914L16.5339 0.667478C16.9747 0.520812 17.2272 0.767478 17.1039 1.19914L12.5589 17.1041C12.4339 17.545 12.1797 17.56 11.9931 17.1416L8.99807 10.4016L13.9981 3.73498L7.33141 8.73498L0.619741 6.49748Z" fill="white" />
                        </svg>
                    }
                >
                    Apply
                </YellowButton>
            </form>
        </Box>
    );
}

export default SimpleTeamBuilder;