import { Box, Button, Container, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { SearchService } from '../../services/SearchService';
import registration from "./registrationicon.png";
import publish from "./publishedicon.png";
import curate from "./curateicon.png";
import add from "./addicon.png"
import addwhite from "./addWhite.png"
import { Edit, PlusOne } from '@material-ui/icons';
import { TextButton } from '../../components/shared/buttons/custom-buttons/CustomButtons';
import { GraphIcon } from '../../components/icons';
import "./sidebar.scss"

// Define styles using makeStyles hook
const useStyles = makeStyles(theme => ({
  root: {
    color: "#fff",
    width: 380,
    background: 'linear-gradient(259.26deg, rgb(1, 25, 54) -14.93%, rgb(1, 25, 54) 61.45%)',
    height:756,
  },
  wrapper: {
    padding: "40px 32px 40px",
   
  },
  overlay: {
    position: 'absolute',
    width: "100%",
    height: "100%",
    background: "#6f7a9980",
    display: props => props.loading ? 'block' : 'none',
    padding: 16,
  },
    container: {
        minHeight: 'calc(100vh - 150px)'
    },
    wrapper: {
        minHeight: 'calc(100vh - 150px)',
        padding: "20px 0 100px"
    },
    title: {
        textAlign: 'center',
        margin: '26px 0 30px'
    },
    buttonWapper: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
   
      
}))

const tabOptions = [
    {
        label: 'Archieved Jobs',
        value: 'My Jobs'
    },
    {
        label: 'Current Jobs',
        value: 'Archieved jobs'
    },
]

// Functional component SidebarFlow
function SidebarFlow(isGraphOpen, 
    setIsGraphOpen,) {
       // Use styles defined above
    const classes = useStyles()
     // State variables using useState hook
    const [options, setOptions] = useState([])
    const [edit, setEdit] = useState(true);
     // Function to handle edit
    const handleEdit = () => {
        setEdit(false);
      };
    return (
      <Box className={classes.root}>
       
        <Typography variant="h3" color="#fff">Build your Data Product</Typography>
      
         <Box className={classes.wrapper}>
            <Box>
               {/* Button with icon and text for Registration */}
         <Button>
            <img src={registration} style={{marginRight:20}}/>
         <Typography style={{color:"#ffff"}}>Registration</Typography>
         <img src={add} style={{marginLeft:100,}}/>
         <img src={addwhite} style={{marginLeft:30,}}/>
            </Button>
            </Box>
             {/* Button with icon and text for Curate */}
            <Button>
            <img src={curate} style={{marginRight:20}}/>
         <Typography style={{color:"#ffff"}}>Curate</Typography>
         <img src={add} style={{marginLeft:150,}}/>
         <img src={addwhite} style={{marginLeft:30,}}/>
            </Button>
             {/* Button with icon and text for Published */}
             <Button>
            <img src={publish}style={{marginRight:20}} />
         <Typography style={{color:"#ffff"}}>Published</Typography>
         <img src={add} style={{marginLeft:122,}}/>
         <img src={addwhite} style={{marginLeft:30,}}/>
            </Button>
         </Box>
        </Box>
    );
}

export default SidebarFlow;
