import Table from "./../assets/images/card-icons/table.png";
import Column from "./../assets/images/card-icons/column.png";
import BusinessProcess from "./../assets/images/card-icons/BusinessProcess.png";
import DataDomain from "./../assets/images/card-icons/data-domain.png";
import DataSet from "./../assets/images/card-icons/data-set.png";
import File from "./../assets/images/card-icons/file.png";
import Failed from "./../assets/images/card-icons/failed.png";
import Term from "./../assets/images/card-icons/term.png";
import Schema from "./../assets/images/card-icons/schema.png";
import Policy from "./../assets/images/card-icons/policy.png";
import Application from "./../assets/images/card-icons/application.png";
import Node from "./../assets/images/card-icons/node.png";
import Collection from "./../assets/images/card-icons/collection.png";
import Directory from "./../assets/images/card-icons/directory.png";
import Standart from "./../assets/images/card-icons/standart.png";
import Geography from "./../assets/images/card-icons/geography.png";
import Api from "./../assets/images/card-icons/Api.png";
import DataProduct from "./../assets/images/card-icons/Data Product.png";
import DataBase from "./../assets/images/card-icons/Database.png";
import DataSharingAgreement from "./../assets/images/card-icons/Data sharing.png";
import Featureset from "./../assets/images/card-icons/Feature set.png";
import Datause from "./../assets/images/card-icons/Datause.png";
import SensitiveType from "./../assets/images/card-icons/Sensitive type.png";
import Container from "./../assets/images/card-icons/Container.png";
import Product from "./../assets/images/card-icons/Product.png";
import Feature from "./../assets/images/card-icons/Feature.png";
import Mlmodel from "./../assets/images/card-icons/ml model (2).png";
import Bucket from "./../assets/images/card-icons/Bucket.png";
import Server from "./../assets/images/card-icons/server.png";
import Route from "./../assets/images/card-icons/Route.png";
import LineofBusiness from "./../assets/images/card-icons/Line of Business.png";
import DataQualityMetric from "./../assets/images/card-icons/Data quality metric.png";
import DataQualityRule from "./../assets/images/card-icons/Data quality rule.png";
import Foreignkey from "./../assets/images/card-icons/Foreign key.png";
import Report from "./../assets/images/card-icons/report.png";
import Field from "./../assets/images/card-icons/field.png";
import APIEndpoint from "./../assets/images/card-icons/API Endpoint.png";
import APIParameter from "./../assets/images/card-icons/API Parameter.png";
import SensitiveTypegroup from "./../assets/images/card-icons/Sensitive Typegroup.png";


export function findNodeIcon(Type) {
  let iconName;
  switch (Type) {
    case "Column":
      iconName = Column;
      break;
    case "column":
      iconName = Column;
      break;
    case "Business Process":
      iconName = BusinessProcess;
      break;
    case "Data Set":
      iconName = DataSet;
      break;
    case "Data domain":
      iconName = DataDomain;
      break;
    case "Application":
      iconName = Application;
      break;

    case "Database":
      iconName = DataBase;
      break;
    case "Failed":
      iconName = Failed;
      break;
    case "File":
      iconName = File;
      break;
    case "Policy":
      iconName = Policy;
      break;
    case "Business Term":
      iconName = Term;
      break;
    case "Server":
      iconName = Server;
      break;
    case "Schema":
      iconName = Schema;
      break;
    case "schema":
      iconName = Schema;
      break;
    case "Collection":
      iconName = Collection;
      break;
    case "Data Source":
      iconName = Node;
      break;
    case "Directory":
      iconName = Directory;
      break;
    case "Standart":
      iconName = Standart;
      break;
    case "Geography":
      iconName = Geography;
      break;
    case "API":
      iconName = Api;
      break;
    case "API Endpoint":
      iconName = Api;
      break;
    case "Data Product":
      iconName = DataProduct;
      break;
    case "Table":
      iconName = Table;
      break;
    case "Data Sharing Agreement":
      iconName = DataSharingAgreement;
      break;
    case "Reports":
      iconName = Report;
      break;
    case "Feature Set":
      iconName = Featureset;
      break;
    case "Data Use":
      iconName = Datause;
      break;
    case "SensitiveType":
      iconName = SensitiveType;
      break;
    case "container":
      iconName = Container;
      break;
    case "Product":
      iconName = Product;
      break;
    case "Feature":
      iconName = Feature;
      break;
    case "ML Model":
      iconName = Mlmodel;
      break;
    case "SensitiveTypeGroup":
      iconName = SensitiveTypegroup;
      break;
    case "API Parameter":
      iconName = APIParameter;
      break;
    case "API Endpoint":
      iconName = APIEndpoint;
      break;
    case "Foreign key":
      iconName = Foreignkey;
      break;
    case "Data Quality Rule":
      iconName = DataQualityRule;
      break;
    case "Data Quality Metric":
      iconName = DataQualityMetric;
      break;
    case "Line of Business":
      iconName = LineofBusiness;
      break;
    case "Route":
      iconName = Route;
      break;
    case "Bucket":
      iconName = Bucket;
      break;
    case "Field":
      iconName = Field;
      break;
    default:
      iconName = Node;
      break;
  }

  return iconName;
}
