import { Typography, makeStyles, Box, IconButton } from "@material-ui/core";
import React, { useContext, useEffect } from 'react';
import CollectionInput from './CollectionInput'
import CollectionsList from "./CollectionsList";
import AddIcon from '@material-ui/icons/Add';
import { TextButton } from "../shared/buttons/custom-buttons/CustomButtons";
import { useState } from "react";
import { CollectionService } from "../../services/CollectionService";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { CloseIcon } from "../icons";
import DataContext from "../../contexts/Context";
import CollectionsListadd from "./CollectionsListadd";

const useStyles = makeStyles(theme => ({
    root: {
        width: 328,
        boxShadow: '0px 2px 20px rgba(23, 87, 123, 0.2)',
        borderRadius: 16,
        color: '#123b64',
        [theme.breakpoints.up('sm')]: {
            width: 410
        }
    },
    wrapper: {
        position: 'relative',
        width: '100%',
        padding: '20px 15px 0 15px',
    },
    iconButton: {
        position: 'absolute',
        top: 18,
        left:20,
        '& path': {
            stroke: '#123b64'
        }
    },
    title: {
        textAlign: 'center',
        fontSize: 22,
        marginBottom: 20,
        fontWeight: 700,
        lineHeight: '33px'
    },
    collectionsTitle: {
        fontSize: 14,
        lineHeight: '140%',
        marginBottom: 10
    },
    allCollections: {
        width: '100%'
    },
    createCollection: {
        padding: 27,
        boxShadow: '0px -5px 30px rgba(0, 0, 0, 0.1)'
    },
}))

const SaveInCollection = (props) => {
    const {
        title,
        arangoNodeKey,
        displayName,
        type,
        typeName,
        setOpenSaveCollection,
        movePinNodesToCollecton,
        movePinCollToPinColl,
        copyNodesToCollection,
        copyPinCollToPinColl,
        selectedNodes,
        page,
    
    } = props
    const classes = useStyles()
    const [text, setText] = useState('')
    const [collectionList, setCollectionList] = useState([])
    const [list, setList] = useState([])
    const {
        loggedInUser: { userId, email },
        favCollections,
        setFavCollections,
        setCheckedCollections,
      } = useContext(DataContext);
    const getCollectionList = () => {
        return CollectionService.fetchCollectionList(userId);
    }
    useEffect(() => {
        setList(collectionList)
    }, [collectionList])

    const { isLoading } = useQuery("collection-list", getCollectionList, {
        onSuccess: res => {
            setCollectionList(res.data)
        }
    })

    const handleChange = (e) => {
        const {value} = e.target
        setText(value)
        let filteredCollectionList = collectionList.filter(collection => {
            return collection.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
        setList(filteredCollectionList)
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>

                <Typography className={classes.title}>
                    {
                        title ? <>{title}</> :
                            <>Save in collection</>
                    }
                </Typography>
                <IconButton onClick={() => setOpenSaveCollection(false)} className={classes.iconButton}>
                    <CloseIcon />
                </IconButton>
                <Box>
                    {
                        title && <Typography className={classes.collectionsTitle}>Selected all nodes</Typography>
                    }
                    <CollectionInput handleChange={handleChange} text={text} />
                    <Box className={classes.allCollections}>
                        <Typography className={classes.collectionsTitle}>
                            All Collections
                         </Typography>
                        {
                            isLoading ? <p style={{ height: 245, }}>Loading...</p> :
                                <CollectionsList
                                    collections={list}
                                    displayName={displayName}
                                    arangoNodeKey={arangoNodeKey}
                                    movePinNodesToCollecton={movePinNodesToCollecton}
                                    movePinCollToPinColl={movePinCollToPinColl}
                                    copyNodesToCollection={copyNodesToCollection}
                                    copyPinCollToPinColl={copyPinCollToPinColl}
                                    selectedNodes={selectedNodes}
                                    title={title}
                                    type={type}
                                  
                                    page={page}
                                />
                        }
                    </Box>
                </Box>
            </Box>
            <Box className={classes.createCollection}>
                <Link style={{ textDecoration: 'none' }} to="/my-collections/create-collection">
                    <TextButton
                        startIcon={<AddIcon />}
                        disableRipple
                        style={{ fontWeight: 700 }}
                    >
                        Create new collection
                    </TextButton>
                </Link>
            </Box>
        </Box>
    );
}


export default SaveInCollection;                
