import { Box, Container, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  classificationService,
  descriptionService,
} from "../../../../../services/custom-hooks/CustomHooks";
import Breadcrumbs from "../../../../shared/breadcrumb/Breadcrumbs";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../../../shared/buttons/custom-buttons/CustomButtons";
//import CustomModal from "../../../../shared/modal/CustomModal";
import SliderPlaceholder from "../../../../shared/placeholder/SliderPlaceholder";
import NewTermModal from "./popupformsoflogo/NewTermModal";
import SemanticsTable3 from "./SemanticsTable3";
import { FilterIcon } from "../../../../icons";
import Loader from "../../../../shared/loader/Loader";
import CustomModal from "./popupformsoflogo/CustomModal";
import { NodeCreationService } from "../../../../../services/NodeCreationService";
import DataContext from "../../../../../contexts/Context";
import SemanticsTable from "./SemanticsTable";
import CustomDrawer from "../../../../shared/drawer/CustomDrawer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CollectionSidebar from "../../../../../pages/digital-publication/CollectionSidebar";

const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 150px)",
    paddingTop: 32,
    paddingBottom: 100,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: 219,
    "& span": {
      fontWeight: 700,
    },
  },
  wrapper: {
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 4,
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 40px",
    borderBottom: "1px solid #E0E7EC",
  },
  tableWrapper: {
    padding: "40px 40px 20px",
  },
});

const columns = [
  { id: "columnName", name: "Column Name" },
  // { id: "term", name: 'Term' },
  //{ id: "suggestedNames", name: 'suggestedNames' },
  { id: "Term", name: "Term Name" },
  { id: "description", name: "Description" },
  { id: "confidence_score", name: "Confidence Score" },
  { id: "status", name: "Status" },
  //{ id: "key", name: 'key' },
];

function DescriptionDataset() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [termStatus, setTermStatus] = useState("");
  const [columnName, setColumnName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [semanticData, setSemanticData] = useState([]);
  const { dataSetPayload } = useContext(DataContext);
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  const { key, nodeName, selectedNodeName } = useParams();
  const getDescriptionDataset = () => {
    return NodeCreationService.addNodeDescription({ columns: dataSetPayload })

      .then((res) => {
        setSemanticData(res.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDescriptionDataset();
  }, []);

  const addNewTerm = (recommendedName) => {
    return descriptionService
      .addNewTerm(columnName, recommendedName, termStatus)
      .then((res) => {
        if (res.status === 200) {
          let newArr = semanticData.map((obj) => {
            if (obj.columnName === columnName) {
              return { ...obj, recommendedName: recommendedName };
            }
            return obj;
          });
          setSemanticData(newArr);
          setOpenModal(false);
        }
      });
  };

  const getTermDetails = (colName, status) => {
    setColumnName(colName);
    setTermStatus(status);
    setOpenModal(true);
  };
  const setBackPage = () => {
   
    navigate(-1)
  };

  const updateStatus = (
    colName,
    recName,
    status,
    index,
    confidenceScore,
    suggestedNameIndex,

    collapse,
    keyProp
  ) => {
    console.log(keyProp, "keys");
    console.log(colName, "keys");
    return descriptionService
      .descriptionStatusUpdate(colName, recName, status, keyProp) // Include the key here
      .then((res) => {
        let suggestedNames;
        let copySemanticsData = [...semanticData];
        if (collapse && res.status === 200) {
          copySemanticsData[`${index}`].status = status;
          copySemanticsData[`${index}`].recommendedName = recName;
          copySemanticsData[`${index}`].confidence_score = confidenceScore;
          suggestedNames = copySemanticsData[`${index}`].suggestedNames.map(
            (item, i) => {
              if (i === suggestedNameIndex) {
                if (status === "Accepted") {
                  return { ...item, status: "Accepted" };
                } else {
                  return { ...item, status: "Rejected" };
                }
              } else {
                return { ...item, status: "Rejected" };
              }
            }
          );
          copySemanticsData[`${index}`].suggestedNames = suggestedNames;
        } else if (res.status === 200) {
          copySemanticsData[`${index}`].status = status;
        }
        setSemanticData(copySemanticsData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className={classes.container}>
      <CustomModal open={openModal} setOpen={setOpenModal}>
        <NewTermModal
          page="description"
          addNewTerm={addNewTerm}
          setOpenModal={setOpenModal}
        />
      </CustomModal>
      <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
  <IconButton
    onClick={setBackPage}
    className={classes.icon}
    style={{ color: '#63ADA6' }}
  >
    <ArrowBackIosIcon style={{ fontSize: '13px' }} />
  </IconButton>

  <Link to="/home" style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
    Home
  </Link>
  {' / '}
  <Link to={`/semanticsdataset/${selectedNodeName}/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Semanticsdataset
  </Link>
  {' / '}
  <Link style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Semantics
  </Link>
  {' / '}
  <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
</Box>
      <Typography
        style={{ marginBottom: 24 }}
        align="center"
        variant="h1"
        color="primary"
      >
        Description
      </Typography>
      <Box className={classes.wrapper} mb={5}>
        <Box className={classes.box}>
          <Typography variant="h5" color="primary">
            Dataset / Description
          </Typography>
          <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer>
          <IconButton onClick={() => setOpenSidebar(true)} style={{ padding: 0 }}>
            <FilterIcon />
          </IconButton>
        </Box>
        <Box className={classes.tableWrapper}>
          {semanticData.length > 0 ? (
            <SemanticsTable
              semanticData={semanticData}
              getTermDetails={getTermDetails}
              updateStatus={updateStatus}
              columns={columns}
              getDescriptionDataset={getDescriptionDataset}
              page="description"
            />
          ) : (
            <SliderPlaceholder error={true} text="No data found" />
            
          )}
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/semanticsdataset/${selectedNodeName}/${nodeName}/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
        {/* <Box>
          <OutlinedButton style={{ marginRight: 15 }} className={classes.button} disableRipple>
            Save
          </OutlinedButton>
        </Box> */}
        <Link
          style={{ textDecoration: "none", color: "#ffff" }}
          to={`/classificationdataset/${selectedNodeName}/${nodeName}/${key}`}
        >
          <PrimaryButton className={classes.button} disableRipple>
            Next
          </PrimaryButton>
        </Link>
      </Box>
    </Container>
  );
}

export default DescriptionDataset;
