import { useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import "./tree.scss";

const Tree = ({ data }) => {
  const [checked, setChecked] = useState([]);

  const [expanded, setExpanded] = useState([]);


  return (
    <CheckboxTree
      className="react-tree"
      nodes={data}
      checked={checked}
      expanded={expanded}
      onCheck={(checked) => setChecked(checked)}
      onExpand={(expanded) => setExpanded(expanded)}
      direction="rtl"
      icons={{
        expandClose: <ExpandMoreIcon />,

        expandOpen: <KeyboardArrowUpIcon />,
      }}
    />
  );
};

export default Tree;
