import React from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        width: 366,
        boxShadow: '0px 4px 20px rgba(23, 87, 123, 0.2)',
        padding: 10,
        borderRadius: 16
    }
})

function Warning() {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Typography style={{lineHeight: '150%'}} variant="body2" color="primary">
            It shows the Added information in table format
            </Typography>
        </Box>

        
    );
}

export default Warning;