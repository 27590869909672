import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { TextButton } from "../shared/buttons/custom-buttons/CustomButtons";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    width: 568,
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: "50px 15px",
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    textAlign: "center",
    color: "#123b64",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down("578")]: {
      width: 330,
      padding: "40px 15px",
    },
  },
  title: {
    fontSize: 22,
    lineHeight: 1.5,
    fontWeight: 700,
    marginBottom: 24,
    marginTop: 12,
    [theme.breakpoints.down("578")]: {
      fontSize: 18,
      marginBottom: 20,
      marginTop: 8,
    },
  },
  doneBtn: {
    color: "#fff",
    background: "#F79F19",
    width: 132,
    padding: "12px 30px",
    borderRadius: 12,
    fontWeight: 700,
    lineHeight: "150%",
    textTransform: "capitalize",
    "&.MuiButton-root:hover": {
      backgroundColor: "#F79F19",
    },
    [theme.breakpoints.down("578")]: {
      width: "100%",
    },
  },
  selectPin: {
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 16,
    textDecoration: "underline",
    "&:hover": {
      textDecoration: 'underline'
    },
    [theme.breakpoints.down("578")]: {
      marginBottom: 24,
    },
  },
  closeBtn: {
    position: "absolute",
    color: "#123b64",
    top: 20,
    right: 20,
    padding: 0,
    [theme.breakpoints.down("578")]: {
      top: 16,
    },
  },
}));

function SaveCollection({ handleClose, collection }) {
  const classes = useStyles();
  const navigate = useNavigate()
  const { pathname } = useLocation()

  // const clickHandler = () => {
  //   navigate(`/my-collections/${collection.name}/${collection.id}`)
  // }
  const clickHandler = () => {
    if (collection && collection.name) {
      navigate(`/my-collections/${collection.name}/${collection.id}`);
    }
  };
  
  return (
    <Box className={classes.paper}>
      <IconButton
        onClick={handleClose}
        className={classes.closeBtn}
        disableRipple
      >
        <CloseIcon />
      </IconButton>
      <h2 className={classes.title} id="transition-modal-title">
        Save a few pins on a new collection
      </h2>
      {
        pathname !== "/my-collections/create-collection" ?
          <TextButton onClick={clickHandler} className={classes.selectPin}
          disabled={!collection || !collection.name}
          >
            Select pin
          </TextButton> : null
      }
      <Button onClick={handleClose} disableRipple className={classes.doneBtn}>
        Done
      </Button>
    </Box>
  );
}

export default SaveCollection;
