import { Box, Container, Typography } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  OutlinedButton,
  PrimaryButton,
} from "../../components/shared/buttons/custom-buttons/CustomButtons";
import SecondarySearchBar from "../../components/shared/inputs/SecondarySearchBar";
import Loader from "../../components/shared/loader/Loader";
import ElementsDataGrid from "./../../components/data-grid/ElementsDataGrid";
import { NodeCreationService } from "../../services/NodeCreationService";
import clsx from "clsx";
import DataContext from "../../contexts/Context";
import { useStyles } from "./elementsDetails";
import SelectedChips from "./SelectedChips";

function Elements() {
  const classes = useStyles();
  const [searchParams] = useSearchParams();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCreateRelation, setIsCreateRelation] = useState(false);
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [filteredRows, setFilteredRows] = useState([]);
  const [isNextPage, setIsNextPage] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedNodes, setSearchedNodes] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [rowsMap, setRowsMap] = useState(new Map());
  const selectedNodeName = searchParams.get("selectedNodeName");
  const nodeName = searchParams.get("nodeName");
  const nodeId = searchParams.get("nodeId");
  const navigate = useNavigate();
  const { setDataSetPayload } = useContext(DataContext);
  const params = useParams();
  const location = useLocation();
  const [sources, setSources] = useState([]);
  const source = searchParams.get("source");
  const dbId = searchParams.get("dbId");

  const [tableData, setTableData] = useState([]);

  const sourceSelection = () => {
    const payloadData = {
      key: source,
      pagination: {
        pageNumber: 1,
        pageSize: 9,
      },
    };
    return NodeCreationService.getDatabasesFromSource(payloadData)
      .then((response) => {
        if (response.data) {
          setNodes(response.data.data);
          setIsLoading(false);
          //navigate(`/data_set_samplecard/${nodeName}/${key}`)
        }
      })
      .catch((error) => {
        console.error("Error fetching more from user:", error);
      });
  };
  useEffect(() => {
    sourceSelection();
  }, []);
  useEffect(() => {
    setLoading(true);

    NodeCreationService.fetchDataset(dbId, source)
      .then((res) => {
        // console.log(res.data, "responsessss");
        setTableData(res?.data);
        if (res && res.data) {
          setRows(res.data);

          const elementsMap = new Map();
          res.data.forEach((row) => {
            elementsMap.set(row.id, row);
          });
          setRowsMap(elementsMap);
        }
      })
      .catch((err) => {
        console.log("Error ", err);
      })
      .finally(() => {
        setLoading(false);
      });
    console.log(selectedRows, "selectedRows");
  }, [selectedNodeName]);

  // console.log(rowsMap, "rowsMaprowsMap");
  // console.log(selectedRows, "selectedRows");

  useEffect(() => {
    if (selectedRows.size) {
      setIsNextPage(true);
    } else {
      setIsNextPage(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    if (isCreateRelation) {
      navigate(`/semanticsdataset/${selectedNodeName}/${nodeName}/${nodeId}`);
    }
  }, [isCreateRelation, nodeName, nodeId]);

  const handleHeirarchyPicker = () => {
    const payload = Array.from(selectedRows);
    setDataSetPayload(payload);
    // create relation api
    const filteredNodes = rows.filter((row) =>
      payload.some((id) => id === row.id && row.nodeType)
    );

    const createRelationPayload = filteredNodes.map((node) => {
      console.log("kjhgfcx", node);
      const { name, id, nodeType } = node;

      return {
        id,
        name,
        displayName: name,
        type: nodeType,
        roletype: "coRole",
        rolevalue: "is part of",
      };
    });

    NodeCreationService.createRelation(createRelationPayload, nodeId)
      .then(() => setIsCreateRelation(true))
      .catch(() => {
        console.log("something went wrog with create relation api");
      });
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className={classes.container}>
      <Typography
        style={{ marginBottom: 24 }}
        align="center"
        variant="h1"
        color="primary"
      >
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
      </Typography>
      <Box className={classes.paper}>
        <Box className={classes.header}>
          <Typography className={classes.title} variant="body1" color="primary">
            2. Elements
          </Typography>
        </Box>
        <Box className={classes.main}>
          <Box mb={2.5}>
            <Typography
              className={classes.chipTitle}
              variant="body1"
              color="primary"
            >
              Heirarchy picker
            </Typography>
          </Box>
          <SelectedChips
            rowsMap={rowsMap}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
          <SecondarySearchBar
            rows={rows}
            setFilteredRows={setFilteredRows}
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <Box mt={5}>
            {searchText.length > 0 && filteredRows.length === 0 ? (
              <h3 className="text-center">No search data found</h3>
            ) : (
              tableData && (
                <ElementsDataGrid
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  tableData={tableData}
                  rows={
                    filteredRows.length
                      ? filteredRows
                      : searchText.length === 0 && rows
                  }
                />
              )
            )}
          </Box>
        </Box>
      </Box>
      <Box mb={12.5} className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/data_set_source/${nodeName}/${nodeId}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
        <Box>
          <OutlinedButton
            className={clsx(classes.button, classes.outlinedButton)}
            disableRipple
          >
            Save
          </OutlinedButton>
          <PrimaryButton
            // disabled={!isNextPage}
            onClick={handleHeirarchyPicker}
            className={classes.button}
            disableRipple
          >
            Next
          </PrimaryButton>
        </Box>
      </Box>
    </Container>
  );
}

export default Elements;
