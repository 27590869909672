import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { makeStyles } from '@material-ui/core';
import { useEffect } from 'react';
import { useState } from 'react';
// Custom styles using Material-UI's makeStyles hook
const useStyles = makeStyles({
    avatarGroup: {
        '& .MuiAvatar-root': {
            width: '24px!important',
            height: '24px!important',
            border: 'none'
        },
        '& .MuiAvatar-root:last-child': {
            marginLeft: 8,
            background: 'transparent',
            color: '#123b64c2',
            fontSize: 14
        }
    },
    defaultAvatar: {
        '& .MuiAvatar-root': {
            width: '24px!important',
            height: '24px!important',
            border: 'none'
        },
        '& .MuiAvatar-root:last-child': {
            marginLeft: -8,
            background: '#bdbdbd',
            color: "#fafafa",
            fontSize: 20
        }
    }
})

const MuiAvatarGroup = ({ avatars, table }) => {
    const classes = useStyles()
    const [roles, setRoles] = useState([])

    useEffect(() => {
        if (table !== "simple") {
            let employees = []
            Object.keys(avatars[0]).forEach(role => {
                let users = avatars[0][role].map(user => {
                    return {
                        ...user,
                        role
                    }
                })
                employees.push(...users)
                setRoles(employees)
            })
        } else {
            setRoles(avatars)
        }
    }, [avatars, table])


    return (
        <AvatarGroup className={roles.length > 4 ? classes.avatarGroup : classes.defaultAvatar} max={4}>
            {
                roles.map((item, index) => {
                    return (
                        <Avatar key={index} alt={item.name} src="asd.jpg" />
                    )
                })
            }
        </AvatarGroup>
    );
}
export default MuiAvatarGroup
