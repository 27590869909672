import ModelRegistration from "../../components/navbar/Dataproductpages/node-registration/ModelRegistration";
import NodeRegistration from "../../components/navbar/Dataproductpages/node-registration/NodeRegistration";

function ModelProductRegistration() {
  const details = {
    header: "Build your Model",
    title: "1. Model creation",
    nodeType: 'Data Product'
  }

  return <ModelRegistration details={details} />
}

export default ModelProductRegistration;
