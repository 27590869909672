
import React, { useRef,useState } from 'react';
import { Box, Typography,makeStyles } from '@material-ui/core';
import CollectionList from './collection-list/CollectionList';
import SearchInput from '../../shared/inputs/SearchInput';
import SliderPlaceholder from '../../shared/placeholder/SliderPlaceholder';

const useStyles = makeStyles(theme => ({
  searchInputBox: {
    maxWidth: 503,
    width: '100%',
    marginLeft: 715,
    marginBottom: 30,
    marginTop: 30,
    [theme.breakpoints.down('992')]: {
        marginBottom: 33
    }
},
}))

function CreatedByYou({ collections,  rows,showShortCollectionCard }) {
  console.log(collections,"collections")
  const scrollRef = useRef(null)
  const classes = useStyles()
  const [filteredRows, setFilteredRows] = useState([])
  const [searchNodeName, setSearchNodeName] = useState('')
  const handleChange = (e) => {
    const value = e.target.value.toLowerCase();
  
    // Filter collections based on displayName
    const filteredCollections = collections.filter((collection) =>
      collection.displayName.toLowerCase().includes(value)
    );
  
    // Update the state with the filtered collections
    setFilteredRows(filteredCollections);
  
    // Update the search term
    setSearchNodeName(value);
  };
  
  return (
    
    <Box style={{ marginTop: 37 }} ref={scrollRef}>
      <Box className={classes.searchInputBox}>
        <SearchInput 
        value={searchNodeName} 
        setValue={setSearchNodeName} 
        placeholder="Search Collections"
      handleChange={handleChange}
         />
      </Box>
      {
        collections.length ?
          // <CollectionList
          //   ref={scrollRef}
          //   paginationSpace={84}
          //   nodesPerPage={12}
          //   collectionNodes={collections}
          //   showShortCollectionCard={showShortCollectionCard}
          // />

          <CollectionList
  ref={scrollRef}
  paginationSpace={84}
  nodesPerPage={12}
  collectionNodes={filteredRows.length ? filteredRows : collections}
  showShortCollectionCard={showShortCollectionCard}
/>
           :
           <SliderPlaceholder error={true} text="No data found"/>
      }
    </Box>
  );
}

export default CreatedByYou;