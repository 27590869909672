import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    width: "100%",
    background: "#fff!important",
    border: "1px solid #808C9A",
    borderRadius: 8,
    padding: "5px 13px!important",
    "&:after": {
      content: "unset",
    },
    "&:before": {
      content: "unset",
    },
    "&:hover": {
      background: "unset",
    },
    "&:focused": {
      background: "#fff",
    },
  },
  endAdornment: {
    display: "none",
  },
  tag: {
    height: 24,
    "& svg": {
      width: 18,
      height: 18,
      "& path": {
        fill: "#597693",
      },
    },
    "& .MuiChip-label": {
      paddingRight: 9,
      color: "#123B64",
      fontSize: 14,
    },
  },
  popper: {
    maxHeight: 200,
  },
}));

export default function CreatableMultiSelect(props) {
  const {
    label,
    name,
    options,
    formik,
    handleChange,
    placeholder,
    clickedTag,
  } = props;
  const classes = useStyles();

  return (
    <>
      {label ? (
        <Typography
          style={{ marginBottom: 8, lineHeight: 1 }}
          variant="h6"
          color="primary"
        >
          {label}
        </Typography>
      ) : null}
      <Autocomplete
        classes={classes}
        multiple
        disableCloseOnSelect
        value={formik.values[name]}
        id="tags-filled"
        options={options.map((option) =>
          option.name ? option.name : option.title
        )}
        onChange={handleChange}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option}
              deleteIcon={<ClearIcon />}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} variant="filled" placeholder={placeholder} />
        )}
      />
      {formik.errors[name] && formik.touched[name] ? (
        <Typography style={{ marginTop: 8 }} variant="body2" color="secondary">
          {formik.errors[name]}
        </Typography>
      ) : null}
    </>
  );
}
