import React from 'react';
import './node-viewer-accardion.scss'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height:'100%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    background: {
        backgroundColor:'#fff',
    }

}));


const NodeViewerAccardion = ({children, accardionTitle}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(true)

    const handleClick = () => {
        setExpanded(!expanded)
    }

    return (
        <div className={`description ${classes.root}`}>
            <Accordion 
            expanded={expanded} 
             className={classes.background} elevation={0}>
                <AccordionSummary
                    onClick={handleClick}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>{accardionTitle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default NodeViewerAccardion