import { Box } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { TeamService } from '../../../../services/TeamService';
import MatrixTeamForm from './MatrixTeamForm';

const MatrixTeamBuilder = (props) => {
    const {
        setState,
        teamId,
        requestType,
        getNewContext,
        editContextData,
        contextName,
        updateContext,
        setOpen,
        contextNumber,
        isApplied,
        setIsApplied,
        lastContext,
        setIsAllFieldFilled,
        isAllFieldFilled,
        context,
        index,
        addContextTwo,
        isSubmited,
        setIsTeamLoading
    } = props

    const [formFields, setFormFields] = useState([])
    const [isContextAdded, setIsContextAdded] = useState('')
    const [isContexUpdated, setIsContextUpdated] = useState('')
    const [isTeamCreated, setIsTeamCreated] = useState('')
    const [dataDomainOptions, setDataDomainOptions] = useState([])

    const createMatrixTeam = (data) => {
        TeamService.createMatrixTeam(data)
            .then(res => {
                setIsTeamCreated(res.status)
                getNewContext(data)
                addContextTwo()
            })
    }
    const addContext = (data, teamId) => {
        setIsTeamLoading(true)
        TeamService.addContext(teamId, data)
            .then(res => {
                setIsContextAdded(res.status)
                getNewContext(data)
                setIsTeamLoading(false)
                addContextTwo()
            })
            .catch(() => setIsTeamLoading(false))
    }

    const updateContextTeam = (data) => {
        const payload = {
            teamId: data.teamId,
            context: [{ ...data.context[0].FormFields, contextName: data.context[0].contextName }]
        }
        
        TeamService.updateMatrixTeam(payload)
            .then(res => {
                updateContext(payload)
                setIsContextUpdated(res.status)
            })
    }

    const onSubmit = () => {
        setIsApplied(false)
        let errors = formFields?.errors && Object.values(formFields.errors)
        let isInvalid = errors?.some(item => item !== null)
        let roles = formFields?.roles
        let isInvalidRole = false
        for (let i = 0; i < roles?.length; i++) {
            if (roles[i].errors.role !== null || roles[i].errors.users !== null) {
                isInvalidRole = true
                break
            }
        }
        if (isInvalid || isInvalidRole) {
            setIsAllFieldFilled('All fields must be filled')
            if (!formFields.dataDomain[0].key) {
                setFormFields(prev => {
                    return {
                        ...prev,
                        errors: {
                            ...prev.errors,
                            dataDomain: 'Select data domain'
                        }
                    }
                })
            }
        } else {
            setIsAllFieldFilled(null)
            let FormFields = { ...formFields }
            let selectedDomain = dataDomainOptions.find(item => item.value === formFields?.dataDomain[0].key).label

            let roles = formFields.roles.map(item => {
                return {
                    role: item.role,
                    users: item.users
                }
            })

            delete FormFields.errors
            FormFields.roles = roles
            FormFields.dataDomain[0].name = selectedDomain;

            let payload = {
                ...FormFields,
                teamId: teamId,
            }
            let updatedContext = {
                teamId: teamId,
                context: [
                    {
                        FormFields,
                        contextName: contextName
                    }
                ]
            }
            requestType === "add-context" ? addContext(payload, teamId) :
                requestType === "update" ? updateContextTeam(updatedContext) :
                    createMatrixTeam(payload)
        }
    }

    useEffect(() => {
        if (context == lastContext && isApplied) {
            onSubmit()
        }
    }, [isApplied])

    return (
        <Box sx={{ padding: '0px 40px' }}>
            <MatrixTeamForm
                setState={setState}
                setFormFields={setFormFields}
                formFields={formFields}
                setDataDomainOptions={setDataDomainOptions}
                dataDomainOptions={dataDomainOptions}
                isContextAdded={isContextAdded}
                isContexUpdated={isContexUpdated}
                isTeamCreated={isTeamCreated}
                contextNumber={contextNumber}
                index={index}
                editContextData={editContextData}
                requestType={requestType}
                setOpen={setOpen}
                isAllFieldFilled={isAllFieldFilled}
                isSubmited={isSubmited}
            />
        </Box>
    );
}

export default MatrixTeamBuilder;


let payload2 = {
    teamId: "20694694",
    context: [
        {
            "FormFields": {
                "dataDomain": [
                    {
                        "name": "Business Unit",
                        "key": "4906054"
                    }
                ],
                "business": [
                    {
                        "name": "Broker / Dealer",
                        "key": "4906039"
                    }
                ],
                "geography": [
                    {
                        "name": "Abkhazia",
                        "key": "4905763"
                    }
                ],
                "product": [
                    {
                        "name": "groceries",
                        "key": "4909251"
                    }
                ],
                "roles": [
                    {
                        "role": "Owner",
                        "users": [
                            {
                                "name": "Siripriya ",
                                "id": "2557956"
                            },
                            {
                                "name": "Mounika",
                                "id": "32872245"
                            }
                        ]
                    },
                    {
                        "role": "SME",
                        "users": [
                            {
                                "name": "Mounika",
                                "id": "32872240"
                            }
                        ]
                    }
                ]
            },
            "contextName": "Context1"
        }
    ]
}


