import React from "react";
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";
import { CartProvider } from "./contexts/Context";
import { TabProvider } from "./contexts/TabContext";
import "./index.css";

const queryClient = new QueryClient();
const root = createRoot(document.getElementById("root"));
root.render(<QueryClientProvider client={queryClient}>
  <CartProvider>
    <TabProvider>
      <Router>
        <App />
      </Router>
    </TabProvider>
  </CartProvider>
</QueryClientProvider>)
