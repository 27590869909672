export const TAB = {
    type: "filter",
    title: "Search results",
    id: "filter",
    loading: true,
    reqTime: 0,
    nodeTitle: "",
    data: [],
}

export const TAB_TYPES = {
    FILTER: 'filter',
    NODE_VIEWER: 'node-viewer'
}

export const NEW_NODE_TYPES = {
    dataProduct: 'dataProduct',
    dataSet: 'dataSet'
}

export const nodeDetailTab = (id, title, isNewNode=false, nodeType='', selectedNode='') => {
    return {
        type: TAB_TYPES.NODE_VIEWER,
        id, title,
        nodeType,
        reqTime: 0,
        loading: true,
        error: false,
        data: [],
        isNewNode,
        selectedNode
    }
}