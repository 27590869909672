


import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useEffect, useState } from "react";
import PopularIcon from "../../../assets/images/card-icons/icon.png";
import RecentIcon from "../../../assets/images/card-icons/icon2.png";
import RelatedIcon from "../../../assets/images/card-icons/related-icon.png";
import "./Navbardropdowninput.scss";
import PopularTag from "./PopularTag";
import { GraphIcon, InfoIcon } from "../../icons";

const useStyles = makeStyles({
  form: {
    height: (props) => (props.mobile ? 40 : 48),
  },
  img: {
    marginRight: 8,
  },
  input: {
    padding: "10px 16px",
    width: "100%",
    height: "100%",
    borderColor: (props) => (props.isFocused ? "#f79f19" : "unset"),
    borderStyle: (props) => (props.isFocused ? "solid" : "unset"),
    borderWidth: (props) => (props.isFocused ? "2px 0" : "unset"),
  },
  recentSearchesWrapper: {
    textAlign: "justify",
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
  },
  suggestedSearchWrapper: {
    display: "flex",
    padding: "0.75rem",
    width: "100%",
    borderRadius: "8px",
    maxHeight: 100,
    cursor: "pointer",
  },
  suggestedSearhes: {
    display: "flex",
    flexWrap: "wrap",
    position: "static",
    color: "#123b64",
    maxHeight: 370,
    paddingRight: 20,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#F79F19",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#808c9a4d",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-button:end:increment": {
      height: "16px",
      display: "block",
    },
    "&::-webkit-scrollbar-button:start:increment": {
      height: "16px",
      display: "block",
    },
  },
  tag: {
    margin: 4,
  },
});

const NavbarDropdownInput = ({
  setIsNodeTypeFilter,
  onHandleChange,
  popularTags,
  recentSearches,
  handleRecentClick,
  handlePopularTag,
  handleRelatedTag,
  autoCompleteResults,
  handleSubmit,
  isGraphOpen,
  setIsGraphOpen,
  setIsFocused,
  isFocused,
  searchText,
  setSearchText,
  mobile,
  tags,
}) => {
  const classes = useStyles({ mobile, isFocused });
  const [active, setActive] = useState(null);

  const keyPress = (e) => {
    if (e.keyCode === 38) {
      if (active === 0 || active === null) {
        setActive(autoCompleteResults.length - 1);
        setSearchText(autoCompleteResults[autoCompleteResults.length - 1].name);
      } else {
        setActive((prev) => prev - 1);
        setSearchText(autoCompleteResults[active - 1].name);
      }
    }
    if (e.keyCode === 40) {
      if (active === autoCompleteResults.length - 1) {
        setActive(0);
        setSearchText(autoCompleteResults[0].name);
      } else {
        setActive((prev) => {
          return prev === null ? 0 : prev + 1;
        });
        setSearchText(
          autoCompleteResults[active === null ? 0 : active + 1].name
        );
      }
    }
    if (e.keyCode === 13) {
      setIsNodeTypeFilter(true);
    }
  };

  useEffect(() => {
    if (searchText === "") {
      setActive(null);
    }
  }, [searchText]);

  return (
    <div className="dropdown nav-dropdown-wrapper flex-grow-1">
      <form
        data-bs-toggle="dropdown"
        onSubmit={handleSubmit}
        id="navbar-form"
        className={`navbar-form ${classes.form}`}
      >
        <input
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onKeyUp={keyPress}
          autoComplete="off"
          onChange={onHandleChange}
          value={searchText}
          className={`dropdown-toggle ${classes.input}`}
          placeholder="Search something..."
          type="text"
          aria-label="Text input with dropdown button"
        />
      </form>
      <ul
        className="dropdown-menu search-texts-wrapper w-100"
        aria-labelledby="navbar-form"
      >
        <div className="row">
          <div className="graph-search-container col-12">
            <div className="row justify-content-between">
              {/* <div className="graphblock d-flex align-items-center mb-3 mb-xxl-0 col-xxl-6 col-12">
                <InfoIcon className="i-icon" />
                <label className="option">
                  You can {isGraphOpen ? "deactivate" : "activate"} the
                  graphical search
                </label>
              </div> */}
              {/* <button
                className={`col-xxl-4 column ${
                  isGraphOpen ? "active-graph-btn" : ""
                }`}
                onClick={() => setIsGraphOpen(!isGraphOpen)}
              >
                {isGraphOpen ? (
                  <GraphIcon className="white-graph" />
                ) : (
                  <GraphIcon className="primary-graph" />
                )}
                <label className="Graphsearch">Graph search</label>
              </button> */}
            </div>
          </div>
          <div className="col-12">
            {autoCompleteResults?.length === 0 && (
              <div className="row">
                <div className="col-xxl-6 col-12">
                  <h6 className="me-4 fw-bold text-primary">
                    <img
                      className={classes.img}
                      src={RecentIcon}
                      alt="Recent icon"
                    />
                    My recent searches
                  </h6>
                  <div className="d-flex flex-wrap">
                    <div className={classes.recentSearchesWrapper}>
                      {recentSearches.map((value, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => handleRecentClick(value)}
                            className="m-2 pb-0 search-container-text text-secondary-100"
                            style={{ cursor: "pointer" }}
                          >
                            {value}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {autoCompleteResults.length > 0 ? (
              <h6 className="ms-2 fw-bold text-primary">Suggested searches</h6>
            ) : null}
            <div className={classes.suggestedSearhes}>
              {autoCompleteResults.map(({ name, type }, index) => (
                <div
                  key={index}
                  className={classes.suggestedSearchWrapper}
                  onMouseEnter={() => setActive(index)}
                  style={{ background: active === index ? "#E0E7EC" : "white" }}
                  onClick={() => handleRecentClick(name)}
                >
                  <span
                    className="suggested-item"
                    style={{
                      paddingRight: "2rem",
                      fontWeight: "700",
                      textDecorationLine:
                        active === index ? "none" : "underline",
                      textUnderlineOffset: "4px",
                      textDecorationColor: "#123b6433",
                      alignself: "stretch",
                      fontFamily: "Roboto",
                      fontSize: "14px",
                    }}
                  >
                    {name}
                  </span>
                  <div>
                    <span style={{ color: "#597693", marginRight: 8 }}>in</span>
                    <span
                      style={{
                        fontWeight: 400,
                        paddingRight: "2rem",
                        textDecorationLine: "underline",
                        textUnderlineOffset: "4px",
                        textDecorationColor: "#123b6433",
                      }}
                    >
                      {type}
                    </span>
                  </div>
                </div>
              ))}
              <hr />
            </div>
          </div>
          <div className="col-12 popular-tag-wrapper">
            <div className="d-flex align-items-baseline">
              {autoCompleteResults.length === 0 ? (
                <h6 className="me-4 fw-bold text-primary">
                  <img src={PopularIcon} alt="Popular icon" /> Popular tags
                </h6>
              ) : (
                <h6
                  style={{ marginBottom: 16 }}
                  className="me-4 fw-bold text-primary"
                >
                  <img src={RelatedIcon} alt="Related icon" />
                  Related tags
                </h6>
              )}
            </div>
            {autoCompleteResults.length === 0 && popularTags.length > 0 ? (
              <div className="d-flex flex-wrap">
                {popularTags.map((tag, index) => {
                  return (
                    <Box mr={1} mb={2} key={index}>
                      <PopularTag
                        onClick={() => handlePopularTag(tag.labelTags)}
                        labelTags={popularTags && tag.labelTags}
                        value={popularTags && tag.nodesCount}
                      />
                    </Box>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex flex-wrap">
                {tags.map(({ labelTags, value }, index) => (
                  <Box mr={1} mb={2} key={index}>
                    <PopularTag
                      onClick={() => handleRelatedTag(labelTags)}
                      labelTags={labelTags}
                      value={value}
                    />
                  </Box>
                ))}
              </div>
            )}
          </div>
        </div>
      </ul>
    </div>
  );
};

export default NavbarDropdownInput;

