import { makeStyles } from "@material-ui/styles";
import * as yup from 'yup'

export const useStyles = makeStyles((theme) => ({
    root: {
      background: '#FFF',
      borderRadius: 16,
      width: 420,
      height: 600
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20
    },
    avatarBox: {
      display: 'flex',
      alignItems: 'center'
    },
    iconButton: {
      padding: 0,
      "& svg": {
        width: 16,
        height: 20
      }
    },
    list: {
      padding: 0,
      "& li": {
        padding: "0 0 0 8px",
  
      }
    },
    listItemText: {
      margin: 0
    },
    urlBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end'
    }
  }));

export const validationSchema = yup.object({
    users: yup.array().min(1, "Select at least one user"),
})