import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

export const CustomBadge = withStyles((theme) => ({
  badge: {
    right: 2,
    top: 5,
    padding: '0 4px',
    minWidth: 14,
    height: 14,
    color: '#fff',
    fontSize: 8
  },
}))(Badge);

