export const config = {
    oidc: {
        issuer: `${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/default`,
        clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
        scopes: ['openid', 'profile', 'email'],
        redirectUri: `${window.location.origin}/login/callback`,
        pkce: true,
    },
    widget: {
        baseUrl: `${process.env.REACT_APP_OKTA_DOMAIN}`,
        clientId: `${process.env.REACT_APP_OKTA_CLIENT_ID}`,
        redirectUri: `${window.location.origin}/home`,
        useInteractionCodeFlow: true,
        authParams: {
            // If your app is configured to use the Implicit flow
            // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
            // you will need to uncomment the below line
            // pkce: false
        },
    }
};

