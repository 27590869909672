import { Box, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import background from "../../../../../assets/imageslogo/background2.png";
import icon2 from "../../../../../assets/imageslogo/icon2.png";
import React from "react";
import "./Discover.scss";
import icon1 from "../../../../../assets/imageslogo/image1.png";
import one from "../../../../../assets/imageslogo/1.png";
import background3 from "../../../../../assets/imageslogo/background3.png";
import logo from "../../../../../assets/imageslogo/logo3.png";
import symbol from "../../../../../assets/imageslogo/symbol3.png";
import two from "../../../../../assets/imageslogo/two.png";
import three from "../../../../../assets/imageslogo/three.png";
import background4 from "../../../../../assets/imageslogo/background3.png";
import icon3 from "../../../../../assets/imageslogo/icon3.png";
import ellipse from "../../../../../assets/imageslogo/ellipse.png";
import line1 from "../../../../../assets/imageslogo/line1.png";
import line2 from "../../../../../assets/imageslogo/line2.png";
import line3 from "../../../../../assets/imageslogo/line1.png";
import greater from "../../../../../assets/imageslogo/greaterthan.png";
import less from "../../../../../assets/imageslogo/lessthan1.png";
import greater1 from "../../../../../assets/imageslogo/greaterthan.png";
import discover from "../../../../../assets/imageslogo/Discoverbg.png";
import democratize from "../../../../../assets/imageslogo/Discoverbg.png";
import discover2 from "../../../../../assets/imageslogo/Discover.png";
import socialise from "../../../../../assets/imageslogo/Socialise.png";
import Business from "../../../../../assets/imageslogo/BusinessProcess.png";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: 300,
    color: "#123b4",
    minHeight: 1900,
  },
  text: {
    width: 865,
    position: "absolute",
    fontFamily: "Roboto",
  },
  discover: {
    borderColor: "1px solid #f9f9f9",
  },

  addNew: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 146,
    height: 18,
    left: 470,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1070,
    color: "#123B64",
  },
  addNew1: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 350,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1100,
    color: "#123B64",
  },
  Refine: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 355,
    left: 900,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1310,
    color: "#123B64",
  },
  Refinedata: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 800,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1340,
    color: "#123B64",
  },
  Explore: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 355,
    left: 465,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1585,
    color: "#123B64",
  },
  Exploredata: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 360,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1610,
    color: "#123B64",
  },
  Traverse: {
    fontWeight: "bold",
    marginBottom: 70,
    width: 355,
    left: 900,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1755,
    color: "#123B64",
  },
  radio: {
    position: "absolute",
    left: 240,
  },
  Traversedata: {
    fontWeight: "normal",
    marginBottom: 70,
    width: 355,
    left: 800,
    position: "absolute",
    fontFamily: "Roboto",
    top: 1780,
    color: "#123B64",
  },
});
function Discover() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h1 className="header" component="span">
        Getting Started
      </h1>
      <Box className="Card1">
        <button className="btn11">1 </button>
        <div className="line" />
        <label className="label">Search</label>
        <br /> <div className="line1" />
        <button size="small" className="btn1">
          2
        </button>
        <label className="label1">Refine</label>
        <br />
        <div className="line2" />
        <button size="small" className="btn2">
          3
        </button>
        <label className="label2">Explore</label>
        <br />
        <button size="small" className="btn3">
          4
        </button>
        <label className="label3">Traverse</label>
      </Box>
      <Link to="/DataProduct1"></Link>
      <Link to="/DataProduct"></Link>
      <div className={classes.discover}>
        <Box className="Card2 ">
          <text className="Discover">Discover</text>
          <br />
          <p className="text1">
            {" "}
            The process of uncovering relevant data sets & data products to the
            data citizens who need them.
          </p>
        </Box>
      </div>
      <Box>
        <Box className="Discoverinbox discover-button-active">
          <label className="text2 text-active">
            <img src={discover} className="discover1" />
            <img src={discover2} className="discover2" />
            Discover
            {/* <CustomizedRadio className={classes.radio} /> */}
          </label>
        </Box>
      </Box>
      <Link to="/socialise">
        <Box>
          <Box className="Socialiseinbox">
            <label className="text3">
              <img src={socialise} className="Socialise" />
              Socialise
              {/* <CustomizedRadio className={classes.radio}>
                <Link to="/Socialise"></Link>
              </CustomizedRadio> */}
            </label>
          </Box>
        </Box>
      </Link>

      <Box>
        <Link to="/democratize">
          <Box className="Democratizeinbox">
            <label className="text4">
              <img src={democratize} className="democratize1" />
              <img src={Business} className="democratize2" />
              Democratize
              {/* <CustomizedRadio className={classes.radio}> */}
              {/* <Link to="/Democratize"></Link> */}
              {/* </CustomizedRadio> */}
            </label>
          </Box>
        </Link>
      </Box>
      <ListItem>
        <ListItemText>
          <label className="Work">How Does It Work?</label>
        </ListItemText>
        {/* <Typography
          className="Worktext"
          sx={{ fontSize: 16 }}
          color="text.secondary"
          gutterBottom
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultricies in
          amet leo non elit est suscipit commodo.
        </Typography> */}
      </ListItem>
      <img src={icon1} className="imageN" />
      <Typography disableRipple className={classes.addNew}>
        Search
      </Typography>
      <Typography disableRipple className={classes.addNew1}>
        A personalized search that relies on user profiles & search history to
        recommend what they need & where they need, with high relevance
      </Typography>
      <img src={ellipse} className="circle" />
      <img src={line1} className="dottedline1" />
      <img src={greater} className="greater" />
      <img src={background} className="imageM" />
      <img src={icon2} className="image" />
      <img src={one} className="one" />
      <Typography disableRipple className={classes.Refine}>
        Refine
      </Typography>
      <Typography disableRipple className={classes.Refinedata}>
        Use filters like status/ freshness & facets like region/ customer type/
        product type & exact words to narrow a search
      </Typography>
      <img src={ellipse} className="circle1" />
      <img src={line2} className="dottedline2" />
      <img src={less} className="less" />
      <img src={background3} className="image3" />
      <img src={logo} className="logo3" />
      <img src={symbol} className="symbol" />
      <img src={two} className="two" />
      <Typography disableRipple className={classes.Explore}>
        Explore
      </Typography>
      <Typography disableRipple className={classes.Exploredata}>
        Allows you to get the additional information of a node like description,
        business & technical metadata, quality, information classification &
        risk.
      </Typography>
      <img src={ellipse} className="circle2" />
      <img src={line3} className="dottedline3" />
      <img src={greater1} className="greater1" />
      <img src={background4} className="image4" />
      <img src={icon3} className="icon3" />
      <img src={three} className="three" />
      <Typography disableRipple className={classes.Traverse}>
        Traverse
      </Typography>
      <Typography disableRipple className={classes.Traversedata}>
        Uncover deeply related nodes in a directed graph in a most efficient
        way. For example, you look for a term called customer name, but the
        application traversing through the directed graph will bring up the
        relevant data products independent of the relation depth.
      </Typography>
    </div>
  );
}
export default Discover;
// import React from "react";
// import './Discover.scss';
// function Discover(){
//     return (
//         <div>
//         nc
//         </div>
//     );
// }
// export default Discover;
