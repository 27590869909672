import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import DataContext from '../../../contexts/Context';

const useStyles = makeStyles({
    drawer: {
        '& .MuiDrawer-paper': {
            '&::-webkit-scrollbar': {
                display: 'none'
            },
            background: props => props.anchor === "left" ? 
            'linear-gradient(259.26deg, #011936 -14.93%, #011936 61.45%)' : '#fff',
        },
    },
    list: {
        position: 'relative',
        height: '100%',
    },
});

const CustomDrawer = (props) => {
    const { anchor, button, node, setState, state, children } = props
    const classes = useStyles(props);
    const { cart } = useContext(DataContext)

    const toggleDrawer = (open) => {
        const isExist = node && cart.some(nodeInCart => nodeInCart.Id === node.id)
        if (isExist) {
            setState(false)
        } else {
            setState(open)
        }
    };

    return (
        <div>
            <React.Fragment >
                <div style={{display: 'inline'}} onClick={() => toggleDrawer(true)}>
                    {button}
                </div>
                <Drawer
                    className={classes.drawer}
                    anchor={anchor}
                    open={state}
                    onClose={() => setState(false)}
                >
                    <div className={classes.list} role="presentation">{children}</div>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
export default CustomDrawer