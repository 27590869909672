import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
    container: {
        minHeight: 'calc(100vh - 150px)',
        paddingTop: 30,
        paddingBottom: 100,
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },
    primaryButton: {
        width: 199,
        height: 48,
        '& .MuiButton-startIcon': {
            marginRight: 12,
            marginLeft: -6
        }
    },
    gridContainer: {
        marginBottom: 40
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'end'
    },
    paper: {
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        marginBottom: 100
    },
    secondaryLabel: {
        color: '#808C9A',
        fontSize: 14
    },
    label: {
        lineHeight: '11px',
        marginBottom: 44
    },
    typography: {
        fontSize: 14,
        fontWeight: 400,
        lineHeight: 1.4,
        marginTop: 10
    },
    errorIndex: {
        marginBottom: 20
    },
    recomendationBox:{
        background: '#c4c4c41a', 
        borderRadius: 8, 
        width: '100%', 
        maxWidth: 509,
        padding: 15
    }
}));  

export const defaultSelectedObjectives = [
    {
        valueType: "percentage",
        name: "integrity"
    },
    {
        valueType: "time",
        name: "timelyNess"
    },
    {
        valueType: "percentage",
        name: "Validity"
    },
    {
        valueType: "number",
        name: "Confirmity"
    },
    {
        valueType: "percentage",
        name: "Completeness"
    },
    {
        valueType: "percentage",
        name: "Consistancy"
    }
]