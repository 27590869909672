import React from "react";
import FacetAccordion from "../facet-accordion/FacetAccordion";
import "./SidebarFacets.scss";
import { SearchService } from "../../../services/SearchService";
import { useQuery } from "react-query";

const SidebarFacets = () => {
  const facetsData = [
    { accordionLabel: "Region" },
    { accordionLabel: "Currency" },
    { accordionLabel: "Status" },
    { accordionLabel: "Product Type" },
    { accordionLabel: "Customer Type" },
  ];
  const currency = [
    { label: "AFN", value: "afn" },
    { label: "EUR", value: "eur" },
    { label: "ALL", value: "all" },
    { label: "DZD", value: "dzd" },
    { label: "USD", value: "usd" },
    { label: "AOA", value: "aoa" },
    { label: "XCD", value: "xcd" },
    { label: "ARS", value: "ars" },
    { label: "AMD", value: "amd" },
    { label: "AWG", value: "awg" },
    { label: "AUD", value: "aud" },
    { label: "AZN", value: "azn" },
    { label: "BHD", value: "bhd" },
    { label: "BSD", value: "bsd" },
    { label: "BDT", value: "bdt" },
    { label: "BBD", value: "bbd" },
    { label: "BYN", value: "byn" },
    { label: "BZD", value: "bzd" },
    { label: "XOF", value: "xof" },
    { label: "BMD", value: "bmd" },
    { label: "INR", value: "inr" },
    { label: "BTN", value: "btn" },
    { label: "BOB", value: "bob" },
    { label: "BOV", value: "bov" },
    { label: "BAM", value: "bam" },
    { label: "BWP", value: "bwp" },
    { label: "NOK", value: "nok" },
    { label: "BRL", value: "brl" },
    { label: "BND", value: "bnd" },
    { label: "BGN", value: "bgn" },
  ];
  const values = [
    { label: "Candidate", value: "candidate" },
    { label: "Approved", value: "approved" },
    { label: "Certified ", value: "certified" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Closed ", value: "closed" },
    { label: "Completed ", value: "completed" },
    { label: "Build ", value: "build" },
    { label: " Accepted ", value: " accepted" },
    { label: "Defined ", value: "defined" },
    { label: "Access Denied ", value: "access denied" },
    { label: "Access Granted", value: "accessgranted" },
    { label: "Approval Pending ", value: "approvalpending" },
    { label: "Deleted", value: "deleted" },
    { label: "Hold ", value: "hold" },
    { label: "Inactive", value: "inactive" },
    { label: "Reviewed", value: "reviewed" },
    { label: "Requested", value: "requested" },
    { label: "Rejected ", value: "rejected" },
    { label: "Resolved ", value: "resolved" },
    { label: "Pending", value: "pending" },
    { label: "Invalid", value: "invalid" },
    { label: "New  ", value: "new " },
    { label: "Inprogress", value: "inprogress" },
    { label: "Underreview ", value: "underreview" },
  ];
  const Productvalues = [
    { label: "DATAWAREHOUSE", value: "datawarehouse" },
    { label: "ALATION", value: "alation" },
    { label: "MIF", value: "mif" },
    { label: "DATAPLEX", value: "dataplex" },
    { label: "ACG", value: "acg" },
    { label: "GCP", value: "gcp" },
    { label: "PKWARE", value: "pkware" },
    { label: "DOCKER", value: "docker" },
  ];
  const Customervalues = [
    { label: "Customers", value: "customers" },
    { label: "Receivers", value: "receivers" },
    { label: "Producers ", value: "producers" },
    { label: "Sellers", value: "sellers" },
    { label: "Buyers ", value: "buyers" },
    { label: "Retailers ", value: "retailers" },
    { label: "Users ", value: "users" },
  ];

  const getFacets = () => {
    return SearchService.fetchfacetsData("user table");
  };

  const { isLoading, data } = useQuery("facets", getFacets);

  if (isLoading) {
    console.log("Loading...");
  }

  const continents = [];
 
  return (
    <div>
      {continents &&
        facetsData.map((filter, index) => {
          const { accordionLabel } = filter;
          return (
            <FacetAccordion
              continents={
                accordionLabel === "Region"
                  ? continents
                  : accordionLabel === "Currency"
                  ? currency
                  : accordionLabel === "Status"
                  ? values
                  : accordionLabel === "Customer Type"
                  ? Customervalues
                  : accordionLabel === "Product Type"
                  ? Productvalues
                  : "null"
              }
              key={index}
              filter={filter}
            />
          );
        })}
      <div className="apply-facets">
        <button className="apply-facets-btn bg-primary text-white" type="button">
          Apply facets
        </button>
      </div>
    </div>
  );
};

export default SidebarFacets;
