import { Box, Typography, makeStyles } from '@material-ui/core';
import SearchBar from '../../SearchBar';
import { OutlinedButton, PrimaryButton } from '../../../buttons/custom-buttons/CustomButtons';
import AddIcon from '@material-ui/icons/Add';
import ObjectiveList from './ObjectiveList';
import { useEffect, useState } from 'react';
import CustomModal from '../../../modal/CustomModal';
import AddNewObjective from './AddNewObjective';

const useStyles = makeStyles({
    root: {
        width: 508,
        boxShadow: "0px 2px 20px rgba(23, 87, 123, 0.2)",
        borderRadius: 8,
        background: "#fff"
    },
    title: {
        lineHeight: 1.1,
        marginBottom: 10
    },
    subtitle: {
        fontWeight: 400
    },
    primaryButton: {
        fontSize: 14,
        fontWeight: 700,
        padding: '5.5px 20px',
        '& svg': {
            width: 17,
            height: 17
        },
        '& primaryButton': {
            marginRight: 4
        }
    },
    bottomSide: {
        padding: 16,
        boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.1)",
        "& button": {
            fontSize: 14,
            width: 130,
            padding: 6,
            borderRadius: 8
        }
    },
    applyButton: {
        marginRight: 15
    }
})

function ObjectiveContent(props) {
    const {
        checkedObjectives,
        setCheckedObjectives,
        objectiveOptions,
        setObjectiveOptions,
        setAppledObjectives,
        setPopperAnc
    } = props
    const classes = useStyles()
    const [flteredOptions, setFilteredOptions] = useState(objectiveOptions)
    const [saerchText, setSearchText] = useState('')
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        if (!saerchText) {
            setFilteredOptions(objectiveOptions)
        }
    }, [saerchText, objectiveOptions])

    const handleClearAll = () => {
        setCheckedObjectives([])
    }

    const handleApply = () => {
        setPopperAnc(false)
        setAppledObjectives(checkedObjectives)
    }

    const handleSearch = (e) => {
        const filteredValues = objectiveOptions.filter(option => {
            return option.name.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1
        })
        setFilteredOptions(filteredValues)
        setSearchText(e.target.value)
    }

    return (
        <Box className={classes.root}>
            <Box p={2}>
                <Typography className={classes.title} variant='body2'>
                    Choose objectives or add own
                </Typography>
                <Box mb={2} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.subtitle} variant='body2'>
                        You can also create your own objectives
                    </Typography>
                    <PrimaryButton
                        onClick={() => setOpenModal(true)}
                        className={classes.primaryButton}
                        startIcon={<AddIcon />}
                    >
                        Add own objectives
                    </PrimaryButton>
                </Box>
                <SearchBar
                    value={saerchText}
                    setValue={setSearchText}
                    handleChange={handleSearch}
                    forRole="for-role"
                    placeholderText="Search user"
                />
                <ObjectiveList
                    objectiveOptions={flteredOptions}
                    checkedObjectives={checkedObjectives}
                    setCheckedObjectives={setCheckedObjectives}
                />
            </Box>
            <Box className={classes.bottomSide}>
                <PrimaryButton onClick={handleApply} className={classes.applyButton}>
                    Apply
                </PrimaryButton>
                <OutlinedButton onClick={handleClearAll}>Clear</OutlinedButton>
            </Box>
            <CustomModal open={openModal} setOpen={setOpenModal}>
                <AddNewObjective
                    setObjectiveOptions={setObjectiveOptions}
                    objectiveOptions={objectiveOptions}
                    setOpen={setOpenModal}
                />
            </CustomModal>
        </Box>
    );
}

export default ObjectiveContent;