import { Box, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { EmailIcon, LinkIcon, SlackIcon } from "../../icons";
import teams from "./../../../assets/images/teams-icon.png";

const useStyles = makeStyles((theme) => ({
  blue: {
    background: "#55ACEE",
    marginLeft: -10,
  },
  pink: {
    backgroundColor: "#611F69",
    marginLeft: -10,
  },
  primary: {
    backgroundColor: "#123B64",
    marginLeft: -5,
    marginBottom: -15,
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 15px",
    cursor: "pointer",
  },
  avatarTitle: {
    fontSize: 12,
    color: "#123b64",
    lineHeight: "16.8px",
    marginTop: 5,
    marginBottom: 10,
  },
  avatarTitle10: {
    fontSize: 12,
    color: "#123b64",
    lineHeight: "16.8px",
    marginTop: 20,
    marginRight: 8,
    // marginBottom:10,
  },
}));

function ShareBtn({ handleClick, name }) {
  const classes = useStyles();
  return (
    <div className={classes.avatarWrapper}>
      {name === "team" ?
        <>
          <Box onClick={() => handleClick("Teams")} style={{ width: 40, height: 40 }}>
            <img style={{ width: "100%" }} src={teams} alt="teams" />
          </Box>
          <span className={classes.avatarTitle}>Teams</span>
        </>
        : name === "copy" ?
          <>
            <Avatar onClick={handleClick} className={classes.primary}>
              <LinkIcon />
            </Avatar>
            <span className={classes.avatarTitle10}>Copy</span>
          </>
          : name === "slack" ?
            <>
              <Avatar onClick={() => handleClick("Slack")} className={classes.pink}>
                <SlackIcon />
              </Avatar>
              <span className={classes.avatarTitle}>Slack</span>
            </>
            :
            name === "email" ?
            <>
              <Avatar onClick={handleClick} className={classes.blue}>
                <EmailIcon />
              </Avatar>
              <span className={classes.avatarTitle}>Mail</span>
            </>:<></>
      }

    </div>
  );
}

export default ShareBtn;
