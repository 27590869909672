import { Box, makeStyles } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import certified from "../../../assets/images/card-icons/sertification.png";
import { nodeDetailTab } from "../../../constants/tab";
import DataContext from "../../../contexts/Context";
import TabContext from "../../../contexts/TabContext";
import { TAB_CLEAR } from "../../../state/constants/tab";
import CheckoutSidebar from "../../checkout-sidebar/CheckoutSidebar";
import CustomModal from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
import SaveInCollection from "../../save-in-collection/SaveInCollection";
import Share from "../../share/Share";
import CardIconBtn from "../buttons/card-icon-btn/CardIconBtn";
import FavoriteBtn from "../buttons/fav-button/FavoriteBtn";
import CurvedProgressBar from "../curved-progress-bar/CurvedProgressBar";
import NodeGraphWrapper from "../d3-graph/force-directed-graph/NodeGraphWrapper";
import CustomDrawer from "../drawer/CustomDrawer";
import { useSearchParams } from "react-router-dom";
import { FaRegistered } from "react-icons/fa";
import MetaDataQuality from "../meta-data-quality/MetaDataQuality";
import NodeLock from "../node-lock-icon/NodeLock";
import SharePopover from "../popover/SharePopover";
import OverflowTooltip from "../tooltip/OverflowTooltip";
import Tooltip from "../tooltip/Tooltip";
import CardIconButton from "./CardIconButton";
import FullNodeCardLogo from "./full-node-card-logo/FullNodeCardLogo";
import "./full-node-card.scss";
import { SearchService } from "../../../services/SearchService";
import InterMediateModal from "../../../components/shared/modal/CustomModal";
import ModalContent from "./graph-node/ModalContent";
import axios from "axios";

const useStyles = makeStyles({
  controls: {
    display: "flex",
    justifyContent: "end",
    backgroundColor: "white",
  },
  closeBtn: {
    border: "none",
    backgroundColor: "white",
  },
  star: {
    "& svg": {
      width: 12,
      height: 12,
    },
  },
});

const FullNodeCard = ({
  data,
  selectedNodes,
  setSelectedNodes,
  currentNodes,
  nodeId,
}) => {
  const {
    securityClassification,
    personallyIdentifiableInformation,
    type,
    qualityScore,
  } = data;
  const idToUse = data.id.includes('/') ? data.id.split('/')[1] : data.id;
// console.log("thrthr",idToUse)
  const { addToCart, cart, setCart } = useContext(DataContext);
  const { addTabHandler, dispatch } = useContext(TabContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openSaveCollection, setOpenSaveCollection] = useState(null);
  const [openShareContent, setOpenShareContent] = useState(null);
  const [selectedNodeStyle, setSelectedNodeStyle] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInterMediagePageOpen, setIsInterMediagePageOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { pathname: location } = useLocation();
  const isDataProductBuildPage = location.includes("samplecard1");
  const isDatasetPage = location.includes("data_set_samplecard");
  const isSampleCard = location.includes("data_product_samplecard");
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const noDescription = "No description";
  const [isInCart, setInCart] = useState(false);
  const [graphNodeData, setGraphNodeData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [viewCount, setViewCount] = useState([]);
  const { key, nodeName, dbId } = useParams();
  // const { key, nodeName } = useParams();

  //console.log("lkjhgvn", nodeName);
  // const key = searchParams.get("key");
  // console.log("khvmnj", key);

  // const getViewcountData = () => {
  //   SearchService.fetchViewCount(key)

  //     .then((res) => {
  //       //console.log(res, "responsess");
  //       setViewCount(res.data);
  //       setIsLoading(false);
  //     })
  //     .catch(() => setIsLoading(false));
  // };

  // useEffect(() => {
  //   console.log("ID:", key); // Debugging line
  //   // getNodeTags(key);
  //   getViewcountData(key);
  // }, []);
  // const resizeObserverRef = useRef(null);

  // useEffect(() => {
  //   const targetElement = document.getElementById('target-element'); // Replace with your target element
  //   resizeObserverRef.current = new ResizeObserver((entries) => {
  //     // Handle ResizeObserver entries
  //   });

  //   resizeObserverRef.current.observe(targetElement);

  //   // Cleanup function
  //   return () => {
  //     if (resizeObserverRef.current) {
  //       resizeObserverRef.current.disconnect();
  //     }
  //   };
  // }, []);

  const isSelectedNode =
    selectedNodes &&
    selectedNodes.find((node) => {
      if (isDataProductBuildPage) {
        return node.id === data.id;
      } else {
        return node === data.id;
      }
    });

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    const isExist = cart.find((item) => item.id === data.id);
    setInCart(isExist);
  }, [cart, data.id]);

  useEffect(() => {
    if (isSelectedNode && isDataProductBuildPage) {
      setSelectedNodeStyle({
        border: "1px solid #123b64",
        backgroundColor: "#dee6eb",
      });
    } else if (isSelectedNode && !isDataProductBuildPage) {
      setSelectedNodeStyle({ border: "1px solid #f79f19" });
    } else {
      setSelectedNodeStyle({ border: "none" });
    }
  }, [isSelectedNode]);

  const extractContent = (s) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   getViewcountData();
  // }, []);

  const showSidebar = (event) => {
    event.stopPropagation();
    let cartCopy = [...cart];

    if (isInCart) {
      alert("This node is already added to cart");
    } else {
      cartCopy.push(data);
      setCart(cartCopy);
      addToCart(data);
      setIsSidebarOpen(true);
    }
  };
  // const handleClick = (relatedSearchText) => {
  //   navigate({
  //     pathname: "/tabs",
  //     search: `?nodeName=${dataSearch.data.displayName}&nodeType=${}`,
  //   });
  //   dispatch({ type: actions.CLEAR_ALL_CHECKED });
  //   handleRelatesSearchClick(relatedSearchText);
  // };

  const clickHandler = async (event) => {
    event.stopPropagation();

    const dataSearch = await SearchService.setTailNodeHistory(data.id,null);
     console.log("gfdfkjh", dataSearch.data);
    console.log("kytyd", dataSearch.data.data);

    if (dataSearch.data.nodeCount === 0) {
      if (location !== "/tabs") {
        dispatch({ type: TAB_CLEAR });
      }
      const tabDetail = nodeDetailTab(
        dataSearch.data.nodeInfo.id,
        extractContent(data.displayName)
      );
      addTabHandler(tabDetail);
      navigate({
        pathname: "/tabs",
        search: `?key=${dataSearch.data.nodeInfo.id}`,
      });
      if (isSampleCard) {
        navigate(`/samplecard1/${nodeName}/${key}/${data?.id}`);
      } else if (isDatasetPage) {
        navigate(
          `/elements?selectedNodeName=${data?.displayName}&dbId=${data?.id}&nodeName=${nodeName}&nodeId=${key}&source=${source}`
        );
      }
    } else {
      //  handleClick(dataSearch.data.displayName);
      openIntermediatePage();
      //console.log("kytyd", dataSearch.data.nodeInfo.id);
      setGraphNodeData(dataSearch.data.data);
    }
  };

  const openGraph = (event) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const openIntermediatePage = () => {
    setIsInterMediagePageOpen(true);
  };

  const apiHandler = (url, event) => {
    event.stopPropagation();
    window.open(url, "_blank");
  };

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleSelectNodes = (nodeId) => {
    if (selectedNodes) {
      if (isSelectedNode) {
        const filteredNodes = selectedNodes.filter((node) => {
          if (isDataProductBuildPage) {
            return node.id !== nodeId;
          } else {
            return node !== nodeId;
          }
        });
        setSelectedNodes(filteredNodes);
      } else {
        let nodes;
        if (isDataProductBuildPage) {
          // we need all node details in data product page
          nodes = [...selectedNodes, data];
        } else {
          nodes = [...selectedNodes, nodeId];
        }
        setSelectedNodes(nodes);
      }
    }
    // sample card page logic
  //   if (isSampleCard) {
  //     navigate(`/samplecard1/${nodeName}/${key}/${data.id}`);
  //   } else if (isDatasetPage) {
  //     navigate(
  //       `/elements?selectedNodeName=${data?.displayName}&nodeName=${nodeName}&nodeId=${key}&source=${source}`
  //     );
  //   }
    
  // };
  if (isSampleCard) {
    navigate(`/samplecard1/${nodeName}/${key}/${idToUse}&source=${source}`);
  } else if (isDatasetPage) {
    navigate(
      `/elements?selectedNodeName=${data.displayName}&dbId=${idToUse}&nodeName=${nodeName}&nodeId=${key}&source=${source}`
    );
  }
};

  return (
    <>
      <div
        onClick={() => handleSelectNodes(data.id)}
        style={selectedNodeStyle}
        className="card filter-region-card"
      >
        <div className="card-body d-flex flex-column flex-lg-row flex-grow-1 justify-content-between">
          <div className="d-flex flex-column flex-lg-row flex-grow-1">
            <div className="left-side d-none d-lg-block">
              <div className="d-flex flex-column justify-content-between h-100">
                <FullNodeCardLogo Type={data.typeName} />
                <div className="certified-icon-wrapper text-center">
                  <div className="d-flex justify-content-center gap-1">
                    {data.certified === "true" ? (
                      <Fragment>
                        <img src={certified} alt="sertified icon" />
                        <img src={certified} alt="sertified icon" />
                        <img src={certified} alt="sertified icon" />
                      </Fragment>
                    ) : (
                      <img src={certified} alt="sertified icon" />
                    )}
                  </div>
                  <p className="m-0">Certified</p>
                </div>
                {/* <div className="d-flex justify-content-center gap-1">
                  <div className="certified-icon-wrapper text-center">
                    {data.certified === "true" ? (
                      <Fragment>
                        <FaRegistered style={{ color: "#14A35E" }} size={15} />
                        <FaRegistered style={{ color: "#14A35E" }} size={15} />
                        <FaRegistered style={{ color: "#14A35E" }} size={15} />
                      </Fragment>
                    ) : (
                      // <img src={certified} alt="sertified icon" />

                      <FaRegistered style={{ color: "#14A35E" }} size={15} />
                    )}
                  </div>
                  <span className="m-0">Registered</span>
                </div> */}

                {/* <CurvedProgressBar percentageValue={qualityScore} /> */}
                <MetaDataQuality metaDataQuality={data.metaQualityScore} />
              </div>
            </div>
            <div className="middle-side w-100 d-flex flex-column justify-content-between">
              <div className="d-flex d-lg-block">
                <div className="d-lg-none">
                  <FullNodeCardLogo Type={data.typeName} />
                </div>
                <div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={(e) => clickHandler(e)}
                  >
                    <OverflowTooltip
                      maxWidth={255}
                      title={extractContent(data.displayName)}
                      variant="h2"
                      currentNodes={currentNodes}
                    />
                  </div>
                  <div className="rate d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <Rating
                        className={classes.star}
                        value={+data.ratingsCount}
                        icon={<BsFillStarFill />}
                        emptyIcon={<BsStar />}
                        readOnly
                      />
                      <span className="rating-count">
                        ({data.ratingsCount})
                      </span>
                    </div>
                    <p className="mb-0 d-none d-lg-block">{data.status}</p>
                    <NodeLock
                      SecurityClassification={securityClassification}
                      PersonallyIdentifiableInformation={
                        personallyIdentifiableInformation
                      }
                      currentNodes={currentNodes}
                    />
                  </div>
                </div>
              </div>
              <Tooltip
                currentNodes={currentNodes}
                variant="subtitle2"
                maxHeight={84}
                maxWidth={310}
                fontSize={14}
                lineClamp={screenWidth > 992 ? 4 : 2}
                title={
                  data.description
                    ? extractContent(data.description)
                    : noDescription
                }
              />
              <div className="source d-none d-lg-flex align-items-center">
                {data.sourceSystem && data.sourceSystem !== "null" && (
                  <button
                    //disabled={!data.url}
                    className="source-btn text-truncate me-2"
                    onClick={(e) => apiHandler(data.url, e)}
                  >
                    {data.sourceSystem}
                  </button>
                )}
                <div className="d-flex">
                  <p className="me-2">Curated</p>
                  <p className="author">
                    By <span className="ms-1">{data.createdBy}</span>
                  </p>
                </div>
                {/* <div className="d-flex">
                  <p className="me-2" style={{ marginLeft: 70 }}>
                    Count:
                  </p>
                  <p className="author">
                    <span className="ms-1">
                      <span style={{ fontWeight: 800, fontSize: 15 }}>
                        {data.nodeCount}
                      </span>
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <div className="card-icons-wrapper">
            <div className="position-relative h-100">
              <div className="right-side h-100 d-flex justify-content-between align-items-end flex-lg-column">
                {data.shoppable === "true" ? (
                  <div
                    className="cart-icon-wraper"
                    onClick={(e) => showSidebar(e)}
                    style={{ width: 130, textAlign: "end" }}
                  >
                    <CardIconButton isInCart={isInCart} />
                  </div>
                ) : null}
                <div className="cart-icon-wraper">
                  <SharePopover
                    anchorEl={openSaveCollection}
                    setAnchorEl={setOpenSaveCollection}
                    iconWidth={screenWidth > 992 ? 142 : 34}
                    popoverButton={
                      <CardIconBtn
                        positionRelative="position-relative"
                        positionAbsolute="position-absolute"
                        data="saved"
                        toggleName="Add to Saved"
                      />
                    }
                  >
                    <SaveInCollection
                      setOpenSaveCollection={setOpenSaveCollection}
                      arangoNodeKey={data.id}
                      type={data.type}
                      displayName={data.displayName}
                    />
                  </SharePopover>
                </div>
                <div className="cart-icon-wraper">
                  <SharePopover
                    anchorEl={openShareContent}
                    setAnchorEl={setOpenShareContent}
                    iconWidth={screenWidth > 992 ? 120 : 34}
                    popoverButton={
                      <CardIconBtn
                        positionRelative="position-relative"
                        positionAbsolute="position-absolute"
                        data="shared"
                        toggleName="Share"
                      />
                    }
                  >
                    <Share setOpenShare={setOpenShareContent} data={data} />
                  </SharePopover>
                </div>
                <div>
                  <div
                    onClick={(e) => openGraph(e)}
                    style={screenWidth > 992 ? { width: 120 } : { width: 40 }}
                  >
                    <CardIconBtn
                      positionRelative="position-relative"
                      positionAbsolute="position-absolute"
                      data="graph"
                      toggleName="View Node"
                    />
                  </div>
                </div>
                <Box ml={1.5}>
                  <FavoriteBtn data={data} />
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomModal setOpen={setIsModalOpen} open={isModalOpen}>
        <NodeGraphWrapper setOpen={setIsModalOpen} node={data} />
      </CustomModal>
      <InterMediateModal
        setOpen={setIsInterMediagePageOpen}
        open={isInterMediagePageOpen}
        // data={data.dataSerach.nodeInfo.id}
        data={graphNodeData}
      >
        <ModalContent
          setOpen={setIsInterMediagePageOpen}
          data={graphNodeData}
        />
      </InterMediateModal>
      <CustomDrawer
        setState={setIsSidebarOpen}
        state={isSidebarOpen}
        anchor="right"
      >
        <CheckoutSidebar
          displayName={data?.displayName}
          setState={setIsSidebarOpen}
        />
      </CustomDrawer>
    </>
  );
};

export default FullNodeCard;
