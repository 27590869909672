import { TableCell, TableRow, withStyles } from "@material-ui/core";


export const StyledTableCell = withStyles({
    head: {
      backgroundColor: "#E0E7EC",
      color: '#123b64'
    },
})(TableCell)

export const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F6F8F9',
      },
    },
  }))(TableRow);

  export const StyledChildTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#F6F8F9',
      },
    },
  }))(TableRow);