import { IoMdArrowDropleftCircle, IoMdArrowDroprightCircle } from "react-icons/io";

export const NextArrow = (props) => {
  const { className, onClick } = props
  return (
    <div
      className={className}
      style={{
        color: "#123B64",
        borderRadius: "100px",
        right: -35,
        top: -12
      }}
      onClick={onClick}
    >
      <IoMdArrowDroprightCircle size={30} />
    </div>
  );
}

export const PrevArrow = (props) => {
  const { className, onClick } = props
  return (
    <div
      className={className}
      style={{
        color: "#123B64",
        borderRadius: "100px",
        left: -35,
        top: -12
      }}
      onClick={onClick}
    >
      <IoMdArrowDropleftCircle size={30} color="#123B64" />
    </div>
  );
}
