import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export const BlogTabs = withStyles({
    root: {
        borderBottom: '2px solid #C4C4C4',
    },
})(Tabs);

export const BlogTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 100,
        fontWeight: 500,
        fontSize: 22,
        lineHeight: 1,
        color:"#f79f19",
        opacity: 1,
        '&$selected': {
            color:"#ffff",
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#ffff',
            fontWeight: 500
        },
        [theme.breakpoints.down('768')]: {
          minWidth: 180,
          fontSize: 16
        }
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }