import { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomModal from "./CustomModal";
import { useState } from "react";
import GotoCreation from "./GotoCreation";
import TextAreaInput from "../../../../../shared/inputs/TextAreaInput";
import CustomizedInput from "../../../../../shared/inputs/CustomizedInput";
import { NODE_TYPE } from "../../../../../../pages/node-creating/nodeCreation";
import { YellowButton } from "../../../../../shared/buttons/custom-buttons/CustomButtons";
import DataContext from "../../../../../../contexts/Context";
import { NodeCreationService } from "../../../../../../services/NodeCreationService";
import { InfoIcon } from "../../../../../icons";
import { CustomTooltip } from "../../../../../shared/tooltip/NodeTooltip";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "568px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
  },
  form: {
    width: "100%",
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 30px",
    borderBottom: "2px solid #E0E7EC",
  },
  mainContentBox: {
    padding: "20px 30px 30px",
  },
  doneBtn: {
    width: "182px",
    height: "48px",
  },
  closeBtn: {
    color: "#123b64",
    padding: 0,
  },
  typography: {
    marginTop: 8,
  },
  addtooltip: {
    backgroundColor: "#fff", 
    color: "#123b64", 
    fontSize: "15px",
    boxShadow: '0px 4px 20px rgba(23, 87, 123, 0.2)', 
    padding: 10,
    borderRadius: 12
   
  },
}));

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("name is required"),
  description: yup
    .string("Enter your description")
    .required("Description is required"),
});

const initialValues = { name: "", description: "" };

function CreateNewRole({ handleClose, newNode }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [responseId, setResponseId] = useState("");
  const { loggedInUser } = useContext(DataContext);

  const onSubmit = (values) => {
    const nodeType =
      newNode === NODE_TYPE.dataSet ? NODE_TYPE.dataSet : NODE_TYPE.dataProduct;
    NodeCreationService.createNewNode(
      values.name,
      values.description,
      loggedInUser.userId,
      nodeType
    )
      .then((res) => {
        setShow(true);
        setResponseId(res.data.key);
      })
      .catch(() => console.log("error is occured!"));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, handleChange, values, isValid } = formik;

  return (
    <div className={classes.paper}>
      <CustomModal open={show} setOpen={setShow}>
        <GotoCreation
          newNode={newNode}
          handleClose={() => setShow(false)}
          id={responseId}
        />
      </CustomModal>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.titleBox}>
          <Typography variant="h5" color="primary">
            Create new Node
          </Typography>
          <IconButton
            onClick={handleClose}
            className={classes.closeBtn}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.mainContentBox}>

        <Box className={classes.labelWrapper}>
  <Box className={classes.formLabel} style={{ display: 'flex', alignItems: 'center' }}>
    <Typography style={{ marginRight: 7 }} variant="h5" color='primary'> New Node</Typography>
    <CustomTooltip
      bottomStart="bottom-start"
      title="Give a new name here "
      classes={{ tooltip: classes.addtooltip }}
    >
      <InfoIcon />
    </CustomTooltip>
  </Box>
</Box>

<Box mb={2.5}>
<CustomizedInput
              id="name"
              name="name"
              value={values.name}
              placeholderText="Data Public"
              onChange={handleChange}
              formik={formik}
            />
</Box>


          {/* <Box mb={2.5}>
            <CustomizedInput
              id="name"
              name="name"
              value={values.name}
              placeholderText="Data Public"
              onChange={handleChange}
              label="New Node"
              labelIcon={true}
              formik={formik}
            />
          </Box> */}

          {/* <Box mb={2.5}>
            <TextAreaInput
              id="description"
              name="description"
              value={values.description}
              label="Description"
              labelIcon={true}
              placeholderText="Enter text"
              onChange={handleChange}
              formik={formik}
            />
          </Box> */}




<Box className={classes.labelWrapper}>
  <Box className={classes.formLabel} style={{ display: 'flex', alignItems: 'center' }}>
    <Typography style={{ marginRight: 7 }} variant="h5" color='primary'> Description</Typography>
    <CustomTooltip
      bottomStart="bottom-start"
      title="you can add the description for the created node"
      classes={{ tooltip: classes.addtooltip }}
    >
      <InfoIcon />
    </CustomTooltip>
  </Box>
</Box>

<Box mb={2.5}>
  <TextAreaInput
   id="description"
   name="description"
    value={formik.values.Purpose}
    onChange={formik.handleChange}
    placeholderText="Enter text"
    formik={formik}
  />
</Box>


          <YellowButton
            disabled={!isValid}
            disableRipple
            className={classes.doneBtn}
            type="submit"
          >
            Go to Creation
          </YellowButton>
        </Box>
      </form>
    </div>
  );
}

export default CreateNewRole;
