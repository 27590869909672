import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import NodeTooltip from "../../../shared/tooltip/NodeTooltip";
import { HeadCells } from '../../../../constants/Jobs'
import axios from "axios";
import TableHeaderJob from "./table-details/TableHeaderjob";

// Material-UI styles definition using makeStyles hook
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.up("992")]: {
      marginBottom: 60,
    },
  },
  paper: {
    width: "100%",
    boxShadow: "unset",
    padding: 0,
    borderRadius: 0,
    [theme.breakpoints.up("768")]: {
      boxShadow: "0px 10px 20px rgba(23, 87, 123, 0.2)",
      padding: "20px 30px 40px",
    },
  },
  table: {
    minWidth: 750,
  },
  tableCell: {
    padding: "19px 10px",
    color: "#123b64",
    borderBottom: "1px solid #1f20201a",
  },
  cirleWrapper: {
    display: "flex",
    alignItems: "center",
  },
  circle: {
    width: 6,
    height: 6,
    background: "#418E84",
    borderRadius: "50%",
    marginRight: theme.spacing(0.5),
  },
  box: {
    width: 85,
  },
  url: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    maxHeight: 18,
    maxWidth: 170,
  },
}));

// Functional component definition for JobsTable
const JobsTable = ({
  expiredOrders,
  rows,
  currentRows,
  removeAllNodesFromOrder,
}) => {
  const classes = useStyles(); // Initializing Material-UI styles
  const [selected, setSelected] = React.useState([]); // State for selected items in the table
  const [data, setData] = useState(null); // State for storing fetched data (currently not used)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get('http://103.59.152.154:8089/jobHistory/load');
  //       setData(response.data);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, []);

   // Function to modify rows data to include a fileName extracted from URL
  const newRows = currentRows.map((item) => {
    let url = item.url;
    if (url) {
      let fileName = url?.split("/");
      fileName = fileName[fileName.length - 1];
      return {
        ...item,
        fileName: fileName,
      };
    } else {
      return {
        ...item,
      };
    }
  });

    // Event handler for selecting all items in the table
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((row) => row._key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

    // Extracting node information from rows
  const allNodes = newRows.map((node) => {
    return node.nodeInfo;
  });

    // Function to handle individual item selection
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

   // Function to check if an item is selected
  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
             {/* Rendering custom table header */}
            <TableHeaderJob
              allNodes={allNodes}
              expiredOrders={expiredOrders}
              headCells={HeadCells}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              removeAllNodesFromOrder={removeAllNodesFromOrder}
            />
            <TableBody>
              {/* Mapping over rows to render table rows */}
              {newRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                return (
                  <TableRow
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                  >
                    {/* Render table cells with row data */}
                    <TableCell className={classes.tableCell}>
                      {/* <CustomCheckbox
                        onClick={(event) => handleClick(event, row.id)}
                        checked={isItemSelected}
                      /> */}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.jobName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.runId}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.exitCode}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.exitMessage}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.status}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.userName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.startAt}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.endAt}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.updatedAt}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.createdAt}
                    </TableCell>
                    {/* Additional table cells commented out */}
                    <TableCell className={classes.tableCell}>
                      {/* <Box className={classes.cirleWrapper}>
                        <span className={classes.circle} />
                        {row.userName ? row.userName : ''}
                      </Box> */}
                    </TableCell>
                    <TableCell>
                       {/* Render cancel button with a tooltip */}
                      <NodeTooltip name="icon-button" placement="bottom-end" title="Cancel">
                        <IconButton>
                          {/* <CancelOutlinedIcon /> */}
                        </IconButton>
                      </NodeTooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
export default JobsTable;
