import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper, Box, IconButton } from '@material-ui/core';
import KebabMenu from '../../../../shared/popover/KebabMenu';
import clsx from 'clsx'
import MuiAvatarGroup from './MuiAvatarGroup';
import CollapseTooltip from './CollapseTooltip';
import CustomModal from '../../../../shared/modal/CustomModal';
import DataExport from '../../data-export/DataExport';
import { TeamService } from '../../../../../services/TeamService';
import { ExportIcon, ImportIcon, ThreeDotsIcon, TrashBinIcon, UnderScoreEditIcon } from '../../../../icons';
// Custom styles using Material-UI's makeStyles hook
const useStyles = makeStyles({
    container: {
        background: '#c4c4c41a',
        borderRadius: 8,
        boxShadow: 'none',
        padding: '13px 16px 20px'
    },
    table: {
        minWidth: 650,
    },
    headerTitle: {
        color: '#123b64',
        lineHeight: '140%',
        padding: '10px 0',
        border: 'none'
    },
    tableCell: {
        color: '#123b64c2',
        lineHeight: '140%',
        padding: '10px 0 8px',
        background: '#E0E7EC',
    },
    kebabMenuContent: {
        width: 123,
        display: 'flex',
        flexDirection: 'column',
        background: '#fff',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 10,
        padding: 12,
        zIndex: 999,
        marginRight: 'auto'
    },
    exportFile: {
        textAlign: 'start',
        cursor: 'pointer',
        '&:first-child': {
            marginBottom: 10
        }
    },
    iconButton: {
        padding: 0,
        '& svg': {
            width: 15,
            height: 15
        }
    }
});

const SimpleTable = (props) => {
    // Extracting necessary props and initializing state variables
    const { deleteTeam, handleEditTeam, teamGroup, setState, isReadOnly } = props
    const classes = useStyles();
    const columns = Object.keys(teamGroup)
    const [openImportContent, setOpenImportContent] = useState(false)
    const [openExportContent, setOpenExportContent] = useState(false)

    const openSidebar = () => {
        handleEditTeam()
        setState(true)
    }

    // Function to download a file
    const downloadFile = () => {
        TeamService.getSimpleTeamFile()
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.xlsx');
                document.body.appendChild(link);
                link.click();
                setOpenImportContent(false)
            })
    }

     // Function to upload a file
    const uploadFile = (event) => {
        let data = new FormData()
        data.append('file', event.target.files[0])
        TeamService.uploadSimpleTeamFile(data)
            .then(res => {
                setOpenExportContent(false)
            })
    }

    return (
        <div>
            <CustomModal setOpen={setOpenImportContent} open={openImportContent}>
                <DataExport downloadFile={downloadFile} type="import" setOpen={setOpenImportContent} />
            </CustomModal>
            <CustomModal setOpen={setOpenExportContent} open={openExportContent}>
                <DataExport type="export" setOpen={setOpenExportContent} uploadFile={uploadFile} />
            </CustomModal>
            <TableContainer className={classes.container} component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headerTitle}>Roles</TableCell>
                            {
                                columns.map((column, index) => {
                                    return (
                                        <TableCell key={index} className={classes.headerTitle}>
                                            {column}
                                        </TableCell>
                                    )
                                })
                            }
                            {
                                !isReadOnly ?
                                    <TableCell className={classes.headerTitle} colSpan="2" align='right'>
                                        <KebabMenu marginLeft="auto" button={<ThreeDotsIcon />}>
                                            <Box className={classes.kebabMenuContent}>
                                                <div onClick={() => setOpenExportContent(true)} className={classes.exportFile}>
                                                    <span style={{ marginRight: 10 }}><ExportIcon /></span>
                                                    <span>Export file</span>
                                                </div>
                                                <div onClick={() => setOpenImportContent(true)} className={classes.exportFile}>
                                                    <span style={{ marginRight: 10 }}><ImportIcon /></span>
                                                    <span>Import file</span>
                                                </div>
                                            </Box>
                                        </KebabMenu>
                                    </TableCell> : null
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.tableCell} />
                            {
                                columns.map((column, index) => {
                                    return (
                                        <TableCell key={index} className={clsx(classes.tableCell, classes.rolesCell)}>
                                            <CollapseTooltip data={teamGroup[column]}>
                                                <MuiAvatarGroup table="simple" avatars={teamGroup[column]} />
                                            </CollapseTooltip>
                                        </TableCell>
                                    )
                                })
                            }
                            {
                                !isReadOnly ?
                                    <>
                                        <TableCell className={classes.tableCell} style={{ textAlign: 'right' }}>
                                            <IconButton onClick={openSidebar} disableRipple style={{ padding: 0 }}>
                                                <UnderScoreEditIcon />
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ textAlign: 'right', paddingRight: 8, width: 50 }}>
                                            <IconButton onClick={() => deleteTeam()} disableRipple className={classes.iconButton}>
                                                <TrashBinIcon />
                                            </IconButton>
                                        </TableCell>
                                    </> : null
                            }
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
export default SimpleTable
