import { ThemeProvider } from "@material-ui/core";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import AppRoutes from "./AppRoutes";
import ErrorBoundary from "./components/error-boundry/ErrorBoundary";
import { defaultTheme } from "./muiCustomStyles";


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {

  return (
    <ThemeProvider theme={defaultTheme}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
