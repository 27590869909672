import NodeRegistration from "../../components/navbar/Dataproductpages/node-registration/NodeRegistration";

function DataProductRegistration() {
  const details = {
    header: "Build your DataProduct",
    title: "1. DataProduct creation",
    nodeType: 'Data Product'
  }

  return <NodeRegistration details={details} />
}

export default DataProductRegistration;
