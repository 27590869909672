import React from "react";
import "./curved-progress-bar.scss";
import FilterButton from "./../../filter-region/FilterButton";

const CurvedProgressBar = ({ percentageValue, barStyle }) => {
  const setQualityScoreColor = () => {
    if (percentageValue < 50) {
      return "red-pr-bar";
    } else if (50 < percentageValue && percentageValue <= 80) {
      return "yellow-pr-bar";
    } else {
      return "green-pr-bar";
    }
  };

  return (
    <div
      className={`d-flex justify-content-center align-items-center percenage-icon-wrapper text-center ${barStyle}`}
    >
      <div
        className={`curved-progress-bar ${setQualityScoreColor()}`}
        style={{ "--percentage": percentageValue }}
      ></div>
      <FilterButton popperName="Data quality">
                <p className="text-center">{percentageValue}%</p>
            </FilterButton>
    </div>
  );
};

export default CurvedProgressBar;
