import axios from "axios";
import api from "../core/api";

const apiUrl = process.env.REACT_APP_API_URL;

export const TeamService = {
  delete: (id) => {
    return axios.delete(`${apiUrl}/deleteSimpleTeam?teamId=${id}`);
  },
  edit: (id) => {
    return axios.get(`${apiUrl}/editTeamDetails?teamId=${id}`);
  },
  deleteContext: (context, id) => {
    return axios.post(
      `${apiUrl}/removeContext?teamId=${id}&context=${context}`
    );
  },
  editContext: (context, id) => {
    return axios.get(`${apiUrl}/editContext?teamId=${id}&context=${context}`);
  },
  fetchTeamOptions: () => {
    return axios.get(`${apiUrl}/Teams`);
  },
  fetchMatrixTeam: (id) => {
    return axios.get(`${apiUrl}/getMatrixTeamDetails`, {
      params: { teamId: id },
    });
  },
  fetchSimpleTeam: (id) => {
    return axios.get(`${apiUrl}/getTeamDetails`, {
      params: { teamId: id },
    });
  },
  fetchRoles: () => {
    return axios.get(`${apiUrl}/Roles`);
  },
  fetchUsers: () => {
    return axios.get(`${apiUrl}/getAllregisterUserDetails`);
  },
  createSimpleTeam: (team) => {
    return axios.post(`${apiUrl}/addRole`, team);
  },
  updateSimpleTeam: (team) => {
    return axios.post(`${apiUrl}/updateSimpleTeam`, team);
  },
  fetchBusiness1: (name) => {
   
    return axios.get(`${apiUrl}/getCategoryRecommendations?domainName=${name}&refreshChoices=true
    `);

  },
  fetchBusiness: () => {
    return axios.get(`${apiUrl}/business`);
  },
  fetchDataDomain: () => {
    return axios.get(`${apiUrl}/dataDomain`);
  },
  fetchGeography: () => {
    return axios.get(`${apiUrl}/geography`);
  },
  fetchProducts: () => {
    return axios.get(`${apiUrl}/product`);
  },
  uploadSimpleTeamFile: (data) => {
    return axios.post(`${apiUrl}/uploadSimpleFile`, data);
  },
  getSimpleTeamFile: () => {
    return axios.get(`${apiUrl}/downloadSimpleFile`, {
      responseType: "blob",
    });
  },
  getMatrixTeamFile: () => {
    return axios.get(`${apiUrl}/downloadMatrixFile`, {
      responseType: "blob",
    });
  },
  uploadMatrixTeamFile: (data) => {
    return axios.post(`${apiUrl}/uploadMatrixFile`, data);
  },
  createMatrixTeam: (data) => {
    return api.post(`/addCategories`, data);
  },
  addContext: (teamId, data) => {
    return api.post(`/addingNewContextToMatrixTeam?teamId=${teamId}`, data);
  },
  updateMatrixTeam: (data) => {
    return api.post("/updateMatrixTeam", data);
  },

  fetchMyTeam: (loginid) => {
    return api.get(`/myteams`, {
      params: { loginid: loginid },
    });
  },

  fetchAllTeams: () => {
    return api.get(`api/pin/allTeams`);
  },
  fetchMatrixTeamTable: (key) => {
    return api.get(`getMatrixTeamDetails?teamId=${key}`);
  },
  fetchSimpleTeamTable: (key) => {
    return api.get(`getTeamDetails?teamId=${key}`);
  },
};
