import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import SaveCollection from '../../save-in-collection/SaveCollection'
import DeleteCollection from '../../save-in-collection/DeleteCollection'
import { useNavigate, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
}));

function CollectionModal({ open, setOpen, type, collection }) {
    const classes = useStyles()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const handleClose = () => {
        setOpen(false);
    };

    const goCollections = () => {
        setOpen(false)
        if(pathname === '/my-collections/create-collection') {
            navigate(`/my-collections`)
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 300,
                }}
            >
                <Fade in={open}>
                    {
                        type === "saveNodeToColl" ?
                            <SaveCollection collection={collection} handleClose={goCollections} /> :
                            <DeleteCollection handleClose={() => setOpen(false)} />
                    }
                </Fade>
            </Modal>
        </div>
    );
}

export default CollectionModal;