import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import NodeLock from '../../shared/node-lock-icon/NodeLock';
import ShortNodeCardLogo from "../../shared/short-node-card/short-node-card-logo/ShortNodeCardLogo";
import OverflowTip from '../../shared/tooltip/OverflowTooltip';
import Tooltip from '../../shared/tooltip/Tooltip';
import certified from './../../../assets/images/card-icons/sertification.png';

// Defining styles using makeStyles hook from Material-UI
const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: '#123b64',
        marginRight: 17,
    },
    paragrapg: { // Typo in 'paragrapg', should be 'paragraph'
        display: '-webkit-box',
        maxHeight: 40,
        overflow: 'hidden',
        '-webkit-line-clamp': '2',
        '-webkit-box-orient': 'vertical',
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold',
        lineHeight: 1.5,
        marginRight: 11
    }
})

// Defining the functional component Node
function Node({ yourOrder, node }) {
    const classes = useStyles(); // Initializing styles using the defined useStyles hook
  
    // Function to extract content from HTML
    const extractContent = (s) => {
        var span = document.createElement('span');
        span.innerHTML = s;
        return span.textContent || span.innerText;
    };

    return (
        <Box className={classes.root}> {/* Root container with custom styles */}
            <Box mr={1.75}> {/* Box for ShortNodeCardLogo */}
                <ShortNodeCardLogo Type={node?.type} /> {/* ShortNodeCardLogo component */}
            </Box>
            <Box> {/* Container for title, icon, and lock */}
                <Box className={classes.titleWrapper}> {/* Container for title, icon, and lock */}
                    <Box mr={1}> {/* Box for OverflowTip */}
                        <OverflowTip maxWidth={200} title={node?.displayName} variant="h5" /> {/* OverflowTip component */}
                    </Box>
                    <img style={{ marginRight: 15 }} src={certified} alt="sertified icon" /> {/* Certified icon */}
                    <NodeLock // NodeLock component with security props
                        SecurityClassification={node?.securityClassification}
                        PersonallyIdentifiableInformation={node?.personallyIdentifiableInformation}
                    />
                </Box>
                <div style={yourOrder ? { width: 200 } : { width: '100%' }}> {/* Container for node description */}
                    {
                        node?.description && // Rendering Tooltip only if node description exists
                        <Tooltip
                            variant="subtitle2"
                            maxHeight={36}
                            maxWidth={200}
                            lineClamp={2}
                            fontSize={12}
                            title={extractContent(node.description)} // Extracting content for Tooltip
                        />
                    }
                </div>
            </Box>
        </Box>
    );
}
export default Node;

// ********* you may use belove *********
/* <InnerHtmlElement className="card-text" element="node-description" value={node.Description} />. */ 