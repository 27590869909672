import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import drawChart from './DrawChart';

const useStyles = makeStyles({
    root: {
        position: 'relative'
    },
    textBox: {
        position: 'absolute',
        top: '50%',
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    processPercentage: {
        color: '#23283C'
    },
    progress: {
        color: '#6D7287'
    }
})

function DonutChart() {
    const classes = useStyles()
    const donutRef = useRef(null)
    const data = [
        { value: 50 },
        { value: 30 },
        { value: 20 },
    ]

    useEffect(() => {
        drawChart(donutRef.current, data)
    }, [])

    return (
        <Box className={classes.root} ref={donutRef}>
            <Box className={classes.textBox}>
                <Typography align='center' variant='h3' className={classes.processPercentage}>12%</Typography>
                <Typography variant='body2' className={classes.progress}>Progress</Typography>
            </Box>
        </Box>
    );
}

export default DonutChart;