import { createTheme } from "@material-ui/core";

export const defaultTheme = createTheme({
    palette: {
      primary: {
        main: "#123b64",
        secondary: '#011936'
      },
      secondary: {
        main: "#F79F19",
      },
      error: {
        main: "#63ADA6",
      },
      info: {
        main: "#123b64",
        dark: "#ff0000de"
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 578,
        md: 992,
        lg: 1280,
        xl: 1920,
      },
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: 40,
          fontWeight: 900,
          lineHeight: '140%',
        },
        h2: {
          fontSize: 28,
          fontWeight: 700,
          lineHeight: '150%'
        },
        h3: {
          fontSize: 22,
          fontWeight: 700,
          lineHeight: "150%"
        },
        h4: {
          fontSize: 18,
          fontWeight: 700,
          lineHeight: '150%'
        },
        h5: {
          fontSize: 16,
          fontWeight: 700,
          lineHeight: '150%'
        },
        h6: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: '150%'
        },
        subtitle1: {
          fontSize: 18,
          fontWeight: 500,
          lineHeight: '150%'
        },
        subtitle2: {
          fontSize: 12,
          lineHeight: '150%',
          fontWeight: 400
        },
        body1: {
          fontSize: 16,
          fontWeight: 500
        },
        body2: {
          fontSize: [14, "!important"],
          fontWeight: 500,
          lineHeight: '140%',
        }
      }
    },
  })
