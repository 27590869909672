import { useOktaAuth } from "@okta/okta-react";
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AuthWrapper.scss";
import { useIdleTimer } from 'react-idle-timer'
import IdlePromptDialog from "../../components/idle-prompt/IdlePromptDialog"
import DataContext from "../../contexts/Context";

export const AuthWrapper = (props) => {
    const navigate = useNavigate()
    const { oktaAuth } = useOktaAuth();
    const [token, setToken] = useState()
    const [propmtOpen, setPropmtOpen] = useState(false)
    const { setLoggedInUser } = useContext(DataContext)
    const sessionTimeoutRef = useRef(null)
    const { pathname } = useLocation()
    const timeOut = 1200000

    useEffect(() => {
        const { authStateManager } = oktaAuth
        const handleAuthentication = () => {
            authStateManager.subscribe((state) => {
                const user = localStorage.getItem('logged-in-user')
                if (!user && !state?.isAuthenticated) {
                    navigate("/sign-in");
                } else if (state && state.accessToken) {
                    localStorage.setItem("logged-in-user", JSON.stringify({
                        userId: state.accessToken.claims.uid,
                        email: state.accessToken.claims.sub
                    }))
                    handleSession(state.accessToken.claims?.sub)
                }
            })
        }

        const handleSession = async () => {
            if (token) {
                oktaAuth.token.revoke(token)
            }
        }

        handleAuthentication()

        handleSession()

        return () => {
            oktaAuth.authStateManager.unsubscribe()
        }
    }, [navigate, oktaAuth]);

    useEffect(() => {
        if (pathname === '/sign-in' || pathname === "sign-on") {
            pause()
        } else {
            reset()
        }

    }, [pathname])

    const onIdle = () => {
        setPropmtOpen(true)
        sessionTimeoutRef.current = setTimeout(handleLogout, 300000)
    }

    const handlePropmtClose = () => {
        setPropmtOpen(false)
        clearTimeout(sessionTimeoutRef.current);
        reset()
    }

    const handleLogout = () => {
        navigate('/sign-in')
        localStorage.removeItem('logged-in-user')
        setLoggedInUser('')
        setPropmtOpen(false)
        clearTimeout(sessionTimeoutRef.current);
        pause()
        // await signOut()
    }

    const { getLastActiveTime, getElapsedTime, pause, reset } = useIdleTimer({
        onIdle,
        timeout: timeOut,
        events: [
            'mousemove',
            'keydown',
            'wheel',
            'DOMMouseScroll',
            'mousewheel',
            'mousedown',
            'touchstart',
            'touchmove',
            'MSPointerDown',
            'MSPointerMove',
            'visibilitychange'
        ],
        immediateEvents: [],
        debounce: 0,
        throttle: 0,
        eventsThrottle: 200,
        element: document,
        startOnMount: true,
        startManually: false,
        stopOnIdle: false,
        crossTab: false,
        name: 'idle-timer',
        syncTimers: 0,
        leaderElection: false
    })

    return (
        <>
            <div>
                {props.children}
            </div>
            <IdlePromptDialog
                open={propmtOpen}
                onClose={handlePropmtClose}
                onLogout={handleLogout}
                timeOut={timeOut}
            />
        </>
    );
};
