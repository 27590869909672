import { Container, Typography, makeStyles, Box } from '@material-ui/core';
import Breadcrumbs from '../../components/shared/breadcrumb/Breadcrumbs';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
    container: {
        minHeight: 'calc(100vh - 150px)',
        paddingBottom: 100,
        paddingTop: 40
    },
    title: {
        margin: "50px 0 30px"
    },
    date: {
        color: '#acacac',
        lineHeight: '20px'
    },
    dataNsw: {
        fontWeight: 700,
        fontSize: 17,
        lineHeight: '21px'
    },
    typographyWrapper: {
        marginBottom: 40,
        '& p': {
            fontWeight: 400,
            lineHeight: 1.5
        }
    },
    title2: {
        fontSize: 24,
        fontWeight: 700,
        lineHeight: 1.25
    },
    list: {
        marginTop: 16,
        paddingLeft: 24,
    },
    listItem: {
        marginBottom: 8,
        '& span': {
            marginLeft: 4,
            fontWeight: 400
        }
    },
    alert: {
        borderLeft: '6px solid #123b64',
        background: '#f4f4f7',
        padding: '24px 24px 24px 16px'
    }
})

function BlogDetails() {
    const classes = useStyles()
    const { name } = useParams()
    return (
        <Container className={classes.container}>
            <Breadcrumbs />
            <Typography className={classes.title} variant='h1' color='primary'>{name}</Typography>
            <Box sx={{ borderBottom: '1px solid #ccc' }} py={2} mb={3}>
                <Typography className={classes.date}>09 June 2023</Typography>
                <Typography className={classes.dataNsw} color='primary'>Data.NSW</Typography>
            </Box>
            <Box className={classes.typographyWrapper}>
                <Typography color='primary'>
                    The NSW Government Data Strategy, which forms Phase 2 of the NSW Data Reform, is now live.
                </Typography>
                <Typography style={{ margin: '16px 0' }} color='primary'>
                    The Strategy, developed by the NSW Data Analytics Centre (DAC) in close consultation with state and federal government stakeholders and academics,
                    establishes the first whole-of-government approach to managing, using and sharing data and insights.
                </Typography>
                <Typography color="primary">
                    his ensures that the NSW Government can achieve the best possible outcomes for the people and businesses of NSW.
                </Typography>
            </Box>
            <Box mb={5}>
                <Typography className={classes.title2} color='primary'>
                    What is the NSW Data Reform?
                </Typography>
                <Typography>
                    The NSW Government is embarking on a three-phase data reform program
                    to uplift capability and advance data services across the sector.
                </Typography>
                <ul className={classes.list}>
                    <li className={classes.listItem}>
                        <strong>Phase 1</strong>
                        <Typography component="span">
                            focused on embedding the data practices that delivered valuable data and insights during COVID-19.
                            The NSW Data Leadership Group (NDLG), made up of Senior NSW Government executives appointed as
                            Cluster Chief Data Officers (CCDOs), has been established to drive cross-sector collaboration.
                        </Typography>
                    </li>
                    <li className={classes.listItem}>
                        <strong>Phase 2</strong>
                        <Typography component="span">
                            involved the development of this Strategy.
                        </Typography>
                    </li>
                    <li className={classes.listItem}>
                        <strong>Phase 3</strong>
                        <Typography component="span">
                            is now underway and includes a statutory review of the Data Sharing (Government Sector) Act.
                        </Typography>
                    </li>
                </ul>
            </Box>
            <Box mb={5}>
                <Typography className={classes.title2} color='primary'>
                    What is the NSW Government Data Strategy and why is it important?
                </Typography>
                <Typography style={{ marginBottom: 16 }}>
                    Using and sharing data allows NSW Government to improve our understanding of our customers,
                    make better decisions, and enhance services, policies and programs.
                </Typography>
                <Typography style={{ marginBottom: 16 }}>
                    The NSW Government Data Strategy sets a vision for NSW Government to deliver better outcomes
                    for the community by putting data at the heart of decision-making through a collaborative,
                    coordinated, consistent and safe approach to using and sharing data.
                    The Strategy also ensures that we maintain the highest privacy, security and ethical standards.
                </Typography>
                <Typography>
                    The Strategy includes four complementary themes:
                </Typography>
                <ul className={classes.list}>
                    <li>Accelerating actionable insights</li>
                    <li>Treating data as an asset</li>
                    <li>Strengthening transparency and trust</li>
                    <li>Fostering culture, leadership and capability</li>
                </ul>
                <Typography style={{ marginBottom: 16 }}>
                    Each theme is supported by a set of principles and sector-wide actions.
                </Typography>
                <Typography>
                    NSW Government clusters will each develop data roadmaps to support
                    consistent and coordinated implementation of the Strategy across the entire sector.
                </Typography>
            </Box>
            <Box mb={5}>
                <Typography className={classes.title2} color='primary'>
                    What happens next?
                </Typography>
                <Typography>
                    The NSW Government Data Strategy will be reviewed and updated every 12 months and we'll continue to add new case studies to
                    highlight how the themes are reflected in the work of different NSW Government agencies.
                </Typography>
            </Box>
            <Box className={classes.alert}>
                <Typography className={classes.title2}>
                    Feedback and questions
                </Typography>
                <Typography style={{marginTop: 12}}>
                    If you have any questions about the NSW Government Data Strategy, or feedback or case studies you would like to share,
                    please contact us at datansw@customerservice.nsw.gov.au.
                </Typography>
            </Box>
        </Container>
    );
}

export default BlogDetails;