// import React, { useContext } from 'react';
// import { SearchService } from '../../../services/SearchService';
// import certified from '../../../assets/images/card-icons/sertification.png';
// import ShortNodeCardLogo from './short-node-card-logo/ShortNodeCardLogo';
// import './short-node-card.scss';
// import DataContext from '../../../contexts/Context';
// import { useNavigate } from 'react-router-dom';
// import OverflowTip from '../tooltip/OverflowTooltip';
// import Tooltip from '../tooltip/Tooltip';
// import TabContext from '../../../contexts/TabContext';

// const ShortNodeCard = ({ data, selectedNodes, setSelectedNodes, currentNodes }) => {
//     const { setrecent } = useContext(DataContext)
//     const { addTabHandler } = useContext(TabContext)
//     const noDescription = "No description"
//     const navigate = useNavigate()
//     const isSelected = selectedNodes && selectedNodes.some((id) => id === data.id);

//     const clickHandler = () => {
//         SearchService.fetchrecent().then(res => {
//             setrecent(res.data)
//         })
//         addTabHandler(tabDetail)
//         navigate('/tabs')
//     }

//     const extractContent = (text) => {
//         var span = document.createElement('span');
//         span.innerHTML = text;
//         return span.textContent || span.innerText;
//     }

//     const tabDetail = {
//         type: 'node-viewer',
//         title: extractContent(data.displayName),
//         id: data.id,
//         reqTime: 0,
//         loading: true,
//         data: []
//     }

//     const handleSelectNodes = (nodeId) => {
//         if (selectedNodes) {
//             const isNodeSelected = selectedNodes.some((id) => id === nodeId);
//             let copySelectedNodes = [...selectedNodes];
//             if (isNodeSelected) {
//                 copySelectedNodes = copySelectedNodes.filter((id) => id !== nodeId);
//                 setSelectedNodes(copySelectedNodes);
//             } else {
//                 copySelectedNodes.push(nodeId);
//                 setSelectedNodes(copySelectedNodes);
//             }
//         }
//     };

//     return (
//         <div
//             onClick={() => handleSelectNodes(data.id)}
//             style={isSelected ? { border: "1px solid #f79f19" } : { border: "none" }}
//             id={data.id}
//             className="card saved-filter-card"
//         >
//             <div className="card-body d-flex align-items-center">
//                 <div style={{ marginRight: 16 }}>
//                     <ShortNodeCardLogo Type={data.type} />
//                 </div>
//                 <div className="right-side">
//                     <div className="d-flex align-items-center certified-icon-wrapper w-100">
//                         <div style={{ cursor: 'pointer', width: '100%', maxWidth: 'max-content' }} onClick={clickHandler}>
//                             <OverflowTip maxWidth={450} title={data.displayName} variant="h5" />
//                         </div>
//                         <img src={certified} alt="sertified icon" />
//                     </div>
//                     <div className='w-100'>
//                         <Tooltip
//                             currentNodes={currentNodes}
//                             variant="subtitle2"
//                             maxHeight={36}
//                             maxWidth={450}
//                             lineClamp={2}
//                             fontSize={12}
//                             title={data.description ? extractContent(data.description) : noDescription}
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>

//     )
// }

// export default ShortNodeCard;
import { Box, makeStyles } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { BsFillStarFill, BsStar } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import certified from "../../../assets/images/card-icons/sertification.png";
import { nodeDetailTab } from "../../../constants/tab";
import DataContext from "../../../contexts/Context";
import TabContext from "../../../contexts/TabContext";
import { TAB_CLEAR } from "../../../state/constants/tab";

import Tooltip from "../tooltip/Tooltip";
import OverflowTip from "../tooltip/OverflowTooltip";

import { SearchService } from "../../../services/SearchService";
import InterMediateModal from "../../../components/shared/modal/CustomModal";
import ShortNodeCardLogo from "./short-node-card-logo/ShortNodeCardLogo";
import ModalContent from "../full-node-card/graph-node/ModalContent";

const useStyles = makeStyles({
  controls: {
    display: "flex",
    justifyContent: "end",
    backgroundColor: "white",
  },
  closeBtn: {
    border: "none",
    backgroundColor: "white",
  },
  star: {
    "& svg": {
      width: 12,
      height: 12,
    },
  },
});

const ShortNodeCard = ({
  data,
  selectedNodes,
  setSelectedNodes,
  currentNodes,
}) => {
  // console.log("mnbv", data);
  const {
    securityClassification,
    personallyIdentifiableInformation,
    type,
    qualityScore,
  } = data;

  const { addToCart, cart, setCart } = useContext(DataContext);
  const { addTabHandler, dispatch } = useContext(TabContext);
  const classes = useStyles();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [openSaveCollection, setOpenSaveCollection] = useState(null);
  const [openShareContent, setOpenShareContent] = useState(null);
  const [selectedNodeStyle, setSelectedNodeStyle] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInterMediagePageOpen, setIsInterMediagePageOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { pathname: location } = useLocation();
  const isDataProductBuildPage = location.includes("samplecard1");
  const isDatasetPage = location.includes("data_set_samplecard");
  const isSampleCard = location.includes("data_product_samplecard");

  const noDescription = "No description";
  const [isInCart, setInCart] = useState(false);
  const [graphNodeData, setGraphNodeData] = useState();
  const isSelected =
    selectedNodes && selectedNodes.some((id) => id === data.id);
  const { key, nodeName } = useParams();

  const isSelectedNode =
    selectedNodes &&
    selectedNodes.find((node) => {
      if (isDataProductBuildPage) {
        return node.id === data.id;
      } else {
        return node === data.id;
      }
    });

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  useEffect(() => {
    const isExist = cart.find((item) => item.id === data.id);
    setInCart(isExist);
  }, [cart, data.id]);

  useEffect(() => {
    if (isSelectedNode && isDataProductBuildPage) {
      setSelectedNodeStyle({
        border: "1px solid #123b64",
        backgroundColor: "#dee6eb",
      });
    } else if (isSelectedNode && !isDataProductBuildPage) {
      setSelectedNodeStyle({ border: "1px solid #f79f19" });
    } else {
      setSelectedNodeStyle({ border: "none" });
    }
  }, [isSelectedNode]);

  const extractContent = (s) => {
    var span = document.createElement("span");
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const showSidebar = (event) => {
    event.stopPropagation();
    let cartCopy = [...cart];

    if (isInCart) {
      alert("This node is already added to cart");
    } else {
      cartCopy.push(data);
      setCart(cartCopy);
      addToCart(data);
      setIsSidebarOpen(true);
    }
  };

  const clickHandler = async (event) => {
    event.stopPropagation();
    const dataSearch = await SearchService.setTailNodeHistory(data.id, null);

    console.log("kytyd", dataSearch.data.data);

    if (dataSearch.data.nodeCount === 0) {
      if (location !== "/tabs") {
        dispatch({ type: TAB_CLEAR });
      }
      const tabDetail = nodeDetailTab(
        dataSearch.data.nodeInfo.id,
        extractContent(data.displayName)
      );
      addTabHandler(tabDetail);
      navigate({
        pathname: "/tabs",
        search: `?key=${dataSearch.data.nodeInfo.id}`,
      });
    } else {
      //  handleClick(dataSearch.data.displayName);
      openIntermediatePage();
      //console.log("kytyd", dataSearch.data.nodeInfo.id);
      setGraphNodeData(dataSearch.data.data);
    }
  };

  const openGraph = (event) => {
    event.stopPropagation();
    setIsModalOpen(true);
  };

  const openIntermediatePage = () => {
    setIsInterMediagePageOpen(true);
  };

  const apiHandler = (url, event) => {
    event.stopPropagation();
    window.open(url, "_blank");
  };

  const updateDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  const handleSelectNodes = (nodeId) => {
    if (selectedNodes) {
      if (isSelectedNode) {
        const filteredNodes = selectedNodes.filter((node) => {
          if (isDataProductBuildPage) {
            return node.id !== nodeId;
          } else {
            return node !== nodeId;
          }
        });
        setSelectedNodes(filteredNodes);
      } else {
        let nodes;
        if (isDataProductBuildPage) {
          // we need all node details in data product page
          nodes = [...selectedNodes, data];
        } else {
          nodes = [...selectedNodes, nodeId];
        }
        setSelectedNodes(nodes);
      }
    }
    // sample card page logic
    if (isSampleCard) {
      navigate(`/samplecard1/${nodeName}/${key}/${data.id}`);
    } else if (isDatasetPage) {
      navigate(
        `/elements?selectedNodeName=${data?.displayName}&nodeName=${nodeName}&nodeId=${key}`
      );
    }
  };

  return (
    <div
      onClick={() => handleSelectNodes(data.id)}
      style={isSelected ? { border: "1px solid #f79f19" } : { border: "none" }}
      id={data.id}
      className="card saved-filter-card"
    >
      <div className="card-body d-flex align-items-center">
        <div style={{ marginRight: 16 }}>
          <ShortNodeCardLogo Type={data.type} />
        </div>
        <div className="right-side">
          <div className="d-flex align-items-center certified-icon-wrapper w-100">
            <div
              style={{
                cursor: "pointer",
                width: "100%",
                maxWidth: "max-content",
              }}
              onClick={clickHandler}
            >
              <OverflowTip
                maxWidth={450}
                title={data.displayName}
                variant="h5"
              />
            </div>
            <img src={certified} alt="sertified icon" />
          </div>
          <div className="w-100">
            <Tooltip
              currentNodes={currentNodes}
              variant="subtitle2"
              maxHeight={36}
              maxWidth={450}
              lineClamp={2}
              fontSize={12}
              title={
                data.description
                  ? extractContent(data.description)
                  : noDescription
              }
            />
          </div>
          <InterMediateModal
            setOpen={setIsInterMediagePageOpen}
            open={isInterMediagePageOpen}
            // data={data.dataSerach.nodeInfo.id}
            data={graphNodeData}
          >
            <ModalContent
              setOpen={setIsInterMediagePageOpen}
              data={graphNodeData}
            />
          </InterMediateModal>
        </div>
      </div>
    </div>
  );
};

export default ShortNodeCard;
