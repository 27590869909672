import { Box, makeStyles, Button, FormControlLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CustomCheckbox } from '../../../shared/inputs/CheckBox';
import NodeTooltip from '../../../shared/tooltip/NodeTooltip';
import Node from '../../../cart/description-node/Node';
import { TrashBinIcon } from '../../../icons';

const useStyles = makeStyles({
    header: {
        padding: '11px 0',
        marginBottom: 10,
        borderBottom: '1px solid #E0E7EC',
        display: 'flex', justifyContent: 'space-between'
    },
    all: {
        margin: 0,
        marginRight: 16,
        '& .MuiFormControlLabel-label': {
            fontSize: 14,
            fontWeight: 'bold',
            marginLeft: 7
        },
    },
    options: {
        fontSize: 14,
        fontWeight: '700',
        lineHeight: '22px'
    },
    button: {
        background: '#fff',
        minWidth: 36,
        marginLeft: 10,
        '& svg': {
            color: '#123b64'
        }
    },
})

function YourSelectedNodeList({ nodes, setSelectedNodes }) {
    const classes = useStyles()
    const [checkedNodes, setCheckedNodes] = useState([])
    const [checkedValues, setCheckedValues] = useState({})
    const [isAllChecked, setIsAllChecked] = useState(false)

    useEffect(() => {
        checkedNodes.length === nodes.length ?
            setIsAllChecked(true) :
            setIsAllChecked(false)
    }, [checkedNodes.length])

    const removeNodeFromChecked = (node) => {
        if (node === 'all') {
            setSelectedNodes([])
        } else {
            const filteredNodes = nodes.filter(item => item.id !== node.id)
            setSelectedNodes(filteredNodes)
        }
    }

    const onCheck = (node, isChecked) => {
        let selectedNodes = {}
        if (node === 'all') {
            nodes.forEach((node) => {
                selectedNodes[node.id] = isChecked
            })
            setIsAllChecked(isChecked)
            isChecked ? setCheckedNodes(nodes) : setCheckedNodes([])
        } else {
            const isCheckedNode = checkedNodes.find(checkedNode => checkedNode.id === node.id)
            selectedNodes[node.id] = isChecked
            if (isCheckedNode) {
                const filteredNodes = checkedNodes.filter(checkedNode => checkedNode.id !== node.id)
                setCheckedNodes(filteredNodes)
            } else {
                setCheckedNodes([...checkedNodes, node])
            }
        }
        setCheckedValues(prev => {
            return { ...prev, ...selectedNodes }
        })
    }

    return (
        <Box>
            <Box className={classes.header}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        className={classes.all}
                        label="ALL "
                        control={
                            <CustomCheckbox
                                value="all"
                                checked={isAllChecked}
                                onChange={(e) => onCheck('all', e.target.checked)}
                            />
                        }
                    />
                    <Typography className={classes.options} color='primary'>OPTIONS</Typography>
                </Box>
                <NodeTooltip placement="bottom-end" title="Delete" name="icon-button">
                    <Button onClick={() => removeNodeFromChecked('all')} className={classes.button}>
                        <TrashBinIcon />
                    </Button>
                </NodeTooltip>
            </Box>
            {
                nodes && nodes.map((node, index) => {
                    return (
                        <Box py={1.25} sx={{ display: 'flex', justifyContent: "space-between", width: '100%' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box mr={5}>
                                    <CustomCheckbox
                                        value={node.id}
                                        checked={checkedValues[node.id] === true}
                                        onChange={(e) => onCheck(node, e.target.checked)}
                                    />
                                </Box>
                                <Node node={node} />
                            </Box>
                            <NodeTooltip placement="bottom-end" title="Delete" name="icon-button">
                                <Button onClick={() => removeNodeFromChecked(node)} className={classes.button}>
                                    <TrashBinIcon />
                                </Button>
                            </NodeTooltip>
                        </Box>
                    )
                })
            }
        </Box>
    );
}

export default YourSelectedNodeList;