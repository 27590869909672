import {
  Box,
  Breadcrumbs as MUIBreadcumbs,
  IconButton,
  Link,
  makeStyles
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import clsx from "clsx";
import React from "react";
import { withRouter } from "../../../hooks/useWithRouter";

const useStyles = makeStyles({
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  breadcrumbs: {
    "& .MuiBreadcrumbs-separator": {
      color: "#123b64",
    },
  },
  icon: {
    padding: 0,
    marginRight: 10,
    color: "#63ADA6!important",
    "& svg": {
      width: 15,
    },
  },
  home: {
    color: "#63ADA6!important",
    fontWeight: 500,
    "&:hover": {
      color: "#63ADA6",
      textDecoration: "none",
    },
  },
  link: {
    textDecoration: "none",
    textTransform: "capitalize",
    cursor: "pointer",
    color: "#63ADA6!important",
    fontSize: 14,
    "&:hover": {
      color: "#123b64",
      textDecoration: "none",
    },
    maxWidth: 250,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  typography: {
    fontSize: 14,
    color: "#63ADA6!important",
    textTransform: "capitalize",
    maxWidth: 250,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
});

const Breadcrumbs = ({ nodeViewer, yourOrder, pathId, title, id, ...props }) => {
  const classes = useStyles()
  
  const { navigate, location: { pathname } } = props.router
  const pathnames = pathname.split("/").filter(x => x)

  if (pathId) {
    pathnames.pop()
  }

  const myCollection = pathnames.find(path => path === "my-collections")
  
  //const curatePrivacy = pathnames.find(path => path === "curatecontext")
  const collectionNodes = pathnames.find(path => path === "collection-nodes")

  const lastIndex = pathnames.length - 1

  const setBackPage = () => {
    // if (pathnames.length > 1 && !nodeViewer) {
    //   navigate(`/${pathnames[lastIndex - 1]}`);
    // } else {
    //   navigate('/home')
    // }
    navigate(-1)
  };

  const handleLinks = (routeTo) => {
    const route = routeTo.split("/").filter(x => x)
    if (pathId && route.length === 2) {
      navigate(-1)
    } else {
      navigate(routeTo)
    }
  }
 

  return (
    <Box className={classes.wrapper}>
      <IconButton onClick={setBackPage} className={classes.icon}>
        <ArrowBackIosIcon />
      </IconButton>
      <MUIBreadcumbs className={classes.breadcrumbs} aria-label="breadcrumb">
        {yourOrder ?
          <Link
            className={clsx(classes.home, classes.link)}
            onClick={() => navigate("/profile")}
          >
            Your Account
          </Link> :
          myCollection ? null :
          collectionNodes ? 
          <Link
              className={clsx(classes.home, classes.link)}
              onClick={() => navigate(`/my-collections/${title}/${id}`)}
            >
              Back to {title}
            </Link> :
            <Link
              className={clsx(classes.home, classes.link)}
              onClick={() => navigate("/home")}
            >
              Home
            </Link>
        }
 {!collectionNodes && pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === lastIndex;
          return isLast ? (
            <Typography key={index} className={classes.typography}>
              {name}
            </Typography>
          ) : (
            !nodeViewer && (
              <Link
                key={index}
                className={classes.link}
                onClick={() => handleLinks(routeTo)}
              >
                {name}
              </Link>
            )
          );
        })} 
         
      </MUIBreadcumbs>
    </Box>
  );
};
export default withRouter(Breadcrumbs);
