import { Box, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        width: 118,
        height: 118,
        borderRadius: '50%',
        background: 'linear-gradient(155.29deg, #90D1CC 3.46%, #418E84 110.38%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative'
    },
    leftRectangleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        position: 'absolute',
        top: 16,
        left: 0
    },
    rightRectangleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '45%',
        right: -13
    },
    secondaryRectangle: {
        background: '#808C9B',
        width: 21,
        height: 6,
        marginBottom: 3
    },
    primaryRectangle: {
        background: '#011936',
        width: 27,
        height: 6
    },
    circle: {
        position: 'absolute',
        bottom: -13,
        right: -22,
        width: 57,
        height: 57,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: '#fff',
        boxShadow: ' 0px 4px 20px rgba(23, 87, 123, 0.2)'
    },
    innerCircle: {
        width: 42,
        height: 42,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        background: 'linear-gradient(180deg, #E0E7EC 0%, rgba(224, 231, 236, 0) 100%)'
    }
})

function NodeIcon({nodeType, stepNumber}) {
    const classes = useStyles()
    return (
        <Box className={classes.root}>
            <Box className={classes.leftRectangleWrapper}>
                <Box className={classes.secondaryRectangle} />
                <Box className={classes.primaryRectangle} />
            </Box>
            <Box className={classes.rightRectangleWrapper}>
                <Box className={classes.secondaryRectangle} />
                <Box className={classes.primaryRectangle} />
            </Box>
            <img src={nodeType} alt="node-type" />
            <Box className={classes.circle}>
                <Box className={classes.innerCircle}>
                    {
                        Number.isInteger(stepNumber) ?
                        <Typography variant='h4' color='primary'>{stepNumber}</Typography> : 
                        <img src={stepNumber} alt="rocket" />
                    } 
                </Box>
            </Box>
        </Box>
    );
}

export default NodeIcon;