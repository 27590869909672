import { Box, FormControl, MenuItem, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const SelectInput = withStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: 8,
        fontSize: 14,
        padding: '14px 16px',
        border: '1px solid #123b64',
        '&:focus': {
            borderRadius: 8,
            background: '#fff'
        },
        '& .MuiSelect-select.MuiSelect-select': {
            paddingRight: 32
        }
    },
}))(Select)

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        '& svg': {
            marginRight: 0
        },
        "& .MuiSelect-root": {
            color: props => props.edit ? "#00000061" : "#123b64",
            border: props => {
                if (props.name === "select") {
                    return props.edit ? "1px solid #123b64" : "none"
                } else {
                    return "1px solid #123b64"
                }
            }
        }
    },
    label: {
        marginBottom: 10
    }
})


function CustomizedSelect(props) {
    const {
        label,
        name,
        value,
        handleChange,
        id,
        options,
        checkout,
        defaultValue,
        formik,
        edit
    } = props
    const classes = useStyles(props)

    const errorHandler = () => {
        if (formik) {
            return (
                <>
                    {
                        formik.errors[name] && formik.touched[name] ?
                            <Typography style={{ marginTop: 8 }} variant="body2" color="secondary">
                                {formik.errors[name]}
                            </Typography> : null
                    }
                </>
            )
        } else {
            return null
        }
    }

    return (
        <Box>
            {
                checkout ?
                    <Typography variant='body2' color='primary' className={classes.label}>
                        {label}
                        <svg style={{ marginLeft: 6.5 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 15.7441C3.85775 15.7441 0.5 12.3864 0.5 8.24414C0.5 4.10189 3.85775 0.744141 8 0.744141C12.1422 0.744141 15.5 4.10189 15.5 8.24414C15.5 12.3864 12.1422 15.7441 8 15.7441ZM8 14.2441C9.5913 14.2441 11.1174 13.612 12.2426 12.4868C13.3679 11.3616 14 9.83544 14 8.24414C14 6.65284 13.3679 5.12672 12.2426 4.0015C11.1174 2.87628 9.5913 2.24414 8 2.24414C6.4087 2.24414 4.88258 2.87628 3.75736 4.0015C2.63214 5.12672 2 6.65284 2 8.24414C2 9.83544 2.63214 11.3616 3.75736 12.4868C4.88258 13.612 6.4087 14.2441 8 14.2441V14.2441ZM7.25 4.49414H8.75V5.99414H7.25V4.49414ZM7.25 7.49414H8.75V11.9941H7.25V7.49414Z" fill="#123B64" />
                        </svg>
                    </Typography>
                    : label ?
                        <Typography variant='body2' color='primary' className={classes.label}>
                            {label}
                        </Typography>
                        : null
            }
            <FormControl disabled={edit} className={classes.formControl}>
                <SelectInput
                    id={id}
                    disableUnderline
                    displayEmpty
                    name={name}
                    value={value}
                    onChange={handleChange}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        disablePortal: true,
                        // anchorEl: this
                    }}
                >
                    <MenuItem disabled value="">
                        {defaultValue}
                    </MenuItem>
                    {options.map((option, index) => {
                        return (
                            <MenuItem
                                key={index}
                                value={name === "teamName" ? option.label : option.value}
                            >
                                {option.label}
                            </MenuItem>
                        )
                    })}
                </SelectInput>
                {errorHandler()}
            </FormControl>
        </Box>
    );
}

export default CustomizedSelect;