import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography, IconButton, FormControl, RadioGroup, FormControlLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import CustomTextField from '../../../shared/inputs/CusomTeaxtField';
import { YellowButton } from '../../../shared/buttons/custom-buttons/CustomButtons';
import CustomizedRadio from '../../../shared/inputs/CustomizedRadio';
import CustomDrawer from './../../../shared/drawer/CustomDrawer'
import AddIcon from '@material-ui/icons/Add';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup'
import Warning from '../../../shared/warning/Warning';
import { CustomTooltip } from '../../../shared/tooltip/NodeTooltip';
import SimpleTeamBuilder from '../../../shared/team-builder/simple-team-builder/SimpleTeamBuilder';
import MatrixTeamWrapper from '../../../shared/team-builder/matrix-team-builder/MatrixTeamWrapper'
import { useState } from 'react';
import axios from 'axios';
import { InfoIcon } from '../../../icons';

// Validation schema for the form
const validationSchema = Yup.object({
    nameYourTeam: Yup.string().required("Required")
})

// Initial values for the form
const initialValues = {
    nameYourTeam: '',
    selectAnOption: 'simple'
}

// Styles for the component using makeStyles hook
const useStyles = makeStyles(theme => ({
    root: {
        width: 568,
        background: '#fff',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        color: '#123b64',
        [theme.breakpoints.down('578')]: {
            width: 328
        }
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 30px',
        borderBottom: '1px solid #E0E7EC',
        [theme.breakpoints.down('578')]: {
            padding: '18px 15px',
            marginBottom: 25
        }
    },
    addtooltip: {
        backgroundColor: "#fff", 
        color: "#123b64", 
        fontSize: "15px",
        boxShadow: '0px 4px 15px rgba(23, 87, 123, 0.2)', 
        padding: 10,
        borderRadius: 12
       
      },
    title: {
        fontWeight: 700,
        lineHeight: '24px'
    },
    inputWrapper: {
        padding: 30,
        [theme.breakpoints.down('578')]: {
            padding: 15
        }
    },
    radioWrapper: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 20,
        '& .MuiFormControlLabel-root': {
            marginRight: 50
        },
        [theme.breakpoints.down('578')]: {
            marginBottom: 30
        }
    },
    yellowButton: {
        width: 234,
        fontSize: 16,
        fontWeight: 700,
        "& .MuiButton-startIcon": {
            marginleft: 0,
            marginRight: 3
        },
        [theme.breakpoints.down('578')]: {
            width: '100%'
        }
    },
    formLabel: {
        marginBottom: 20,
        display: 'flex',
        alignItems: 'center',
    },
    typography: {
        marginTop: 8
    }
}))

// CreateTeam component definition
const CreateTeam = ({ setOpen, getTeamOptions, setNewTeam, getNewContext, getUpdatedSimpleData }) => {
    const classes = useStyles() // Using styles defined above
    const [teamId, setTeamId] = useState('') // State for team ID
    const [simpleTeamStatus, setSimpleTeamStatus] = useState('') // State for simple team status
    const [matrixTeamStatus, setMatrixTeamStatus] = useState('') // State for matrix team status
    const [state, setState] = useState(false); // State for managing drawer open/close

    const apiUrl = process.env.REACT_APP_API_URL // API URL

    // Function to create a simple team
    const crateSimpleTeam = (data) => {
        return axios.post(`${apiUrl}/createNewTeamForSimple`, data)
            .then(res => {
                setSimpleTeamStatus(res.status)
                const id = res.data.length > 0 ? res.data[0]._key : ''
                setTeamId(id)
                setState(true)
                getTeamOptions()
            })
            .catch(err => {
                setSimpleTeamStatus(err.response.status)
                setState(false)
            })
    }

    // Function to create a matrix team
    const createNewMatrixTeam = (data) => {
        return axios.post(`${apiUrl}/createNewMatrixTeam`, data)
            .then(response => {
                setMatrixTeamStatus(response.status)
                const id = response.data.length > 0 ? response.data[0]._key : ''
                setTeamId(id)
                setState(true)
                getTeamOptions()
            })
            .catch(err => {
                setMatrixTeamStatus(err.response.status)
                setState(false)
            })
    }

    // Form submit handler
    const onSubmit = (values) => {
        values.selectAnOption === "simple" ?
            crateSimpleTeam(values) :
            createNewMatrixTeam(values)
    }


    return (
        <Box className={classes.root}>
            <Box className={classes.titleWrapper}>
                <Typography className={classes.title}>Create new team</Typography>
                <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }} disableRipple>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {
                    formik => {
                        const option = formik.values.selectAnOption
                        const handleChange = (event) => {
                            formik.setFieldValue("nameYourTeam", event.target.value)
                            setNewTeam(event.target.value)
                            option === "simple" ? setSimpleTeamStatus(200) :
                                setMatrixTeamStatus(200)
                        }
                        return (
                            <Form className={classes.inputWrapper}>
                                <Field
                                    style={{ with: '100%' }}
                                    name="nameYourTeam"
                                >
                                    {
                                        ({ field }) => (
                                            <CustomTextField
                                                field={field}
                                                handleChange={handleChange}
                                                placeholder="Enter text"
                                                label="Name your team"
                                            />
                                        )
                                    }
                                </Field>
                                <Box mb={2.5}>
                                    {
                                        (formik.errors.nameYourTeam && formik.touched.nameYourTeam)
                                            || simpleTeamStatus === 403 || matrixTeamStatus === 403 ? (
                                            <Typography className={classes.typography} variant="body2" color="secondary">
                                                {simpleTeamStatus === 403 || matrixTeamStatus === 403 ?
                                                    "Team with the choosen name is already exist!" :
                                                    formik.errors.nameYourTeam}
                                            </Typography>
                                        ) : null
                                    }
                                </Box>
                                <FormControl component="fieldset">
                                    <Box className={classes.formLabel}>
                                        <Typography style={{ marginRight: 7 }} variant="h5" color='primary'>Select an option</Typography>
                                        <CustomTooltip
    bottomStart="bottom-start"
    title="Select the type of team either simple/matrix"
    classes={{ tooltip: classes.addtooltip }}
    >
 <InfoIcon />
 </CustomTooltip>
                                    </Box>
                                    <RadioGroup
                                        name="selectAnOption"
                                        onChange={formik.handleChange}
                                        value={formik.values.selectAnOption}
                                        className={classes.radioWrapper}
                                        aria-label="teamType"
                                    >
                                        <FormControlLabel value="simple" control={<CustomizedRadio />} label="Simple" />
                                        <FormControlLabel value="matrix" control={<CustomizedRadio />} label="Matrix" />
                                    </RadioGroup>
                                </FormControl>
                                <Box>
                                    <YellowButton type='submit' startIcon={<AddIcon />} className={classes.yellowButton}>
                                        Add responsibility
                                    </YellowButton>
                                    <CustomDrawer
                                        state={state}
                                        setState={setState}
                                        anchor='right'
                                    >
                                        {
                                            option === "simple" ?
                                                <SimpleTeamBuilder
                                                    teamId={teamId}
                                                    page="simple-team"
                                                    setState={setState}
                                                    setOpen={setOpen}
                                                    getUpdatedSimpleData={getUpdatedSimpleData}
                                                /> :
                                                <MatrixTeamWrapper
                                                    teamId={teamId}
                                                    setState={setState}
                                                    setOpen={setOpen}
                                                    requestType="create-team"
                                                    getNewContext={getNewContext}
                                                />
                                        }
                                    </CustomDrawer>
                                </Box>
                            </Form>
                        )
                    }
                }
            </Formik>
        </Box>
    );
}

export default CreateTeam;