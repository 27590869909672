import { makeStyles } from "@material-ui/styles"
import { Box, InputLabel, TextField, Typography } from "@material-ui/core"

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        "& label": {
            color: '#123b64',
            fontWeight: 500,
            fontSize: 14,
            marginBottom: 8
        }
    },
    typography: {
        marginTop: 8
    },
    textAreaInput: {
        width: '100%',
        "& .MuiOutlinedInput-root": {
            fontSize: 14,
            '& textarea': {
                height: '70px!important',
                color: props => props.edit ? '#00000061' : '#123b64',
                '::placeholder': {
                    color: '#000'
                },
            },
            "& fieldset": {
                top: 0,
                border: '1px solid #808C9A',
                borderRadius: 8,
            },
            "&.Mui-focused fieldset": {
                borderColor: "#F79F19",
            }
        },
    },
})

const TextAreaInput = (props) => {
    const classes = useStyles(props)
    const {
        placeholderText,
        label,
        id,
        name,
        value,
        onChange,
        labelIcon,
        formik,
        edit
    } = props

    return (
        <Box disabled={edit} className={classes.formControl}>
            {
                labelIcon ?
                    <InputLabel>
                        {label}
                        <svg style={{ marginLeft: 6.5 }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 15.7441C3.85775 15.7441 0.5 12.3864 0.5 8.24414C0.5 4.10189 3.85775 0.744141 8 0.744141C12.1422 0.744141 15.5 4.10189 15.5 8.24414C15.5 12.3864 12.1422 15.7441 8 15.7441ZM8 14.2441C9.5913 14.2441 11.1174 13.612 12.2426 12.4868C13.3679 11.3616 14 9.83544 14 8.24414C14 6.65284 13.3679 5.12672 12.2426 4.0015C11.1174 2.87628 9.5913 2.24414 8 2.24414C6.4087 2.24414 4.88258 2.87628 3.75736 4.0015C2.63214 5.12672 2 6.65284 2 8.24414C2 9.83544 2.63214 11.3616 3.75736 12.4868C4.88258 13.612 6.4087 14.2441 8 14.2441V14.2441ZM7.25 4.49414H8.75V5.99414H7.25V4.49414ZM7.25 7.49414H8.75V11.9941H7.25V7.49414Z" fill="#123B64" />
                        </svg>
                    </InputLabel>
                    :
                    <InputLabel>
                        {label}
                    </InputLabel>
            }
            <TextField
                id={id}
                value={value}
                name={name}
                onChange={onChange}
                disabled={edit}
                variant="outlined"
                placeholder={placeholderText}
                multiline
                maxRows={4}
                className={classes.textAreaInput}
                type='text'
                {...formik.getFieldProps(name)}
            />
            {formik.errors[name] && formik.touched[name] ? (
                <Typography
                    className={classes.typography}
                    variant="body2"
                    color="secondary"
                >
                    {formik.errors[name]}
                </Typography>
            ) : null}
        </Box>
    )
}
export default TextAreaInput