import { makeStyles, Typography } from '@material-ui/core';
import NodeTooltip from './NodeTooltip';
import { useEffect, useState } from 'react';
import { useRef } from 'react';

const useStyles = makeStyles({
    typography: {
        fontSize: props => props.fontSize,
        width: '100%'
    },
    overflowed: {
        maxWidth: props => props.maxWidth,
        width: '100%',
        display: '-webkit-box',
        '-webkit-line-clamp': props => props.lineClamp,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        maxHeight: props => props.maxHeight,
        fontSize: props => props.fontSize,
        '& b': {
            maxWidth: props => props.maxWidth,
            display: '-webkit-box',
            '-webkit-line-clamp': props => props.lineClamp,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
            maxHeight: props => props.maxHeight,
            fontSize: props => props.fontSize
        }
    }
})

function Tooltip({ title, variant, currentNodes, ...props }) {
    const classes = useStyles(props)
    const elementnRef = useRef()
    const [isOverflowed, setIsOwerflowed] = useState(false)
    
    useEffect(() => {
        setIsOwerflowed(elementnRef.current.scrollHeight > props.maxHeight)
    }, [currentNodes])

    return (
        <NodeTooltip name="card" disableHoverListener={!isOverflowed} placement='bottom-start' title={title}>
            <Typography color='primary' className={isOverflowed ? classes.overflowed : classes.typography} ref={elementnRef}>
                {title}
            </Typography>
        </NodeTooltip>
    );
}

export default Tooltip;