

export const HeadCells = [
    { id: 'job-id', label: 'ID' },
    { id: 'job-name', label: 'JOB NAME' },
    { id: 'run-id', label: 'RUN ID' },
    { id: 'exit-code', label: 'EXIT CODE' },
      { id: 'exit-message', label: 'EXIT Message' },
    { id: 'status', label: 'STATUS' },
    { id: 'user-name', label: 'USER NAME' },
    { id: 'start-at', label: 'START AT' },
    { id: 'end-at', label: 'END AT ' },
    { id: 'updated-at', label: 'UPDATED AT' },
      { id: 'created-at', label: 'CREATED  AT' },
];
