import _debounce from "lodash/debounce";
import React, { useEffect, useState, useContext } from 'react';
import { ListItemText } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { CustomCheckbox } from './CheckBox';
import CollectionInput from '../../save-in-collection/CollectionInput';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TabContext from '../../../contexts/TabContext';
import { TextButton } from '../buttons/custom-buttons/CustomButtons';

const StyledMenu = withStyles({
    paper: {
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        width: 240,
        height: 260
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{vertical: "bottom", horizontal: "start"}}
        transformOrigin={{vertical: "top", horizontal: "start"}}
        {...props}
    />
));

const useStyles = makeStyles({
    button: {
        width: 101,
        height: 48,
        background: '#E0E7EC',
        color: '#123b64',
        boxShadow: 'unset',
        borderColor: props => props.anchorEl || props.isFocused ? '#f79f19' : 'unset',
        borderStyle: props => props.isFocused || props.anchorEl ? 'solid' : 'unset',
        borderWidth: props => props.isFocused ? '2px 0 2px 2px' : props.anchorEl ? 2 : 'unset',
        '&:hover': {
            background: '#E0E7EC',
            boxShadow: 'unset'
        },
        borderRadius: '10px 0 0 10px'
    },
    menuItem: {
        color: '#123b64',
        width: 220,
        "&:hover": {
            background: '#E0E7EC'
        },
    },
    selectAll: {
        textDecoration: 'underline #123b64',
        marginRight: 20,
    },
    clearAll: {
        textDecoration: 'underline #123b64'
    }
})


export default function NavbarMenu({ options, isFocused,setSelectedNodeType,selectedNodeType,searchNodes,searchText,setSearchText,setSelectedTypeNameFilter,prepareSearchFilters }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles({ anchorEl, isFocused })
    // const [searchText, setSearchText] = useState('')
    const [nodeTypeOptions, setNodeTypeOptions] = useState([])
    const { setNodeType, nodeType, setIsNodeTypeFilter } = useContext(TabContext)
    const [selectedType, setSelectedType] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);
    


    useEffect(() => {
        setNodeTypeOptions(options)
        setNodeType(options)
        console.log(nodeType,"nodeType")
        console.log(options,"1234455")
    }, [options])
    useEffect(() => {
        // Check if all checkboxes are selected
        const allSelected = nodeTypeOptions.every((option) => nodeType.includes(option));
        setSelectAllChecked(allSelected);
    }, [nodeType, nodeTypeOptions]);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (e) => {
        const { value } = e.target
        setSearchText(value)
        let filteredCollectionList = options.filter(option => {
            return option.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
        setNodeTypeOptions(filteredCollectionList)
        setSelectedNodeType(value);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

   

    const onChange = (e) => {
        const { value } = e.target;
        let checkedNodeTypes = [...nodeType];
        
        const existingState = nodeType.find(item => item === value);
    
        if (existingState) {
            checkedNodeTypes = checkedNodeTypes.filter(item => item !== value);
        } else {
            checkedNodeTypes.push(value);
        }
    
        setSelectedNodeType(checkedNodeTypes);
        setNodeType(checkedNodeTypes);
        setIsNodeTypeFilter(true);
    
        // Check if all checkboxes are selected
        const allSelected = nodeTypeOptions.every((option) => checkedNodeTypes.includes(option));
        setSelectAllChecked(allSelected);
    
        // Handle the search here
        // handleNewSearch(searchText, checkedNodeTypes);
    };




    const debounce = (func, delay) => {
        let timeout;
        return function () {
          const context = this;
          const args = arguments;
          clearTimeout(timeout);
          timeout = setTimeout(() => func.apply(context, args), delay);
        };
      };





    const handleSelectAll = () => {
        setIsNodeTypeFilter(true);
        setNodeType(nodeTypeOptions);
        setSelectedNodeType(nodeTypeOptions);
        setSearchText((prevSearchText) => prevSearchText.trim() !== '' ? prevSearchText : '');
        setSelectAllChecked(true); // Set selectAllChecked to true
    
        // Create the filter payload for the API call
        const filterPayload = {
            filters: [
                {
                    fieldName: "doc.name",
                    fieldValue: searchText, // Use the searchText value here
                    stringFunction: "STARTS_WITH",
                    searchType: "SEARCH"
                },
                {
                    fieldName: "doc.name",
                    fieldValue: searchText, // Use the searchText value here
                    stringFunction: "NGRAM_MATCH",
                    searchType: "SEARCH",
                    threshold: 0.4,
                    condition: "or"
                },
                {
                    fieldName: "doc.nodesClassification",
                    fieldValue: "private",
                    searchType: "FILTER",
                    op: "not like"
                },
                {
                    fieldName: "doc.typeName",
                    fieldValue: "Data Set,ACG,API,API Endpoint,API Parameter,Application,Bucket,Business Process,Business Term,Column,Data Attribute,Data Product,Data Quality Metric,Data Quality Rule,Data Sharing Agreement,Data Use,Data domain,Database,Directory,Feature,Feature Set,Field,File,ForeignKey,Line of Business,ML Model,Model Version,Product,Route,Schema,SensitiveType,SensitiveTypeGroup,Server,Service,Table,container", // Use the desired field value here
                    searchType: "FILTER",
                    op: "in",
                    condition: "and"
                }
            ],
            pagination: {
                pageNumber: 1,
                pageSize: 9
            }
        };
    
        // Make the API call with the updated filter payload
        searchNodes(filterPayload, searchText); // Pass the searchText value as the search text
        // const debouncedSearch = debounce(() => {
        //     prepareSearchFilters(searchText, nodeTypeOptions);
        //   }, 300); // 300 milliseconds delay
        
        //   // Call the debounced function
        //   debouncedSearch();
          
    };
    

//     const handleSelectAll = () => {
//     setIsNodeTypeFilter(true);
//     setNodeType(nodeTypeOptions);
//     setSelectedNodeType(nodeTypeOptions);
//     setSearchText(''); // Clear the search text
//     setSelectAllChecked(true); // Set selectAllChecked to true

//     // Create the filter payload for the API call
//     const filterPayload = {
//         filters: [
//             // ... Other filters ...
//             {
//                 fieldName: "doc.name",
//                 fieldValue: '', // Clear the search text here
//                 stringFunction: "STARTS_WITH",
//                 searchType: "SEARCH"
//             },
//             {
//                 fieldName: "doc.name",
//                 fieldValue: '', // Clear the search text here
//                 stringFunction: "NGRAM_MATCH",
//                 searchType: "SEARCH",
//                 threshold: 0.4,
//                 condition: "or"
//             },
//             // ... Other filters ...
//         ],
//         pagination: {
//             pageNumber: 1,
//             pageSize: 9
//         }
//     };

//     // Make the API call with the updated filter payload
//     searchNodes(filterPayload, ''); // Pass an empty string as the search text
// };

    
    const handleClearAll = () => {
        setIsNodeTypeFilter(true);
        setNodeType([]);
        setSelectedNodeType([]);
        setSearchText((prevSearchText) => prevSearchText.trim() !== '' ? prevSearchText : '');
        setSelectAllChecked(false); // Set selectAllChecked to false
   
        const filterPayload = {
                      filters: [
                        {
                          fieldName: "doc.name",
                          fieldValue: searchText,
                          stringFunction: "STARTS_WITH",
                          searchType: "SEARCH",
                        },
                        {
                          fieldName: "doc.name",
                          fieldValue: searchText,
                          stringFunction: "NGRAM_MATCH",
                          searchType: "SEARCH",
                          threshold: 0.4,
                          condition: "or",
                        },
                        {
                          fieldName: "doc.nodesClassification",
                          fieldValue: "private",
                          searchType: "FILTER",
                          op: "not like",
                        },
                       
                      ],
                      pagination: {
                        pageNumber: 1,
                        pageSize: 9,
                      },
                    };
                
                    // Make the API call with the updated filter payload
                    searchNodes(filterPayload, searchText);

                    debounce(() => {
                        prepareSearchFilters(searchText, nodeTypeOptions);
                      }, 300);
                  };
            
    
//  const handleCheckboxClick = (value) => {
//     let checkedNodeTypes = [...nodeType];
//     const existingState = nodeType.find(item => item === value);

//     if (existingState) {
//         checkedNodeTypes = checkedNodeTypes.filter(item => item !== value);
//     } else {
//         checkedNodeTypes.push(value);
//     }

//     setSelectedNodeType(checkedNodeTypes);
//     setNodeType(checkedNodeTypes);
//     setIsNodeTypeFilter(true);

//     // Check if all checkboxes are selected
//     const allSelected = nodeTypeOptions.every((option) => checkedNodeTypes.includes(option));
//     setSelectAllChecked(allSelected);

//     // Define the base filters
//     const filters = [
//         {
//             fieldName: "doc.name",
//             fieldValue: searchText,
//             stringFunction: "STARTS_WITH",
//             searchType: "SEARCH",
//         },
//         {
//             fieldName: "doc.name",
//             fieldValue: searchText,
//             stringFunction: "NGRAM_MATCH",
//             searchType: "SEARCH",
//             threshold: 0.4,
//             condition: "or",
//         },
//         {
//             fieldName: "doc.nodesClassification",
//             fieldValue: "private",
//             searchType: "FILTER",
//             op: "not like",
//         },
//     ];

//     // Include "doc.typeName" filter only if there are selected node types
//     if (checkedNodeTypes.length > 0) {
//         filters.push({
//             fieldName: "doc.typeName",
//             fieldValue: checkedNodeTypes.join(","),
//             searchType: "FILTER",
//             op: "in",
//             condition: "and",
//         });
//     }

//     // Make the API call with the updated filter payload
//     const filterPayload = {
//         filters,
//         pagination: {
//             pageNumber: 1,
//             pageSize: 9,
//         },
//     };

//     searchNodes(filterPayload, searchText); // Make the API call here
// }




// const handleCheckboxClick = (value) => {
//     let checkedNodeTypes = [...nodeType];
//     const existingState = nodeType.find((item) => item === value);
  
//     if (existingState) {
//       checkedNodeTypes = checkedNodeTypes.filter((item) => item !== value);
//     } else {
//       checkedNodeTypes.push(value);
//     }
  
//     setSelectedNodeType(checkedNodeTypes);
//     setNodeType(checkedNodeTypes);
  
//     // Define the base filters
//     const filters = [
//       {
//         fieldName: "doc.name",
//         fieldValue: searchText,
//         stringFunction: "STARTS_WITH",
//         searchType: "SEARCH",
//       },
//       {
//         fieldName: "doc.name",
//         fieldValue: searchText,
//         stringFunction: "NGRAM_MATCH",
//         searchType: "SEARCH",
//         threshold: 0.4,
//         condition: "or",
//       },
//       {
//         fieldName: "doc.nodesClassification",
//         fieldValue: "private",
//         searchType: "FILTER",
//         op: "not like",
//       },
//     ];
  
//     // Include "doc.typeName" filter only if there are selected node types
//     if (checkedNodeTypes.length > 0) {
//       filters.push({
//         fieldName: "doc.typeName",
//         fieldValue: checkedNodeTypes.join(","),
//         searchType: "FILTER",
//         op: "in",
//         condition: "and",
//       });
//     }
  
//     // Make the API call with the updated filter payload
//     const filterPayload = {
//       filters,
//       pagination: {
//         pageNumber: 1,
//         pageSize: 9,
//       },
//     };
  
//     searchNodes(filterPayload, searchText); // Make the API call here
//   };
  
const handleCheckboxClick = (value) => {
    let checkedNodeTypes = [...nodeType];
    const existingState = nodeType.find((item) => item === value);
  
    if (existingState) {
      checkedNodeTypes = checkedNodeTypes.filter((item) => item !== value);
    } else {
      checkedNodeTypes.push(value);
    }
  
    setSelectedNodeType(checkedNodeTypes);
    setNodeType(checkedNodeTypes);
  
    // Trigger a search with updated filters
    prepareSearchFilters(searchText, checkedNodeTypes);
    debounce(() => {
        prepareSearchFilters(searchText, nodeTypeOptions);
      }, 300);
  };
  
    return (
        <div>
            <Button
                className={classes.button}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                color="primary"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Select
            </Button>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onKeyDown={(e) => e.stopPropagation()}>
                    <CollectionInput handleChange={handleChange} text={searchText} />
                </MenuItem>
                <MenuItem className={classes.buttonsWrapper} onKeyDown={(e) => e.stopPropagation()}>
                    <TextButton onClick={handleSelectAll} className={classes.selectAll}>
                        Select all
                    </TextButton>
                    <TextButton onClick={handleClearAll} className={classes.clearAll}>
                        Clear all
                    </TextButton>
                </MenuItem>
                {/* <MenuItem>
                    <Typography variant='body2' color='secondary'>Dataset is already selected!</Typography>
                </MenuItem> */}
                {/* {nodeTypeOptions.map((type) => (
                    <MenuItem className={classes.menuItem} key={type}>
                        <CustomCheckbox
                            value={type}
                            onChange={onChange}
                            checked={nodeType.indexOf(type) > -1}
                        />
                        <ListItemText primary={type} />
                    </MenuItem>
                ))} */}


{nodeTypeOptions.map((type) => (
    <MenuItem className={classes.menuItem} key={type}>
        <CustomCheckbox
            value={type}
            onChange={onChange}
            checked={nodeType.indexOf(type) > -1}
            onClick={() => handleCheckboxClick(type)} // Add this line
        />
        <ListItemText primary={type} />
    </MenuItem>
))}
            </StyledMenu>
        </div>
    );
}