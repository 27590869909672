import { SignInForm } from "../../components/sign-in-form/SignInForm";
import './SignIn.scss';
import BannerImage from '../../assets/auth1.svg';
import mlsouklogo from '../../assets/mlsouklogo.png';
import { Box, Grid, makeStyles } from "@material-ui/core";
import banner from '../../assets/auth2.svg'
import { useContext, useEffect } from "react";
import TabContext from "../../contexts/TabContext";
import NodeGraphWrapper from "../../components/shared/d3-graph/force-directed-graph/NodeGraphWrapper";

// Custom CSS styles for the Signin component
const useStyles = makeStyles({
    imgWrapper: {
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginTop: '0PX!important',
        "& img": {
            width: '100%',
            height: 'calc(100vh - 81px)',
            minHeight: 600
        }
    },
    grid: {
        display: 'flex',
        justifyContent: 'center'
    },
    signInWrapper: {
        padding: 30,
        maxWidth: 600,
        width: '100%'
    }
})

// Signin functional component
export const SignIn = () => {
    const classes = useStyles()
    const { setNodeType } = useContext(TabContext)

    useEffect(() => {
        setNodeType(['Data Set'])
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} sm={6} className={classes.imgWrapper}>
                <img src={BannerImage} alt="BannerImage" />
                {/* <img src={mlsouklogo} alt="BannerImage" /> */}
            </Grid>
            {/* Grid item for sign-in form, takes up 12 columns on small screens and 6 columns on medium screens */}
            <Grid className={classes.grid} item xs={12} sm={6}>
                <Box className={classes.signInWrapper}>
                    {/* Rendering SigninForm component */}
                    <SignInForm />
                </Box>
            </Grid>
        </Grid>
    )
}

