export const SEARCH_NODE_PAYLOAD = {
  // filters: [
  //   {
  //     fieldName: "doc.name",
  //     fieldValue: "Data_Governance_Arena.adventureworks2017.human_resources",
  //     stringFunction: "STARTS_WITH",
  //     searchType: "SEARCH",
  //   },
  //   {
  //     fieldName: "doc.name",
  //     fieldValue: "Data_Governance_Arena.adventureworks2017.human_resources",
  //     stringFunction: "NGRAM_MATCH",
  //     searchType: "SEARCH",
  //     threshold: 0.4,
  //     condition: "or",
  //   },
  //   {
  //     fieldName: "doc.typeName",
  //     fieldValue:
  //       "Data Set,API,API Endpoint,API Parameter,Business Term,Column,Control,Data Domain,Data Product,Data Quality Metric,Data Quality rule,DataProduct,DataSet,Database,Experiment,Hive Column,Hive Column Lineage,Hive Database,Hive Process,Hive Storage Description,Hive Table,Line Of Business,ML Model,Model Version,Policy,Report,Route,Run,Schema,SensitiveType,SensitiveTypeGroup,Server,Service,Table",
  //     searchType: "FILTER",
  //     op: "in",
  //     condition: "and",
  //   },
  // ],
  filters: [
    {
      fieldName: "doc.name",
      fieldValue: "Data_Governance_Arena.adventureworks2017.human_resources",
      stringFunction: "STARTS_WITH",
      searchType: "SEARCH",
    },
    {
      fieldName: "doc.name",
      fieldValue: "Data_Governance_Arena.adventureworks2017.human_resources",
      stringFunction: "NGRAM_MATCH",
      searchType: "SEARCH",
      threshold: 0.4,
      condition: "or",
    },
    {
      fieldName: "doc.nodesClassification",
      fieldValue: "private",
      searchType: "FILTER",
      op: "not like",
    },

    {
      fieldName: "doc.typeName",
      fieldValue: "Data Set,ACG,API,API Endpoint,API Parameter,Application,Bucket,Business Process,Business Term,Column,Data Attribute,Data Product,Data Quality Metric,Data Quality Rule,Data Sharing Agreement,Data Use,Data domain,Database,Directory,Feature,Feature Set,Field,File,ForeignKey,Line of Business,ML Model,Model Version,Product,Route,Schema,SensitiveType,SensitiveTypeGroup,Server,Service,Table,container",
      searchType: "FILTER",
      op: "in",
      condition: "and",
    },
  ],
  pagination: {
    pageNumber: 1,
    pageSize: 9,
  },
};



export const TABDATA = {
  type: "filter",
  title: "Search results",
  id: "filter",
  loading: true,
  nodeTitle: "",
  reqTime: 0,
  filterType: "",
  data: [],
  error: false,
  selectedFilterTypes: [],
  selectedNodeTypes: ["Data Set"],
  paging: {
    hasNextPage: true,
    hasPreviousPage: false,
    pageNumber: 1,
    pageSize: 9,
    totalNumberOfPages: 1,
    totalNumberOfRecords: 1,
  },
};

export function createSearchLoadingPayload(filterType = "") {
  return { ...TABDATA, loading: true, error: false, filterType };
}

export function createSearchLoadedPayload(payload) {
  return { ...TABDATA, loading: false, error: false, ...payload };
}

export function createSearchErrorPayload() {
  return { ...TABDATA, loading: false, error: true };
}
