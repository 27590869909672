import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Box,
  Table,
  TableBody,
  TableCell,
  IconButton,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MuiAvatarGroup from "./MuiAvatarGroup";
import KebabMenu from "../../../../shared/popover/KebabMenu";
import { TextButton } from "../../../../shared/buttons/custom-buttons/CustomButtons";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import CollapseTooltip from "./CollapseTooltip";
import CustomModal from "../../../../shared/modal/CustomModal";
import DataExport from "./../../data-export/DataExport";
import MatrixTeamWrapper from "../../../../shared/team-builder/matrix-team-builder/MatrixTeamWrapper";
import CustomDrawer from "../../../../shared/drawer/CustomDrawer";
import { TeamService } from "../../../../../services/TeamService";
import {
  ExportIcon,
  ImportIcon,
  ThreeDotsIcon,
  TrashBinIcon,
  UnderScoreEditIcon,
} from "../../../../icons";

// Custom styles using Material-UI's makeStyles hook
const useStyles = makeStyles({
  tableContainer: {
    boxShadow: "none",
    background: "#c4c4c41a",
    padding: "0 16px",
  },
  table: {
    minWidth: 600,
  },
  headerTitle: {
    color: "#123b64",
    lineHeight: "140%",
    padding: "10px 0",
    border: "none",
  },
  rolles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  context: {
    color: "#123b64",
    lineHeight: "140%",
    fontWeight: 500,
    padding: "10px 5px",
    // background: '#E0E7EC',
    borderBottom: "3px solid #c4c4c41a",
  },
  tableCell: {
    color: "#123b64c2",
    lineHeight: "140%",
    padding: "8px!important",
    borderBottom: "3px solid #c4c4c41a",
  },
  kebabMenuContent: {
    width: 123,
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 10,
    padding: 12,
    zIndex: 999,
    marginRight: "auto",
  },
  collapseBtnWrapper: {
    height: 51,
  },
  collapseBtn: {
    position: "absolute",
    right: 13,
    bottom: 16,
  },
  rolesCell: {
    width: 90,
  },
  kebabIcon: {
    paddingRight: "0px!important",
    width: 24,
    textAlign: "-webkit-right",
  },
  exportFile: {
    textAlign: "start",
    marginBottom: 10,
    cursor: "pointer",
  },
  iconButton: {
    padding: 0,
    "& svg": {
      width: 15,
      height: 15,
    },
  },
});

const MatrixTable = (props) => {
  // Extracting necessary props and initializing state variables
  const classes = useStyles();
  const {
    setOpenCollapse,
    openCollapse,
    handleEditContext,
    editContextData,
    updateContext,
    deleteContext,
    contextName,
    teamId,
    data,
    isReadOnly,
  } = props;

  const [viewRows, setViewRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [openExportContent, setOpenExportContent] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Function to toggle between showing all rows or only a few
  function onSetChecked() {
    if (openCollapse) {
      setViewRows(data.slice(0, 2));
    } else {
      setViewRows(data);
    }
    setOpenCollapse(!openCollapse);
  }

  // Sorting and setting view rows based on data changes
  useEffect(() => {
    data.sort((a, b) => {
      return (
        a.context.substr(7, a.context.length - 1) -
        b.context.substr(7, b.context.length - 1)
      );
    });
    if (!openCollapse) {
      setViewRows(data.slice(0, 2));
    } else {
      setViewRows(data);
    }
  }, [data]);

  // Function to download a file
  const downloadFile = () => {
    TeamService.getMatrixTeamFile().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.click();
      setOpen(false);
    });
  };

  // Function to upload a file
  const uploadFile = (event) => {
    let data = new FormData();
    data.append("file", event.target.files[0]);
    TeamService.uploadMatrixTeamFile(data).then(() => {
      setOpenExportContent(false);
    });
  };
// Function to edit a context
  const editContext = (contextName) => {
    handleEditContext(contextName);
    setOpenDrawer(true);
  };

  return (
    <div>
      {data[0]?.roles ? (
        <>
          <CustomModal setOpen={setOpen} open={open}>
            <DataExport
              downloadFile={downloadFile}
              type="import"
              setOpen={setOpen}
            />
          </CustomModal>
          <CustomModal setOpen={setOpenExportContent} open={openExportContent}>
            <DataExport
              type="export"
              setOpen={setOpenExportContent}
              uploadFile={uploadFile}
            />
          </CustomModal>
        </>
      ) : null}
      <CustomDrawer state={openDrawer} setState={setOpenDrawer} anchor="right">
        <MatrixTeamWrapper
          requestType="update"
          teamId={teamId}
          contextName={contextName}
          setState={setOpenDrawer}
          editContextData={editContextData}
          updateContext={updateContext}
        />
      </CustomDrawer>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerTitle} />
              <TableCell className={classes.headerTitle}>Business</TableCell>
              <TableCell className={classes.headerTitle}>Data Domain</TableCell>
              <TableCell className={classes.headerTitle}>Products</TableCell>
              <TableCell className={classes.headerTitle}>Geograohy</TableCell>
              {data[0].roles ? (
                <TableCell className={classes.headerTitle}>Roles</TableCell>
              ) : null}
              {!isReadOnly ? (
                <>
                  <TableCell
                    style={{ width: 38 }}
                    className={classes.headerTitle}
                  />
                  <TableCell
                    className={clsx(classes.headerTitle, classes.kebabIcon)}
                  >
                    {data[0].roles ? (
                      <KebabMenu button={<ThreeDotsIcon />}>
                        <Box className={classes.kebabMenuContent}>
                          <div
                            onClick={() => setOpenExportContent(true)}
                            className={classes.exportFile}
                          >
                            <span style={{ marginRight: 10 }}>
                              <ExportIcon />
                            </span>
                            <span>Export file</span>
                          </div>
                          <div
                            onClick={() => setOpen(true)}
                            className={classes.exportFile}
                          >
                            <span style={{ marginRight: 10 }}>
                              <ImportIcon />
                            </span>
                            <span>Import file</span>
                          </div>
                        </Box>
                      </KebabMenu>
                    ) : null}
                  </TableCell>
                </>
              ) : null}
            </TableRow>
          </TableHead>
          <TableBody style={{ position: "relative" }}>
            {viewRows.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  className={classes.context}
                  component="th"
                  scope="row"
                >
                  {row.context}
                </TableCell>
                {/* <TableCell className={classes.tableCell}>
                  {row.business && row.business.length > 0 ? (
                    <CollapseTooltip type="simple" data={row.business}>
                      {`${row.business[0].name}  (${row.business.length})`}
                    </CollapseTooltip>
                  ) : (
                    <>{row.business[0].name}</>
                  )}
                </TableCell> */}
                <TableCell className={classes.tableCell}>
                  {row.business && row.business.length > 0 ? (
                    <CollapseTooltip type="simple" data={row.business}>
                      {`${row.business[0].name} (${row.business.length})`}
                    </CollapseTooltip>
                  ) : (
                    <span>No business available</span>
                  )}
                </TableCell>
                <TableCell
                  className={clsx(classes.tableCell, classes.moreText)}
                >
                  {row.dataDomain[0].name}
                </TableCell>
                {/* <TableCell className={classes.tableCell}>
                  {row.products.length > 1 ? (
                    <CollapseTooltip type="simple" data={row.products}>
                      {`${row.products[0].name} (${row.products.length})`}
                    </CollapseTooltip>
                  ) : (
                    <>{row.products[0].name}</>
                  )}
                </TableCell> */}
                <TableCell className={classes.tableCell}>
                  {row.products && row.products.length > 0 ? (
                    <CollapseTooltip type="simple" data={row.products}>
                      {`${row.products[0].name} (${row.products.length})`}
                    </CollapseTooltip>
                  ) : (
                    <span>No products available</span>
                  )}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {row.geography.length > 1 ? (
                    <CollapseTooltip type="simple" data={row.geography}>
                      {`${row.geography[0].name} (${row.geography.length})`}
                    </CollapseTooltip>
                  ) : (
                    <>{row.geography[0].name}</>
                  )}
                </TableCell>
                {row.roles ? (
                  <TableCell
                    className={clsx(classes.tableCell, classes.rolesCell)}
                  >
                    <CollapseTooltip type="matrix" data={row.roles}>
                      <MuiAvatarGroup avatars={row.roles} />
                    </CollapseTooltip>
                  </TableCell>
                ) : null}
                {!isReadOnly ? (
                  <>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: "center" }}
                    >
                      <IconButton
                        onClick={() => editContext(row.context)}
                        disabled={!row.roles}
                        style={{ padding: 0 }}
                        disableRipple
                      >
                        <UnderScoreEditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: "center" }}
                    >
                      <IconButton
                        onClick={() => deleteContext(row.context)}
                        disableRipple
                        className={classes.iconButton}
                      >
                        <TrashBinIcon />
                      </IconButton>
                    </TableCell>
                  </>
                ) : null}
              </TableRow>
            ))}
            <TableRow className={classes.collapseBtnWrapper}>
              <TableCell
                style={{ textAlign: "end", border: "none" }}
                colSpan={9}
              >
                {data.length > 2 ? (
                  <TextButton
                    onClick={onSetChecked}
                    className={classes.collapseBtn}
                    disableRipple
                    endIcon={
                      openCollapse ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    {openCollapse ? "Less context" : "More context"}
                  </TextButton>
                ) : null}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default MatrixTable;
