import { Box, FormControl, FormGroup, FormControlLabel, makeStyles } from "@material-ui/core";
import { CustomCheckbox } from "../../CheckBox";
import { TextButton } from "../../../buttons/custom-buttons/CustomButtons";

const useStyles = makeStyles({
    textButtonWrapper: {
        textAlign: 'end',
        marginTop: 15
    },
    root: {
        width: '100%',
        height: 170,
        overflowY: 'auto',
        marginTop: 6,
        backgroundColor: '#fff',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#F79F19',
            borderRadius: 2,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#808c9a4d',
            borderRadius: 2
        }
    },
    formControlLabel: {
        marginLeft: -9,
        '& .MuiTypography-body1': {
            fontSize: 14,
            color: '#123b64',
        }
    },
    userName: {
        '& .MuiTypography-body1': {
            fontSize: 14,
            fontWeight: 700,
            color: '#123b64'
        }
    }
})

function ObjectiveList({objectiveOptions=[], checkedObjectives, setCheckedObjectives}) {
    const classes = useStyles()

    const handleChange = (objective) => {
        const isExist = checkedObjectives.find(item => item.name === objective.name)
        if(isExist) {
            const filteredValues = checkedObjectives.filter(item => item.name !== objective.name)
            setCheckedObjectives(filteredValues)
        } else {
            setCheckedObjectives([...checkedObjectives, objective])
        }
    }

    const handleSelectAll = () => {
        setCheckedObjectives(objectiveOptions)
    }

    return (
        <>
            <Box className={classes.textButtonWrapper} style={{ padding: '0 6px' }}>
                <TextButton onClick={handleSelectAll}>Select all</TextButton>
            </Box>
            <FormControl component="fieldset" className={classes.root}>
                <FormGroup>
                    {objectiveOptions && objectiveOptions.map(objective => {
                        return (
                            <FormControlLabel
                                className={classes.formControlLabel}
                                key={objective.name}
                                control={<CustomCheckbox
                                    value={objective.name}
                                    checked={checkedObjectives.findIndex(item => item.name === objective.name) > -1 }
                                    onChange={() => handleChange(objective)}
                                />}
                                label={objective.name}
                            />
                        )
                    })}
                </FormGroup>
            </FormControl>
        </>
    );
}

export default ObjectiveList;