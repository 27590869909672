import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { NodeCreationService } from "../../../services/NodeCreationService";
import YourSelectedNodes from "../../data-product-builder/your-selected-nodes/YourSelectedNodes";
import { NodeList } from "../../filter-region/node-list/NodeList";
import { FilterIcon } from "../../icons";
import {
  BackBtn,
  CircleButton,
  PrimaryButton,
} from "../buttons/custom-buttons/CustomButtons";
import ListViewTypeButton from "../buttons/list-view-button/ListViewTypeButton";
import ErorrPage from "../error/Erorr";
import SearchInput from "../inputs/SearchInput";
import Loader from "../loader/Loader";
import CustomModal from "../modal/CustomModal";
import SliderPlaceholder from "../placeholder/SliderPlaceholder";
import { CustomTooltip } from "../tooltip/NodeTooltip";
import CustomDrawer from "../drawer/CustomDrawer";
import CollectionSidebar from "../../../pages/digital-publication/CollectionSidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "calc(100vh - 150px)",
    paddingTop: 50,
    paddingBottom: 100,
  },
  tooltip: {
    fontSize: 12,
    minWidth: 108,
    textAlign: "center",
    color: "#123b64",
    padding: "7px 5px",
    borderRadius: 6,
  },
  PrimaryButton: {
    borderRadius: 8,
    fontWeight: 500,
    marginRight: 8,
  },
  nodeNumber: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 14,
    height: 14,
    background: "#fff",
    fontSize: "10px!important",
    color: "#011936",
    borderRadius: 2,
  },
  saveButton: {
    marginRight: 15,
  },
}));

const DataProductBuilder = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [searchedNodes, setSearchedNodes] = useState([]);
  const [isNodeSelected, setIsNodeSelected] = useState(false);
  const [searchNodeName, setSearchNodeName] = useState("");
  const [isListView, setIsListView] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const scrollRef = useRef(null);
  const { id } = useParams();
  const { key, nodeName } = useParams();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");


  const apiHandler = () => {
    window.open("https://pkware.alationcatalog.com/compose/", "_blank");
  };

  useEffect(() => {
    if (selectedNodes.length) {
      setIsNodeSelected(true);
    } else {
      setIsNodeSelected(false);
    }
  }, [selectedNodes]);

  useEffect(() => {
    NodeCreationService.fetchDatasetNodes(id,source)
      .then((res) => {
        setIsLoading(false);
        setNodes(res.data);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  }, []);

  useEffect(() => {
    setSearchedNodes(nodes);
  }, [nodes]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearchNodeName(value);
    const filteredNodes = nodes.filter((node) => {
      return node.displayName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchedNodes(filteredNodes);
  };

  const createRelationHandler = () => {
    if (nodes.length) {
      if (isNodeSelected) {
        const transformedData = selectedNodes.map((node) => {
          const { id, name } = node;

          return {
            id,
            name,
            displayName: name,
            type: node.typeName,
            roletype: "coRole",
            rolevalue: "is part of",
          };
        });

        NodeCreationService.createRelation(transformedData, key)
          .then(() => {
            navigate(`/curatecontext/${nodeName}/${key}`);
          })
          .catch(() => {
            console.log("error occurede in api");
          });
      } else {
        setShowPopup(true);
      }
    } else {
      navigate(`/curatecontext/${nodeName}/${key}`);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErorrPage />;
  }

  return (
    <Container ref={scrollRef} className={classes.root}>
      <CustomModal open={showPopup} setOpen={setShowPopup}>
        <YourSelectedNodes
          selectedNodes={selectedNodes}
          setSelectedNodes={setSelectedNodes}
          setShowPopup={setShowPopup}
        />
      </CustomModal>
      <Typography variant="h1" align="center" color="primary">
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
      </Typography>
      {nodes.length ? (
        <Box mt={5}>
          <Box mb={5} sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ maxWidth: 415, width: "100%" }}>
              <SearchInput
                value={searchNodeName}
                setValue={setSearchNodeName}
                handleChange={handleChange}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PrimaryButton
                onClick={() => setShowPopup(true)}
                className={classes.PrimaryButton}
                endIcon={
                  <div className={classes.nodeNumber}>
                    {selectedNodes.length}
                  </div>
                }
              >
                Selected nodes
              </PrimaryButton>
              <CustomTooltip
                arrow
                title={
                  <Typography className={classes.tooltip}>
                    Create New Dataset
                  </Typography>
                }
              >
                <CircleButton
                  onClick={apiHandler}
                  style={{ margin: "0 8px" }}
                  disableRipple
                >
                  <AddIcon />
                </CircleButton>
              </CustomTooltip>
              <CustomTooltip
                arrow
                title={
                  <Typography className={classes.tooltip}>
                    Open filter
                  </Typography>
                }
              >
                {/* <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer> */}
                <CircleButton  style={{ margin: "0 8px" }} disableRipple>
                  <FilterIcon />
                </CircleButton>
              </CustomTooltip>
              <CustomTooltip
                arrow
                title={
                  <Typography className={classes.tooltip}>Delete</Typography>
                }
              >
                <CircleButton style={{ margin: "0 8px" }} disableRipple>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 4H20V6H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V6H0V4H5V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4ZM16 6H4V18H16V6ZM7 9H9V15H7V9ZM11 9H13V15H11V9ZM7 2V4H13V2H7Z"
                      fill="#123B64"
                    />
                  </svg>
                </CircleButton>
              </CustomTooltip>
              <Box ml={4}>
                <ListViewTypeButton
                  handleListViewChange={setIsListView}
                  isListView={isListView}
                />
              </Box>
            </Box>
          </Box>
          <NodeList
            nodes={searchedNodes}
            showSaved={isListView}
            setSelectedNodes={setSelectedNodes}
            selectedNodes={selectedNodes}
            nodesPerPage={6}
            isClickable={true}
            ref={scrollRef}
          />
        </Box>
      ) : (
        <Box mt={5}>
          <SliderPlaceholder error={true} text="No data found" />
        </Box>
      )}
      <Box mt={5} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Link
          to={`/data_product_source/${nodeName}/${key}/2`}
          style={{ textDecoration: "none" }}
        >
          <BackBtn>Previous Step</BackBtn>
        </Link>
        <Box sx={{ display: "flex" }}>
          {nodes.length ? (
            <BackBtn className={classes.saveButton}>Save</BackBtn>
          ) : null}
          <BackBtn onClick={createRelationHandler}>Next</BackBtn>
        </Box>
      </Box>
    </Container>
  );
};

export default DataProductBuilder;
