import { withStyles, InputBase } from '@material-ui/core'

export const Input = withStyles((theme) => ({
  root: {
    width: '100%',
    borderRadius: '8px 8px 0 0',
    background: '#F2F3F4',
    borderBottom: '1px solid #123b64',
  },
  input: {
    borderRadius: '0 8px 8px 0',
    border: 'none',
    position: 'relative',
    width: 'auto',
    padding: '7px 12px 9px 0',
    fontSize: 14,
    lineHeight: '140%'
  },
}))(InputBase);
