import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'

export const ENCRYPTION = 'vendor'
export const TYPE = 'Type'
export const OFFERED_AS = 'Offered as'
export const FREQUENCY = 'Frequency'

export const initialValues = {
  [ENCRYPTION]: 'internal',
  [TYPE]: '',
  [OFFERED_AS]: '',
  [FREQUENCY]: '',
}

export const validationSchema = yup.object({
  [TYPE]: yup.string().required(`${TYPE} is required`),
  [FREQUENCY]: yup.string().required(`${FREQUENCY} is required`),
  [OFFERED_AS]: yup.string().required(`${OFFERED_AS} is required`),
  [ENCRYPTION]: yup.string('external').required(`${ENCRYPTION}Vendor name is required`),

});

export const getTransformedData = (data) => {
  return data.map(item => {
    return { label: item, value: item }
  })
}

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 150px)',
    paddingTop: 30,
    paddingBottom: 100
  },
  header: {
    margin: '40px 0'
  },
  paper: {
    boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
    borderRadius: 4,
    marginBottom: 40
  },
  paperTitle: {
    borderBottom: '1px solid #E0E7EC'
  },
  form: {
    maxWidth: 508
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  radioWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 20,
    "& .MuiFormControlLabel-root": {
      marginRight: 50,
    },
  },
  text: {
    paddingBottom: 10,
    lineHeight: 1
  }
}
));