import Popover from '@material-ui/core/Popover';
import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    popover: {
        '& .MuiPopover-paper': {
            borderRadius: 16
        },
        marginTop: (props) => props.bottomLeft ? 5 : 0
    }
})

const ShareTooltip = (props) => {
    const classes = useStyles({ props })
    const {
        setAnchorEl,
        anchorEl,
        children,
        iconWidth,
        popoverButton,
        bottomLeft,
        badge,
    } = props

    const handleClick = (event) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <div
                aria-describedby={id}
                type="button"
                style={badge ? { margin: '0 30px' } : { width: iconWidth }}
                onClick={handleClick}
            >
                {popoverButton}
            </div>
            <Popover
                className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={bottomLeft ? {
                    vertical: 'bottom',
                    horizontal: 'left',
                } : {
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={!bottomLeft ? {
                    vertical: 'top',
                    horizontal: 'right',
                } : {
                    vertical: 'center',
                    horizontal: "center"
                }}
            >
                {children}
            </Popover>
        </>
    );
}
export default ShareTooltip